<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/green/destination/list"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 送付先一覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item"><router-link :to="'/green/destination/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw"></i></button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw"></i></button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div v-if="!form.id" class="alert alert-info">
              本画面からの送付先作成は、送付相手のメールアドレスが明確であり、かつ先方がそのメールアドレスでの明細受領に同意している時のみご利用ください。
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="form.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
            <div v-if="!changeEmail && form.id" class="form-edit-line">
              <button @click="changeEmail = true" class="btn btn-outline-danger btn-sm">送付先のメールアドレスを変更する</button>
              <div style="font-size: 90%; margin-top: 3px; text-decoration: underline; ">原則、メールアドレスの変更は先方に実施してもらってください。先方がメールが受領できず、ログインして変更という処理が行えない場合に変更処理を実施してください。</div>
            </div>
            <div v-if="changeEmail || !form.id" class="form-edit-line">
              <input v-model="newEmail" type="email" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>敬称</div>
            <div class="form-edit-line">
              <div class="input-group" style="width: 200px;">
                <select v-model="form.honorificPosition" @change="changeHonorificPosition" class="form-select">
                  <option value="">なし</option>
                  <option value="BEFORE">前</option>
                  <option value="AFTER">後</option>
                </select>
                <select v-if="form.honorificPosition === 'BEFORE'" v-model="form.honorificTitle" class="form-select">
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="Dear">Dear</option>
                </select>
                <select v-if="form.honorificPosition === 'AFTER'" v-model="form.honorificTitle" class="form-select">
                  <option value="様">様</option>
                  <option value="殿">殿</option>
                  <option value="さん">さん</option>
                  <option value="先生">先生</option>
                  <option value="御中">御中</option>
                </select>
              </div>
            </div>
            <div class="form-label align-bottom">CC/BCC</div>
            <div class="form-edit-line">
              <button @click="openUserAdd" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(user, index) in userList" :key="index" :class="{'deleted-row': user.deleted}">
                    <td style="width: 150px;">{{user.name}}</td>
                    <td>
                      <p v-for="email in user.emailList" :key="email" style="margin: 0;">{{email}}</p>
                      <p v-if="!user.emailList || user.emailList.length === 0" style="margin: 0;">メールアドレス非公開</p>
                    </td>
                    <td style="width: 130px;" class="text-end">
                      <select v-model="user.ccOrBcc" class="form-select" style="width: 120px;">
                        <option value="CC">CC</option>
                        <option value="BCC">BCC</option>
                      </select>
                    </td>
                    <td style="width: 50px;" class="text-center">
                      <button @click="deleteUser(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="session.session && session.session.greenCapo" class="form-label align-bottom">capo権利者</div>
            <div v-if="session.session && session.session.greenCapo" class="form-edit-line">
              <button @click="openRightholderAdd('capo')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(rightholder, index) in capoRightholderList" :key="index" :class="{'deleted-row': rightholder.deleted}">
                    <td style="width: 150px;">{{rightholder.code}}</td>
                    <td>
                      {{rightholder.name}}<br>
                      <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                    </td>
                    <td style="width: 50px;" class="text-center">
                      <button @click="deleteRightholder('capo', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="session.session && session.session.greenJrca" class="form-label align-bottom">capo(JRCA)権利者</div>
            <div v-if="session.session && session.session.greenJrca" class="form-edit-line">
              <button @click="openRightholderAdd('jrca')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(rightholder, index) in jrcaRightholderList" :key="index" :class="{'deleted-row': rightholder.deleted}">
                    <td style="width: 150px;">{{rightholder.code}}</td>
                    <td>
                      {{rightholder.name}}<br>
                      <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                    </td>
                    <td style="width: 50px;" class="text-center">
                      <button @click="deleteRightholder('jrca', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="session.session && session.session.greenContano" class="form-label align-bottom">contano権利者</div>
            <div v-if="session.session && session.session.greenContano" class="form-edit-line">
              <button @click="openRightholderAdd('contano')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(rightholder, index) in contanoRightholderList" :key="index" :class="{'deleted-row': rightholder.deleted}">
                    <td style="width: 150px;">{{rightholder.code}}</td>
                    <td>
                      {{rightholder.name}}<br>
                      <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                    </td>
                    <td style="width: 50px;" class="text-center">
                      <button @click="deleteRightholder('contano', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="session.session && session.session.red" class="form-label align-bottom">RED権利者</div>
            <div v-if="session.session && session.session.red" class="form-edit-line">
              <button @click="openRightholderAdd('red')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(rightholder, index) in redRightholderList" :key="index" :class="{'deleted-row': rightholder.deleted}">
                  <td style="width: 150px;">{{rightholder.code}}</td>
                  <td>
                    {{rightholder.name}}<br>
                    <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                  </td>
                  <td style="width: 50px;" class="text-center">
                    <button @click="deleteRightholder('red', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">メモ</div>
            <div class="form-edit-line">
              <textarea v-model="form.memo" class="form-control" rows="5"></textarea>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="destinationDeleteModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">送付先の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">以下の送付先を削除しようとしています。削除すると、送付先情報の確認ができなくなるだけでなく、過去の送付実績にもアクセスできなくなります。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{form.name}}
            </div>
            <div class="form-label align-bottom">メールアドレス</div>
            <div class="form-edit-line">
              {{email}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="deleteDestination" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="userAddModalDiv">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ユーザーの追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="userName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
            <div class="form-edit-line">
              <input v-model="userEmail" type="email" class="form-control">
            </div>
            <div class="form-label align-bottom">CC/BCC</div>
            <div class="form-edit-line">
              <select v-model="userCcOrBcc" class="form-select" style="width: 120px;">
                <option value="CC">CC</option>
                <option value="BCC">BCC</option>
              </select>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="addUser" type="button" class="btn btn-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rightholderAddModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">権利者の追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-edit-line">
              <select v-if="companySystem === 'capo'" v-model="rightholderCapoCompanyId" @change="rightsHolderCondition.page; findRightholder('capo')" class="form-select">
                <option v-for="company in capoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
              </select>
              <select v-if="companySystem === 'jrca'" v-model="rightholderJrcaCompanyId" @change="rightsHolderCondition.page; findRightholder('jrca')" class="form-select">
                <option v-for="company in jrcaCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
              </select>
              <select v-if="companySystem === 'contano'" v-model="rightholderContanoCompanyId" @change="rightsHolderCondition.page; findRightholder('contano')" class="form-select">
                <option v-for="company in contanoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
              </select>
              <select v-if="companySystem === 'red'" v-model="rightholderRedCompanyId" @change="rightsHolderCondition.page; findRightholder('red')" class="form-select">
                <option v-for="company in redCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
              </select>
            </div>
            <div class="input-group">
              <input v-model="rightsHolderCondition.searchString" type="text" class="form-control" placeholder="絞り込み条件">
              <select v-model="rightholderDestinationNotCreated" @change="rightsHolderCondition.page; findRightholder(companySystem)" class="form-select">
                <option value="false">全て</option>
                <option value="true">送付先未作成権利者のみ</option>
              </select>
              <button @click="rightsHolderCondition.page; findRightholder(companySystem)" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="rightholderList.length > 0" class="table table-responsive table-hover">
              <tbody>
                <tr v-for="rightholder in rightholderList" :key="rightholder.id">
                  <td>
                    {{rightholder.name}}<br>
                  </td>
                  <td class="text-end" style="width: 60px;">
                    <button @click="addRightholder(rightholder.id)" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <nav v-if="rightholderResult && rightholderList.length > 0" aria-label="Page navigation">
                <ul class="pagination">
                  <li v-if="rightsHolderCondition.page !== 0" class="page-item"><button @click="rightholderFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                  <li v-if="rightsHolderCondition.page !== 0" class="page-item"><button @click="rightholderPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                  <li class="page-item"><span class="page-link">{{rightsHolderCondition.page + 1}}/{{rightholderResult.allPages}}ページ</span></li>
                  <li v-if="rightsHolderCondition.page !== rightholderResult.allPages - 1" class="page-item"><button @click="rightholderNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                  <li v-if="rightsHolderCondition.page !== rightholderResult.allPages - 1" class="page-item"><button @click="rightholderLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { useRoute, useRouter } from 'vue-router'
import { destinationFunction } from './function'
import { rightsHolderFunction } from '@/views/red/rightsholder/function'
import { rightsHolderStore } from './store'
import { redCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const errors = ref([])
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const errorToastDiv = ref(null)
    const destinationDeleteModalDiv = ref(null)
    const userAddModalDiv = ref(null)
    const rightholderAddModalDiv = ref(null)
    let processModal = null
    let errorToast = null
    let destinationDeleteModal = null
    let userAddModal = null
    let rightholderAddModal = null
    const showDeleteButton = ref(false)
    // Form
    const destinationFunc = destinationFunction()
    const form = ref(null)
    const email = ref('')
    const changeEmail = ref(false)
    const newEmail = ref('')
    // ユーザー追加用
    const userList = ref([])
    const userName = ref('')
    const userEmail = ref('')
    const userCcOrBcc = ref('CC')
    // 権利者追加用
    const redCompanyFunc = redCompanyFunction()
    const rightsHolderFunc = rightsHolderFunction()
    const rightsHolderCondition = rightsHolderStore()
    const companySystem = ref('')
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    const redCompanyList = ref([])
    const rightholderCapoCompanyId = ref(null)
    const rightholderJrcaCompanyId = ref(null)
    const rightholderContanoCompanyId = ref(null)
    const rightholderRedCompanyId = ref(null)
    const rightholderDestinationNotCreated = ref(true)
    const rightholderList = ref([])
    const rightholderResult = ref(null)
    const capoRightholderList = ref([])
    const jrcaRightholderList = ref([])
    const contanoRightholderList = ref([])
    const redRightholderList = ref([])
    // メソッド
    const initAdd = () => {
      form.value = {
        id: null,
        name: '',
        honorificPosition: '',
        memo: ''
      }
      email.value = ''
      userList.value = []
      capoRightholderList.value = []
      jrcaRightholderList.value = []
      contanoRightholderList.value = []
    }
    const openDestinationDelete = () => {
      destinationDeleteModal.show()
    }
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (changeEmail.value && !newEmail.value) {
        errors.value.push('メールアドレスを入力してください')
      } else if (changeEmail.value) {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(newEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        try {
          processMessage.value = '保存処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
          processModal.show()
          const data = {
            name: form.value.name,
            email: newEmail.value,
            honorificTitle: form.value.honorificTitle,
            honorificPosition: form.value.honorificPosition,
            memo: form.value.memo
          }
          if (!form.value.id) {
            const response = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination', data, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
            form.value.id = response.data
          } else {
            await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id, data, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
          }
          for (const user of userList.value) {
            const data = {
              name: user.name,
              ccOrBcc: user.ccOrBcc,
              email: user.email
            }
            if (user.id) {
              if (user.deleted) {
                await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/cc/' + user.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              } else {
                await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/cc/' + user.id, data, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              }
            } else {
              await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/cc', data, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).catch(error => { ex.identifyEx(error) })
            }
          }
          for (const rightholder of capoRightholderList.value) {
            if (rightholder.id) {
              if (rightholder.deleted) {
                await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/capo/' + rightholder.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              }
            } else {
              await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/capo', {
                companyId: rightholder.companyId,
                rightholderId: rightholder.rightholderId
              }, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).catch(error => { ex.identifyEx(error) })
            }
          }
          for (const rightholder of jrcaRightholderList.value) {
            if (rightholder.id) {
              if (rightholder.deleted) {
                await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/jrca/' + rightholder.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              }
            } else {
              await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/jrca', {
                companyId: rightholder.companyId,
                rightholderId: rightholder.rightholderId
              }, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).catch(error => { ex.identifyEx(error) })
            }
          }
          for (const rightholder of contanoRightholderList.value) {
            if (rightholder.id) {
              if (rightholder.deleted) {
                await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/contano/' + rightholder.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              }
            } else {
              await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/contano', {
                companyId: rightholder.companyId,
                rightholderId: rightholder.rightholderId
              }, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).catch(error => { ex.identifyEx(error) })
            }
          }
          for (const rightholder of redRightholderList.value) {
            if (rightholder.id) {
              if (rightholder.deleted) {
                await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/red/' + rightholder.rightsHolderId, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).catch(error => { ex.identifyEx(error) })
              }
            } else {
              await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id + '/red', {
                rightsHolderId: rightholder.rightholderId
              }, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).catch(error => { ex.identifyEx(error) })
            }
          }
          processModal.hide()
          await router.push('/green/destination/disp/' + form.value.id)
        } catch (error) {
          if (error.response?.status === 409) {
            processModal.hide()
            errors.value.push('入力されたメールアドレスは既に別の送付先に使用されています')
            errorToast.show()
          } else {
            await ex.identifyEx(error)
          }
        }
      }
    }
    const openDeleteModal = () => {
      destinationDeleteModal.show()
    }
    const deleteDestination = async () => {
      errors.value = []
      await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(() => {
        destinationDeleteModal.hide()
      }).catch(error => {
        if (error.response?.status === 490) {
          errors.value.push('明細送付済の送付先のため削除できません')
          errorToast.show()
        } else {
          ex.identifyEx(error)
        }
      })
    }
    const changeHonorificPosition = () => {
      if (form.value.honorificPosition === 'BEFORE') {
        form.value.honorificTitle = 'Mr.'
      } else {
        form.value.honorificTitle = '様'
      }
    }
    const openUserAdd = () => {
      userName.value = ''
      userEmail.value = ''
      userAddModal.show()
    }
    const addUser = () => {
      errors.value = []
      if (!userName.value) {
        errors.value.push('名前を入力してください')
      }
      if (!userEmail.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(userEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (userList.value.findIndex(e => e.email === userEmail.value) > -1) {
        errors.value.push('同じメールアドレスが追加済みです')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        userList.value.push({
          name: userName.value,
          email: userEmail.value,
          emailList: [userEmail.value],
          ccOrBcc: userCcOrBcc.value,
          deleted: false
        })
        userAddModal.hide()
      }
    }
    const deleteUser = (index) => {
      if (userList.value[index].accountId) {
        userList.value[index].deleted = !userList.value[index].deleted
      } else {
        userList.value.splice(index, 1)
      }
    }
    const openRightholderAdd = (system) => {
      companySystem.value = system
      rightsHolderCondition.page = 0
      if (system === 'red') {
        axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          redCompanyList.value = response.data
          if (redCompanyList.value.length > 0) {
            rightholderRedCompanyId.value = redCompanyList.value[0].id
          }
        })
      } else {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/contract/' + companySystem.value + '/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          capoCompanyList.value = []
          jrcaCompanyList.value = []
          contanoCompanyList.value = []
          rightholderCapoCompanyId.value = null
          rightholderJrcaCompanyId.value = null
          rightholderContanoCompanyId.value = null
          rightsHolderCondition.searchString = ''
          rightholderDestinationNotCreated.value = true
          rightholderResult.value = null
          rightholderList.value = []
          for (const company of response.data) {
            axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + companySystem.value + '/company/' + company.companyId, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              const obj = {
                id: response.data.id,
                name: response.data.name
              }
              switch (companySystem.value) {
                case 'capo':
                  if (!rightholderCapoCompanyId.value) {
                    rightholderCapoCompanyId.value = obj.id
                    findRightholder('capo')
                  }
                  capoCompanyList.value.push(obj)
                  break
                case 'jrca':
                  if (!rightholderJrcaCompanyId.value) {
                    rightholderJrcaCompanyId.value = obj.id
                  }
                  jrcaCompanyList.value.push(obj)
                  break
                case 'contano':
                  if (!rightholderContanoCompanyId.value) {
                    rightholderContanoCompanyId.value = obj.id
                  }
                  contanoCompanyList.value.push(obj)
                  break
                default: break
              }
            })
          }
        }).catch(error => { ex.identifyEx(error) })
      }
      rightholderAddModal.show()
    }
    const findRightholder = async (system) => {
      let companyId
      if (system === 'red') {
        rightholderResult.value = await rightsHolderFunc.find(session.session.company.id, rightsHolderCondition)
        rightholderList.value = rightholderResult.value.list
      } else {
        switch (system) {
          case 'capo':
            companyId = rightholderCapoCompanyId.value
            break
          case 'jrca':
            companyId = rightholderJrcaCompanyId.value
            break
          case 'contano':
            companyId = rightholderContanoCompanyId.value
            break
          default: break
        }
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            companyId: companyId,
            searchString: rightsHolderCondition.searchString,
            useOnlineStatement: true,
            destinationNotCreated: rightholderDestinationNotCreated.value,
            sortColumn: 'name',
            sortOrder: 'asc',
            page: rightsHolderCondition.page,
            perPage: 20
          }
        }).then(response => {
          rightholderResult.value = response.data
          rightholderList.value = rightholderResult.value.list
        }).catch(error => { ex.identifyEx(error) })
      }
    }
    const rightholderClearSearch = () => {
      rightsHolderCondition.searchString = ''
      rightsHolderCondition.page = 0
      findRightholder(companySystem.value)
    }
    const rightholderFirstPage = () => {
      rightsHolderCondition.page = 0
      findRightholder(companySystem.value)
    }
    const rightholderPrevPage = () => {
      rightsHolderCondition.page = rightsHolderCondition.page - 1
      findRightholder(companySystem.value)
    }
    const rightholderNextPage = () => {
      rightsHolderCondition.page = rightsHolderCondition.page + 1
      findRightholder(companySystem.value)
    }
    const rightholderLastPage = () => {
      rightsHolderCondition.page = rightholderResult.value.allPages - 1
      findRightholder(companySystem.value)
    }
    const addRightholder = async (rightholderId) => {
      let company
      let rightholder
      switch (companySystem.value) {
        case 'capo':
          company = await getCompany('capo', rightholderCapoCompanyId.value)
          rightholder = await getRightholder('capo', rightholderId)
          if (!capoRightholderList.value.some(r => r.rightholderId === rightholderId)) {
            capoRightholderList.value.push({
              companyId: rightholderCapoCompanyId.value,
              rightholderId: rightholderId,
              code: rightholder.code,
              name: rightholder.name,
              companyName: company.name
            })
          }
          break
        case 'jrca':
          company = await getCompany('jrca', rightholderJrcaCompanyId.value)
          rightholder = await getRightholder('jrca', rightholderId)
          if (!jrcaRightholderList.value.some(r => r.rightholderId === rightholderId)) {
            jrcaRightholderList.value.push({
              companyId: rightholderJrcaCompanyId.value,
              rightholderId: rightholderId,
              code: rightholder.code,
              name: rightholder.name,
              companyName: company.name
            })
          }
          break
        case 'contano':
          company = await getCompany('contano', rightholderContanoCompanyId.value)
          rightholder = await getRightholder('contano', rightholderId)
          if (!contanoRightholderList.value.some(r => r.rightholderId === rightholderId)) {
            contanoRightholderList.value.push({
              companyId: rightholderContanoCompanyId.value,
              rightholderId: rightholderId,
              code: rightholder.code,
              name: rightholder.name,
              companyName: company.name
            })
          }
          break
        case 'red': {
          const company = await redCompanyFunc.get(rightholderRedCompanyId.value)
          const rightholder = await rightsHolderFunc.get(rightholderId)
          console.log(rightholder)
          if (!redRightholderList.value.some(r => r.rightholderId === rightholderId)) {
            redRightholderList.value.push({
              companyId: rightholderRedCompanyId.value,
              rightholderId: rightholderId,
              code: rightholder.code,
              name: rightholder.name,
              companyName: company.name
            })
          }
          break
        }
        default: break
      }
    }
    const deleteRightholder = (system, index) => {
      switch (system) {
        case 'capo':
          if (capoRightholderList.value[index].id) {
            capoRightholderList.value[index].deleted = !capoRightholderList.value[index].deleted
          } else {
            capoRightholderList.value.splice(index, 1)
          }
          break
        case 'jrca':
          if (jrcaRightholderList.value[index].id) {
            jrcaRightholderList.value[index].deleted = !jrcaRightholderList.value[index].deleted
          } else {
            jrcaRightholderList.value.splice(index, 1)
          }
          break
        case 'contano':
          if (contanoRightholderList.value[index].id) {
            contanoRightholderList.value[index].deleted = !contanoRightholderList.value[index].deleted
          } else {
            contanoRightholderList.value.splice(index, 1)
          }
          break
        case 'red':
          if (redRightholderList.value[index].id) {
            redRightholderList.value[index].deleted = !redRightholderList.value[index].deleted
          } else {
            redRightholderList.value.splice(index, 1)
          }
          break
        default: break
      }
    }
    async function getRightholder (system, id) {
      const response = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).catch(error => { ex.identifyEx(error) })
      return response.data
    }
    function getRightholderAsync (system, id, callback) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        callback(response.data)
      }).catch(error => { ex.identifyEx(error) })
    }
    async function getCompany (system, id) {
      if (system === 'red') {
        const response = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        console.log(response.data)
        return response.data
      } else {
        const response = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + id, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).catch(error => { ex.identifyEx(error) })
        return response.data
      }
    }
    function getCompanyAsync (system, id, callback) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        callback(response.data)
      }).catch(error => { ex.identifyEx(error) })
    }
    const initEdit = async (id) => {
      form.value = await destinationFunc.get(id)
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + form.value.accountId, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        email.value = response.data.email
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/cc/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        userList.value = response.data
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/capo/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        capoRightholderList.value = response.data
        for (const rightholder of capoRightholderList.value) {
          getRightholderAsync('capo', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('capo', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/jrca/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        jrcaRightholderList.value = response.data
        for (const rightholder of jrcaRightholderList.value) {
          getRightholderAsync('jrca', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('jrca', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/contano/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        contanoRightholderList.value = response.data
        for (const rightholder of contanoRightholderList.value) {
          getRightholderAsync('contano', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('contano', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/red/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(async response => {
        redRightholderList.value = response.data
        for (const rightholder of redRightholderList.value) {
          const rightsHolder = await rightsHolderFunc.get(rightholder.rightsHolderId)
          rightholder.code = rightsHolder.code
          rightholder.name = rightsHolder.name
          const company = await redCompanyFunc.get(rightsHolder.companyId)
          rightholder.companyName = company.name
        }
      }).catch(error => { ex.identifyEx(error) })
    }
    onMounted(async () => {
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        session.value = response.data
      }).catch(error => { ex.identifyEx(error) })
      errorToast = new Toast(errorToastDiv.value, {})
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      destinationDeleteModal = new Modal(destinationDeleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      userAddModal = new Modal(userAddModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rightholderAddModal = new Modal(rightholderAddModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      if (route.path.includes('/add')) {
        await initAdd()
      } else {
        await initEdit(route.params.id)
      }
      loadingModal.hide()
    })
    return {
      loadingModalDiv,
      session,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      destinationDeleteModalDiv,
      userAddModalDiv,
      rightholderAddModalDiv,
      showDeleteButton,
      form,
      email,
      userList,
      userName,
      userEmail,
      userCcOrBcc,
      companySystem,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      rightholderCapoCompanyId,
      rightholderJrcaCompanyId,
      rightholderContanoCompanyId,
      rightholderDestinationNotCreated,
      rightholderResult,
      rightholderList,
      capoRightholderList,
      jrcaRightholderList,
      contanoRightholderList,
      redCompanyList,
      rightholderRedCompanyId,
      redRightholderList,
      openDestinationDelete,
      openUserAdd,
      addUser,
      deleteUser,
      openRightholderAdd,
      findRightholder,
      rightholderClearSearch,
      rightholderFirstPage,
      rightholderPrevPage,
      rightholderNextPage,
      rightholderLastPage,
      addRightholder,
      deleteRightholder,
      save,
      deleteDestination,
      changeHonorificPosition,
      openDeleteModal,
      changeEmail,
      newEmail,
      rightsHolderCondition
    }
  }
})
</script>
