import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usageStore = defineStore('usage', () => {
  const searchString = ref('')
  const status = ref('all')
  const page = ref(0)
  return { searchString, page, status }
})

export const dataStore = defineStore('usage_data', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const uploadedFileStore = defineStore('usage_uploaded_file', () => {
  const divYear = ref(0)
  const divTerm = ref(0)
  return { divYear, divTerm }
})

export const albumStore = defineStore('usage_album', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const masterStore = defineStore('usage_master', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
