<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>システム運営状況等のお知らせメールの受け取りを停止する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>初期状態では、本システムからのお知らせ（メンテナンス情報やバージョンアップ情報等）を受け取る設定になっています。不要な場合にはこれを停止することができます。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">
          <router-link to="/help/myaccount/edit">2-5</router-link>の手順に従い、自分の情報を変更する画面を表示します。
        </li>
        <li style="margin-top: 20px;">
          お知らせメール欄の「受け取る」をクリック／タップして「受け取らない」に変更します。<br>
          <img src="../../assets/help/account_16.png" class="responsive-img" style="border: 1px solid #cccccc;"><br><br>
          <img src="../../assets/help/account_17.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
        <li style="margin-top: 20px;">
          画面右下の保存ボタンをクリック／タップして保存します。<br>
          <img src="../../assets/help/account_05.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
