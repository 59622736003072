<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/common/bank/list"><i class="fas fa-building-columns fa-fw" style="margin-right: 5px;"></i> 銀行一覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item"><router-link :to="'/common/bank/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>コード</div>
        <div class="form-edit-line">
          <input v-model="form.code" type="text" class="form-control" style="width: 80px;" maxlength="4">
        </div>
        <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
        <div class="form-edit-line">
          <input v-model="form.name" type="text" class="form-control" style="width: 200px;">
        </div>
        <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>カナ</div>
        <div class="form-edit-line">
          <input v-model="form.kana" type="text" class="form-control" maxlength="15" style="width: 200px;">
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">銀行の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">以下の銀行を削除しようとしています。削除すると、銀行情報の確認ができなくなるだけでなく、該当銀行が紐づいている権利者の銀行情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{form.name}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { bankFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const bankFunc = bankFunction()
    const form = ref(null)
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.code) {
        errors.value.push('コードを入力してください')
      }
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!form.value.kana) {
        errors.value.push('カナを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!form.value.id) {
          form.value.id = await bankFunc.insert(form.value)
        } else {
          await bankFunc.update(form.value.id, form.value)
        }
        bankFunc.indexUpdate(form.value.id)
        await router.push('/common/bank/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await bankFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/common/bank/list')
    }
    async function init (id) {
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          code: '',
          name: '',
          kana: ''
        }
      } else {
        form.value = await bankFunc.get(id)
      }
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      errors,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      save,
      openDeleteModal,
      executeDelete
    }
  }
})
</script>
