<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>明細の詳細を確認する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>受領明細一覧画面から「税別内訳」「楽曲別明細（著作権印税の場合）」「集計明細（原盤印税の場合）」の各種ボタンをクリック／タップすることで、各種詳細情報を閲覧する画面に移動することができます。</p>
      <p>以下、詳細な説明です。</p>
      <ol>
        <li style="margin-top: 20px;">税別内訳
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              受領明細画面の「税別内訳」ボタンをクリック／タップで税別内訳画面に移動します。<br>
              <img src="../../assets/help/header_05.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              この画面では、消費税率別の支払金額を確認することができます。<br>
              <img src="../../assets/help/tax.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">楽曲別明細（著作権印税の場合のみ）
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              受領明細画面の「楽曲別明細」ボタンをクリック／タップで楽曲別詳細画面に移動します。<br>
              <img src="../../assets/help/header_06.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              この画面では、楽曲別の支払金額を確認することができます。<br>
              <img src="../../assets/help/music_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              「絞り込み条件」に検索条件を入力し、Enterキーを押すまたは右側の抽出マークをクリック／タップすると入力した条件で絞り込んで確認が行えます。<br>
              検索対象：楽曲名、アーティスト名、JASRACコード、NexToneコード<br>
              <img src="../../assets/help/music_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">集計明細（原盤印税の場合のみ）
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              受領明細画面の「集計明細」ボタンをクリック／タップで製品・原盤別詳細画面に移動します。<br>
              <img src="../../assets/help/header_08.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              この画面では、製品・原盤別の支払金額を確認することができます。<br>
              <img src="../../assets/help/summary_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              「絞り込み条件」に検索条件を入力し、Enterキーを押すまたは右側の抽出マークをクリック／タップすると入力した条件で絞り込んで確認が行えます。<br>
              検索対象：製品名、原盤名、製品番号、ISRC<br>
              <img src="../../assets/help/summary_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
