import AdminContractDisp from './Disp'
import AdminContractEdit from './Edit'
import AdminContractList from './List'

export default {
  routes: [
    {
      path: '/admin/contract/list',
      name: 'AdminContractList',
      component: AdminContractList
    },
    {
      path: '/admin/contract/disp/:id',
      name: 'AdminContractDisp',
      component: AdminContractDisp
    },
    {
      path: '/admin/contract/add',
      name: 'AdminContractAdd',
      component: AdminContractEdit
    },
    {
      path: '/admin/contract/edit/:id',
      name: 'AdminContractEdit',
      component: AdminContractEdit
    }
  ]
}
