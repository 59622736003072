import { defineStore } from 'pinia'
import { ref } from 'vue'

export const albumStore = defineStore('album', () => {
  const searchString = ref('')
  const page = ref(0)
  const detailSearchMode = ref(false)
  const detailCondition = ref({
    searchString: null,
    artistName: null,
    makerName: null,
    code: null,
    catalogNo: null,
    janCode: null,
    userCode: null,
    suspension: 'all',
    suspensionStatus: 'all',
    sortColumn1: 'name',
    sortOrder1: 'asc',
    sortColumn2: 'kana',
    sortOrder2: 'asc'
  })
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder, detailCondition, detailSearchMode }
})

export const contractStore = defineStore('album_contract', () => {
  const searchString = ref('')
  return { searchString }
})

export const artistStore = defineStore('album_artist', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const projectStore = defineStore('album_project', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const userStore = defineStore('album_user', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const masterStore = defineStore('contract_master', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder }
})
