import axios from 'axios'
import { sessionStore } from './sessionStore'

export const authFunction = () => {
  const session = sessionStore()
  const login = async (email, authCode, persist) => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/' + email + '/' + authCode)
    if (!session.session) {
      session.session = {}
    }
    session.session.token = data.toString()
    if (persist === true || persist === 'true') {
      localStorage.setItem('access_token', session.session.token)
    } else {
      sessionStorage.setItem('access_token', session.session.token)
    }
    session.session = await getSession()
  }
  const getAccessToken = () => {
    if (localStorage.getItem('access_token')) {
      session.session.token = localStorage.getItem('access_token')
    }
    if (sessionStorage.getItem('access_token')) {
      session.session.token = sessionStorage.getItem('access_token')
    }
    if (session.session && session.session.token) {
      return session.session.token
    } else {
      return null
    }
  }
  const getSession = async () => {
    const token = getAccessToken()
    if (!token) {
      return null
    }
    if (session.session && session.session.loggedIn) {
      return session.session
    }
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
      headers: { Authorization: 'Bearer ' + getAccessToken() }
    })
    if (!data) {
      return {}
    }
    session.session = data
    session.session.token = token
    if (data.red || data.blue || data.pink) {
      if (!session.session.company || !session.session.company.id) {
        const companyList = await getCompanyInuseList()
        if (companyList && companyList.length > 0) {
          data.company = {}
          data.company.id = companyList[0].id
        }
      }
    }
    data.loggedIn = true
    return data
  }
  const getCompanyInuseList = async () => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list/inuse', {
      headers: { Authorization: 'Bearer ' + getAccessToken() }
    }).catch(error => {
      console.error(error)
    })
    return data
  }
  return {
    login,
    getAccessToken,
    getSession
  }
}
