<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-sitemap fa-fw" style="margin-right: 5px;"></i> 通貨一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/common/currency/edit" class="btn btn-outline-primary"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input v-model="store.year" @change="find" type="number" class="form-control" style="flex: 0 1 90px;">
            <span class="input-group-text">年</span>
            <input v-model="store.month" @change="find" type="number" class="form-control" style="flex: 0 1 70px;" min="1" max="12">
            <span class="input-group-text">月</span>
            <button @click="find" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </div>
      <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 60px; overflow: auto;">
        <div class="container" style="max-width: 800px;">
          <table v-if="currencyList && currencyList.length > 0" class="table table-responsive table-hover">
            <thead>
            <tr>
              <th>通貨</th>
              <th style="width: 100px;">略称</th>
              <th style="width: 150px;">レート</th>
              <th style="width: 100px;">規定値</th>
            </tr>
            </thead>
            <tbody v-if="currencyList">
            <tr v-for="currency in currencyList" :key="currency.id" class="text-start">
              <td>{{ currency.name }}</td>
              <td>{{ currency.mark }}</td>
              <td class="text-end">{{ currency.rate }}円</td>
              <td class="text-center">
                <span v-if="currency.defaultCurrency" class="text-primary">●</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { currencyStore } from './store'
import { currencyFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const store = currencyStore()
    const currencyFunc = currencyFunction()
    const currencyList = ref([])
    // メソッド
    const find = async () => {
      currencyList.value = await currencyFunc.find(store)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    onMounted(async () => {
      if (!store.year) {
        store.year = new Date().getFullYear()
      }
      if (!store.month) {
        store.month = new Date().getMonth() + 1
      }
      session.session = await auth.getSession()
      await find()
    })
    return {
      store,
      currencyList,
      find
    }
  }
})
</script>
