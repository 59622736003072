<template>
  <footer v-if="companyId" class="fixed-bottom">
    <div v-if="companyList" class="input-group">
      <select v-model="companyId" @change="selectCompany" class="form-select">
        <option v-for="company in companyList" :key="company.id" :value="company.id">
          {{ company.nameDp }}
        </option>
      </select>
      <span v-if="session.session && session.session.company && session.session.company.red" class="input-group-text">
        RED : {{session.session.company.red.divYear}}年{{session.session.company.red.divTerm}}期
      </span>
    </div>
  </footer>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const companyId = ref(null)
    const companyList = ref(null)
    const selectCompany = () => {
      changeCompany()
      router.push('/portal')
    }
    const changeCompany = () => {
      session.session.company = {}
      session.session.company.id = companyId.value
      axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId.value + '/divterm/current', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        const model = response.data
        session.session.company.red = {}
        session.session.company.red.divYear = model.divYear
        session.session.company.red.divTerm = model.divTerm
      })
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      if (!session.session) {
        await router.push('/login')
        return
      }
      if (session.session.hasAdminRole !== 'YES') {
        axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list/inuse', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          companyList.value = response.data
          if (!companyId.value && session.session.company) {
            companyId.value = session.session.company.id
            changeCompany()
          }
        })
      }
    })
    return {
      route,
      session,
      companyId,
      companyList,
      changeCompany,
      selectCompany
    }
  }
})
</script>
