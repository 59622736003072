<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 送付先一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="canAdd" to="/green/destination/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
              <button v-else class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>登録上限</button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="destinationResult && destinationResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th>名前</th>
                <th class="d-none d-md-block">メールアドレス</th>
              </tr>
            </thead>
            <tbody v-if="destinationResult">
              <tr v-for="destination in destinationResult.list" :key="destination.id" @click="router.push('/green/destination/disp/' + destination.id)" class="text-start" style="cursor: pointer;">
                <td>{{destination.name}}</td>
                <td class="d-none d-md-table-cell">
                  <p v-for="email in destination.emailList" :key="email" style="padding: 0; margin: 0;">{{email}}</p>
                  <span v-if="!destination.emailList || destination.emailList.length === 0">非公開</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="destinationResult && destinationResult.allRecords > 0">{{destinationResult.allRecords}}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="destinationResult && destinationResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="destinationResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{store.page + 1}}/{{destinationResult.allPages}}ページ</span></li>
                <li v-if="store.page !== destinationResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== destinationResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { destinationFunction } from './function'
import { destinationStore } from './store'
import { accountFunction } from '@/functions/common'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const canAdd = ref(false)
    // 検索
    const accountFunc = accountFunction()
    const destinationFunc = destinationFunction()
    const store = destinationStore()
    const destinationResult = ref(null)
    // メソッド
    const find = async () => {
      destinationResult.value = await destinationFunc.find(store)
      for (const destination of destinationResult.value.list) {
        const account = await accountFunc.getByDestination(destination.id)
        destination.emailList = account.emailList
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const clearSearch = () => {
      destinationFunc.clearSearch(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = destinationResult.value.allPages - 1
      find()
    }
    async function canAddDestination () {
      if (!session.session.planGreen) {
        return false
      }
      const plan = session.session.planGreen
      const capo = session.session.greenCapo
      const jrca = session.session.greenJrca
      const contano = session.session.greenContano
      const red = session.session.greenRed
      let contractMode = 'SINGLE'
      if ((capo || jrca) && contano) {
        contractMode = 'DOUBLE'
      }
      let maxCount = 0
      if (!(!capo && !jrca && !contano && !red)) {
        switch (plan) {
          case 'FREE':
            if (contractMode === 'SINGLE') {
              maxCount = 99
            } else {
              maxCount = 0
            }
            break
          case 'BRONZE':
            if (contractMode === 'SINGLE') {
              maxCount = 999
            } else {
              maxCount = 99
            }
            break
          case 'SILVER':
            if (contractMode === 'SINGLE') {
              maxCount = 2999
            } else {
              maxCount = 999
            }
            break
          case 'GOLD':
            maxCount = 99999
            break
          default:
            maxCount = 0
        }
      }
      const destinationCount = await destinationFunc.count()
      return (destinationCount < maxCount)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      canAdd.value = await canAddDestination()
      await find()
    })
    return {
      router,
      session,
      canAdd,
      destinationResult,
      store,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
