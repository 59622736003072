<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 17px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-history fa-fw" style="margin-right: 5px;"></i> 送信履歴</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="page = 0; find();" v-model="searchString" type="text" class="form-control" placeholder="検索条件">
            <span class="input-group-text">ステータス</span>
            <select v-model="searchStatus" @change="page = 0; find();" class="form-select" style="flex: 0 1 120px;">
              <option value="ALL">全て</option>
              <option value="UNREAD">未読のみ</option>
              <option value="READ">既読のみ</option>
            </select>
            <span class="input-group-text">種類</span>
            <select v-model="searchType" @change="page = 0; find();" class="form-select" style="flex: 0 1 160px;">
              <option value="ALL">全て</option>
              <option value="copyright">著作権のみ</option>
              <option value="master">原盤のみ</option>
              <option value="external">メッセージのみ</option>
            </select>
            <button v-show="searchString" @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
      </div>
      <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
        <table v-if="receiptResult && receiptResult.allRecords > 0" class="table table-responsive">
          <thead>
            <tr>
              <th style="width: 60px;"></th>
              <th class="d-lg-none" style="width: 110px;">送信日時</th>
              <th class="d-none d-lg-table-cell" style="width: 180px;">送信日時</th>
              <th class="d-none d-lg-table-cell">送付先</th>
              <th class="d-none d-md-table-cell" style="width: 110px;">種別</th>
              <th>明細発行元</th>
            </tr>
          </thead>
          <tbody v-if="receiptResult">
            <tr v-for="(receipt, index) in receiptList" :key="receipt.id" class="text-start">
              <td>
                <span v-if="receipt.status === 'UNREAD'" class="badge bg-danger">未読</span>
                <span v-if="receipt.status === 'READ'" class="badge bg-dark">既読</span>
                <span v-if="receipt.toCcBcc !== 'TO'" class="badge bg-dark">{{receipt.toCcBcc}}</span>
              </td>
              <td>
                <a v-if="receipt.type !== 'external'" href="#" @click.prevent.stop="openHeaderModal(index)">{{receipt.createdAt}}</a>
                <a v-else href="#" @click.prevent.stop="openExternalModal(index)">{{receipt.createdAt}}</a>
              </td>
              <td class="d-none d-lg-table-cell">
                <p style="margin: 0;">
                  {{receipt.destinationName}}
                </p>
                <p v-for="email in receipt.emailList" :key="email" style="padding: 0; margin: 0; font-size: 90%;">{{email}}</p>
                <span v-if="!receipt.emailList || receipt.emailList.length === 0">非公開</span>
              </td>
              <td class="d-none d-md-table-cell">
                <p style="margin: 0;">
                  <span v-if="receipt.type === 'contano'">原盤印税</span>
                  <span v-else-if="receipt.type === 'red'">原盤印税</span>
                  <span v-else-if="receipt.type === 'external'">メッセージ</span>
                  <span v-else>著作権印税</span>
                </p>
                <p v-if="receipt.type !== 'external'" style="margin: 0; font-size: 80%;">
                  (
                  <span v-if="receipt.type === 'capo'">{{receipt.destinationCapoSentModel.divYear}}年{{receipt.destinationCapoSentModel.divTerm}}期</span>
                  <span v-else-if="receipt.type === 'jrca'">{{receipt.destinationJrcaSentModel.divYear}}年{{receipt.destinationJrcaSentModel.divTerm}}期</span>
                  <span v-else-if="receipt.type === 'contano'">{{receipt.destinationContanoSentModel.divYear}}年{{receipt.destinationContanoSentModel.divMonth}}月</span>
                  <span v-if="receipt.type === 'red'">{{receipt.destinationRedSentModel.divYear}}年{{receipt.destinationRedSentModel.divTerm}}期</span>
                  )
                </p>
              </td>
              <td>
                <p style="margin: 0;">
                  {{receipt.companyName}}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
        <div class="col-6 d-flex justify-content-start">
        </div>
        <div v-if="receiptResult && receiptResult.allRecords > 0" class="col-6 d-flex justify-content-end">
          <nav v-if="receiptResult" aria-label="Page navigation">
            <ul class="pagination">
              <li v-if="page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
              <li v-if="page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
              <li class="page-item"><span class="page-link">{{page + 1}}/{{receiptResult.allPages}}ページ</span></li>
              <li v-if="page !== receiptResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
              <li v-if="page !== receiptResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
            </ul>
          </nav>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="headerModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">受領明細閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">送信日時</div>
            <div class="form-edit-line">
              {{createdAt}}
            </div>
            <div class="form-label align-bottom">送付先</div>
            <div class="form-edit-line">
              <span class="badge bg-dark" style="margin-right: 5px;">{{toCcBcc}}</span>{{destinationName}}<br>
              <p v-for="email in emailList" :key="email" style="padding: 0; margin: 0; font-size: 90%;">{{email}}</p>
              <span v-if="!emailList || emailList.length === 0" style="font-size: 90%;">メールアドレス非公開</span>
            </div>
            <div class="form-label align-bottom">明細種別</div>
            <div class="form-edit-line">
              <span v-if="type === 'contano'">原盤印税</span>
              <span v-else-if="type === 'red'">原盤印税</span>
              <span v-else-if="type === 'external'">メッセージ</span>
              <span v-else>著作権印税</span>
            </div>
            <div class="form-label align-bottom">分配期</div>
            <div class="form-edit-line">
              <span v-if="type === 'capo'">{{divYear}}年{{divTerm}}期</span>
              <span v-else-if="type === 'jrca'">{{divYear}}年{{divTerm}}期</span>
              <span v-else-if="type === 'contano'">{{divYear}}年{{divMonth}}月</span>
              <span v-else-if="type === 'red'">{{divYear}}年{{divTerm}}期</span>
            </div>
            <div class="form-label align-bottom">明細発行元</div>
            <div class="form-edit-line">
              {{companyName}}
            </div>
            <div class="accordion" id="accordionHeader">
              <div class="accordion-item" v-for="(header, index) in headerList" :key="header.id">
                <h2 class="accordion-header" :id="'heading' + index">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                    <span v-if="type === 'red'">{{header.rightsHolderName}}</span>
                    <span v-else>{{header.name}}</span>
                  </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{show: index === 0}" :aria-labelledby="'heading' + index" data-bs-parent="#accordionHeader">
                  <div v-if="type === 'red'" class="accordion-body">
                    <div v-if="header.taxation_type !== 'unknown'" class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">課税事業者／免税事業者</div>
                        <div class="form-edit-line" style="margin-bottom: 5px;">
                          <span v-if="header.taxationType === 'taxation'">課税事業者</span>
                          <span v-else>免税事業者</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">事業者登録番号</div>
                        <div class="form-edit-line" style="margin-bottom: 5px;">
                          <span v-if="header.taxationType === 'taxation'">T{{header.invoiceNo}}</span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">前期繰越額</div>
                        <div v-if="header.amountBroughtForward" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.amountBroughtForward.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">今期発生額</div>
                        <div v-if="header.amountInThisTerm" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.amountInThisTerm.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">合計発生額</div>
                        <div v-if="header.totalAmount" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.totalAmount.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">管理手数料額</div>
                        <div v-if="header.managementFeeAmount" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.managementFeeAmount.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">税抜アドバンス相殺対象額</div>
                        <div v-if="header.offsetTargetAmountOutTax" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.offsetTargetAmountOutTax.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">税抜アドバンス相殺額</div>
                        <div v-if="header.offsetAmountOutTax" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.offsetAmountOutTax.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div v-if="header.taxationType !== 'unknown'" class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">控除額</div>
                        <div v-if="header.invoiceDeductionAmount" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.invoiceDeductionAmount.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div v-if="header.amountCarriedForwardOutTax && header.amountCarriedForwardOutTax !== 0" class="form-label align-bottom">次期繰越額（税抜）</div>
                    <div v-if="header.amountCarriedForwardOutTax && header.amountCarriedForwardOutTax !== 0" class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.amountCarriedForwardOutTax.toLocaleString()}}
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">課税対象額</div>
                        <div v-if="header.beforeTaxAmount" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.beforeTaxAmount.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">消費税額</div>
                        <div v-if="header.taxAmount" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.taxAmount.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-label align-bottom">税込アドバンス相殺対象額</div>
                        <div v-if="header.offsetTargetAmountInTax" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.offsetTargetAmountInTax.toLocaleString()}}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-label align-bottom">税込アドバンス相殺額</div>
                        <div v-if="header.offsetAmountInTax" class="form-edit-line" style="margin-bottom: 5px;">
                          {{header.offsetAmountInTax.toLocaleString()}}
                        </div>
                        <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                      </div>
                    </div>
                    <div class="form-label align-bottom">源泉徴収額</div>
                    <div v-if="header.withholdingTaxAmount" class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.withholdingTaxAmount.toLocaleString()}}
                    </div>
                    <div v-else class="form-edit-line" style="margin-bottom: 5px;">0</div>
                    <div v-if="header.paymentAmount && header.paymentAmount !== 0" class="form-label align-bottom fs-6"><b>支払額</b></div>
                    <div v-if="header.paymentAmount && header.paymentAmount !== 0" class="form-edit-line fs-5" style="margin-bottom: 5px; border-bottom: 1px solid #cccccc;">
                      ￥<b>{{header.paymentAmount.toLocaleString()}}</b>
                    </div>
                    <div v-if="header.amountCarriedForwardInTax && header.amountCarriedForwardInTax !== 0" class="form-label align-bottom">次期繰越額（税込）</div>
                    <div v-if="header.amountCarriedForwardInTax && header.amountCarriedForwardInTax !== 0" class="form-edit-line fs-5" style="margin-bottom: 5px;">
                      <b>{{header.amountCarriedForwardInTax.toLocaleString()}}</b>
                    </div>
                    <div class="d-flex" style="margin-top: 15px;">
                      <div class="me-auto">
                        <button @click="openTaxModal(header.id)" class="btn btn-primary btn-sm d-none d-md-table-cell" style="margin-right: 5px;">消費税内訳</button>
                        <button @click="openSummaryModal(header.id)" class="btn btn-primary btn-sm d-none d-md-table-cell" style="margin-right: 5px;">詳細</button>
                      </div>
                      <div class="justify-content-end">
                        <button @click="download(header.id, header.pdfBlob, 'pdf')" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDF</button>
                        <button @click="download(header.id, header.textBlob, 'txt')" class="btn btn-outline-primary btn-sm"><i class="fas fa-file-alt" style="margin-right: 5px;"></i>テキスト</button>
                      </div>
                    </div>
                    <div v-if="header.attachmentList && header.attachmentList.length > 0" style="margin-top: 10px;">
                      <div class="me-auto">
                        <table class="table table-responsive table-striped">
                          <tbody>
                          <tr v-for="attachment in header.attachmentList" :key="attachment.blobName">
                            <td>
                              <a :href="attachment.url">{{attachment.fileName}}</a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-else class="accordion-body">
                    <div v-if="header.taxationType !== 'unknown'" class="form-label align-bottom">課税事業者／免税事業者</div>
                    <div v-if="header.taxationType !== 'unknown'" class="form-edit-line" style="margin-bottom: 5px;">
                      <span v-if="header.taxationType === 'taxation'">課税事業者</span>
                      <span v-else>免税事業者</span>
                    </div>
                    <div v-if="header.taxationType !== 'unknown'" class="form-label align-bottom">事業者登録番号</div>
                    <div v-if="header.taxationType !== 'unknown'" class="form-edit-line" style="margin-bottom: 5px;">
                      <span v-if="header.taxationType === 'taxation'">T{{header.invoiceNo}}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="form-label align-bottom">前期繰越額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.amountBroughtForward.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">今期発生額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.amountInThisTerm.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">合計発生額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.totalAmount.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">管理手数料額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.managementAmount.toLocaleString()}}
                    </div>
                    <div v-if="header.taxationType !== 'unknown'" class="form-label align-bottom">控除額</div>
                    <div v-if="header.taxationType !== 'unknown'" class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.invoiceDeductionAmount.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">課税対象額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.beforeAmount.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">消費税額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.taxAmount.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">源泉徴収額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.withholdingTaxAmount.toLocaleString()}}
                    </div>
                    <div class="form-label align-bottom">相殺額</div>
                    <div class="form-edit-line" style="margin-bottom: 5px;">
                      {{header.offsetAmount.toLocaleString()}}
                    </div>
                    <div v-if="header.amountCarriedForward === 0" class="form-label align-bottom">支払額</div>
                    <div v-if="header.amountCarriedForward === 0" class="form-edit-line" style="margin-bottom: 5px;">
                      <span style="font-size: 120%; text-decoration: underline;" class="fw-bold">¥{{header.paymentAmount.toLocaleString()}}</span>
                    </div>
                    <div v-if="header.amountCarriedForward !== 0" class="form-label align-bottom">次期繰越額</div>
                    <div v-if="header.amountCarriedForward !== 0" class="form-edit-line" style="margin-bottom: 5px;">
                      <span style="font-size: 120%; text-decoration: underline;" class="fw-bold">¥{{header.amountCarriedForward.toLocaleString()}}</span>
                    </div>
                    <div class="d-flex">
                      <div class="me-auto">
                        <button @click="openTaxModal(header.id)" class="btn btn-primary btn-sm d-none d-md-table-cell" style="margin-right: 5px;">消費税内訳</button>
                        <button v-if="type === 'contano' || type === 'red'" @click="openSummaryModal(header.id)" class="btn btn-primary btn-sm d-none d-md-table-cell" style="margin-right: 5px;">詳細</button>
                        <button v-else @click="openMusicModal(header.id)" class="btn btn-primary btn-sm d-none d-md-table-cell" style="margin-right: 5px;">楽曲別明細</button>
                      </div>
                      <div class="justify-content-end">
                        <button @click="download(header.id, header.pdfBlob, 'pdf')" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDF</button>
                        <button @click="download(header.id, header.textBlob, 'txt')" class="btn btn-outline-primary btn-sm"><i class="fas fa-file-alt" style="margin-right: 5px;"></i>テキスト</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="downloadAll('pdf')" class="btn btn-outline-primary d-none d-md-table-cell" style="margin-right: 5px;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDF一括DL</button>
              <button @click="downloadAll('txt')" class="btn btn-outline-primary d-none d-md-table-cell"><i class="fas fa-file-alt" style="margin-right: 5px;"></i>テキスト一括DL</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="taxModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">消費税内訳閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="text-end">消費税率</th>
                  <th scope="col">0%</th>
                  <th scope="col">3%</th>
                  <th scope="col">5%</th>
                  <th scope="col">8%</th>
                  <th scope="col">10%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">前期繰越額</th>
                  <td class="text-end">{{amountBroughtForward0.toLocaleString()}}</td>
                  <td class="text-end">{{amountBroughtForward3.toLocaleString()}}</td>
                  <td class="text-end">{{amountBroughtForward5.toLocaleString()}}</td>
                  <td class="text-end">{{amountBroughtForward8.toLocaleString()}}</td>
                  <td class="text-end">{{amountBroughtForward10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">今期発生額</th>
                  <td class="text-end">{{amountInThisTerm0.toLocaleString()}}</td>
                  <td class="text-end">{{amountInThisTerm3.toLocaleString()}}</td>
                  <td class="text-end">{{amountInThisTerm5.toLocaleString()}}</td>
                  <td class="text-end">{{amountInThisTerm8.toLocaleString()}}</td>
                  <td class="text-end">{{amountInThisTerm10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">合計発生額</th>
                  <td class="text-end">{{totalAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{totalAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{totalAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{totalAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{totalAmount10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">管理手数料額</th>
                  <td class="text-end">{{managementAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{managementAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{managementAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{managementAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{managementAmount10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">控除額</th>
                  <td class="text-end">{{invoiceDeductionAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{invoiceDeductionAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{invoiceDeductionAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{invoiceDeductionAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{invoiceDeductionAmount10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">課税対象額</th>
                  <td class="text-end">{{beforeAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{beforeAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{beforeAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{beforeAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{beforeAmount10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">消費税額</th>
                  <td class="text-end">{{taxAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{taxAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{taxAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{taxAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{taxAmount10.toLocaleString()}}</td>
                </tr>
                <tr>
                  <th scope="row">相殺額</th>
                  <td class="text-end">{{offsetAmount0.toLocaleString()}}</td>
                  <td class="text-end">{{offsetAmount3.toLocaleString()}}</td>
                  <td class="text-end">{{offsetAmount5.toLocaleString()}}</td>
                  <td class="text-end">{{offsetAmount8.toLocaleString()}}</td>
                  <td class="text-end">{{offsetAmount10.toLocaleString()}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeTaxModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="musicModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">楽曲別明細閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">楽曲名、各種コード（カナ含む）</div>
            <div class="form-edit-line">
              <div class="input-group" style="margin-top: 10px;">
                <input @keypress.enter="musicPage = 0; musicFind();" v-model="musicSearchString" type="text" class="form-control">
                <button v-show="musicSearchString" @click="musicClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="musicPage = 0; musicFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <table v-if="musicResult && musicResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th style="width: 150px;">
                    作品コード
                  </th>
                  <th>楽曲名</th>
                  <th class="d-none d-lg-table-cell">アーティスト</th>
                  <th>支払額</th>
                </tr>
              </thead>
              <tbody v-if="musicList">
                <tr v-for="music in musicList" :key="music.id" class="text-start">
                  <td>
                    <p v-if="music.jasracCode" style="margin: 0;"><span class="badge bg-info" style="font-family: monospace;">J</span> {{music.jasracCode}}</p>
                    <p v-if="music.nextoneCode" style="margin: 0;"><span class="badge bg-info" style="font-family: monospace;">N</span> {{music.nextoneCode}}</p>
                  </td>
                  <td>{{music.name}}</td>
                  <td class="d-none d-lg-table-cell">{{music.artistName}}</td>
                  <td class="text-end">{{music.paymentAmount.toLocaleString()}}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex" style="margin-bottom: 50px;">
              <div class="col-6 justify-content-start">
                作品コード：
                <span class="badge bg-info" style="font-family: monospace;">J</span> Jasrac、
                <span class="badge bg-info" style="font-family: monospace;">N</span> NexTone
              </div>
              <div v-if="musicResult && musicResult.allRecords > 0" class="d-flex col-6 justify-content-end">
                <nav v-if="musicResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="musicPage !== 0" class="page-item"><button @click="musicFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                    <li v-if="musicPage !== 0" class="page-item"><button @click="musicPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                    <li class="page-item"><span class="page-link">{{musicPage + 1}}/{{musicResult.allPages}}ページ</span></li>
                    <li v-if="musicPage !== musicResult.allPages - 1" class="page-item"><button @click="musicNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                    <li v-if="musicPage !== musicResult.allPages - 1" class="page-item"><button @click="musicLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeMusicModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="summaryModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">詳細閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">商品名、原盤名、アーティスト、各種コード（カナ含む）</div>
            <div class="form-edit-line">
              <div class="input-group" style="margin-top: 10px;">
                <input @keypress.enter="detailStore.page = 0; summaryFind();" v-model="detailStore.searchString" type="text" class="form-control">
                <button v-show="detailStore.searchString" @click="summaryClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="detailStore.page = 0; summaryFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <table v-if="summaryResult && summaryResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th style="width: 80px;">
                    種別
                  </th>
                  <th style="width: 150px;">
                    商品番号／ISRC
                  </th>
                  <th>製品名／原盤名</th>
                  <th class="d-none d-lg-table-cell">アーティスト</th>
                  <th>支払額</th>
                </tr>
              </thead>
              <tbody v-if="summaryList">
                <tr v-for="summary in summaryList" :key="summary.id" class="text-start">
                  <td>
                    <span v-if="summary.dataUnit === 'PRODUCT'">製品</span>
                    <span v-else>原盤</span>
                  </td>
                  <td v-if="summary.dataUnit === 'PRODUCT'">{{summary.catalogNo}}</td>
                  <td v-else>{{summary.isrc}}</td>
                  <td v-if="summary.dataUnit === 'PRODUCT'">{{summary.productName}}</td>
                  <td v-else>{{summary.masterName}}</td>
                  <td>{{summary.artistName}}</td>
                  <td class="text-end">{{summary.paymentAmount.toLocaleString()}}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex" style="margin-bottom: 50px;">
              <div class="col-6 justify-content-start">
              </div>
              <div v-if="summaryResult && summaryResult.allRecords > 0" class="d-flex col-6 justify-content-end">
                <nav v-if="summaryResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="detailStore.page !== 0" class="page-item"><button @click="summaryFirstPage" class="page-link"><i class="fas fa-fast-backward"></i></button></li>
                    <li v-if="detailStore.page !== 0" class="page-item"><button @click="summaryPrevPage" class="page-link"><i class="fas fa-chevron-left"></i></button></li>
                    <li class="page-item"><span class="page-link">{{detailStore.page + 1}}/{{summaryResult.allPages}}ページ</span></li>
                    <li v-if="detailStore.page !== summaryResult.allPages - 1" class="page-item"><button @click="summaryNextPage" class="page-link"><i class="fas fa-chevron-right"></i></button></li>
                    <li v-if="detailStore.page !== summaryResult.allPages - 1" class="page-item"><button @click="summaryLastPage" class="page-link"><i class="fas fa-fast-forward"></i></button></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeSummaryModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="externalDispModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メッセージの閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">送信日時</div>
            <div class="form-edit-line">
              <span v-if="sentAt">{{sentAt}}</span>
              <span v-else>未送信</span>
            </div>
            <div class="form-label align-bottom">送付先</div>
            <div class="form-edit-line">
              {{destinationName}}
            </div>
            <div class="form-label align-bottom">タイトル</div>
            <div class="form-edit-line">
              {{title}}
            </div>
            <div class="form-label align-bottom">メッセージ</div>
            <div class="form-edit-line">
              <span v-if="message" v-html="message.replaceAll('\n', '<br>')"></span>
            </div>
            <div v-if="blobList.length > 0" class="form-label align-bottom">添付ファイル</div>
            <div v-if="blobList.length > 0" class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="blob in blobList" :key="blob.blobName">
                  <td>
                    <a href="#" @click.prevent.stop="downloadExt(blob.blobName)">{{blob.fileName}}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navComponent from '@/components/Nav'
import { authFunction } from '@/functions/auth'
import exception from '@/functions/exception'
import axios from 'axios'
import { Modal } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { historyDetailStore } from './store'
import { paymentFunction } from '@/views/red/payment/function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const { identifyEx } = exception()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const page = ref(0)
    const receiptResult = ref(null)
    const receiptList = ref([])
    const destinationSentId = ref('')
    const searchString = ref('')
    const searchStatus = ref('ALL')
    const searchType = ref('ALL')
    const paymentFunc = paymentFunction()
    // ヘッダー
    const headerModalDiv = ref(null)
    let headerModal = null
    const createdAt = ref('')
    const type = ref('')
    const divYear = ref(null)
    const divTerm = ref(null)
    const divMonth = ref(null)
    const companyName = ref('')
    const destinationName = ref('')
    const toCcBcc = ref('')
    const emailList = ref([])
    const accountEmail = ref('')
    const headerList = ref([])
    // 消費税内訳
    const taxModalDiv = ref(null)
    let taxModal = null
    const amountBroughtForward0 = ref(0)
    const amountBroughtForward3 = ref(0)
    const amountBroughtForward5 = ref(0)
    const amountBroughtForward8 = ref(0)
    const amountBroughtForward10 = ref(0)
    const amountInThisTerm0 = ref(0)
    const amountInThisTerm3 = ref(0)
    const amountInThisTerm5 = ref(0)
    const amountInThisTerm8 = ref(0)
    const amountInThisTerm10 = ref(0)
    const totalAmount0 = ref(0)
    const totalAmount3 = ref(0)
    const totalAmount5 = ref(0)
    const totalAmount8 = ref(0)
    const totalAmount10 = ref(0)
    const managementAmount0 = ref(0)
    const managementAmount3 = ref(0)
    const managementAmount5 = ref(0)
    const managementAmount8 = ref(0)
    const managementAmount10 = ref(0)
    const beforeAmount0 = ref(0)
    const beforeAmount3 = ref(0)
    const beforeAmount5 = ref(0)
    const beforeAmount8 = ref(0)
    const beforeAmount10 = ref(0)
    const taxAmount0 = ref(0)
    const taxAmount3 = ref(0)
    const taxAmount5 = ref(0)
    const taxAmount8 = ref(0)
    const taxAmount10 = ref(0)
    const offsetAmount0 = ref(0)
    const offsetAmount3 = ref(0)
    const offsetAmount5 = ref(0)
    const offsetAmount8 = ref(0)
    const offsetAmount10 = ref(0)
    const invoiceDeductionAmount0 = ref(0)
    const invoiceDeductionAmount3 = ref(0)
    const invoiceDeductionAmount5 = ref(0)
    const invoiceDeductionAmount8 = ref(0)
    const invoiceDeductionAmount10 = ref(0)
    // 楽曲別明細
    const headerId = ref('')
    const musicModalDiv = ref(null)
    let musicModal = null
    const musicSearchString = ref('')
    const musicPage = ref(0)
    const musicResult = ref(null)
    const musicList = ref([])
    // 原盤詳細
    const detailStore = historyDetailStore()
    const summaryModalDiv = ref(null)
    let summaryModal = null
    const summaryResult = ref(null)
    const summaryList = ref([])
    // メッセージ
    const externalDispModalDiv = ref(null)
    let externalDispModal = null
    const title = ref('')
    const message = ref('')
    const sentAt = ref('')
    const blobList = ref([])
    // メソッド
    const find = () => {
      const params = {
        page: page.value,
        searchString: searchString.value,
        perPage: 30
      }
      if (searchStatus.value !== 'ALL') {
        params.status = searchStatus.value
      }
      if (searchType.value !== 'ALL') {
        params.type = searchType.value
      }
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/history/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: params
      }).then(response => {
        receiptResult.value = response.data
        receiptList.value = receiptResult.value.list
        for (const receipt of receiptList.value) {
          axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + receipt.accountId, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            receipt.emailList = response.data.emailList
          })
          let destinationSentModel
          switch (receipt.type) {
            case 'capo': {
              destinationSentModel = receipt.destinationCapoSentModel
              break
            }
            case 'jrca': {
              destinationSentModel = receipt.destinationJrcaSentModel
              break
            }
            case 'contano': {
              destinationSentModel = receipt.destinationContanoSentModel
              break
            }
          }
          if (receipt.type === 'red') {
            axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/divterm/' + receipt.destinationRedSentModel.companyDivTermId, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              receipt.destinationRedSentModel.divYear = response.data.divYear
              receipt.destinationRedSentModel.divTerm = response.data.divTerm
              axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/' + response.data.companyId, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                receipt.companyName = response.data.name
              })
            })
          } else {
            if (receipt.type !== 'external') {
              axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + receipt.type + '/company/' + destinationSentModel.companyId, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                receipt.companyName = response.data.name
              })
            }
          }
          receipt.accountEmail = receipt.email
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }).catch(error => { identifyEx(error) })
    }
    const clearSearch = () => {
      page.value = 0
      find()
    }
    const firstPage = () => {
      page.value = 0
      find()
    }
    const prevPage = () => {
      page.value = page.value - 1
      find()
    }
    const nextPage = () => {
      page.value = page.value + 1
      find()
    }
    const lastPage = () => {
      page.value = receiptResult.value.allPages - 1
      find()
    }
    const openExternalModal = (index) => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/' + receiptList.value[index].destinationSentId, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + response.data.destinationId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          destinationName.value = response.data.name
        })
        title.value = response.data.title
        message.value = response.data.message
        sentAt.value = response.data.sentAt
        blobList.value = response.data.blobList
        externalDispModal.show()
      })
    }
    const openHeaderModal = (index) => {
      destinationSentId.value = receiptList.value[index].destinationSentId
      headerList.value = []
      createdAt.value = receiptList.value[index].createdAt
      type.value = receiptList.value[index].type
      companyName.value = receiptList.value[index].companyName
      destinationName.value = receiptList.value[index].destinationName
      toCcBcc.value = receiptList.value[index].toCcBcc
      emailList.value = receiptList.value[index].emailList
      switch (type.value) {
        case 'capo': {
          divYear.value = receiptList.value[index].destinationCapoSentModel.divYear
          divTerm.value = receiptList.value[index].destinationCapoSentModel.divTerm
          break
        }
        case 'jrca': {
          divYear.value = receiptList.value[index].destinationJrcaSentModel.divYear
          divTerm.value = receiptList.value[index].destinationJrcaSentModel.divTerm
          break
        }
        case 'contano': {
          divYear.value = receiptList.value[index].destinationContanoSentModel.divYear
          divMonth.value = receiptList.value[index].destinationContanoSentModel.divMonth
          break
        }
        case 'red': {
          divYear.value = receiptList.value[index].destinationRedSentModel.divYear
          divTerm.value = receiptList.value[index].destinationRedSentModel.divTerm
          break
        }
      }
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/receipt/' + type.value + '/' + destinationSentId.value + '/header/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(async response => {
        headerList.value = response.data
        if (type.value === 'red') {
          for (const [index, header] of Object.entries(headerList.value)) {
            headerList.value[index] = await paymentFunc.get(header.redPaymentHeaderId)
            const statementBlob = await paymentFunc.getStatementBlob(header.redPaymentHeaderId)
            headerList.value[index].pdfBlob = statementBlob.pdfBlob
            headerList.value[index].textBlob = statementBlob.textBlob
            await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + header.redPaymentHeaderId + '/attachment/list', {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(async response => {
              headerList.value[index].attachmentList = response.data
              for (const attachment of headerList.value[index].attachmentList) {
                attachment.url = await urlForDownload(attachment.blobName)
              }
            })
          }
        }
      })
      headerModal.show()
    }
    const urlForDownload = async (blobName) => {
      console.log(blobName)
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    const openTaxModal = (headerId) => {
      if (type.value === 'red') {
        axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/tax/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(async response => {
          for (const tax of response.data) {
            const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + headerId + '/tax/' + tax.id, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
            switch (tax.rate) {
              case 0: {
                amountBroughtForward0.value = data !== null ? data.amountBroughtForward : 0
                amountInThisTerm0.value = data !== null ? data.amountInThisTerm : 0
                totalAmount0.value = data !== null ? data.totalAmount : 0
                managementAmount0.value = data !== null ? data.managementFeeAmount : 0
                beforeAmount0.value = data !== null ? data.beforeTaxAmount : 0
                taxAmount0.value = data !== null ? data.taxAmount : 0
                offsetAmount0.value = data !== null ? data.offsetAmountOutTax : 0
                invoiceDeductionAmount0.value = data !== null ? data.invoiceDeductionAmount : 0
                break
              }
              case 3: {
                amountBroughtForward3.value = data !== null ? data.amountBroughtForward : 0
                amountInThisTerm3.value = data !== null ? data.amountInThisTerm : 0
                totalAmount3.value = data !== null ? data.totalAmount : 0
                managementAmount3.value = data !== null ? data.managementFeeAmount : 0
                beforeAmount3.value = data !== null ? data.beforeTaxAmount : 0
                taxAmount3.value = data !== null ? data.taxAmount : 0
                offsetAmount3.value = data !== null ? data.offsetAmountOutTax : 0
                invoiceDeductionAmount3.value = data !== null ? data.invoiceDeductionAmount : 0
                break
              }
              case 5: {
                amountBroughtForward5.value = data !== null ? data.amountBroughtForward : 0
                amountInThisTerm5.value = data !== null ? data.amountInThisTerm : 0
                totalAmount5.value = data !== null ? data.totalAmount : 0
                managementAmount5.value = data !== null ? data.managementFeeAmount : 0
                beforeAmount5.value = data !== null ? data.beforeTaxAmount : 0
                taxAmount5.value = data !== null ? data.taxAmount : 0
                offsetAmount5.value = data !== null ? data.offsetAmountOutTax : 0
                invoiceDeductionAmount5.value = data !== null ? data.invoiceDeductionAmount : 0
                break
              }
              case 8: {
                amountBroughtForward8.value = data !== null ? data.amountBroughtForward : 0
                amountInThisTerm8.value = data !== null ? data.amountInThisTerm : 0
                totalAmount8.value = data !== null ? data.totalAmount : 0
                managementAmount8.value = data !== null ? data.managementFeeAmount : 0
                beforeAmount8.value = data !== null ? data.beforeTaxAmount : 0
                taxAmount8.value = data !== null ? data.taxAmount : 0
                offsetAmount8.value = data !== null ? data.offsetAmountOutTax : 0
                invoiceDeductionAmount8.value = data !== null ? data.invoiceDeductionAmount : 0
                break
              }
              case 10: {
                amountBroughtForward10.value = data !== null ? data.amountBroughtForward : 0
                amountInThisTerm10.value = data !== null ? data.amountInThisTerm : 0
                totalAmount10.value = data !== null ? data.totalAmount : 0
                managementAmount10.value = data !== null ? data.managementFeeAmount : 0
                beforeAmount10.value = data !== null ? data.beforeTaxAmount : 0
                taxAmount10.value = data !== null ? data.taxAmount : 0
                offsetAmount10.value = data !== null ? data.offsetAmountOutTax : 0
                invoiceDeductionAmount10.value = data !== null ? data.invoiceDeductionAmount : 0
                break
              }
            }
          }
        })
      } else {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/receipt/' + type.value + '/header/' + headerId + '/tax/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          for (const tax of response.data) {
            switch (tax.taxRate) {
              case 0: {
                amountBroughtForward0.value = tax.amountBroughtForward
                amountInThisTerm0.value = tax.amountInThisTerm
                totalAmount0.value = tax.totalAmount
                managementAmount0.value = tax.managementAmount
                beforeAmount0.value = tax.beforeAmount
                taxAmount0.value = tax.taxAmount
                offsetAmount0.value = tax.offsetAmount
                invoiceDeductionAmount0.value = tax.invoiceDeductionAmount
                break
              }
              case 3: {
                amountBroughtForward3.value = tax.amountBroughtForward
                amountInThisTerm3.value = tax.amountInThisTerm
                totalAmount3.value = tax.totalAmount
                managementAmount3.value = tax.managementAmount
                beforeAmount3.value = tax.beforeAmount
                taxAmount3.value = tax.taxAmount
                offsetAmount3.value = tax.offsetAmount
                invoiceDeductionAmount3.value = tax.invoiceDeductionAmount
                break
              }
              case 5: {
                amountBroughtForward5.value = tax.amountBroughtForward
                amountInThisTerm5.value = tax.amountInThisTerm
                totalAmount5.value = tax.totalAmount
                managementAmount5.value = tax.managementAmount
                beforeAmount5.value = tax.beforeAmount
                taxAmount5.value = tax.taxAmount
                offsetAmount5.value = tax.offsetAmount
                invoiceDeductionAmount5.value = tax.invoiceDeductionAmount
                break
              }
              case 8: {
                amountBroughtForward8.value = tax.amountBroughtForward
                amountInThisTerm8.value = tax.amountInThisTerm
                totalAmount8.value = tax.totalAmount
                managementAmount8.value = tax.managementAmount
                beforeAmount8.value = tax.beforeAmount
                taxAmount8.value = tax.taxAmount
                offsetAmount8.value = tax.offsetAmount
                invoiceDeductionAmount8.value = tax.invoiceDeductionAmount
                break
              }
              case 10: {
                amountBroughtForward10.value = tax.amountBroughtForward
                amountInThisTerm10.value = tax.amountInThisTerm
                totalAmount10.value = tax.totalAmount
                managementAmount10.value = tax.managementAmount
                beforeAmount10.value = tax.beforeAmount
                taxAmount10.value = tax.taxAmount
                offsetAmount10.value = tax.offsetAmount
                invoiceDeductionAmount10.value = tax.invoiceDeductionAmount
                break
              }
            }
          }
        })
      }
      taxModal.show()
      headerModal.hide()
    }
    const closeTaxModal = () => {
      headerModal.show()
      taxModal.hide()
    }
    const openMusicModal = (hId) => {
      headerId.value = hId
      musicClearSearch()
      musicModal.show()
      headerModal.hide()
    }
    const closeMusicModal = () => {
      headerModal.show()
      musicModal.hide()
    }
    const openSummaryModal = (hId) => {
      headerId.value = hId
      summaryClearSearch()
      summaryModal.show()
      headerModal.hide()
    }
    const closeSummaryModal = () => {
      headerModal.show()
      summaryModal.hide()
    }
    const musicFind = () => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/receipt/' + type.value + '/header/' + headerId.value + '/music/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          searchString: detailStore.searchString,
          page: detailStore.page,
          perPage: 20
        }
      }).then(response => {
        musicResult.value = response.data
        musicList.value = musicResult.value.list
      })
    }
    const summaryFind = async () => {
      if (type.value === 'red') {
        summaryResult.value = await paymentFunc.findStatementDetail(headerId.value, detailStore)
        summaryList.value = summaryResult.value.list
        for (const summary of summaryList.value) {
          if (summary.dataUnit === 'master') {
            summary.dataUnit = 'MASTER'
            summary.artistName = summary.masterArtistName
          } else {
            summary.dataUnit = 'PRODUCT'
            summary.artistName = summary.albumArtistName
          }
          summary.catalogNo = summary.albumCatalogNo
          summary.album = summary.albumName
          summary.isrc = summary.masterIsrc
        }
      } else {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/receipt/contano/header/' + headerId.value + '/summary/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            searchString: detailStore.searchString,
            page: detailStore.page,
            perPage: 20
          }
        }).then(response => {
          summaryResult.value = response.data
          summaryList.value = summaryResult.value.list
        })
      }
    }
    const musicClearSearch = () => {
      musicSearchString.value = ''
      musicPage.value = 0
      musicFind()
    }
    const musicFirstPage = () => {
      musicPage.value = 0
      musicFind()
    }
    const musicPrevPage = () => {
      musicPage.value = musicPage.value - 1
      musicFind()
    }
    const musicNextPage = () => {
      musicPage.value = musicPage.value + 1
      musicFind()
    }
    const musicLastPage = () => {
      musicPage.value = musicResult.value.allPages - 1
      musicFind()
    }
    const summaryClearSearch = () => {
      detailStore.searchString = ''
      detailStore.page = 0
      summaryFind()
    }
    const summaryFirstPage = () => {
      detailStore.page = 0
      summaryFind()
    }
    const summaryPrevPage = () => {
      detailStore.page = detailStore.page - 1
      summaryFind()
    }
    const summaryNextPage = () => {
      detailStore.page = detailStore.page + 1
      summaryFind()
    }
    const summaryLastPage = () => {
      detailStore.page = summaryResult.value.allPages - 1
      summaryFind()
    }
    const download = async (headerId, blobName, extension) => {
      processMessage.value = 'ダウンロード処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
      headerModal.hide()
      processModal.show()
      if (type.value === 'red') {
        // blobなしの場合はPDF作成
        if (!blobName) {
          await paymentFunc.createFile(headerId)
          const statementBlob = await paymentFunc.getStatementBlob(headerId)
          if (extension === 'pdf') {
            blobName = statementBlob.pdfBlob
          } else {
            blobName = statementBlob.textBlob
          }
        }
        const url = await paymentFunc.getStatementUrl(headerId, blobName)
        const a = document.createElement('a')
        a.href = url
        a.click()
        processModal.hide()
        headerModal.show()
      } else {
        const response = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/receipt/' + type.value + '/header/' + headerId + '/' + extension + '/download/url', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: { blobName: blobName }
        })
        const a = document.createElement('a')
        a.href = response.data
        a.click()
      }
      processModal.hide()
      headerModal.show()
    }
    const downloadExt = async (blobName) => {
      processMessage.value = 'ダウンロード処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
      externalDispModal.hide()
      processModal.show()
      const response = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      const a = document.createElement('a')
      a.href = response.data
      a.click()
      processModal.hide()
      externalDispModal.show()
    }
    const downloadAll = async (extension) => {
      processMessage.value = '一括ダウンロード処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
      headerModal.hide()
      processModal.show()
      const response = await axios.get(process.env.VUE_APP_ZIP_ENDPOINT + '/receipt/' + type.value + '/download/' + destinationSentId.value + '/' + extension + '/all', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      const a = document.createElement('a')
      a.href = response.data
      a.click()
      processModal.hide()
      headerModal.show()
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      headerModal = new Modal(headerModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      taxModal = new Modal(taxModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      musicModal = new Modal(musicModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      summaryModal = new Modal(summaryModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      externalDispModal = new Modal(externalDispModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      find()
    })
    return {
      processMessage,
      processModalDiv,
      page,
      receiptResult,
      receiptList,
      headerModalDiv,
      destinationSentId,
      createdAt,
      type,
      divYear,
      divTerm,
      divMonth,
      companyName,
      destinationName,
      toCcBcc,
      accountEmail,
      headerList,
      taxModalDiv,
      amountBroughtForward0,
      amountBroughtForward3,
      amountBroughtForward5,
      amountBroughtForward8,
      amountBroughtForward10,
      amountInThisTerm0,
      amountInThisTerm3,
      amountInThisTerm5,
      amountInThisTerm8,
      amountInThisTerm10,
      totalAmount0,
      totalAmount3,
      totalAmount5,
      totalAmount8,
      totalAmount10,
      managementAmount0,
      managementAmount3,
      managementAmount5,
      managementAmount8,
      managementAmount10,
      beforeAmount0,
      beforeAmount3,
      beforeAmount5,
      beforeAmount8,
      beforeAmount10,
      taxAmount0,
      taxAmount3,
      taxAmount5,
      taxAmount8,
      taxAmount10,
      offsetAmount0,
      offsetAmount3,
      offsetAmount5,
      offsetAmount8,
      offsetAmount10,
      invoiceDeductionAmount0,
      invoiceDeductionAmount3,
      invoiceDeductionAmount5,
      invoiceDeductionAmount8,
      invoiceDeductionAmount10,
      headerId,
      musicModalDiv,
      musicSearchString,
      musicPage,
      musicResult,
      musicList,
      summaryModalDiv,
      summaryResult,
      summaryList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      openHeaderModal,
      download,
      openTaxModal,
      closeTaxModal,
      openMusicModal,
      closeMusicModal,
      openSummaryModal,
      closeSummaryModal,
      musicFind,
      musicClearSearch,
      musicFirstPage,
      musicPrevPage,
      musicNextPage,
      musicLastPage,
      summaryFind,
      summaryClearSearch,
      summaryFirstPage,
      summaryPrevPage,
      summaryNextPage,
      summaryLastPage,
      externalDispModalDiv,
      title,
      message,
      sentAt,
      blobList,
      openExternalModal,
      downloadExt,
      searchStatus,
      searchString,
      searchType,
      detailStore,
      emailList,
      downloadAll
    }
  }
})
</script>
