import { defineStore } from 'pinia'
import { ref } from 'vue'

export const artistStore = defineStore('modal_artist', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const albumStore = defineStore('modal_album', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const masterStore = defineStore('modal_master', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const contractStore = defineStore('modal_contract', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
