<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-5 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active">
                    <i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                    [管理者用] インデックス再構築
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button v-if="!inProgress" @click="execute" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-exclamation-triangle fa-fw" style="margin-right: 5px;"></i>実行</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="alert alert-danger">
            全データのインデックスを再構築します。非常に大きな負荷がかかる処理のため、必要な場合以外には実行しないでください。
          </div>
          <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
              <table class="table table-responsive">
                <tbody>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.artist"><button @click="target.artist = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.artist"><button @click="target.artist = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>アーティスト</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.artist === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.artist === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.artist === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.addressee"><button @click="target.addressee = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.addressee"><button @click="target.addressee = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>送付先</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.addressee === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.addressee === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.addressee === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.payee"><button @click="target.payee = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.payee"><button @click="target.payee = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>振込先</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.payee === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.payee === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.payee === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.album"><button @click="target.album = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.album"><button @click="target.album = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>アルバム</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.album === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.album === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.album === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.master"><button @click="target.master = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.master"><button @click="target.master = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>原盤</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.master === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.master === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.master === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.contract"><button @click="target.contract = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.contract"><button @click="target.contract = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>契約書</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.contract === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.contract === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.contract === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.definition"><button @click="target.definition = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.definition"><button @click="target.definition = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>定義</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.definition === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.definition === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.definition === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.user"><button @click="target.user = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.user"><button @click="target.user = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>利用者</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.user === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.user === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.user === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.userSales"><button @click="target.userSales = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.userSales"><button @click="target.userSales = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>頒布形態</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.userSales === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.userSales === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.userSales === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                  <tr class="align-middle">
                    <td style="width: 55px;" class="text-center">
                      <span v-if="!target.rightsHolder"><button @click="target.rightsHolder = true" class="btn btn-light"><i class="far fa-square fa-fw"></i></button></span>
                      <span v-if="target.rightsHolder"><button @click="target.rightsHolder = false" class="btn btn-light"><i class="far fa-check-square fa-fw"></i></button></span>
                    </td>
                    <td>権利者</td>
                    <td style="width: 100px;" class="text-end">
                      <span v-if="progress.rightsHolder === 'none'" class="badge bg-secondary">未処理</span>
                      <span v-if="progress.rightsHolder === 'in_progress'" class="badge bg-primary">処理中</span>
                      <span v-if="progress.rightsHolder === 'done'" class="badge bg-success">完了</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index"><span v-html="message"></span>
        </p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { Toast } from 'bootstrap'
import { sessionStore } from '@/functions/sessionStore'
import { indexFunction } from './function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const messages = ref([])
    const messageToastDiv = ref(null)
    const errors = ref([])
    const errorToastDiv = ref(null)
    let messageToast = null
    let errorToast = null
    const inProgress = ref(false)
    const target = ref({
      artist: true,
      addressee: true,
      payee: true,
      album: true,
      master: true,
      contract: true,
      definition: true,
      user: true,
      userSales: true,
      rightsHolder: true
    })
    const progress = ref({
      artist: 'none',
      addressee: 'none',
      payee: 'none',
      album: 'none',
      master: 'none',
      contract: 'none',
      definition: 'none',
      user: 'none',
      userSales: 'none',
      rightsHolder: 'none'
    })
    const indexFunc = indexFunction()
    // Form
    const execute = async () => {
      messages.value = []
      errors.value = []
      inProgress.value = true
      const intId = setInterval(indexFunc.check, 1000 * 60)
      try {
        const promises = []
        if (target.value.artist) {
          promises.push(build(process.env.VUE_APP_COMMON_ENDPOINT, 'artist'))
        }
        if (target.value.addressee) {
          promises.push(build(process.env.VUE_APP_COMMON_ENDPOINT, 'addressee'))
        }
        if (target.value.payee) {
          promises.push(build(process.env.VUE_APP_COMMON_ENDPOINT, 'payee'))
        }
        if (target.value.album) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'album'))
        }
        if (target.value.master) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'master'))
        }
        if (target.value.contract) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'contract'))
        }
        if (target.value.definition) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'definition'))
        }
        if (target.value.user) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'user'))
        }
        if (target.value.userSales) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'userSales'))
        }
        if (target.value.rightsHolder) {
          promises.push(build(process.env.VUE_APP_RED_MASTER_ENDPOINT, 'rightsHolder'))
        }
        if (promises.length > 0) {
          await Promise.all(promises)
        }
        messages.value.push('インデックスの再構築が完了しました')
        messageToast.show()
      } catch (e) {
        console.error(e)
        errors.value = ['システムエラーが発生しました']
        errorToast.show()
      }
      clearInterval(intId)
      inProgress.value = false
    }
    const build = async (endpoint, target) => {
      progress.value[target] = 'in_progress'
      let path = target
      if (path === 'userSales') {
        path = 'user/sales'
      } else {
        path = path.toLowerCase()
      }
      await indexFunc.execute(endpoint, path)
      progress.value[target] = 'done'
    }
    // メソッド
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      session.session = await auth.getSession()
    })
    return {
      messages,
      errors,
      messageToastDiv,
      errorToastDiv,
      execute,
      target,
      progress,
      inProgress
    }
  }
})
</script>
