import GreenRightsholderDisp from './Disp'
import GreenRightsholderList from './List'
import GreenRightsholderExternalEdit from './external/Edit.vue'
import GreenRightsholderExternalDisp from './external/Disp.vue'
import GreenRightsholderExternalCollect from './external/Collect.vue'
import GreenRightsholderExternalPrint from './external/Print.vue'
import GreenRightsholderExternalInput from './external/Input.vue'
import GreenRightsholderExternalUpload from './external/Upload.vue'
import GreenRightsholderInternalCollect from './internal/Collect.vue'
import GreenRightsholderInternalPrint from './internal/Print.vue'
import GreenRightsholderInternalInput from './internal/Input.vue'

export default {
  routes: [
    {
      path: '/green/rightsholder/list',
      name: 'GreenRightsholderList',
      component: GreenRightsholderList
    },
    {
      path: '/green/rightsholder/disp/:system/:id/:companyId',
      name: 'GreenRightsholderDisp',
      component: GreenRightsholderDisp
    },
    {
      path: '/green/rightsholder/external/edit/:id',
      name: 'GreenRightsholderExternalEdit',
      component: GreenRightsholderExternalEdit
    },
    {
      path: '/green/rightsholder/external/disp/:id',
      name: 'GreenRightsholderExternalDisp',
      component: GreenRightsholderExternalDisp
    },
    {
      path: '/green/rightsholder/external/collect/:id',
      name: 'GreenRightsholderExternalCollect',
      component: GreenRightsholderExternalCollect
    },
    {
      path: '/green/rightsholder/external/print/:id',
      name: 'GreenRightsholderExternalPrint',
      component: GreenRightsholderExternalPrint
    },
    {
      path: '/green/collect/external/:id/:token',
      name: 'GreenRightsholderExternalInput',
      component: GreenRightsholderExternalInput
    },
    {
      path: '/green/collect/external/upload',
      name: 'GreenRightsholderExternalUpload',
      component: GreenRightsholderExternalUpload
    },
    {
      path: '/green/rightsholder/internal/collect/:system/:id',
      name: 'GreenRightsholderInternalCollect',
      component: GreenRightsholderInternalCollect
    },
    {
      path: '/green/rightsholder/internal/print/:id',
      name: 'GreenRightsholderInternalPrint',
      component: GreenRightsholderInternalPrint
    },
    {
      path: '/green/collect/internal/:system/:id/:token',
      name: 'GreenRightsholderInternalInput',
      component: GreenRightsholderInternalInput
    }
  ]
}
