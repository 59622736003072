<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>自分の情報を確認する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>本システムに登録されている自分の情報を確認できます。初期状態では、明細を送付した方が入力した名前になっています。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">
          画面右上の人物マークをクリック／タップすると自分の情報を表示する画面に移動します。<br>
          <img src="../../assets/help/account_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
        <li style="margin-top: 20px;">
          登録済みの情報を確認することができます。<br>
          <img src="../../assets/help/account_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
