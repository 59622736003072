<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-envelope-open-text fa-fw" style="margin-right: 5px;"></i> 送付先一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/common/addressee/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加
              </router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="addresseeResult && addresseeResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th>名前</th>
                <th style="width: 200px;"></th>
              </tr>
            </thead>
            <tbody v-if="addresseeList" class="text-start align-middle">
              <tr v-for="addressee in addresseeList" :key="addressee.id">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/common/addressee/disp/' + addressee.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>{{ addressee.name }}</td>
                <td class="text-end">
                  <span v-if="session.session.blue && addressee.useOnBlue" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
                  <span v-if="session.session.red && addressee.useOnRed" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
                  <span v-if="session.session.pink && addressee.useOnPink" class="badge text-white" style="background-color: #EE86A8;">PINK</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="addresseeResult && addresseeResult.allRecords > 0">{{ addresseeResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="addresseeResult && addresseeResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="addresseeResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ addresseeResult.allPages }}ページ</span>
                </li>
                <li v-if="store.page !== addresseeResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== addresseeResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { addresseeFunction } from './function'
import { addresseeStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    // 検索
    const addresseeFind = addresseeFunction()
    const store = addresseeStore()
    const addresseeResult = ref(null)
    const addresseeList = ref([])
    // メソッド
    const find = async () => {
      addresseeResult.value = await addresseeFind.find(store)
      addresseeList.value = addresseeResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const clearSearch = () => {
      addresseeFind.clearSearch(store)
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = addresseeResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      session,
      router,
      store,
      addresseeResult,
      addresseeList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
