<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/payment/data/definition/list"><i class="fas fa-database fa-fw" style="margin-right: 5px;"></i>分配データ定義一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/payment/data/definition/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/red/payment/data/definition/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">名前</div>
          <div class="form-edit-line fs-5" style="font-weight: bold;">
            {{ form.name }}
          </div>
          <div class="form-label align-bottom">データ定義</div>
          <div>
            <table class="table table-responsive table-striped">
              <tbody>
                <tr v-for="(item, index) in form.itemList" :key="index">
                  <td style="width: 50px;">{{index + 1}}.</td>
                  <td>{{getItemType(item.itemType)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { paymentDataDefinitionFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const definitionFunc = paymentDataDefinitionFunction()
    // Form
    const form = ref(null)
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      form.value = await definitionFunc.get(id)
    }
    const getItemType = (key) => {
      return itemTypeList.filter(x => x.key === key)[0].name
    }
    const itemTypeList = [
      { key: 'albumName', name: 'アルバムタイトル' },
      { key: 'masterName', name: '原盤名' },
      { key: 'artistName', name: 'アーティスト' },
      { key: 'projectName', name: 'プロジェクト' },
      { key: 'userName', name: '利用者' },
      { key: 'userSalesName', name: '頒布形態' },
      { key: 'catalogNo', name: '品番' },
      { key: 'janCode', name: 'JANコード' },
      { key: 'userAlbumCode', name: '利用者アルバムコード' },
      { key: 'isrc', name: 'ISRC' },
      { key: 'masterCode', name: '原盤コード' },
      { key: 'userMasterCode', name: '利用者原盤コード' },
      { key: 'useType', name: '利用種別' },
      { key: 'royaltyType', name: '印税種別' },
      { key: 'calcType', name: '計算式' },
      { key: 'revenue', name: '売上' },
      { key: 'income', name: '受領額' },
      { key: 'usedNo', name: '使用数' },
      { key: 'calcTargetNo', name: '計算対象数' },
      { key: 'basePrice', name: '販売価格（按分前）' },
      { key: 'price', name: '販売価格（按分後）' },
      { key: 'rate', name: '印税率／分配率' },
      { key: 'unitPrice', name: '印税単価' },
      { key: 'basePayment', name: '支払額（按分前）' },
      { key: 'potion', name: 'ポーション' },
      { key: 'shareRate', name: 'メンバー按分比' },
      { key: 'payment', name: '支払額' },
      { key: 'taxRate', name: '消費税率' }
    ]
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      form,
      getItemType
    }
  }
})
</script>
