import RedContractDisp from './Disp'
import RedContractEdit from './Edit'
import RedContractList from './List'

export default {
  routes: [
    {
      path: '/red/contract/list',
      name: 'RedContractList',
      component: RedContractList
    },
    {
      path: '/red/contract/edit/:id',
      name: 'RedContractEdit',
      component: RedContractEdit
    },
    {
      path: '/red/contract/add',
      name: 'RedContractAdd',
      component: RedContractEdit
    },
    {
      path: '/red/contract/copy/:id',
      name: 'RedContractCopy',
      component: RedContractEdit
    },
    {
      path: '/red/contract/disp/:id',
      name: 'RedContractDisp',
      component: RedContractDisp
    },
    {
      path: '/red/album/contract/disp/:id/:albumId',
      name: 'RedContractAlbumDisp',
      component: RedContractDisp
    },
    {
      path: '/red/master/contract/disp/:id/:masterId',
      name: 'RedContractMasterDisp',
      component: RedContractDisp
    },
    {
      path: '/red/rightsholder/contract/disp/:id/:rightsHolderId',
      name: 'RedContractRightsHolderDisp',
      component: RedContractDisp
    }
  ]
}
