import { defineStore } from 'pinia'
import { ref } from 'vue'

export const unMatchStore = defineStore('error_unmatch', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const notDivStore = defineStore('error_notdiv', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const albumStore = defineStore('error_album', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const masterStore = defineStore('error_master', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const contractStore = defineStore('error_contracr', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
