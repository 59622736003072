import axios from 'axios'
import { sessionStore } from '@/functions/sessionStore'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const payeeFunction = () => {
  const session = sessionStore()
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/payee/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/payee/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: session.session.company.id,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const insert = async (form) => {
    const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/payee', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/payee/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/payee/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/payee/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, clearSearch, insert, update, remove, indexUpdate }
}
