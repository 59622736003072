import CommonAddresseeDisp from './Disp'
import CommonAddresseeEdit from './Edit'
import CommonAddresseeList from './List'

export default {
  routes: [
    {
      path: '/common/addressee/list',
      name: 'CommonAddresseeList',
      component: CommonAddresseeList
    },
    {
      path: '/common/addressee/disp/:id',
      name: 'CommonAddresseeDisp',
      component: CommonAddresseeDisp
    },
    {
      path: '/common/addressee/edit/:id',
      name: 'CommonAddresseeEdit',
      component: CommonAddresseeEdit
    },
    {
      path: '/common/addressee/add',
      name: 'CommonAddresseeAdd',
      component: CommonAddresseeEdit
    }
  ]
}
