import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const postalFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (code) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/postal/' + code, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { get }
}
