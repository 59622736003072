import CommonCurrencyEdit from './Edit'
import CommonCurrencyList from './List'

export default {
  routes: [
    {
      path: '/common/currency/list',
      name: 'CommonCurrencyList',
      component: CommonCurrencyList
    },
    {
      path: '/common/currency/edit',
      name: 'CommonCurrencyEdit',
      component: CommonCurrencyEdit
    }
  ]
}
