<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>受領した明細を確認する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>受領明細一覧画面（ログイン後の画面）には、今まで受領した全ての明細が表示されます。本画面から詳細を確認する画面に移動することができます。</p>
      <p>以下、詳細な説明です。</p>
      <ol>
        <li style="margin-top: 20px;">受領明細一覧
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              今まで受領した全ての明細が新しく届いた順に表示されています。（新しく届いた未読の明細には「未読」マークが表示されます）この画面の受領日時部分をクリック／タップすると明細の金額を確認したりPDF等をダウンロードする画面に移動します。<br>
              <img src="../../assets/help/receive_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">受領明細</li>
        <ol>
          <li style="margin-top: 10px;">
            明細の合計支払額等を確認できます。また、PDFファイルやテキストデータのダウンロード、各種詳細画面へ移動することもできます。<br>
            <img src="../../assets/help/header_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
          </li>
          <li style="margin-top: 10px;">
            「PDF」ボタンのクリック／タップで明細のPDFファイルをダウンロードできます。印刷等の用途にご使用ください。<br>
            <img src="../../assets/help/header_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
          </li>
          <li style="margin-top: 10px;">
            「テキスト」ボタンのクリック／タップで明細のテキストデータをダウンロードできます。他システムでの利用等にご使用ください。<br>
            <img src="../../assets/help/header_03.png" class="responsive-img" style="border: 1px solid #cccccc;">
          </li>
          <li style="margin-top: 10px;">
            「税別内訳」「楽曲別明細（著作権印税の場合）」「集計明細（原盤印税の場合）」ボタンのクリック／タップで各種詳細情報の閲覧が可能です。詳しくは<router-link to="/help/detail">こちら</router-link>をご覧ください。<br>
            ＜著作権印税の場合＞<br>
            <img src="../../assets/help/header_04.png" class="responsive-img" style="border: 1px solid #cccccc;"><br>
            ＜原盤印税の場合＞<br>
            <img src="../../assets/help/header_07.png" class="responsive-img" style="border: 1px solid #cccccc;">
          </li>
        </ol>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
