<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol v-if="route.path.includes('/common/artist/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/artist/list"><i class="fas fa-user-music fa-fw" style="margin-right: 5px;"></i>
                      アーティスト一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
                <ol v-if="route.path.includes('/red/album/artist/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/album/list"><i class="fas fa-album fa-fw" style="margin-right: 5px;"></i>
                      アルバム一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/album/disp/' + route.params.albumId"><i class="fas fa-eye" style="margin-right: 5px;"></i>{{albumName}}</router-link>
                  </li>
                </ol>
                <ol v-if="route.path.includes('/red/master/artist/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/master/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      原盤一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/master/disp/' + route.params.masterId"><i class="fas fa-eye" style="margin-right: 5px;"></i>{{masterName}}</router-link>
                  </li>
                </ol>
                <ol v-if="route.path.includes('/red/result/artist/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/result/list"><i class="fas fa-album fa-fw" style="margin-right: 5px;"></i>
                      リアルタイム結果一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/result/disp/' + route.params.resultId"><i class="fas fa-eye" style="margin-right: 5px;"></i>結果詳細</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/artist/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/common/artist/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-label align-bottom">名前</div>
                <div class="form-edit-line fs-5" style="font-weight: bold;">
                  {{ form.name }}
                </div>
                <div v-if="form.kana" class="form-label align-bottom">カナ</div>
                <div v-if="form.kana" class="form-edit-line">
                  {{ form.kana }}
                </div>
                <div v-if="form.name_en" class="form-label align-bottom">英語名</div>
                <div v-if="form.name_en" class="form-edit-line">
                  {{ form.name_en }}
                </div>
                <div v-if="form.officialSiteUrl" class="form-label align-bottom">公式サイト</div>
                <div v-if="form.officialSiteUrl" class="form-edit-line text-break">
                  <a :href="form.officialSiteUrl" target="_blank">{{ form.officialSiteUrl }}</a>
                </div>
                <div v-if="form.twitterId" class="form-label align-bottom"><i class="fa-brands fa-twitter fa-fw" style="margin-right: 5px;"></i>Twitter</div>
                <div v-if="form.twitterId" class="form-edit-line text-break">
                  <a :href="'https://twitter.com/' + form.twitterId" target="_blank">@{{ form.twitterId }}</a>
                </div>
                <div v-if="form.facebookUrl" class="form-label align-bottom"><i class="fa-brands fa-facebook fa-fw" style="margin-right: 5px;"></i>facebook</div>
                <div v-if="form.facebookUrl" class="form-edit-line text-break">
                  <a :href="form.facebookUrl" target="_blank">{{ form.facebookUrl }}</a>
                </div>
                <div v-if="form.instagramId" class="form-label align-bottom"><i class="fa-brands fa-instagram fa-fw" style="margin-right: 5px;"></i>Instagram</div>
                <div v-if="form.instagramId" class="form-edit-line text-break">
                  <a :href="'https://instagram.com/' + form.instagramId" target="_blank">@{{ form.instagramId }}</a>
                </div>
                <div v-if="form.tiktokId" class="form-label align-bottom"><i class="fa-brands fa-tiktok fa-fw" style="margin-right: 5px;"></i>TikTok</div>
                <div v-if="form.tiktokId" class="form-edit-line text-break">
                  <a :href="'https://tiktok.com/' + form.tiktokId" target="_blank">@{{ form.tiktokId }}</a>
                </div>
                <div v-if="form.soundcloudId" class="form-label align-bottom"><i class="fa-brands fa-soundcloud fa-fw" style="margin-right: 5px;"></i>SOUNDCLOUD</div>
                <div v-if="form.soundcloudId" class="form-edit-line text-break">
                  <a :href="'https://soundcloud.com/' + form.soundcloudId" target="_blank">@{{ form.soundcloudId }}</a>
                </div>
                <div v-if="form.youtubeUrl" class="form-label align-bottom"><i class="fa-brands fa-youtube fa-fw" style="margin-right: 5px;"></i>YouTube</div>
                <div v-if="form.youtubeUrl" class="form-edit-line text-break">
                  <a :href="form.youtubeUrl" target="_blank">{{ form.youtubeUrl }}</a>
                </div>
                <div v-if="form.appleMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-apple fa-fw" style="margin-right: 5px;"></i>Apple Music</div>
                <div v-if="form.appleMusicUrl" class="form-edit-line text-break">
                  <a :href="form.appleMusicUrl" target="_blank">{{ form.appleMusicUrl }}</a>
                </div>
                <div v-if="form.spotifyUrl" class="form-label align-bottom"><i class="fa-brands fa-spotify fa-fw" style="margin-right: 5px;"></i>Spotify</div>
                <div v-if="form.spotifyUrl" class="form-edit-line text-break">
                  <a :href="form.spotifyUrl" target="_blank">{{ form.spotifyUrl }}</a>
                </div>
                <div v-if="form.youtubeMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-youtube-square fa-fw" style="margin-right: 5px;"></i>YouTube Music</div>
                <div v-if="form.youtubeMusicUrl" class="form-edit-line text-break">
                  <a :href="form.youtubeMusicUrl" target="_blank">{{ form.youtubeMusicUrl }}</a>
                </div>
                <div v-if="form.amazonMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-amazon fa-fw" style="margin-right: 5px;"></i>Amazon Music</div>
                <div v-if="form.amazonMusicUrl" class="form-edit-line text-break">
                  <a :href="form.amazonMusicUrl" target="_blank">{{ form.amazonMusicUrl }}</a>
                </div>
                <div v-if="form.lineMusicUrl" class="form-label align-bottom"><i class="fa-brands fa-line fa-fw" style="margin-right: 5px;"></i>LINE Music</div>
                <div v-if="form.lineMusicUrl" class="form-edit-line text-break">
                  <a :href="form.lineMusicUrl" target="_blank">{{ form.lineMusicUrl }}</a>
                </div>
                <div v-for="site in form.siteList" :key="site.siteUrl">
                  <div class="form-label align-bottom"><i class="fas fa-globe fa-fw" style="margin-right: 5px;"></i>{{ site.name }}</div>
                  <div class="form-edit-line text-break">
                    <a :href="site.siteUrl" target="_blank">{{ site.siteUrl }}</a>
                  </div>
                </div>
                <div v-if="form.photoList && form.photoList.length > 0" class="form-label align-bottom">アー写</div>
                <div v-if="form.photoList && form.photoList.length > 0" class="form-edit-line">
                  <div v-for="(photo, index) in form.photoList" :key="photo.blobName">
                    <a @click="selectPhoto(index)" href="#"><img v-if="photo.url" :src="photo.url" class="img-thumbnail float-start" style="max-width: 180px; margin: 5px;"></a>
                  </div>
                </div>
                <div v-if="form.memo" class="form-label align-bottom">メモ</div>
                <div v-if="form.memo" class="form-edit-line">
                  <span v-html="form.memo"></span>
                </div>
                <div v-if="form.createdAt" class="form-label align-bottom">登録</div>
                <div v-if="form.createdAt" class="form-edit-line">
                  {{form.createdAt}}（{{form.createdBy}}）
                </div>
                <div v-if="form.updatedAt" class="form-label align-bottom">最終更新</div>
                <div v-if="form.updatedAt" class="form-edit-line">
                  {{form.updatedAt}}（{{form.updatedBy}}）
                </div>
              </div>
              <div class="col-lg-6">
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item">
                    <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
                      原盤
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                      アルバム
                    </a>
                  </li>
<!--                  <li class="nav-item">-->
<!--                    <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }" aria-current="page" href="#">-->
<!--                      分析-->
<!--                    </a>-->
<!--                  </li>-->
                </ul>
                <div v-if="rightAreaMode === 'MASTER'">
                  <div class="input-group">
                    <input @keydown.enter="masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                    <button v-show="masterCondition.searchString" @click="masterClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                    <button @click="masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                  </div>
                  <table v-if="masterList" class="table table-responsive">
                    <tbody>
                      <tr v-for="(master, index) in masterList" :key="index" class="align-middle">
                        <td class="text-center align-middle" style="width: 55px;">
                          <router-link class="btn btn-outline-primary btn-sm" :to="'/red/master/disp/' + master.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                        </td>
                        <td>
                          <span v-if="master.isrc" style="font-size: 80%;">ISRC : {{master.isrc}}<br></span>
                          {{master.displayName}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-6 d-flex justify-content-start">
                      <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
                      <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                    </div>
                    <div v-if="masterResult && masterResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                      <nav v-if="masterResult" aria-label="Page navigation">
                        <ul class="pagination">
                          <li v-if="masterCondition.page !== 0" class="page-item">
                            <button @click="masterFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                          </li>
                          <li v-if="masterCondition.page !== 0" class="page-item">
                            <button @click="masterPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                          </li>
                          <li class="page-item"><span class="page-link">{{ masterCondition.page + 1 }}/{{ masterResult.allPages }}ページ</span></li>
                          <li v-if="masterCondition.page !== masterResult.allPages - 1" class="page-item">
                            <button @click="masterNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                          </li>
                          <li v-if="masterCondition.page !== masterResult.allPages - 1" class="page-item">
                            <button @click="masterLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div v-if="rightAreaMode === 'ALBUM'">
                  <div class="input-group">
                    <input @keydown.enter="albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                    <button v-show="albumCondition.searchString" @click="albumClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                    <button @click="findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                  </div>
                  <table v-if="albumList" class="table table-responsive">
                    <tbody>
                      <tr v-for="(album, index) in albumList" :key="index" class="align-middle">
                        <td class="text-center" style="width: 55px;">
                          <router-link class="btn btn-outline-primary btn-sm" :to="'/red/album/disp/' + album.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                        </td>
                        <td>
                          <p v-if="album.catalogNo" style="margin: 0; font-size: 80%;"><span v-html="album.catalogNo"></span></p>
                          {{album.name}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-6 d-flex justify-content-start">
                      <span v-if="albumResult && albumResult.allRecords > 0">{{ albumResult.allRecords }}件の一致データ</span>
                      <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                    </div>
                  </div>
                </div>
                <div v-if="rightAreaMode === 'PROFIT'">
                  <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                    <li class="nav-item">
                      <a @click="profitMode = 'COPYRIGHT_INCOME'" class="nav-link"
                        :class="{ 'active': profitMode === 'COPYRIGHT_INCOME' }" aria-current="page" href="#">
                        著作権収入
                      </a>
                    </li>
                    <li class="nav-item">
                      <a @click="profitMode = 'ALBUM_INCOME'" class="nav-link"
                        :class="{ 'active': profitMode === 'ALBUM_INCOME' }" aria-current="page" href="#">
                        アルバム収入
                      </a>
                    </li>
                    <li class="nav-item">
                      <a @click="profitMode = 'MASTER_INCOME'" class="nav-link"
                        :class="{ 'active': profitMode === 'MASTER_INCOME' }" aria-current="page" href="#">
                        原盤収入
                      </a>
                    </li>
                    <li class="nav-item">
                      <a @click="profitMode = 'MASTER_USED_NO'" class="nav-link"
                        :class="{ 'active': profitMode === 'MASTER_USED_NO' }" aria-current="page" href="#">
                        原盤使用数
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="photoModalDiv">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img :src="photoUrl" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { artistFunction } from './function'
import { masterFunction } from '@/views/red/master/function'
import { albumStore, masterStore } from './store'
import { albumFunction } from '@/views/red/album/function'
import footerComponent from '@/components/Footer.vue'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const artistFunc = artistFunction()
    const photoModalDiv = ref(null)
    let photoModal = null
    const albumName = ref('')
    const masterName = ref('')
    // Form
    const form = ref(null)
    const rightAreaMode = ref('MASTER')
    const profitMode = ref('COPYRIGHT_INCOME')
    const photoUrl = ref('')
    // 原盤一覧
    const masterCondition = masterStore()
    const masterFind = masterFunction()
    const masterResult = ref(null)
    const masterList = ref([])
    // アルバム一覧
    const albumCondition = albumStore()
    const albumFind = albumFunction()
    const albumResult = ref(null)
    const albumList = ref([])
    // メソッド
    const selectPhoto = (index) => {
      photoUrl.value = form.value.photoList[index].url
      photoModal.show()
    }
    const init = async (id) => {
      form.value = {
        id: id
      }
      const promises = []
      promises.push(artistFunc.get(id))
      promises.push(artistFunc.findPhoto(id))
      promises.push(artistFunc.findSite(id))
      promises.push(findMaster())
      promises.push(findAlbum())
      if (route.path.includes('/red/album/artist/disp/')) {
        promises.push(albumFind.get(route.params.albumId))
      }
      if (route.path.includes('/red/master/artist/disp/')) {
        promises.push(masterFind.get(route.params.masterId))
      }
      const response = await Promise.all(promises)
      form.value = response[0]
      form.value.photoList = response[1]
      form.value.siteList = response[2]
      if (route.path.includes('/red/album/artist/disp/')) {
        albumName.value = response[5].name
        if (albumName.value.length > 15) {
          albumName.value = albumName.value.substring(0, 15) + '...'
        }
      }
      if (route.path.includes('/red/master/artist/disp/')) {
        masterName.value = response[5].name
        if (masterName.value.length > 15) {
          masterName.value = masterName.value.substring(0, 15) + '...'
        }
      }
    }
    const findMaster = async () => {
      const result = await masterFind.findByArtist(form.value.id, masterCondition)
      masterResult.value = result
      masterList.value = result.list
    }
    const masterClearSearch = () => {
      masterCondition.searchString = ''
      masterCondition.page = 0
      findMaster()
    }
    const masterFirstPage = () => {
      masterCondition.page = 0
      findMaster()
    }
    const masterPrevPage = () => {
      masterCondition.page = masterCondition.page - 1
      findMaster()
    }
    const masterNextPage = () => {
      masterCondition.page = masterCondition.page + 1
      findMaster()
    }
    const masterLastPage = () => {
      masterCondition.page = masterResult.value.allPages - 1
      findMaster()
    }
    const findAlbum = async () => {
      const result = await albumFind.findByArtist(form.value.id, albumCondition)
      albumResult.value = result
      albumList.value = result.list
    }
    const albumClearSearch = () => {
      albumCondition.searchString = ''
      albumCondition.page = 0
      findAlbum()
    }
    const albumFirstPage = () => {
      albumCondition.page = 0
      findAlbum()
    }
    const albumPrevPage = () => {
      albumCondition.page = albumCondition.page - 1
      findAlbum()
    }
    const albumNextPage = () => {
      albumCondition.page = albumCondition.page + 1
      findAlbum()
    }
    const albumLastPage = () => {
      albumCondition.page = albumResult.value.allPages - 1
      findAlbum()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      photoModal = new Modal(photoModalDiv.value, {
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      route,
      router,
      photoModalDiv,
      form,
      albumName,
      masterName,
      rightAreaMode,
      profitMode,
      photoUrl,
      selectPhoto,
      masterCondition,
      masterResult,
      masterList,
      findMaster,
      masterClearSearch,
      masterFirstPage,
      masterPrevPage,
      masterNextPage,
      masterLastPage,
      albumCondition,
      albumResult,
      albumList,
      findAlbum,
      albumClearSearch,
      albumFirstPage,
      albumPrevPage,
      albumNextPage,
      albumLastPage
    }
  }
})
</script>
