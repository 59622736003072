<template>
  <div class="container">
    <div class="row" style="margin-top: 10px;">
      <div class="d-flex justify-content-center">
        <img src="../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div style="height: 40px;"></div>
    <div class="alert alert-danger">
      <p class="h2"><i class="fas fa-exclamation-circle" style="margin-right: 10px;"></i>システムエラーが発生しました</p>
      <p class="text-start">ご迷惑をおかけして大変申し訳ございません。システムエラーが発生したため直前の処理を失敗している可能性があります。運営会社でも自動的に状況の把握はしておりますが、より詳しく状況把握をするために、行っていた作業内容を下記連絡先までご連絡いただけますと幸いです。</p>
      <p class="text-start" style="margin: 0;">メール : <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a></p>
      <p class="text-start" style="margin: 0;">サポートポータル（当社と契約がある場合） : <a href="https://monstr2.rights.tokyo" target="_blank">https://monstr2.rights.tokyo</a></p>
    </div>
    <footer class="alert alert-warning" style="margin-top: 50px;">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
  </div>
</template>
