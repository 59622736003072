<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/green/organization/list"><i class="fas fa-users fa-fw" style="margin-right: 5px;"></i>受領グループ一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/green/organization/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/green/organization/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div class="form-label align-bottom">
                招待キー
                <button @click="copy" class="btn btn-outline-primary btn-sm"><i class="fas fa-copy fa-fw"></i></button>
              </div>
              <div class="form-edit-line" style="word-break: break-all; margin-top: 5px;">
                <textarea class="form-control" rows="10" v-model="form.token" disabled></textarea>
              </div>
              <div>グループに参加させたい方に招待キーを安全な方法で送付し、キーを用いて参加してもらってください。</div>
              <div class="alert alert-danger" style="margin-top: 20px;">このキーがあると誰でもグループに参加できてしまいます。取り扱いには十分に注意してください。</div>
              <div class="alert alert-info" style="margin-top: 5px;">
                rights.servicesにアカウント作成済みの方のみグループに追加することができます。新規にアカウントの追加をご希望の場合には、以下の内容を記載の上、
                <a href="mailto:supprt@monstrs.rights.tokyo">supprt@monstrs.rights.tokyo</a>までメールをお送りください。
                <ol>
                  <li>追加する方のお名前</li>
                  <li>追加する方のメールアドレス</li>
                </ol>
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">
                    グループに含める送付元
                  </a>
                </li>
              </ul>
              <div>
                <table v-if="form.destinationList" class="table table-responsive">
                  <tbody>
                    <tr v-for="(destination, index) in form.destinationList" :key="index" class="align-middle">
                      <td>
                        {{destination.destinationName}}<br>
                        <span v-if="destination.hasCopyrightStatement" class="badge bg-success" style="margin-right: 5px;">著作権</span>
                        <span v-if="destination.hasMasterStatement" class="badge bg-success">原盤</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="form.destinationList.length > 0">{{ form.destinationList.length }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { organizationFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const organizationFunc = organizationFunction()
    // Form
    const form = ref(null)
    const destinationList = ref([])
    // メソッド
    async function init (id) {
      form.value = await organizationFunc.get(id)
    }
    const copy = () => {
      navigator.clipboard.writeText(form.value.token)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      session,
      router,
      form,
      destinationList,
      copy
    }
  }
})
</script>
