<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/green/organization/list"><i class="fas fa-users fa-fw" style="margin-right: 5px;"></i>受領グループ一覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/green/organization/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
              <div class="form-edit-line">
                <input v-model="form.name" type="text" class="form-control">
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">
                    グループに含める送付元
                  </a>
                </li>
              </ul>
              <div>
                <table v-if="destinationList" class="table table-responsive">
                  <tbody>
                  <tr v-for="(destination, index) in destinationList" :key="index" class="align-middle" :class="{'deleted-row': destination.deleted}">
                    <td style="width: 60px" class="text-center">
                      <button v-if="destination.joined" @click="destination.joined = false" class="btn btn-primary btn-sm"><i class="fas fa-circle fa-fw"></i></button>
                      <button v-if="!destination.joined" @click="destination.joined = true" class="btn btn-secondary btn-sm"><i class="fas fa-times fa-fw"></i></button>
                    </td>
                    <td>
                      {{destination.destinationName}}<br>
                      <span v-if="destination.hasCopyrightStatement" class="badge bg-success" style="margin-right: 5px;">著作権</span>
                      <span v-if="destination.hasMasterStatement" class="badge bg-success">原盤</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="destinationList.length > 0">{{ destinationList.length }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 50px;"></div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">受領グループの削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の受領グループを削除しようとしています。削除すると、この受領グループに参加している人全員が受領グループに紐付く明細の閲覧ができなくなります。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { organizationFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const organizationFunc = organizationFunction()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const form = ref(null)
    const destinationList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        form.value.destinationList = destinationList.value
        if (!form.value.id) {
          const response = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/organization', form.value, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).catch(error => {
            ex.identifyEx(error)
            errors.value = ['システムエラーが発生したため保存されませんでした']
            errorToast.show()
          })
          form.value.id = response.data
        } else {
          await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/organization/' + form.value.id, form.value, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).catch(error => {
            ex.identifyEx(error)
            errors.value = ['システムエラーが発生したため保存されませんでした']
            errorToast.show()
          })
        }
        await router.push('/green/organization/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/organization/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      deleteModal.hide()
      await router.push('/green/organization/list')
    }
    async function init (id) {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          name: ''
        }
      } else {
        form.value = await organizationFunc.get(id)
      }
      const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/destination/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      for (const destination of data) {
        destinationList.value.push({
          destinationId: destination.id,
          destinationName: destination.name
        })
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      session,
      router,
      errors,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      save,
      openDeleteModal,
      executeDelete,
      destinationList
    }
  }
})
</script>
