<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i> 契約</li>
                  <li class="breadcrumb-item">
                    <router-link to="/common/contract/disp"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div v-if="form" class="container text-start">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
            <div class="form-label align-bottom">使用システム</div>
            <div class="form-edit-line">
              <span v-if="form.blue === 'YES'" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
              <span v-if="form.red === 'YES'" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
              <span v-if="form.pink === 'YES'" class="badge text-white" style="background-color: #EE86A8; margin-right: 5px;">PINK</span>
              <span v-if="form.green === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">GREEN</span>
            </div>
            <div class="form-label align-bottom">ユーザー</div>
            <div class="form-edit-line">
              <button @click="openUserAdd" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(user, index) in form.accountList" :key="index" :class="{ 'deleted-row': user.deleted }">
                    <td>{{ user.name }}</td>
                    <td>
                      <span style="font-size: 90%;">{{user.email}}</span>
                    </td>
                    <td style="width: 130px;" class="text-end">
                      <select v-model="user.hasPicRole" class="form-select form-select-sm" style="width: 120px;">
                        <option value="NO">一般</option>
                        <option value="YES">責任者</option>
                      </select>
                    </td>
                    <td style="width: 50px;" class="text-center">
                      <button @click="deleteUser(index)" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="form.red === 'YES'" class="card" style="margin-bottom: 10px;">
              <div class="card-header text-white" style="background-color: #E83821;">RED設定</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/red" target="_blank">こちら</a></div>
                <div class="form-edit-line">
                  {{ form.planRed }}
                </div>
                <div class="form-label align-bottom">
                  会社
                </div>
                <div class="form-edit-line">
                  <button @click="openCompanyAdd" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in companyList" :key="index" :class="{ 'deleted-row': company.deleted }">
                      <td>{{ company.nameDp }}</td>
                      <td style="width: 50px;" class="text-center">
                        <button @click="company.deleted = !company.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="form.green === 'YES'" class="card" style="margin-bottom: 10px;">
              <div class="card-header text-white" style="background-color: #8EC31F;">GREEN設定</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/green" target="_blank">こちら</a></div>
                <div class="form-edit-line">
                  {{ form.planGreen }}
                </div>
                <div class="form-label align-bottom">問い合わせ用メールアドレス</div>
                <div class="form-edit-line">
                  <input type="email" class="form-control" v-model="form.greenQaEmail">
                </div>
                <div class="form-label align-bottom">連携システム</div>
                <div class="form-edit-line">
                  <span v-if="form.greenRed === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">RED</span>
                  <span v-if="form.greenCapo === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">capo</span>
                  <span v-if="form.greenJrca === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">JRCA</span>
                  <span v-if="form.greenContano === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">contano</span>
                </div>
                <div v-if="form.greenCapo === 'YES'" class="form-label align-bottom">capo会社</div>
                <div v-if="form.greenCapo === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in capoCompanyList" :key="index" :class="{ 'deleted-row': company.deleted }">
                      <td>{{ company.companyName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="form.greenJrca === 'YES'" class="form-label align-bottom">JRCA会社</div>
                <div v-if="form.greenJrca === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in jrcaCompanyList" :key="index" :class="{ 'deleted-row': company.deleted }">
                      <td>{{ company.companyName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="form.greenContano === 'YES'" class="form-label align-bottom">contano会社</div>
                <div v-if="form.greenContano === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in contanoCompanyList" :key="index" :class="{ 'deleted-row': company.deleted }">
                      <td>{{ company.companyName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-label align-bottom">PDFダウンロード</div>
                <div class="form-edit-line">
                  <select v-model="form.downloadablePdf" class="form-select">
                    <option value="true">受領者によるPDFダウンロードを許可する</option>
                    <option value="false">受領者によるPDFダウンロードを許可しない</option>
                  </select>
                </div>
                <div class="form-label align-bottom">テキストダウンロード</div>
                <div class="form-edit-line">
                  <select v-model="form.downloadableText" class="form-select">
                    <option value="true">受領者によるテキストダウンロードを許可する</option>
                    <option value="false">受領者によるテキストダウンロードを許可しない</option>
                  </select>
                </div>
                <div class="form-label align-bottom">
                  権利者情報収集挨拶
                  <button @click="openGreetingModal('collect', -1)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus fa-fw"></i></button>
                </div>
                <div class="form-edit-line">
                  <p v-for="(greeting, index) in collectGreetingList" :key="greeting.id" style="margin: 0 0 2px 0;">
                    <button @click="openGreetingModal('collect', index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-edit fa-fw"></i></button>
                    {{greeting.title}}
                  </p>
                </div>
                <div class="form-label align-bottom">
                  事業者登録番号収集挨拶
                  <button @click="openGreetingModal('invoice', -1)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus fa-fw"></i></button>
                </div>
                <div class="form-edit-line">
                  <p v-for="(greeting, index) in invoiceGreetingList" :key="greeting.id" style="margin: 0 0 2px 0;">
                    <button @click="openGreetingModal('invoice', index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-edit fa-fw"></i></button>
                    {{greeting.title}}
                  </p>
                </div>
              </div>
            </div>
<!--            <ul class="nav nav-tabs" style="margin-bottom: 10px;">-->
<!--              <li v-if="form.blue === 'YES'" class="nav-item">-->
<!--                <a @click="detailMode = 'BLUE'" class="nav-link" :class="{ 'active': detailMode === 'BLUE' }" href="#">-->
<!--                  <i class="fas fa-square" style="margin-right: 5px; color: #0097E0;"></i>BLUE-->
<!--                </a>-->
<!--              </li>-->
<!--              <li v-if="form.pink === 'YES'" class="nav-item">-->
<!--                <a @click="detailMode = 'PINK'" class="nav-link" :class="{ active: detailMode === 'PINK' }" href="#">-->
<!--                  <i class="fas fa-square" style="margin-right: 5px; color: #EE86A8;"></i>PINK-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="userAddModalDiv">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ユーザーの追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="userName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
            <div class="form-edit-line">
              <input v-model="userEmail" type="email" class="form-control">
            </div>
            <div class="form-label align-bottom">権限</div>
            <div class="form-edit-line">
              <select v-model="userHasPicRole" class="form-select" style="width: 120px;">
                <option value="NO">一般</option>
                <option value="YES">責任者</option>
              </select>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="addUser" type="button" class="btn btn-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="companyModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">会社の追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名称</div>
            <div class="form-edit-line">
              <input v-model="company.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>表示用名称</div>
            <div class="form-edit-line">
              <input v-model="company.nameDp" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">
              分配期
              <button @click="addDivTermSetting(company)" class="btn btn-sm btn-outline-primary"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-edit-line">
              <div v-for="(divTermSetting, index) in company.divTermSettingList" :key="index" class="input-group" style="margin-top: 3px;">
                <span class="input-group-text">{{ index + 1 }}期</span>
                <select v-model="divTermSetting.startMonthYearType" class="form-select" style="flex: 0 1 90px;">
                  <option value="previous">前年</option>
                  <option value="current">当年</option>
                </select>
                <select v-model="divTermSetting.startMonth" class="form-select" style="flex: 0 1 100px;">
                  <option value="1">1月</option>
                  <option value="2">2月</option>
                  <option value="3">3月</option>
                  <option value="4">4月</option>
                  <option value="5">5月</option>
                  <option value="6">6月</option>
                  <option value="7">7月</option>
                  <option value="8">8月</option>
                  <option value="9">9月</option>
                  <option value="10">10月</option>
                  <option value="11">11月</option>
                  <option value="12">12月</option>
                </select>
                <span class="input-group-text">〜</span>
                <select v-model="divTermSetting.endMonthYearType" class="form-select" style="flex: 0 1 90px;">
                  <option value="current">当年</option>
                  <option value="next">翌年</option>
                </select>
                <select v-model="divTermSetting.endMonth" class="form-select" style="flex: 0 1 100px;">
                  <option value="1">1月</option>
                  <option value="2">2月</option>
                  <option value="3">3月</option>
                  <option value="4">4月</option>
                  <option value="5">5月</option>
                  <option value="6">6月</option>
                  <option value="7">7月</option>
                  <option value="8">8月</option>
                  <option value="9">9月</option>
                  <option value="10">10月</option>
                  <option value="11">11月</option>
                  <option value="12">12月</option>
                </select>
                <span class="input-group-text">支払日</span>
                <select v-model="divTermSetting.paymentMonthYearType" class="form-select" style="flex: 0 1 90px;">
                  <option value="previous">前年</option>
                  <option value="current">当年</option>
                  <option value="next">翌年</option>
                </select>
                <select v-model="divTermSetting.paymentMonth" class="form-select" style="flex: 0 1 90px;">
                  <option value="1">1月</option>
                  <option value="2">2月</option>
                  <option value="3">3月</option>
                  <option value="4">4月</option>
                  <option value="5">5月</option>
                  <option value="6">6月</option>
                  <option value="7">7月</option>
                  <option value="8">8月</option>
                  <option value="9">9月</option>
                  <option value="10">10月</option>
                  <option value="11">11月</option>
                  <option value="12">12月</option>
                </select>
                <select v-model="divTermSetting.paymentDateType" class="form-select" style="flex: 0 1 90px;">
                  <option value="last">末日</option>
                  <option value="exact">指定</option>
                </select>
                <input v-if="divTermSetting.paymentDateType !== 'last'" v-model="divTermSetting.paymentDate" type="number" class="form-control">
                <span v-if="divTermSetting.paymentDateType !== 'last'" class="input-group-text">日</span>
                <select v-model="divTermSetting.paymentOnHoliday" class="form-select">
                  <option value="before">休日の場合は前日</option>
                  <option value="after">休日の場合は翌日</option>
                </select>
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>初回分配期</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="company.divYear" type="number" class="form-control" style="flex: 0 1 100px;">
                <span class="input-group-text">年</span>
                <select v-model="company.divTerm" class="form-select" style="flex: 0 1 70px;">
                  <option v-for="(divTermSetting, index) in company.divTermSettingList" :key="index" :value="index + 1">
                    {{ index + 1 }}
                  </option>
                </select>
                <span class="input-group-text">期</span>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="addCompany" type="button" class="btn btn-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="greetingModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">
              用途
            </div>
            <div class="form-edit-line">
              <input v-model="greetingTitle" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">
              挨拶文
            </div>
            <div class="form-edit-line">
              <textarea v-model="greeting" class="form-control" rows="10"></textarea>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="applyGreeting" type="button" class="btn btn-outline-primary"><i class="fas fa-check fa-fw" style="margin-right: 5px;"></i>適用</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { exFunction } from '@/functions/exception'
import { contractFindFunction } from './function'
import navComponent from '@/components/Nav'
import footerComponent from '@/components/Footer'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const contractFind = contractFindFunction()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const greetingModalDiv = ref(null)
    let greetingModal = null
    // Form
    const form = ref(null)
    const detailMode = ref('BLUE')
    // GREEN会社
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    // ユーザー
    const userAddModalDiv = ref(null)
    let userAddModal = null
    const userName = ref('')
    const userEmail = ref('')
    const userHasPicRole = ref('NO')
    // 会社追加
    const companyModalDiv = ref(null)
    let companyModal = null
    const company = ref({})
    // BLUE/RED会社
    const companyList = ref([])
    const collectGreetingList = ref([])
    const invoiceGreetingList = ref([])
    const greetingType = ref('collect')
    const greetingIndex = ref(0)
    const greeting = ref('')
    const greetingTitle = ref('')
    // メソッド
    const openUserAdd = () => {
      userName.value = ''
      userEmail.value = ''
      userAddModal.show()
    }
    const addUser = () => {
      errors.value = []
      if (!userName.value) {
        errors.value.push('名前を入力してください')
      }
      if (!userEmail.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(userEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (form.value.accountList.findIndex(e => e.email === userEmail.value) > -1) {
        errors.value.push('同じメールアドレスが追加済みです')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        form.value.accountList.push({
          name: userName.value,
          email: userEmail.value,
          hasPicRole: userHasPicRole.value,
          deleted: false
        })
        userAddModal.hide()
      }
    }
    const deleteUser = (index) => {
      console.log(form.value.accountList[index])
      if (form.value.accountList[index].accountId) {
        form.value.accountList[index].deleted = !form.value.accountList[index].deleted
      } else {
        form.value.accountList.splice(index, 1)
      }
    }
    const openCompanyAdd = () => {
      company.value = {
        name: '',
        nameDp: '',
        divTermSettingList: [{
          startMonth: 1,
          startMonthYearType: 'current',
          endMonth: 1,
          endMonthYearType: 'current',
          paymentMonthYearType: 'current',
          paymentMonth: 1,
          paymentDateType: 'last',
          paymentOnHoliday: 'before'
        }],
        divYear: new Date().getFullYear(),
        divTerm: 1
      }
      companyModal.show()
    }
    const save = async () => {
      let isExistsPic = false
      for (const user of form.value.accountList) {
        if (user.hasPicRole === 'YES') {
          isExistsPic = true
        }
      }
      if (userEmail.value) {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(userEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (!isExistsPic) {
        errors.value.push('責任者は最低一人必要です')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        await axios.put(process.env.VUE_APP_API_ENDPOINT + '/contract', {
          name: form.value.name,
          blue: form.value.blue,
          red: form.value.red,
          pink: form.value.pink,
          green: form.value.green,
          greenRed: form.value.greenRed,
          greenCapo: form.value.greenCapo,
          greenJrca: form.value.greenJrca,
          greenContano: form.value.greenContano,
          greenQaEmail: form.value.greenQaEmail,
          planRed: form.value.planRed,
          planGreen: form.value.planGreen,
          downloadablePdf: form.value.downloadablePdf,
          downloadableText: form.value.downloadableText,
          accountList: form.value.accountList
        }, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).catch(error => {
          ex.identifyEx(error)
        })
        for (const company of companyList.value) {
          console.log(company)
          if (company.divTermSettingList) {
            for (let index = 0; index < company.divTermSettingList.length; index++) {
              const divTermSetting = company.divTermSettingList[index]
              divTermSetting.divTerm = index + 1
            }
          }
          // delete company.div_term_target_month_type
          // delete company.div_term_setting_list
          if (company.deleted) {
            if (company.id) {
              await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + company.id, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              })
              await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/company/' + company.id, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              })
            }
          } else {
            if (!company.id) {
              const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/company', company, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              })
              company.id = data
              await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company', {
                name: company.name,
                kana: '',
                nameEn: '',
                divTermSettingList: company.divTermSettingList,
                divTerm: company.divTermModel
              }, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
                params: { companyId: company.id }
              })
            } else {
              await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/company/' + company.id, company, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              })
            }
          }
        }
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/collect/greeting', collectGreetingList.value, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting', invoiceGreetingList.value, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        await router.push('/common/contract/disp')
      }
    }
    const addCompany = () => {
      errors.value = []
      if (!company.value.name) {
        errors.value.push('名称を入力してください')
      }
      if (!company.value.nameDp) {
        errors.value.push('表示用名称を入力してください')
      }
      if (!company.value.divYear || !company.value.divTerm) {
        errors.value.push('初回分配期を入力／選択してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        companyList.value.push({
          id: null,
          contractId: session.contractId,
          name: company.value.name,
          nameDp: company.value.nameDp,
          notInUse: false,
          useOnBlue: false,
          useOnRed: true,
          useOnPink: false,
          divTermSettingList: company.value.divTermSettingList,
          divTermModel: {
            divYear: company.value.divYear,
            divTerm: company.value.divTerm
          },
          deleted: false
        })
      }
      companyModal.hide()
    }
    const addDivTermSetting = (company) => {
      company.divTermSettingList.push({
        startMonth: 1,
        startMonthYearType: 'current',
        endMonth: 1,
        endMonthYearType: 'current',
        paymentMonthYearType: 'current',
        paymentMonth: 1,
        paymentDateType: 'last',
        paymentOnHoliday: 'before'
      })
    }
    const init = async () => {
      let promises = []
      promises.push(contractFind.get())
      promises.push(contractFind.findCompany())
      promises.push(contractFind.findCollectGreeting())
      promises.push(contractFind.findInvoiceGreeting())
      let response = await Promise.all(promises)
      form.value = response[0]
      companyList.value = response[1]
      collectGreetingList.value = response[2]
      invoiceGreetingList.value = response[3]
      if (form.value.blue === 'YES') {
        detailMode.value = 'BLUE'
      } else if (form.value.red === 'YES') {
        detailMode.value = 'RED'
      } else if (form.value.pink === 'YES') {
        detailMode.value = 'PINK'
      } else if (form.value.green === 'YES') {
        detailMode.value = 'GREEN'
      }
      promises = []
      if (form.value.green === 'YES') {
        promises.push(contractFind.findGreenCompany('capo'))
        promises.push(contractFind.findGreenCompany('jrca'))
        promises.push(contractFind.findGreenCompany('contano'))
        response = await Promise.all(promises)
        capoCompanyList.value = response[0]
        jrcaCompanyList.value = response[1]
        contanoCompanyList.value = response[2]
      }
    }
    const updateRightsHolder = async () => {
      processMessage.value = '権利者の挨拶文を更新しています。しばらくお待ちください。'
      processModal.show()
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/greeting/update', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      processModal.hide()
    }
    const openGreetingModal = (target, index) => {
      greetingType.value = target
      greetingIndex.value = index
      if (index !== -1) {
        if (target === 'collect') {
          greetingTitle.value = collectGreetingList.value[index].title
          greeting.value = collectGreetingList.value[index].greeting
        }
        if (target === 'invoice') {
          greetingTitle.value = invoiceGreetingList.value[index].title
          greeting.value = invoiceGreetingList.value[index].greeting
        }
      } else {
        greetingTitle.value = ''
        greeting.value = ''
      }
      greetingModal.show()
    }
    const applyGreeting = () => {
      errors.value = []
      if (!greetingTitle.value) {
        errors.value.push('用途を入力してください')
      }
      if (!greeting.value) {
        errors.value.push('挨拶文を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (greetingIndex.value === -1) {
          if (greetingType.value === 'collect') {
            collectGreetingList.value.push({
              title: greetingTitle.value,
              greeting: greeting.value
            })
          } else {
            invoiceGreetingList.value.push({
              title: greetingTitle.value,
              greeting: greeting.value
            })
          }
        } else {
          if (greetingType.value === 'collect') {
            collectGreetingList.value[greetingIndex.value] = {
              title: greetingTitle.value,
              greeting: greeting.value
            }
          } else {
            invoiceGreetingList.value[greetingIndex.value] = {
              title: greetingTitle.value,
              greeting: greeting.value
            }
          }
        }
        greetingModal.hide()
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      userAddModal = new Modal(userAddModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      companyModal = new Modal(companyModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      greetingModal = new Modal(greetingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      session,
      errors,
      errorToastDiv,
      form,
      detailMode,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      userAddModalDiv,
      userName,
      userEmail,
      userHasPicRole,
      companyModalDiv,
      company,
      companyList,
      openUserAdd,
      addUser,
      deleteUser,
      openCompanyAdd,
      addCompany,
      save,
      addDivTermSetting,
      collectGreetingList,
      invoiceGreetingList,
      processModalDiv,
      processMessage,
      updateRightsHolder,
      greetingModalDiv,
      greeting,
      greetingTitle,
      openGreetingModal,
      greetingType,
      greetingIndex,
      applyGreeting
    }
  }
})
</script>
