import axios from 'axios'
import { authFunction } from '@/functions/auth'

export const indexFunction = () => {
  const auth = authFunction()
  const execute = async (endpoint, target) => {
    await axios.post(endpoint + '/' + target + '/index/rebuild', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const check = async () => {
    await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/check')
  }
  return { execute, check }
}
