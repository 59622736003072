<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-cabinet-filing" style="margin-right: 5px;"></i>RED設定閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" to="/red/setting/edit" class="btn btn-outline-primary">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">名前</div>
          <div class="form-edit-line fs-5" style="font-weight: bold;">
            {{ form.name }}
          </div>
          <div class="form-label align-bottom">表示用名</div>
          <div class="form-edit-line">
            {{ form.nameDp }}
          </div>
          <div v-if="form.kana" class="form-label align-bottom">カナ</div>
          <div v-if="form.kana" class="form-edit-line">
            {{ form.kana }}
          </div>
          <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
          <div v-if="form.nameEn" class="form-edit-line">
            {{ form.nameEn }}
          </div>
          <div v-if="form.postalCode || form.prefecture || form.ciry || form.townArea || form.building" class="form-label align-bottom">住所</div>
          <div v-if="form.postalCode || form.prefecture || form.ciry || form.townArea || form.building" class="form-edit-line">
            <p v-if="form.postalCode" style="margin: 0;">〒{{ form.postalCode }}</p>
            <p style="margin: 0;">{{ form.prefecture }}{{ form.city }}{{ form.townArea }}</p>
            <p style="margin: 0;">{{ form.building }}</p>
          </div>
          <div v-if="form.tel" class="form-label align-bottom">電話番号</div>
          <div v-if="form.tel" class="form-edit-line">
            {{ form.tel }}
          </div>
          <div v-if="form.email" class="form-label align-bottom">メールアドレス</div>
          <div v-if="form.email" class="form-edit-line">
            {{ form.email }}
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">支払計算書</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line">
                {{ form.statementName }}
              </div>
              <div v-if="form.statementNameEn" class="form-label align-bottom">英語名</div>
              <div v-if="form.statementNameEn" class="form-edit-line">
                {{ form.statementNameEn }}
              </div>
              <div v-if="form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding" class="form-label align-bottom">住所</div>
              <div v-if="form.statementPostalCode || form.statementPrefecture || form.statementCity || form.statementTownArea || form.statementBuilding" class="form-edit-line">
                <p v-if="form.statementPostalCode" style="margin: 0;">〒{{ form.statementPostalCode }}</p>
                <p style="margin: 0;">{{ form.statementPrefecture }}{{ form.statementCity }}{{ form.statementTownArea }}</p>
                <p style="margin: 0;">{{ form.statementBuilding }}</p>
              </div>
              <div v-if="form.statementAddressEn" class="form-label align-bottom">英語住所</div>
              <div v-if="form.statementAddressEn" class="form-edit-line">
                <span v-html="form.statementAddressEn.replaceAll('\n', '<br>')"></span>
              </div>
              <div v-if="form.statementTel" class="form-label align-bottom">電話番号</div>
              <div v-if="form.statementTel" class="form-edit-line">
                {{ form.statementTel }}
              </div>
              <div v-if="form.statementEmail" class="form-label align-bottom">メールアドレス</div>
              <div v-if="form.statementEmail" class="form-edit-line">
                {{ form.statementEmail }}
              </div>
              <div v-if="session.session && session.session.green" class="form-label align-bottom">明細作成対象</div>
              <div v-if="session.session && session.session.green" class="form-edit-line">
                <span v-if="form.makeStatementRange === 'all'">全て</span>
                <span v-if="form.makeStatementRange === 'sum_income'">前期繰越または今期発生のあるもののみ</span>
                <span v-if="form.makeStatementRange === 'income'">今期発生のあるもののみ</span>
                <span v-if="form.makeStatementRange === 'payment'">支払のあるもののみ</span>
              </div>
              <div v-if="form.statementGreeting" class="form-label align-bottom">挨拶文（初期値）</div>
              <div v-if="form.statementGreeting" class="form-edit-line">
                <span v-html="form.statementGreeting.replaceAll('\n', '<br>')"></span>
              </div>
              <div v-if="form.paymentTel" class="form-label align-bottom">鑑形式</div>
              <div v-if="form.paymentTel" class="form-edit-line">
                <span v-if="form.paymentHeaderType === 'landscape'">横</span>
                <span v-else>縦</span>
              </div>
              <div class="form-label align-bottom">振込先情報の表記</div>
              <div class="form-edit-line">
                <span v-if="form.bankInfoOnStatement">計算書に振込先を記載する</span>
                <span v-else>計算書に振込先を記載しない</span>
              </div>
              <div v-if="form.invoiceNo" class="form-label align-bottom">インボイス事業者登録番号（自社）</div>
              <div v-if="form.invoiceNo" class="form-edit-line">
                T{{ form.invoiceNo }}
              </div>
              <div class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
              <div class="form-edit-line">
                <span v-if="form.invoiceExemptionTaxPayable==='pay'">課税事業者と同様に全額支払う</span>
                <span v-if="form.invoiceExemptionTaxPayable==='deduct'">消費税相当額を支払額から控除する</span>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">控除額の算出方法</div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceDeductionTarget === 'tax'">控除前消費税相当（税抜10,000円の時、経過措置対応前の控除額を1,000円とする）</span>
                <span v-if="form.invoiceDeductionTarget === 'payment'">控除前課税対象額から算出（10,000円の内税額を算出し、これを経過措置対応前の控除額とする）</span>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">消費税相当額の控除対象</div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceDeductUnit==='rights_holder'">権利者単位</span>
                <span v-if="form.invoiceDeductUnit==='contract'">契約書単位</span>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceSmallException==='adapt'">対応する</span>
                <span v-if="form.invoiceSmallException==='not_adapt'">対応しない</span>
              </div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
              <div v-if="form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceTransitionalMeasures==='adapt'">対応する</span>
                <span v-if="form.invoiceTransitionalMeasures==='not_adapt'">対応しない</span>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">支払調書</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line">
                {{ form.paymentName }}
              </div>
              <div v-if="form.paymentPostalCode || form.paymentPrefecture || form.paymentCity || form.paymentTownArea || form.paymentBuilding" class="form-label align-bottom">住所</div>
              <div v-if="form.paymentPostalCode || form.paymentPrefecture || form.paymentCity || form.paymentTownArea || form.paymentBuilding" class="form-edit-line">
                <p v-if="form.paymentPostalCode" style="margin: 0;">〒{{ form.paymentPostalCode }}</p>
                <p style="margin: 0;">{{ form.paymentPrefecture }}{{ form.paymentCity }}{{ form.paymentTownArea }}</p>
                <p style="margin: 0;">{{ form.paymentBuilding }}</p>
              </div>
              <div v-if="form.paymentTel" class="form-label align-bottom">電話番号</div>
              <div v-if="form.paymentTel" class="form-edit-line">
                {{ form.paymentTel }}
              </div>
              <div v-if="form.paymentGreeting" class="form-label align-bottom">挨拶文（初期値）</div>
              <div v-if="form.paymentGreeting" class="form-edit-line">
                <span v-html="form.paymentGreeting.replaceAll('\n', '<br>')"></span>
              </div>
              <div v-if="form.corporateNumber" class="form-label align-bottom">法人番号</div>
              <div v-if="form.corporateNumber" class="form-edit-line">
                {{ form.corporateNumber }}
              </div>
              <div v-if="form.paymentRecordNo1 || form.paymentRecordNo2" class="form-label align-bottom">整理番号</div>
              <div v-if="form.paymentRecordNo1 || form.paymentRecordNo2" class="form-edit-line">
                <div class="input-group">
                  <span class="input-group-text">①</span>
                  <span class="input-group-text">{{form.paymentRecordNo1}}</span>
                  <span class="input-group-text">②</span>
                  <span class="input-group-text">{{form.paymentRecordNo2}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">振込元</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div v-if="bankName" class="form-label align-bottom">銀行</div>
              <div v-if="bankName" class="form-edit-line">
                {{ bankName }}（{{ bankCode }}）
              </div>
              <div v-if="branchName" class="form-label align-bottom">支店</div>
              <div v-if="branchName" class="form-edit-line">
                {{ branchName }}（{{ branchCode }}）
              </div>
              <div v-if="form.accountNo" class="form-label align-bottom">預金種目</div>
              <div v-if="form.accountNo" class="form-edit-line">
                <span v-if="form.depositItem === '1'">普通</span>
                <span v-if="form.depositItem === '2'">当座</span>
                <span v-if="form.depositItem === '4'">貯蓄</span>
                <span v-if="form.depositItem === '9'">その他</span>
              </div>
              <div v-if="form.accountNo" class="form-label align-bottom">口座番号</div>
              <div v-if="form.accountNo" class="form-edit-line">
                {{ form.accountNo }}
              </div>
              <div v-if="form.accountName" class="form-label align-bottom">口座名義</div>
              <div v-if="form.accountName" class="form-edit-line">
                {{ form.accountName }}
              </div>
              <div v-if="form.accountKana" class="form-label align-bottom">口座名義カナ</div>
              <div v-if="form.accountKana" class="form-edit-line">
                {{ form.accountKana }}
              </div>
              <div v-if="form.assignorCode" class="form-label align-bottom">振込委託者コード</div>
              <div v-if="form.assignorCode" class="form-edit-line">
                {{form.assignorCode}}
              </div>
              <div v-if="form.assignorName" class="form-label align-bottom">振込委託者名</div>
              <div v-if="form.assignorName" class="form-edit-line">
                {{form.assignorName}}
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">端数／初期値</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">受領額設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.incomeAmountScale}}
                ／
                端数処理 :
                <span v-if="form.incomeAmountRoundMode === 'round'">四捨五入</span>
                <span v-if="form.incomeAmountRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.incomeAmountRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">使用数設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.usedNoScale}}
                ／
                端数処理 :
                <span v-if="form.usedNoRoundMode === 'round'">四捨五入</span>
                <span v-if="form.usedNoRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.usedNoRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">計算対象数設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.calcTargetNoScale}}
                ／
                端数処理 :
                <span v-if="form.calcTargetNoRoundMode === 'round'">四捨五入</span>
                <span v-if="form.calcTargetNoRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.calcTargetNoRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">価格設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.priceScale}}
                ／
                端数処理 :
                <span v-if="form.priceRoundMode === 'round'">四捨五入</span>
                <span v-if="form.priceRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.priceRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">印税単価設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.unitPriceScale}}
                ／
                端数処理 :
                <span v-if="form.unitPriceRoundMode === 'round'">四捨五入</span>
                <span v-if="form.unitPriceRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.unitPriceRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">支払額設定</div>
              <div class="form-edit-line">
                小数点以下桁数 : {{form.paymentAmountScale}}
                ／
                端数処理 :
                <span v-if="form.paymentAmountRoundMode === 'round'">四捨五入</span>
                <span v-if="form.paymentAmountRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.paymentAmountRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">消費税額設定</div>
              <div class="form-edit-line">
                端数処理 :
                <span v-if="form.taxRoundMode === 'round'">四捨五入</span>
                <span v-if="form.taxRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.taxRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">源泉税額設定</div>
              <div class="form-edit-line">
                端数処理 :
                <span v-if="form.withholdingRoundMode === 'round'">四捨五入</span>
                <span v-if="form.withholdingRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.withholdingRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">権利者単位支払額設定</div>
              <div class="form-edit-line">
                端数処理 :
                <span v-if="form.totalPaymentAmountRoundMode === 'round'">四捨五入</span>
                <span v-if="form.totalPaymentAmountRoundMode === 'ceil'">切り上げ</span>
                <span v-if="form.totalPaymentAmountRoundMode === 'floor'">切り捨て</span>
              </div>
              <div class="form-label align-bottom">年度末強制支払</div>
              <div class="form-edit-line">
                {{form.lastTermOfYear}}期
                ／
                <span v-if="form.carriedForwardAtEndOfYear">保留</span>
                <span v-if="!form.carriedForwardAtEndOfYear">強制支払</span>
              </div>
              <div class="form-label align-bottom">少額保留下限額（初期値）</div>
              <div class="form-edit-line">
                ￥{{form.defaultCarriedForwardLine.toLocaleString()}}
              </div>
              <div class="form-label align-bottom">0円明細出力（初期値）</div>
              <div class="form-edit-line">
                <span v-if="form.statementDetailZeroOmit">出力しない</span>
                <span v-if="!form.statementDetailZeroOmit">出力する</span>
              </div>
            </div>
          </div>
          <div class="card" style="margin-bottom: 10px;">
            <div class="card-header">分配期</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <table v-if="form.divTermSettingList" class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 40px;">期</th>
                  <th>利用月</th>
                  <th style="width: 230px;">支払日</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="divTermSetting in form.divTermSettingList" :key="divTermSetting.divTerm">
                  <td>{{divTermSetting.divTerm}}</td>
                  <td>
                    <span v-if="divTermSetting.startMonthYearType === 'previous'">前年</span>
                    <span v-if="divTermSetting.startMonthYearType === 'current'">当年</span>
                    {{divTermSetting.startMonth}}月
                    〜
                    <span v-if="divTermSetting.endMonthYearType === 'current'">当年</span>
                    <span v-if="divTermSetting.endMonthYearType === 'next'">翌年</span>
                    {{divTermSetting.endMonth}}月
                    <span v-if="form.divTermTargetMonthType === 'use'">利用分</span>
                    <span v-if="form.divTermTargetMonthType === 'income'">入金分</span>
                  </td>
                  <td>
                    <div>
                      <span v-if="divTermSetting.paymentMonthYearType === 'previous'">前年</span>
                      <span v-if="divTermSetting.paymentMonthYearType === 'current'">当年</span>
                      <span v-if="divTermSetting.paymentMonthYearType === 'next'">翌年</span>
                      {{divTermSetting.paymentMonth}}月
                      <span v-if="divTermSetting.paymentDateType === 'last'">末日</span>
                      <span v-else>{{divTermSetting.paymentDate}}日</span>
                    </div>
                    <div style="font-size: 90%;">
                      (
                      <span v-if="divTermSetting.paymentOnHoliday === 'before'">休日の場合は<b>前日</b>に支払う</span>
                      <span v-if="divTermSetting.paymentOnHoliday === 'after'">休日の場合は<b>翌日</b>に支払う</span>
                      )
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import footerComponent from '@/components/Footer.vue'
import { redCompanyFunction, commonCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    // Form
    const companyFunc = redCompanyFunction()
    const commonCompanyFunc = commonCompanyFunction()
    const form = ref(null)
    const bankCode = ref('')
    const bankName = ref('')
    const branchCode = ref('')
    const branchName = ref('')
    const rightAreaMode = ref('STATEMENT')
    // メソッド
    const init = async () => {
      form.value = await companyFunc.get(session.session.company.id)
      const commonCompany = await commonCompanyFunc.get(session.session.company.id)
      form.value.nameDp = commonCompany.nameDp
      if (form.value.branchId) {
        axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + form.value.branchId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          const branch = response.data
          branchCode.value = branch.code
          branchName.value = branch.name
          axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + branch.bankId, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            const bank = response.data
            bankCode.value = bank.code
            bankName.value = bank.name
          })
        })
      } else {
        bankCode.value = ''
        bankName.value = ''
        branchCode.value = ''
        branchName.value = ''
      }
      form.value.divTermSettingList = await companyFunc.findDivTermSetting(session.session.company.id)
      const companyDivTerm = companyFunc.getCurrentDivTerm(session.session.company.id)
      form.value.redDivYear = companyDivTerm.divYear
      form.value.redDivTerm = companyDivTerm.divTerm
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init()
    })
    return {
      session,
      form,
      bankCode,
      bankName,
      branchCode,
      branchName,
      rightAreaMode
    }
  }
})
</script>
