import RedResultDisp from './Disp'
import RedResultList from './List'

export default {
  routes: [
    {
      path: '/red/result/list',
      name: 'RedResultList',
      component: RedResultList
    },
    {
      path: '/red/result/disp/:id',
      name: 'RedResultDisp',
      component: RedResultDisp
    }
  ]
}
