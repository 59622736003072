<template>
  <div class="container">
    <div class="row" style="margin-top: 10px;">
      <div class="d-flex justify-content-center">
        <img src="../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div style="height: 30px;"></div>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="row text-start">
        <p v-if="!isAuthCodeSent">本システムに登録されているメールアドレスを入力してください</p>
        <p v-if="isAuthCodeSent">入力したメールアドレス宛に届いた認証コードを入力してください</p>
      </div>
      <div v-if="!isAuthCodeSent" class="form-floating">
        <input v-model="email" @keypress.enter="sendAuthCode" type="email" class="form-control" id="email" placeholder="name@example.com">
        <label for="email">メールアドレス</label>
      </div>
      <div v-if="isAuthCodeSent" class="form-floating">
        <input v-model="authCode" @keypress.enter="login" type="text" class="form-control" id="authCode" placeholder="111111">
        <label for="authCode">認証コード</label>
      </div>
      <div style="height: 15px;"></div>
      <div class="d-flex justify-content-end align-items-center">
        <button v-if="!isAuthCodeSent" @click="sendAuthCode" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>認証コード送信</button>
        <div v-if="isAuthCodeSent" class="form-check form-switch" style="margin-right: 5px;">
          <input v-model="isPersistSession" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
          <label class="form-check-label" for="flexSwitchCheckDefault">この端末を認証する</label>
        </div>
        <button v-if="isAuthCodeSent" @click="login" class="btn btn-primary"><i class="fas fa-unlock" style="margin-right: 5px;"></i>ログイン</button>
      </div>
    </div>
    <div style="height: 30px;"></div>
    <div class="text-start">
      ※ログイン方法は<router-link to="/help/login" target="_blank">こちら</router-link>をご参照ください。
    </div>
    <div class="text-start" style="margin-top: 10px;">
      <button @click="openRequestModal" class="btn btn-warning" style="margin-right: 10px;"><i class="fas fa-info-circle" style="margin-right: 5px;"></i>上記リンク先の手順通りにやってもログインできない場合</button>
    </div>
    <div style="height: 20px;"></div>
    <div class="alert alert-success text-start">
      <div class="row">
        <div class="col-12">【動作環境】</div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">ブラウザ（PC）</div>
        <div class="col-12 col-md-8 col-lg-6">Google Chrome、Microsoft Edge、Firefox、Safari</div>
      </div>
      <div class="d-block d-md-none" style="height: 10px;"></div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">ブラウザ（モバイル）</div>
        <div class="col-12 col-md-8 col-lg-6">Safari、Google Chrome、Microsoft Edge</div>
      </div>
      <div class="d-block d-md-none" style="height: 10px;"></div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">OS</div>
        <div class="col-12 col-md-8 col-lg-6">Windows10、macOSX、iOS、Android、Chrome OS</div>
      </div>
      <div class="d-block d-md-none" style="height: 10px;"></div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">ハードウェア</div>
        <div class="col-12 col-md-8 col-lg-6">上記ブラウザ、OSの要件に準ずる</div>
      </div>
      <div class="d-block d-md-none" style="height: 10px;"></div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">その他</div>
        <div class="col-12 col-md-8 col-lg-6">インターネット回線への常時接続</div>
      </div>
    </div>
    <footer class="alert alert-warning">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
    <div class="modal" tabindex="-1" ref="requestModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ログインができない原因の確認</h5>
          </div>
          <div class="modal-body text-start">
            <div v-if="!requestFrom" class="form-edit-line">
              <button @click="requestFrom = 'RECEIVER'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> rights.services GREENで明細を受け取るためにログインしたい方はこちら
            </div>
            <div v-if="!requestFrom" class="form-edit-line">
              <button @click="requestFrom = 'USER'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> rights.servicesの利用契約を締結している方はこちら
            </div>
            <div v-if="requestFrom === 'USER' && !infoType" class="form-edit-line">
              <a href="https://monstr2.rights.tokyo" target="_blank">MONSTR2</a>にてお問い合わせください
            </div>
            <div v-if="requestFrom === 'RECEIVER' && !infoType">
              <div class="form-label align-bottom">現状に一番合う状況を選択してください</div>
              <div class="form-edit-line">
                <button @click="infoType = '1'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> 「メールアドレスを正しく入力してください」というエラーが出る
              </div>
              <div class="form-edit-line">
                <button @click="infoType = '2'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> 「入力されたメールアドレスはシステムに登録されていません」というエラーが出る
              </div>
              <div class="form-edit-line">
                <button @click="infoType = '3'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> 「メールアドレスの存在確認と認証コードの送信中です。しばらくお待ちください。」というメッセージが消えない
              </div>
              <div class="form-edit-line">
                <button @click="infoType = '4'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> 「認証コードが送信されました」というメッセージが出るがメールが届かない
              </div>
              <div class="form-edit-line">
                <button @click="infoType = '5'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> 届いた認証コードを入力すると「認証コードが正しくありません」というエラーが出る
              </div>
              <div class="form-edit-line">
                <button @click="infoType = '9'" class="btn btn-outline-primary btn-sm"><i class="fas fa-chevron-right"></i></button> その他
              </div>
            </div>
            <div v-if="requestFrom === 'RECEIVER' && !showRequestMethod">
              <ol v-if="infoType === '1'" class="list-group list-group-numbered">
                <li class="list-group-item">
                  ログイン画面に記載の「動作環境」に当てはまらない機器またはアプリケーションを使用していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">適切な機器またはアプリケーションをご使用ください。</span>
                </li>
                <li class="list-group-item">
                  一部の文字が全角になっていませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">全て半角でご入力ください。</span>
                </li>
                <li class="list-group-item">
                  余分なスペースが入っていませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">スペースを除いて入力してください。</span>
                </li>
                <li class="list-group-item">
                  ゼロとオーやエルとアイ等間違えやすい文字を間違えて入力していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">正しいメールアドレスを入力してください。</span>
                </li>
                <li class="list-group-item">
                  上記内容に当てはまらない場合は<a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>からお問い合わせください。
                </li>
              </ol>
              <ol v-if="infoType === '2'" class="list-group list-group-numbered">
                <li class="list-group-item">
                  ログイン画面に記載の「動作環境」に当てはまらない機器またはアプリケーションを使用していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">適切な機器またはアプリケーションをご使用ください。</span>
                </li>
                <li class="list-group-item">
                  明細送付に使用されるメールアドレスとは異なるメールアドレスでログインしようとしていませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">送付メールアドレスを変えて欲しい場合には、送付元にて変更可能なので、送付元にお問い合わせください。</span>
                </li>
                <li class="list-group-item">
                  上記内容に当てはまらない場合は<a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>からお問い合わせください。
                </li>
              </ol>
              <ol v-if="infoType === '3'" class="list-group list-group-numbered">
                <li class="list-group-item">
                  ログイン画面に記載の「動作環境」に当てはまらない機器またはアプリケーションを使用していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">適切な機器またはアプリケーションをご使用ください。</span>
                </li>
                <li class="list-group-item">
                  上記内容に当てはまらない場合はシステム障害の可能性があるため、お手数ですが<a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>よりお問い合わせください。
                </li>
              </ol>
              <ol v-if="infoType === '4'" class="list-group list-group-numbered">
                <li class="list-group-item">
                  迷惑メールに入っていませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">迷惑メールに入らないように、アドレス帳に登録したり、フィルターを設定してください。（ご使用のメール環境により設定すべき内容は異なります）</span>
                </li>
                <li class="list-group-item">
                  メールサーバーの容量オーバーで受け取れないといった、受領環境の問題はありませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">システム管理部門や、メールサービス提供会社にお問い合わせください。</span>
                </li>
                <li class="list-group-item">
                  上記内容に当てはまらない場合は<a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>からお問い合わせください。
                </li>
              </ol>
              <ol v-if="infoType === '5'" class="list-group list-group-numbered">
                <li class="list-group-item">
                  ログイン画面に記載の「動作環境」に当てはまらない機器またはアプリケーションを使用していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">適切な機器またはアプリケーションをご使用ください。</span>
                </li>
                <li class="list-group-item">
                  全角で入力していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">全て半角で入力してください。</span>
                </li>
                <li class="list-group-item">
                  ゼロとオーやエルとアイ等間違えやすい文字を間違えて入力していませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">文字を確認して入力してください。</span>
                </li>
                <li class="list-group-item">
                  余分なスペースが入っていませんか？<br>
                  <div style="height: 10px;"></div>
                  <span style="font-weight: bold;">届いたメールからコピーをすると、稀にスペースが入ってしまうことがあります。コピー＆ペーストではなく、入力をしてみてください。</span>
                </li>
                <li class="list-group-item">
                  上記内容に当てはまらない場合は<a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>からお問い合わせください。
                </li>
              </ol>
              <span v-if="infoType === '9'"><a @click.prevent.stop="showRequestMethod = true" href="#">こちら</a>からお問い合わせください。</span>
            </div>
            <div v-if="showRequestMethod">
              以下の内容で調査を依頼する
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
              <div class="form-edit-line">
                <input v-model="requestName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">会社名</div>
              <div class="form-edit-line">
                <input v-model="requestCompanyName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>ログインに使用するメールアドレス</div>
              <div class="form-edit-line">
                <input v-model="requestLoginEmail" type="email" class="form-control">
              </div>
              <div class="form-label align-bottom">問い合わせに使用するメールアドレス（ログインに使用するメールアドレスとは別のアドレスを希望する場合）</div>
              <div class="form-edit-line">
                <input v-model="requestEmail" type="email" class="form-control">
              </div>
              <div class="form-label align-bottom">電話番号（電話での連絡を希望する場合）</div>
              <div class="form-edit-line">
                <input v-model="requestTel" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">明細書送付元の会社名</div>
              <div class="form-edit-line">
                <input v-model="requestSenderName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">起きている現象</div>
              <div class="form-edit-line">
                <span v-if="infoType === '1'">「メールアドレスを正しく入力してください」というエラーが出る</span>
                <span v-if="infoType === '2'">「入力されたメールアドレスはシステムに登録されていません」というエラーが出る</span>
                <span v-if="infoType === '3'">「メールアドレスの存在確認と認証コードの送信中です。しばらくお待ちください。」というメッセージが消えない</span>
                <span v-if="infoType === '4'">「認証コードが送信されました」というメッセージが出るがメールが届かない</span>
                <span v-if="infoType === '5'">届いた認証コードを入力すると「認証コードが正しくありません」というエラーが出る</span>
                <span v-if="infoType === '9'">その他</span>
              </div>
              <div class="form-label align-bottom">その他連絡事項（電話での連絡を希望する場合には、ここに連絡希望日時（平日 10:00 〜 18:00 の間）を記載してください）
              </div>
              <div class="form-edit-line">
                <textarea v-model="requestNotes" class="form-control" rows="5"></textarea>
              </div>
              <div class="form-edit-line">
                <button @click="sendRequest" class="btn btn-outline-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>送信する</button>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <span v-if="requestFrom && !infoType"><button @click="requestFrom = ''" type="button" class="btn btn-primary"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button></span>
              <span v-if="infoType && !showRequestMethod"><button @click="infoType = ''" type="button" class="btn btn-primary"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button></span>
              <span v-if="showRequestMethod"><button @click="showRequestMethod = false" type="button" class="btn btn-primary"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    const email = ref('')
    const authCode = ref('')
    const isPersistSession = ref(false)
    const isAuthCodeSent = ref(false)
    const processMessage = ref('')
    const messages = ref([])
    const errors = ref([])
    const processModalDiv = ref(null)
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    const requestModalDiv = ref(null)
    let processModal = null
    let messageToast = null
    let errorToast = null
    let requestModal = null
    const requestFrom = ref('')
    const infoType = ref('')
    const showRequestMethod = ref(false)
    const requestName = ref('')
    const requestCompanyName = ref('')
    const requestLoginEmail = ref('')
    const requestEmail = ref('')
    const requestTel = ref('')
    const requestSenderName = ref('')
    const requestIncident = ref('')
    const requestNotes = ref('')
    const sendAuthCode = async () => {
      messages.value = []
      errors.value = []
      messageToast.hide()
      errorToast.hide()
      if (!email.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(email.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = 'メールアドレスの存在確認と認証コードの送信中です。しばらくお待ちください。'
        processModal.show()
        try {
          await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/code/' + email.value)
          isAuthCodeSent.value = true
          processModal.hide()
          messages.value.push('認証コードが送信されました')
          messageToast.show()
        } catch (e) {
          if (e.response?.status === 401 || e.response?.status === 404) {
            errors.value.push('入力されたメールアドレスはシステムに登録されていません')
          } else {
            errors.value.push('システムエラーが発生しました')
          }
          processModal.hide()
          errorToast.show()
        }
      }
    }
    const login = async () => {
      errors.value = []
      messageToast.hide()
      errorToast.hide()
      if (!authCode.value) {
        errors.value.push('認証コードを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        try {
          await auth.login(email.value, authCode.value, isPersistSession)
          session.session.loggedIn = true
          isAuthCodeSent.value = false
          if (!session.session.contractId && session.session.hasAdminRole !== 'YES') {
            if (session.session.agree !== 'YES') {
              await router.push('/green/agreement')
            } else {
              await router.push('/green/receipt')
            }
          } else {
            if (session.session.red || session.session.blue || session.session.pink) {
              axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list/inuse', {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                if (response.data.length > 0) {
                  session.session.company = {}
                  session.session.company.id = response.data[0].id
                }
              })
            }
            await router.push('/portal')
          }
        } catch (e) {
          console.error(e)
          errors.value.push('認証コードが正しくありません')
          processModal.hide()
          errorToast.show()
        }
      }
    }
    const openRequestModal = () => {
      requestFrom.value = ''
      infoType.value = ''
      showRequestMethod.value = false
      requestModal.show()
    }
    const sendRequest = async () => {
      errors.value = []
      if (!requestName.value) {
        errors.value.push('名前を入力してください')
      }
      if (!requestLoginEmail.value) {
        errors.value.push('ログインに使用するメールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(requestLoginEmail.value)) {
          errors.value.push('ログインに使用するメールアドレスを正しく入力してください')
        }
      }
      if (requestEmail.value) {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(requestEmail.value)) {
          errors.value.push('問い合わせに使用するメールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '調査依頼を送信中です。完了までしばらくこのままでお待ちください。'
        requestModal.hide()
        processModal.show()
        let incidentValue = 'その他'
        switch (infoType.value) {
          case '1':
            incidentValue = '「メールアドレスを正しく入力してください」というエラーが出る'
            break
          case '2':
            incidentValue = '「入力されたメールアドレスはシステムに登録されていません」というエラーが出る'
            break
          case '3':
            incidentValue = '「メールアドレスの存在確認と認証コードの送信中です。しばらくお待ちください。」というメッセージが消えない'
            break
          case '4':
            incidentValue = '「認証コードが送信されました」というメッセージが出るがメールが届かない'
            break
          case '5':
            incidentValue = '届いた認証コードを入力すると「認証コードが正しくありません」というエラーが出る'
            break
          default:
            break
        }
        await axios.post(process.env.VUE_APP_API_ENDPOINT + '/request', {
          name: requestName.value,
          companyName: requestCompanyName.value,
          loginEmail: requestLoginEmail.value,
          email: requestEmail.value,
          tel: requestTel.value,
          senderName: requestSenderName.value,
          incident: incidentValue,
          notes: requestNotes.value
        }, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(() => {
          processModal.hide()
          messages.value = ['調査依頼の送信が完了しました。依頼時間によっては、翌営業日の返信になる場合はあることをご了承ください。']
          messageToast.show()
        }).catch(() => {
          errors.value.push('送信に失敗しました')
          errorToast.show()
          requestModal.show()
          processModal.hide()
        })
      }
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      requestModal = new Modal(requestModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      if (session.session && session.session.loggedIn) {
        if (!session.session.contractId && session.session.hasAdminRole !== 'YES') {
          if (session.session.agree !== 'YES') {
            await router.push('/green/agreement')
          } else {
            await router.push('/green/receipt')
          }
        } else {
          await router.push('/portal')
        }
      }
    })
    return {
      email,
      authCode,
      isPersistSession,
      isAuthCodeSent,
      processMessage,
      messages,
      errors,
      processModalDiv,
      messageToastDiv,
      errorToastDiv,
      requestModalDiv,
      sendAuthCode,
      login,
      openRequestModal,
      requestFrom,
      infoType,
      showRequestMethod,
      requestName,
      requestCompanyName,
      requestLoginEmail,
      requestEmail,
      requestTel,
      requestSenderName,
      requestIncident,
      requestNotes,
      sendRequest
    }
  }
})
</script>
