import { defineStore } from 'pinia'
import { ref } from 'vue'

export const historyDetailStore = defineStore('history_detail', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const receiptMasterDetailStore = defineStore('receipt_master_detail', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
