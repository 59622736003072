import { defineStore } from 'pinia'
import { ref } from 'vue'

export const masterStore = defineStore('master', () => {
  const searchString = ref('')
  const page = ref(0)
  const detailSearchMode = ref(false)
  const detailCondition = ref({
    searchString: null,
    artistName: null,
    code: null,
    isrc: null,
    userCode: null,
    suspension: 'all',
    suspensionStatus: 'all',
    sortColumn1: 'name',
    sortOrder1: 'asc',
    sortColumn2: 'kana',
    sortOrder2: 'asc'
  })
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder, detailCondition, detailSearchMode }
})

export const artistStore = defineStore('master_artist', () => {
  const searchString = ref('')
  return { searchString }
})

export const projectStore = defineStore('master_project', () => {
  const searchString = ref('')
  return { searchString }
})
