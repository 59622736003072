<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/usage/list"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績ファイル一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>取込</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button v-if="form && !form.dataNotExists && currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" @click="registerAll" class="btn btn-primary">
                <span class="d-none d-xl-block"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>全て取込</span>
                <span class="d-xl-none"><i class="fas fa-file-import fa-fw"></i></span>
              </button>
              <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" @click="openDeleteAllModal" class="btn btn-outline-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>全て削除</button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-5">
                <div v-if="form.userName" class="form-label align-bottom">分配期</div>
                <div v-if="form.userName" class="form-edit-line input-group">
                  <input v-model="uploadedFileCondition.divYear" @change="find" type="number" class="form-control" style="flex: 0 1 90px;">
                  <span class="input-group-text">年</span>
                  <input v-model="uploadedFileCondition.divTerm" @change="find" type="number" class="form-control" style="flex: 0 1 60px;">
                  <span class="input-group-text">期</span>
                  <button @click="find" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <div v-if="form.userName" class="form-label align-bottom">利用者</div>
                <div v-if="form.userName" class="form-edit-line">
                  {{ form.userName }}
                </div>
                <div v-if="form.name" class="form-label align-bottom">頒布形態</div>
                <div v-if="form.name" class="form-edit-line">
                  {{ form.name }}
                </div>
                <div v-if="form.definitionName" class="form-label align-bottom">定義</div>
                <div v-if="form.definitionName" class="form-edit-line">
                  {{ form.definitionName }}
                </div>
                <div v-if="form.currencyName" class="form-label align-bottom">通貨</div>
                <div v-if="form.currencyName" class="form-edit-line">
                  {{ form.currencyName }}
                </div>
                <div class="form-label align-bottom">消費税</div>
                <div class="form-edit-line">
                  <span v-if="form.taxation">課税</span>
                  <span v-else>非課税</span>
                </div>
                <hr>
                <template v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm">
                  <div class="form-label align-bottom">今期の取込ファイル</div>
                  <div v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" class="form-edit-line">
                    <select v-model="form.status" @change="save" class="form-select">
                      <option value="todo">未取込</option>
                      <option value="done">取込済</option>
                      <option value="none">ファイルなし</option>
                    </select>
                  </div>
                  <div v-else class="form-edit-line">
                    <span v-if="form.status === 'none'">取込ファイルなし</span>
                    <span v-else>取込ファイルあり</span>
                  </div>
                  <template v-if="form.status !== 'none'">
                    <div class="form-label align-bottom">取込予定ファイル数</div>
                    <div class="form-edit-line">
                      {{form.registerFileCount}}
                    </div>
                    <div class="form-label align-bottom">
                      予定された利用実績データ
                    </div>
                    <div>
                      <table v-if="form.registerMonthList.length > 0" class="table table-responsive">
                        <tbody>
                          <template v-for="(month, index) in form.registerMonthList" :key="index">
                            <tr v-if="form.usedMonthType === 'input'"  class="align-middle">
                              <td style="padding-bottom: 0; border-bottom: none;">{{paymentYear(currentTerm.paymentYear, currentTerm.paymentMonth, month.monthsAgo)}}年{{paymentMonth(currentTerm.paymentMonth, month.monthsAgo)}}月</td>
                            </tr>
                            <tr>
                              <td style="padding-bottom: 5px; border-bottom: none;">
                                <div class="input-group custom-file-button">
                                  <span v-if="form.taxation && !hasTaxRateDefinition" class="input-group-text">税</span>
                                  <select v-if="form.taxation && !hasTaxRateDefinition" v-model="month.taxRateId" class="form-select" style="flex: 0 1 90px;">
                                    <option v-for="tax in taxRateList" :key="tax.id" :value="tax.id">
                                      {{tax.rate}}%
                                    </option>
                                  </select>
                                  <label class="input-group-text" for="inputGroupFile"><i class="far fa-file"></i></label>
                                  <input class="form-control" type="file" @change="selectFile($event, index)" multiple="multiple">
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding-top: 0;">
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <span v-else>なし</span>
                    </div>
                    <div class="form-label align-bottom">
                      予定外の利用実績データ
                    </div>
                    <div>
                      <table class="table table-responsive">
                        <tbody>
                          <template v-for="(otherFile, index) in otherFileList" :key="index">
                            <tr>
                              <td v-if="form.usedMonthType === 'input'" style="padding-bottom: 0; border-bottom: none;">
                                <div class="input-group">
                                  <input v-model="otherFile.usedYear" type="number" class="form-control" style="flex: 0 1 90px;">
                                  <span class="input-group-text">年</span>
                                  <input v-model="otherFile.usedMonth" type="number" class="form-control" style="flex: 0 1 65px;">
                                  <span class="input-group-text">月</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding-bottom: 5px; border-bottom: none;">
                                <div class="input-group custom-file-button">
                                  <span v-if="form.taxation && !hasTaxRateDefinition" class="input-group-text">税</span>
                                  <select v-if="form.taxation && !hasTaxRateDefinition" v-model="otherFile.taxRateId" class="form-select" style="flex: 0 1 100px;">
                                    <option v-for="tax in taxRateList" :key="tax.id" :value="tax.id">
                                      {{tax.rate}}%
                                    </option>
                                  </select>
                                  <label class="input-group-text" for="inputGroupFile"><i class="far fa-file"></i></label>
                                  <input class="form-control" type="file" @change="selectOtherFile($event, index)" multiple="multiple">
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="text-start">
                          <button @click="addOtherFile" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw"></i></button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="text-end">
                          <button @click="upload" class="btn btn-primary"><i class="fas fa-cloud-upload fa-fw" style="margin-right: 5px;"></i>アップロード</button>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <div class="form-label align-bottom" style="margin-top: 10px;">メモ</div>
                <div v-if="memoMode === 'edit'" class="form-edit-line">
                  <textarea v-model="form.memo" class="form-control" rows="5"></textarea>
                  <div class="text-end" style="margin-top: 5px;">
                    <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" @click="save(); memoMode = 'disp';" class="btn btn-sm btn-outline-primary"><i class="fas fa-save"></i></button>
                  </div>
                </div>
                <div v-if="memoMode === 'disp'" class="form-edit-line">
                  <span v-if="form.memo" v-html="form.memo.replaceAll('\n', '<br>')"></span>
                  <div class="text-end" style="margin-top: 5px;">
                    <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" @click="memoMode = 'edit'" class="btn btn-sm btn-outline-primary"><i class="fas fa-edit"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-7">
                <div v-for="(uploadedFile, index) in uploadedFileList" :key="uploadedFile.id" class="card" style="margin-top: 10px; font-size: 90%;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-2" style="width: 120px;">
                        アップロード
                      </div>
                      <div class="col">
                        {{uploadedFile.uploadedAt}}（{{uploadedFile.uploadedBy}}）
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-2" style="width: 120px;">
                        ファイル名
                      </div>
                      <div class="col" style="word-break: break-all;">
                        {{uploadedFile.fileName}}
                      </div>
                    </div>
                    <div v-if="uploadedFile.sheetName" class="row">
                      <div class="col-2" style="width: 120px;">
                        シート名
                      </div>
                      <div class="col">
                        {{uploadedFile.sheetName}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-2" style="width: 120px;">
                        サイズ
                      </div>
                      <div class="col">
                        {{unitChangeForByte(uploadedFile.fileSize)}}
                      </div>
                    </div>
                    <div v-if="uploadedFile.fileSize > 1000 * 1000 * 10" class="alert alert-danger">サイズの大きなファイルです。間違いがないか確認の上で取込を実行してください。なお、取込には通常より多くの時間がかかる場合があります。</div>
                    <div v-if="uploadedFile.usedYear && uploadedFile.usedMonth" class="row">
                      <div class="col-2" style="width: 120px;">
                        使用年月
                      </div>
                      <div class="col">
                        <span v-if="uploadedFile.usedYear && uploadedFile.usedMonth">{{uploadedFile.usedYear}}年{{uploadedFile.usedMonth}}月</span>
                      </div>
                    </div>
                    <div v-if="uploadedFile.taxRateId" class="row">
                      <div class="col-2" style="width: 120px;">
                        消費税率
                      </div>
                      <div class="col">
                        {{uploadedFile.taxRate}}%
                      </div>
                    </div>
                    <hr v-if="uploadedFile.errorCount && uploadedFile.errorCount > 0">
                    <div v-if="uploadedFile.errorCount && uploadedFile.errorCount > 0" class="row">
                      <div class="col-2 bg-danger text-white" style="width: 120px;">
                        データエラー
                      </div>
                      <div class="col">
                        <a href="#" @click="openDataErrorModal(index)">{{uploadedFile.errorCount.toLocaleString()}}</a>
                      </div>
                    </div>
                    <hr>
                    <div v-if="definition.incomeAmountType.includes('distribution')" class="row align-middle">
                      <div class="col-2 align-middle" style="width: 120px;">
                        合計受領額
                      </div>
                      <div class="col">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">￥</span>
                          <input v-model="uploadedFile.incomeAmount" type="number" class="form-control form-control-sm" style="flex: 0 1 200px; text-align: right;">
                        </div>
                      </div>
                    </div>
                    <div v-if="definition.incomeAmountType.includes('all_amount_deduction')" class="row align-middle">
                      <div class="col-2 align-middle" style="width: 120px;">
                        合計控除額
                      </div>
                      <div class="col">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text">￥</span>
                          <input v-model="uploadedFile.deductionAmount" type="number" class="form-control form-control-sm" style="flex: 0 1 200px; text-align: right;">
                        </div>
                      </div>
                    </div>
                    <div v-if="definition.incomeAmountType.includes('percent_deduction')" class="row align-middle">
                      <div class="col-2 align-middle" style="width: 120px;">
                        控除率
                      </div>
                      <div class="col">
                        <div class="input-group input-group-sm">
                          <input v-model="uploadedFile.deductionRate" type="number" class="form-control form-control-sm" style="flex: 0 1 70px; text-align: right;">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                    <template v-if="uploadedFile.importedAt">
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          取込
                        </div>
                        <div class="col">
                          {{uploadedFile.importedAt}}（{{uploadedFile.importedBy}}）
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          総行数
                        </div>
                        <div class="col">
                          {{uploadedFile.totalLine.toLocaleString()}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          データ行数
                        </div>
                        <div class="col">
                          {{uploadedFile.totalDataCount.toLocaleString()}}
                        </div>
                      </div>
                      <div v-if="uploadedFile.totalSkipCount > 0" class="row">
                        <div class="col-2 bg-warning" style="width: 120px;">
                          除外行数
                        </div>
                        <div class="col">
                          {{uploadedFile.totalSkipCount.toLocaleString()}}
                        </div>
                      </div>
                      <div v-if="uploadedFile.totalErrorCount > 0" class="row">
                        <div class="col-2 bg-danger text-white" style="width: 120px;">
                          取込エラー
                        </div>
                        <div class="col">
                          {{uploadedFile.totalErrorCount.toLocaleString()}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          総使用数
                        </div>
                        <div class="col" style="font-weight: bold;">
                          {{uploadedFile.totalUsedNo.toLocaleString()}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          総受領額
                        </div>
                        <div class="col" style="font-weight: bold;">
                          {{uploadedFile.totalIncomeAmount.toLocaleString()}}（{{form.currencyName}}）
                        </div>
                      </div>
                      <div v-if="uploadedFile.totalIncomeAmount !== uploadedFile.totalConvertedIncomeAmount" class="row">
                        <div class="col-2" style="width: 120px;">
                          総受領額(円貨)
                        </div>
                        <div class="col" style="font-weight: bold;">
                          {{uploadedFile.totalConvertedIncomeAmount.toLocaleString()}}円
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-2" style="width: 120px;">
                          チェック対象
                        </div>
                        <div class="col">
                          {{uploadedFile.checkCount.toLocaleString()}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 bg-success text-white" style="width: 120px;">
                          マッチ済
                        </div>
                        <div class="col">
                          {{uploadedFile.matchCount.toLocaleString()}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 bg-danger text-white" style="width: 120px;">
                          アンマッチ
                        </div>
                        <div class="col">
                          <router-link :to="'/red/usage/file/' + form.id + '/' + uploadedFile.id + '/unmatch'">{{uploadedFile.unMatchCount.toLocaleString()}}</router-link>
                        </div>
                      </div>
                    </template>
                    <hr>
                    <div class="text-end">
                      <div class="row">
                        <div class="col text-end">
                          <div class="input-group">
                            <button v-if="uploadedFile.importedAt" @click="router.push('/red/usage/file/' + form.id + '/' + uploadedFile.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-check-circle" style="margin-right: 5px;"></i>確認</button>
                            <div class="input-group-text">
                              <div class="form-check">
                                <input v-model="uploadedFile.continueOnError" class="form-check-input" type="checkbox" value="" :id="'continueOnError_' + index">
                                <label class="form-check-label" :for="'continueOnError_' + index">
                                  エラー時続行
                                </label>
                              </div>
                            </div>
                            <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm && !uploadedFile.importedAt" @click="registerOne(index)" class="btn btn-sm btn-primary" style="margin-right: 10px;">
                              <i class="fas fa-file-import" style="margin-right: 5px;"></i>取込
                            </button>
                            <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm && uploadedFile.importedAt" @click="registerOne(index)" class="btn btn-sm btn-warning" style="margin-right: 10px;">
                              <i class="fas fa-arrow-rotate-forward" style="margin-right: 5px;"></i>再取込
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col d-flex justify-content-end">
                          <button v-if="currentTerm.divYear === uploadedFileCondition.divYear && currentTerm.divTerm === uploadedFileCondition.divTerm" @click="openDeleteModal(index)" class="btn btn-sm btn-danger">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="dataErrorModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">データエラー詳細</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th style="width: 100px;">行数</th>
                  <th>エラー内容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in dataErrorList" :key="error.dataLineNo">
                  <td>{{error.dataLineNo}}</td>
                  <td>{{error.errorNotes}}</td>
                </tr>
              </tbody>
              <tfoot v-if="dataErrorResult.allRecords > dataErrorList.length">
                <tr>
                  <td colspan="5">
                    <div class="d-grid gap-2">
                      <button @click="dataErrorPage = dataErrorPage + 1; findDataError();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アップロード済ファイルの削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下のアップロード済ファイルを削除しようとしています。
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ deleteFileName }}
            </div>
            <div v-if="deleteSheetName" class="form-label align-bottom">シート名</div>
            <div v-if="deleteSheetName" class="form-edit-line">
              {{ deleteSheetName }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="deleteUploadedFile" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteAllModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アップロード済ファイルの削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下のアップロード済ファイルを全て削除しようとしています。
            </div>
            <div>
              <table class="table table-responsive">
                <thead>
                  <tr>
                    <th>ファイル名</th>
                    <th style="width: 200px;">シート名</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in uploadedFileList" :key="file.id">
                    <td>{{file.fileName}}</td>
                    <td v-if="file.sheetName">{{file.sheetName}}</td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="deleteUploadedFileAll" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
         ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer'
import { userFunction } from '../user/function'
import { taxFunction, redCompanyFunction } from '@/functions/common'
import { usageFunction } from './function'
import { definitionFunction } from '../definition/function'
import { Modal, Toast } from 'bootstrap'
import { uploadedFileStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const dataErrorModalDiv = ref(null)
    let dataErrorModal = null
    // Form
    const usageFunc = usageFunction()
    const userFunc = userFunction()
    const taxFunc = taxFunction()
    const companyFunc = redCompanyFunction()
    const definitionFunc = definitionFunction()
    const form = ref(null)
    const definition = ref({})
    const currentTerm = ref({})
    const taxRateList = ref([])
    const hasTaxRateDefinition = ref(false)
    const memoMode = ref('disp')
    let uploadFiles = []
    let uploadOtherFiles = []
    const uploadedFileCondition = uploadedFileStore()
    const uploadedFileList = ref([])
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const deleteAllModalDiv = ref(null)
    let deleteAllModal = null
    const deleteFileId = ref('')
    const deleteFileName = ref('')
    const deleteSheetName = ref('')
    const dataErrorResult = ref({})
    const dataErrorList = ref([])
    const otherFileList = ref([{}])
    const dataErrorId = ref(null)
    const dataErrorPage = ref(0)
    // メソッド
    const init = async (id) => {
      currentTerm.value = await companyFunc.getCurrentDivTerm(session.session.company.id)
      uploadedFileCondition.divYear = currentTerm.value.divYear
      uploadedFileCondition.divTerm = currentTerm.value.divTerm
      form.value = await userFunc.getUserSales(id)
      for (const item of form.value.definitionItemList) {
        if (item.itemType === 'tax_rate') {
          hasTaxRateDefinition.value = true
          break
        }
      }
      otherFileList.value[0].usedYear = currentTerm.value.divYear
      taxRateList.value = await taxFunc.find()
      for (const month of form.value.registerMonthList) {
        if (taxRateList.value.length > 0) {
          month.taxRateId = taxRateList.value[0].id
        }
      }
      otherFileList.value[0].taxRateId = taxRateList.value[0].id
      definition.value = await definitionFunc.get(form.value.definitionId)
      await find()
    }
    const find = async () => {
      const companyDivTerm = await companyFunc.getDivTerm(session.session.company.id, uploadedFileCondition.divYear, uploadedFileCondition.divTerm)
      if (!companyDivTerm) {
        errors.value = ['正しい分配年期を指定してください']
        errorToast.show()
        uploadedFileList.value = []
      } else {
        const userSalesData = await usageFunc.get(form.value.id, companyDivTerm.id)
        if (userSalesData) {
          form.value.memo = userSalesData.memo
          form.value.status = userSalesData.status
        } else {
          form.value.memo = null
          form.value.status = 'todo'
        }
        uploadedFileList.value = await usageFunc.findFile(form.value.id, companyDivTerm.id)
        const errorPromises = []
        const checkPromises = []
        const matchPromises = []
        const unMatchPromises = []
        for (const uploadedFile of uploadedFileList.value) {
          uploadedFile.continueOnError = true
          uploadedFile.checkCount = 0
          uploadedFile.matchCount = 0
          uploadedFile.unMatchCount = 0
          errorPromises.push(usageFunc.getErrorCount(uploadedFile.id))
          checkPromises.push(usageFunc.getCheckCount(uploadedFile.id))
          matchPromises.push(usageFunc.getMatchCount(uploadedFile.id))
          unMatchPromises.push(usageFunc.getUnMatchCount(uploadedFile.id))
        }
        (await Promise.all(errorPromises)).forEach((errorCount, index) => {
          uploadedFileList.value[index].errorCount = errorCount
        });
        (await Promise.all(checkPromises)).forEach((count, index) => {
          uploadedFileList.value[index].checkCount = count
        });
        (await Promise.all(matchPromises)).forEach((count, index) => {
          uploadedFileList.value[index].matchCount = count
        });
        (await Promise.all(unMatchPromises)).forEach((count, index) => {
          uploadedFileList.value[index].unMatchCount = count
        })
      }
    }
    const unitChangeForByte = (size) => {
      const { target, unit } = getTarget(size)
      const newSize = target !== null ? Math.floor((size / target) * Math.pow(10, 2)) / Math.pow(10, 2) : size
      return newSize + unit
    }
    function getTarget (size) {
      const kb = 1000
      const mb = Math.pow(kb, 2)
      const gb = Math.pow(kb, 3)
      const tb = Math.pow(kb, 4)
      const returnData = (target, unit) => ({ target, unit })
      if (size >= tb) return returnData(tb, 'TB')
      if (size >= gb) return returnData(gb, 'GB')
      if (size >= mb) return returnData(mb, 'MB')
      if (size >= kb) return returnData(kb, 'KB')
      return returnData(null, 'byte')
    }
    const selectFile = (event, index) => {
      uploadFiles.push(event.target.files)
    }
    const selectOtherFile = (event) => {
      uploadOtherFiles.push(event.target.files)
    }
    const addOtherFile = () => {
      otherFileList.value.push({
        usedYear: currentTerm.value.divYear,
        taxRateId: taxRateList.value[0].id
      })
    }
    const upload = async () => {
      processMessage.value = 'ファイルのアップロード中です。しばらくお待ちください。'
      processModal.show()
      errors.value = []
      for (const [index, month] of Object.entries(form.value.registerMonthList)) {
        if (uploadFiles[index]) {
          let taxRateId = null
          if (form.value.taxation && !hasTaxRateDefinition.value) {
            taxRateId = month.taxRateId
          }
          for (const file of uploadFiles[index]) {
            try {
              await usageFunc.upload(
                form.value.id, currentTerm.value.id, form.value.usedMonthType,
                paymentYear(currentTerm.value.paymentYear, currentTerm.value.paymentMonth, month.monthsAgo),
                paymentMonth(currentTerm.value.paymentMonth, month.monthsAgo),
                taxRateId,
                file
              )
            } catch (e) {
              console.error(e)
              errors.value.push('・アップロードまたはファイルの読み込みに失敗しました。（' + file.name + '）')
            }
          }
        }
      }
      for (const [index, otherFile] of Object.entries(otherFileList.value)) {
        if (uploadOtherFiles[index]) {
          let taxRateId = null
          if (form.value.taxation && !hasTaxRateDefinition.value) {
            taxRateId = otherFile.taxRateId
          }
          for (const file of uploadOtherFiles[index]) {
            if ((otherFile.usedYear && otherFile.usedMonth) || form.value.usedMonthType !== 'input') {
              try {
                await usageFunc.upload(
                  form.value.id, currentTerm.value.id, form.value.usedMonthType,
                  otherFile.usedYear,
                  otherFile.usedMonth,
                  taxRateId,
                  file
                )
                otherFileList.value = []
                addOtherFile()
              } catch (e) {
                console.error(e)
                errors.value.push('・アップロードまたはファイルの読み込みに失敗しました。（' + file.name + '）')
              }
            } else {
              errors.value.push('・使用年月を指定してください。（' + file.name + '）')
            }
          }
        }
      }
      processModal.hide()
      if (errors.value.length > 0) {
        errors.value.push('　')
        errors.value.push('※Excelファイルが破損している場合もこのエラーが発生する場合があります')
        errorToast.show()
      }
      for (const inputFile of document.querySelectorAll('input[type=file]')) {
        inputFile.value = null
      }
      uploadFiles = []
      uploadOtherFiles = []
      await find()
    }
    const save = async () => {
      if (uploadedFileList.value.length > 0 && form.value.status === 'none') {
        errors.value = ['アップロード済データがあるため「取込ファイルなし」に変更することができません。']
        errorToast.show()
        form.value.status = 'todo'
      } else {
        await usageFunc.save(form.value.id, currentTerm.value.id, form.value)
      }
    }
    const paymentYear = (paymentYear, paymentMonth, monthsAgo) => {
      return (paymentMonth - monthsAgo < 1) ? paymentYear - 1 : paymentYear
    }
    const paymentMonth = (paymentMonth, monthsAgo) => {
      return (paymentMonth - monthsAgo < 1) ? 12 + (paymentMonth - monthsAgo) : paymentMonth - monthsAgo
    }
    const openDeleteModal = (index) => {
      const file = uploadedFileList.value[index]
      deleteFileId.value = file.id
      deleteFileName.value = file.fileName
      deleteSheetName.value = file.sheetName
      deleteModal.show()
    }
    const openDeleteAllModal = () => {
      deleteAllModal.show()
    }
    const deleteUploadedFile = async () => {
      processMessage.value = '削除処理中です。しばらくお待ちください。'
      deleteModal.hide()
      processModal.show()
      await usageFunc.removeFile(deleteFileId.value)
      deleteFileName.value = ''
      deleteSheetName.value = ''
      deleteFileId.value = ''
      await find()
      processModal.hide()
    }
    const deleteUploadedFileAll = async () => {
      processMessage.value = '削除処理中です。しばらくお待ちください。'
      deleteModal.hide()
      processModal.show()
      await usageFunc.removeAllFiles(form.value.id, currentTerm.value.id)
      await find()
      deleteAllModal.hide()
      processModal.hide()
    }
    const registerOne = async (index) => {
      processMessage.value = 'データ取込中です。しばらくお待ちください。'
      processModal.show()
      await register(index)
      await find()
      processModal.hide()
    }
    const registerAll = async () => {
      processMessage.value = 'データ取込中です。しばらくお待ちください。'
      processModal.show()
      const promises = []
      try {
        for (let index = 0; index < uploadedFileList.value.length; index++) {
          promises.push(register(index))
        }
        await Promise.all(promises)
      } catch (error) {
        console.error(error)
        errors.value = ['取込時にシステムエラーが発生しました']
      }
      await find()
      processModal.hide()
    }
    const register = async (index) => {
      const file = uploadedFileList.value[index]
      await usageFunc.register(file.id, file.continueOnError, file.incomeAmount, file.deductionRate, file.deductionAmount)
      usageFunc.calculate(file.id)
    }
    const openDataErrorModal = async (index) => {
      dataErrorId.value = uploadedFileList.value[index].id
      dataErrorList.value = []
      await findDataError()
      dataErrorModal.show()
    }
    const findDataError = async () => {
      dataErrorResult.value = await usageFunc.findError(dataErrorId.value, dataErrorPage.value)
      dataErrorList.value = dataErrorList.value.concat(dataErrorResult.value.list)
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteAllModal = new Modal(deleteAllModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      dataErrorModal = new Modal(dataErrorModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      processMessage,
      processModalDiv,
      errors,
      errorToastDiv,
      deleteModalDiv,
      deleteAllModalDiv,
      form,
      unitChangeForByte,
      init,
      taxRateList,
      hasTaxRateDefinition,
      currentTerm,
      upload,
      selectFile,
      memoMode,
      save,
      paymentYear,
      paymentMonth,
      uploadedFileList,
      uploadedFileCondition,
      find,
      deleteUploadedFile,
      definition,
      deleteFileId,
      deleteFileName,
      deleteSheetName,
      openDeleteModal,
      deleteUploadedFileAll,
      openDeleteAllModal,
      register,
      dataErrorModalDiv,
      dataErrorList,
      openDataErrorModal,
      otherFileList,
      selectOtherFile,
      addOtherFile,
      registerOne,
      registerAll,
      dataErrorResult,
      dataErrorPage,
      findDataError,
      dataErrorId
    }
  }
})
</script>

<style scoped>
.custom-file-button input[type=file] {
  margin-left: -2px !important;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
</style>
