<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/green/organization/list"><i class="fas fa-users fa-fw" style="margin-right: 5px;"></i>受領グループ一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-link" style="margin-right: 5px;"></i>グループ参加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>参加する</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>招待キー</div>
          <div class="form-edit-line">
            <textarea class="form-control" rows="10" v-model="token"></textarea>
          </div>
        </div>
        <div style="height: 50px;"></div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import footerComponent from '@/components/Footer.vue'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    // Form
    const token = ref('')
    // メソッド
    const save = async () => {
      errors.value = []
      if (!token.value) {
        errors.value.push('招待キーを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/organization/account', {}, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            token: token.value
          }
        }).then(() => {
          messages.value = ['グループに参加しました']
          messageToast.show()
        }).catch(error => {
          ex.identifyEx(error)
          errors.value = ['システムエラーが発生したため保存されませんでした']
          errorToast.show()
        })
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
    })
    return {
      session,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      token,
      save
    }
  }
})
</script>
