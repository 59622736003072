import AdminPostalRegister from './Register.vue'

export default {
  routes: [
    {
      path: '/admin/postal/register',
      name: 'AdminPostalRegister',
      component: AdminPostalRegister
    }
  ]
}
