import RedPaymentDisp from './Disp'
import RedPaymentList from './List'
import RedPaymentPrint from './Print.vue'
import RedPaymentStatementPrint from './StatementPrint.vue'
import RedPaymentEdit from './Edit.vue'

export default {
  routes: [
    {
      path: '/red/payment/list',
      name: 'RedPaymentList',
      component: RedPaymentList
    },
    {
      path: '/red/payment/print/:id/:detail',
      name: 'RedPaymentPrint',
      component: RedPaymentPrint
    },
    {
      path: '/red/receipt/print/:id/:detail',
      name: 'RedReceiptPrint',
      component: RedPaymentPrint
    },
    {
      path: '/red/receipt/print/all/:id',
      name: 'RedReceiptPrintAll',
      component: RedPaymentPrint
    },
    {
      path: '/red/payment/print/statement/:id',
      name: 'RedPaymentStatementPrint',
      component: RedPaymentStatementPrint
    },
    {
      path: '/red/payment/disp/:id',
      name: 'RedPaymentDisp',
      component: RedPaymentDisp
    },
    {
      path: '/red/payment/edit/:id',
      name: 'RedPaymentEdit',
      component: RedPaymentEdit
    }
  ]
}
