import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { holidayFunction } from '@/functions/common'
import { paymentStore } from './store'

export const paymentFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const holidayFunc = holidayFunction()
  const store = paymentStore()
  const findStatus = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (paymentStatusId) => {
    const params = {
      paymentStatusId: paymentStatusId,
      searchString: store.searchString,
      likeOrNotLike: store.likeOrNotLike,
      page: store.page,
      perPage: 20
    }
    if (store.payOrCarryForward !== 'all') {
      params.payOrCarryForward = store.payOrCarryForward
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findDetail = async (paymentStatusId) => {
    const params = {
      paymentStatusId: paymentStatusId,
      sortColumn: store.detailCondition.sortColumn,
      sortOrder: store.detailCondition.sortOrder,
      page: store.page,
      perPage: 20
    }
    if (store.detailCondition.searchString) {
      params.searchString = store.detailCondition.searchString
    }
    if (store.detailCondition.taxationType !== 'all') {
      params.taxationType = store.detailCondition.taxationType
    }
    if (store.detailCondition.invoiceNo) {
      params.invoiceNo = store.detailCondition.invoiceNo.replace('T', '')
    }
    if (store.detailCondition.payOrCarryForward !== 'all') {
      params.payOrCarryForward = store.detailCondition.payOrCarryForward
    }
    if (store.detailCondition.statementSendType !== 'all') {
      params.statementSendType = store.detailCondition.statementSendType
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/list/detail', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findStatementTax = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/tax/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    const list = []
    for (const tax of data) {
      await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id + '/tax/' + tax.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        if (response.data) {
          console.log(response.data)
          tax.amount = response.data
        } else {
          tax.amount = {
            amountBroughtForward: 0,
            amountInThisTerm: 0,
            beforeTaxAmount: 0,
            invoiceDeductionAmount: 0,
            managementFeeAmount: 0,
            offsetAmountOutTax: 0,
            offsetTargetAmountOutTax: 0,
            taxAmount: 0,
            taxRate: 0,
            totalAmount: 0,
            paymentHeaderId: id,
            taxRateId: tax.id
          }
        }
        list.push(tax)
      })
    }
    return list
  }
  const findStatementDetail = async (id, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id + '/detail/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: 0,
        perPage: 30
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findStatementAttachment = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id + '/attachment/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    for (const file of data) {
      file.url = await urlForDownload(file.blobName)
    }
    return data
  }
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getStatus = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        divYear: store.divYear,
        divTerm: store.divTerm
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const getStatementBlob = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id + '/blob', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const getStatementUrl = async (id, blob) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + id + '/blob/url', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { blobName: blob }
    })
    return data
  }
  const createStatus = async (companyId) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const createStatement = async (companyId, paymentStatusId, rightsHolderId, paymentDate) => {
    await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status/' + paymentStatusId + '/create', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        rightsHolderId: rightsHolderId,
        paymentDate: paymentDate
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const createSelfStatement = async (paymentStatusId, divTermId) => {
    await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/status/' + paymentStatusId + '/self/create', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const createFile = async (paymentHeaderId) => {
    await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + paymentHeaderId + '/create', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const clear = (store) => {
    store.searchString = ''
    store.likeOrNotLike = 'like'
    store.payOrCarryForward = 'all'
    store.sortColumn = 'name'
    store.sortOrder = 'asc'
    store.page = 0
  }
  const detailClear = (store) => {
    store.detailCondition.value = {
      searchString: null,
      taxationType: 'all',
      invoiceNo: null,
      payOrCarryForward: 'all',
      statementSendType: 'all',
      sortColumn: 'name',
      sortOrder: 'asc'
    }
    store.page = 0
  }
  const getPaymentYear = (divYear, paymentMonthYearType) => {
    if (paymentMonthYearType === 'previous') {
      return divYear - 1
    } else if (paymentMonthYearType === 'next') {
      return divYear + 1
    } else {
      return divYear
    }
  }
  const getPaymentDay = (year, month, day, paymentDateType) => {
    if (paymentDateType === 'last') {
      return new Date(year, month, 0).getDate()
    } else {
      return day
    }
  }
  const getPaymentDate = async (year, month, day, dayOfWeek, paymentOnHoliday) => {
    let additionalDate = 0
    if (dayOfWeek === 0) {
      if (paymentOnHoliday === 'before') {
        additionalDate = additionalDate - 2
      } else {
        additionalDate = additionalDate + 1
      }
    } else if (dayOfWeek === 6) {
      if (paymentOnHoliday === 'before') {
        additionalDate = additionalDate - 1
      } else {
        additionalDate = additionalDate + 2
      }
    }
    const tmpDate = new Date(year, month - 1, day)
    tmpDate.setDate(day + additionalDate)
    additionalDate = 0
    while (true) {
      if (await holidayFunc.isHoliday(year, month, day)) {
        if (paymentOnHoliday === 'before') {
          additionalDate = additionalDate - 1
        } else {
          additionalDate = additionalDate + 1
        }
      } else {
        break
      }
    }
    tmpDate.setDate(tmpDate.getDate() + additionalDate)
    return tmpDate
  }
  const urlForDownload = async (blobName) => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { blobName: blobName }
    })
    return data
  }
  const countOldStatement = async (rightsHolderId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + rightsHolderId + '/count/old', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const deleteCurrentStatement = async (rightsHolderId) => {
    await axios.delete(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/' + rightsHolderId + '/current', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  return { findStatus, get, getStatus, getStatementBlob, getStatementUrl, find, findDetail, findStatementTax, findStatementDetail, findStatementAttachment, createStatus, createStatement, createFile, clear, detailClear, getPaymentYear, getPaymentDay, getPaymentDate, createSelfStatement, countOldStatement, deleteCurrentStatement }
}
