import GreenSenderList from './List'

export default {
  routes: [
    {
      path: '/green/sender/list',
      name: 'GreenSenderList',
      component: GreenSenderList
    }
  ]
}
