import CommonBankDisp from './Disp'
import CommonBankEdit from './Edit'
import CommonBankList from './List'

export default {
  routes: [
    {
      path: '/common/bank/list',
      name: 'CommonBankList',
      component: CommonBankList
    },
    {
      path: '/common/bank/disp/:id',
      name: 'CommonBankDisp',
      component: CommonBankDisp
    },
    {
      path: '/common/bank/edit/:id',
      name: 'CommonBankEdit',
      component: CommonBankEdit
    },
    {
      path: '/common/bank/add',
      name: 'CommonBankAdd',
      component: CommonBankEdit
    }
  ]
}
