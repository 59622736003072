<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>rights.services <span style="color: #8EC31F;">GREEN</span> サービスの目的</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      本サービスは、当社運営の印税計算システム（著作権再分配システム「capo」、原盤管理システム「contano」）で作成された印税計算書をオンラインで閲覧、必要データのダウンロードを行うこと、及びそれ以外のデータを送信、ダウンロードすることを目的としています。<br>
      PDF等のダウンロードだけでなく、オンラインで明細データの検索、閲覧を行えることを特徴としています。
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
