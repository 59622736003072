<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page">
                    <i class="fas fa-database fa-fw" style="margin-right: 5px;"></i> [管理者用] GREEN移行
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="convert" type="button" class="btn btn-primary"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>データ移行開始</button>
            </div>
          </div>
        </div>
        <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom">契約</div>
            <div class="form-edit-line">
              <select v-model="importContractId" class="form-select">
                <option v-for="contract in contractList" :key="contract.id" :value="contract.id">
                  {{ contract.name }}
                </option>
              </select>
            </div>
            <div class="form-label align-bottom">連携先</div>
            <div class="form-edit-line">
              <select v-model="importSystem" class="form-select">
                <option value="capo">capo</option>
                <option value="jrca">JRCA</option>
                <option value="contano">contano</option>
              </select>
            </div>
            <div class="form-label align-bottom">送付先作成用データ</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="fileD" accept="text/*" ref="fileD">
            </div>
            <div class="form-label align-bottom">権利者連携データ</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="fileR" accept="text/*" ref="fileR">
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal, Toast } from 'bootstrap'
import navComponent from '@/components/Nav.vue'
import axios from 'axios'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const messages = ref([])
    const errors = ref([])
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    let processModal = null
    let messageToast = null
    let errorToast = null
    const importContractId = ref('')
    const contractList = ref([])
    const importSystem = ref('capo')
    const fileD = ref(null)
    const fileR = ref(null)

    const convert = async () => {
      messages.value = []
      errors.value = []
      if (!fileD.value.files[0]) {
        errors.value.push('送付先用データファイルを選択してください')
      }
      if (!fileR.value.files[0]) {
        errors.value.push('権利者用データファイルを選択してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const startDate = new Date()
        processMessage.value = 'GREEN送付先データのアップロードと取り込み処理中です。しばらくお待ちください。'
        processModal.show()
        let formData = new FormData()
        formData.append('uploadFile', fileD.value.files[0])
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/import/destination/' + importContractId.value, formData, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        formData = new FormData()
        formData.append('uploadFile', fileR.value.files[0])
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/import/' + importSystem.value + '/rightholder/' + importContractId.value, formData, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        const endDate = new Date()
        const diff = endDate.getTime() - startDate.getTime()
        messages.value.push('GREEN送付先データの取り込みが完了しました<br>（取込時間 : ' + Math.round(diff / 1000) + '秒）')
        fileD.value.value = null
        fileR.value.value = null
        messageToast.show()
        processModal.hide()
      }
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/list/all', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        contractList.value = response.data
        if (contractList.value.length > 0) {
          importContractId.value = contractList.value[0].id
        }
      })
    })
    return {
      messages,
      errors,
      processMessage,
      processModalDiv,
      messageToastDiv,
      errorToastDiv,
      importContractId,
      contractList,
      importSystem,
      fileD,
      fileR,
      convert
    }
  }
})
</script>
