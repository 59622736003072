import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

const app = createApp(App)

if (process.env.VUE_APP_APP_STAGE !== 'develop') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'www.rights.services', 'rights.services', 'stg.rights.services', /^\//]
      })
    ],
    environment: process.env.VUE_APP_APP_STAGE,
    tracesSampleRate: 1.0
  })
}
app.use(router).use(createPinia()).mount('#app')
