<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-building fa-fw" style="margin-right: 5px;"></i> 送付元一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 60px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="alert alert-info">送信先として登録された場合に表示されます。実際の明細送信は未実行の場合がありますのでご注意ください。</div>
          <table v-if="senderList && senderList.length > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th>送信元名</th>
                <th style="width: 80px;">著作権</th>
                <th style="width: 80px;">原盤</th>
              </tr>
            </thead>
            <tbody v-if="senderList">
              <tr v-for="sender in senderList" :key="sender.id" class="text-start align-middle">
                <td>{{ sender.companyName }}</td>
                <td class="text-center">
                  <span v-if="sender.hasCopyrightStatement" class="badge bg-success">○</span>
                  <span v-else class="badge bg-secondary">×</span>
                </td>
                <td class="text-center">
                  <span v-if="sender.hasMasterStatement" class="badge bg-success">○</span>
                  <span v-else class="badge bg-secondary">×</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="senderList && senderList.length > 0">{{ senderList.length }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const senderList = ref([])
    // メソッド
    const find = async () => {
      const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/destination/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      senderList.value = data
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      session,
      router,
      senderList,
      find
    }
  }
})
</script>
