import AdminSystemIndex from './Index.vue'

export default {
  routes: [
    {
      path: '/admin/system/index',
      name: 'AdminSystemIndex',
      component: AdminSystemIndex
    }
  ]
}
