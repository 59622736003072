<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid text-start">
        <div style="margin-top: 17px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-paper-plane fa-fw" style="margin-right: 5px;"></i> 明細送付</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <span class="input-group-text">対象</span>
            <select v-model="system" @change="loadCompanyList" class="form-select" style="flex: 0 1 120px;" :disabled="detailSearchMode">
              <option v-if="session && session.greenCapo" value="capo">capo</option>
              <option v-if="session && session.greenJrca" value="jrca">JRCA</option>
              <option v-if="session && session.greenContano" value="contano">contano</option>
              <option v-if="session && session.red" value="red">RED</option>
            </select>
            <span class="input-group-text">会社</span>
            <select v-model="capoCompanyId" v-if="system === 'capo' && capoCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select" :disabled="detailSearchMode">
              <option v-for="company in capoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
            </select>
            <select v-model="jrcaCompanyId" v-if="system === 'jrca' && jrcaCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select" :disabled="detailSearchMode">
              <option v-for="company in jrcaCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
            </select>
            <select v-model="contanoCompanyId" v-if="system === 'contano' && contanoCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select" :disabled="detailSearchMode">
              <option v-for="company in contanoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
            </select>
            <select v-model="redCompanyId" v-if="system === 'red' && redCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select" :disabled="detailSearchMode">
              <option v-for="company in redCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
            </select>
            <span class="input-group-text">期</span>
            <select v-if="system === 'capo' && capoCompanyId" v-model="capoDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 140px;" :disabled="detailSearchMode">
              <option v-for="(divYearTerm, index) in capoDivTermList" :key="index" :value="index">
                {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
              </option>
            </select>
            <select v-if="system === 'jrca' && jrcaCompanyId" v-model="jrcaDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 140px;" :disabled="detailSearchMode">
              <option v-for="(divYearTerm, index) in jrcaDivTermList" :key="index" :value="index">
                {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
              </option>
            </select>
            <select v-if="system === 'contano' && contanoCompanyId" v-model="contanoDivMonthIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 140px;" :disabled="detailSearchMode">
              <option v-for="(divYearMonth, index) in contanoDivMonthList" :key="index" :value="index">
                {{divYearMonth.divYear}}年{{divYearMonth.divMonth}}月
              </option>
            </select>
            <select v-if="system === 'red' && redCompanyId" v-model="redDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 140px;" :disabled="detailSearchMode">
              <option v-for="(divYearTerm, index) in redDivTermList" :key="index" :value="index">
                {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
              </option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア" :disabled="detailSearchMode"><i class="fas fa-undo"></i></button>
            <button @click="detailSearchMode = true" class="btn btn-outline-primary" :disabled="detailSearchMode"><i class="fas fa-filter-list"></i></button>
            <button @click="page = 0; find();" class="btn btn-primary" :disabled="detailSearchMode"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 20px; bottom: 150px; overflow: auto;">
          <div v-if="detailSearchMode" class="alert border-dark bg-white text-start" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-label align-bottom">対象システム</div>
                    <div class="form-edit-line">
                      <select v-model="system" @change="loadCompanyList" class="form-select" style="flex: 0 1 120px;">
                        <option v-if="session && session.greenCapo" value="capo">capo</option>
                        <option v-if="session && session.greenJrca" value="jrca">JRCA</option>
                        <option v-if="session && session.greenContano" value="contano">contano</option>
                        <option v-if="session && session.red" value="red">RED</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-label align-bottom">対象分配期</div>
                    <div class="form-edit-line">
                      <select v-if="system === 'capo' && capoCompanyId" v-model="capoDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 150px;">
                        <option v-for="(divYearTerm, index) in capoDivTermList" :key="index" :value="index">
                          {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
                        </option>
                      </select>
                      <select v-if="system === 'jrca' && jrcaCompanyId" v-model="jrcaDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 150px;">
                        <option v-for="(divYearTerm, index) in jrcaDivTermList" :key="index" :value="index">
                          {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
                        </option>
                      </select>
                      <select v-if="system === 'contano' && contanoCompanyId" v-model="contanoDivMonthIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 150px;">
                        <option v-for="(divYearMonth, index) in contanoDivMonthList" :key="index" :value="index">
                          {{divYearMonth.divYear}}年{{divYearMonth.divMonth}}月
                        </option>
                      </select>
                      <select v-if="system === 'red' && redCompanyId" v-model="redDivTermIndex" @change="page = 0; find()" class="form-select" style="flex: 0 1 150px;">
                        <option v-for="(divYearTerm, index) in redDivTermList" :key="index" :value="index">
                          {{divYearTerm.divYear}}年{{divYearTerm.divTerm}}期
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">会社</div>
                <div class="form-edit-line">
                  <select v-model="capoCompanyId" v-if="system === 'capo' && capoCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select">
                    <option v-for="company in capoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
                  </select>
                  <select v-model="jrcaCompanyId" v-if="system === 'jrca' && jrcaCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select">
                    <option v-for="company in jrcaCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
                  </select>
                  <select v-model="contanoCompanyId" v-if="system === 'contano' && contanoCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select">
                    <option v-for="company in contanoCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
                  </select>
                  <select v-model="redCompanyId" v-if="system === 'red' && redCompanyList" @change="loadDivTermList(); page = 0; find()" class="form-select">
                    <option v-for="company in redCompanyList" :key="company.id" :value="company.id">{{company.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">送付先名（メールアドレス含む）</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <input @keypress.enter="page = 0; find();" v-model="searchString" type="text" class="form-control">
                    <button v-show="searchString" @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">権利者名（コード含む）</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <input @keypress.enter="page = 0; find();" v-model="searchRhName" type="text" class="form-control">
                    <button v-show="searchRhName" @click="searchRhName = ''" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">明細作成状況</div>
                <div class="form-edit-line">
                  <select v-model="statementMakeStatus" @change="statementMakeStatusChange" class="form-select">
                    <option value="all">全て</option>
                    <option value="made">作成済のみ</option>
                    <option value="notMade">未作成のみ</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">送信状況</div>
                <div class="form-edit-line">
                  <select v-model="statementSendStatus" class="form-select">
                    <option value="all">全て</option>
                    <option value="sent">送信済のみ</option>
                    <option value="notSent">未送信のみ</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">支払額</div>
                <div class="form-edit-line">
                  <select v-model="paymentAmountType" class="form-select" :disabled="statementMakeStatus === 'notMade'">
                    <option value="all">全て</option>
                    <option value="notZero">0円以外</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">発生額</div>
                <div class="form-edit-line">
                  <select v-model="generatedAmountType" class="form-select" :disabled="statementMakeStatus === 'notMade'">
                    <option value="all">全て</option>
                    <option value="notZeroInThisTerm">今期発生0円以外</option>
                    <option value="notZero">合計発生（繰越＋今期）0円以外</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 text-end" style="margin-top: 10px;">
              <button @click="page = 0; find();" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
              <button @click="clearSearchDetail" class="btn btn-outline-success" title="クリア"><i class="fas fa-undo" style="margin-right: 5px;"></i>クリア</button>
            </div>
          </div>
          <table v-if="statementResult && statementResult.allRecords > 0" class="table table-responsive">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th>送付先名 <span style="font-weight: normal;">（背景色 : <span class="badge text-black" style="background-color: lightgray; font-weight: normal; margin-right: 5px;">送信済</span><span class="badge text-black" style="background-color: #ffff99; font-weight: normal; margin-right: 5px;">処理中</span><span class="badge text-black" style="background-color: #ff6633; font-weight: normal;">エラー</span>）</span></th>
              </tr>
            </thead>
            <tbody v-if="statementResult">
              <tr v-for="(statement, index) in statementList" :key="statement.id" class="text-start align-middle">
                <td>
                  <a @click.prevent.stop="openSendModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></a>
                </td>
                <td :class="{ statusSent: statement.status === 'SENT', statusProcessing: statement.status === 'PROCESSING', statusError: statement.status === 'ERROR' }">
                  <p style="padding-bottom: 2px; margin-bottom: 3px;">{{statement.destinationName}}
                    （<span v-if="statement.destinationEmail" style="font-size: 85%;">{{statement.destinationEmail}}</span><span v-else>メールアドレス非公開</span>）
                  </p>
                  <p v-for="rightholder in statement.rightholderList" :key="rightholder.id" style="font-size: 90%; padding-left: 50px; padding-bottom: 2px; margin-bottom: 5px;">
                    権利者名 : {{rightholder.name}}<br>
                    <span v-if="rightholder.notCalculated">
                      <span class="badge bg-dark">計算書なし</span>
                    </span>
                    <span v-else>
                      <span v-if="rightholder.amountCarriedForward > 0"><span class="badge bg-warning">保留額</span> : {{rightholder.amountCarriedForward}}</span>
                      <template v-else>
                        <span class="badge bg-info">今期発生額</span> : {{rightholder.amountInThisTerm}}
                        <span class="badge bg-info" style="margin-left: 5px;">合計発生額</span> : {{rightholder.totalAmount}}
                        <span class="badge bg-info" style="margin-left: 5px;">支払額</span> : {{rightholder.paymentAmount}}
                      </template>
                    </span>
                  </p>
                  <p class="d-flex justify-content-end" v-if="statement.sentDate" style="font-size: 80%;">送信日時 : {{statement.sentDate}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
        <div class="col-6 d-flex justify-content-start">
          <span v-if="statementResult && statementResult.allRecords > 0">{{statementResult.allRecords}}件の一致データ</span>
          <span v-else style="margin-top: 20px;">一致データが存在しません</span>
        </div>
        <div v-if="statementResult && statementResult.allRecords > 0" class="col-6 d-flex justify-content-end">
          <nav v-if="statementResult" aria-label="Page navigation">
            <ul class="pagination">
              <li v-if="page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
              <li v-if="page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
              <li class="page-item"><span class="page-link">{{page + 1}}/{{statementResult.allPages}}ページ</span></li>
              <li v-if="page !== statementResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
              <li v-if="page !== statementResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
            </ul>
          </nav>
        </div>
        <div class="input-group" style="margin-top: 5px; margin-bottom: 10px;">
          <span class="input-group-text">送信者名</span>
          <select v-model="capoSenderName" v-if="system === 'capo'" class="form-select">
            <option value="1">{{contract.name}}</option>
            <option value="2">{{contract.name}}（{{capoCompanyName}}の代理で送信）</option>
            <option value="3">{{capoCompanyName}}</option>
          </select>
          <select v-model="jrcaSenderName" v-if="system === 'jrca'" class="form-select">
            <option value="1">{{contract.name}}</option>
            <option value="2">{{contract.name}}（{{jrcaCompanyName}}の代理で送信）</option>
            <option value="3">{{jrcaCompanyName}}</option>
          </select>
          <select v-model="contanoSenderName" v-if="system === 'contano'" class="form-select">
            <option value="1">{{contract.name}}</option>
            <option value="2">{{contract.name}}（{{contanoCompanyName}}の代理で送信）</option>
            <option value="3">{{contanoCompanyName}}</option>
          </select>
          <select v-model="redSenderName" v-if="system === 'red'" class="form-select">
            <option value="1">{{contract.name}}</option>
            <option value="2">{{contract.name}}（{{redCompanyName}}の代理で送信）</option>
            <option value="3">{{redCompanyName}}</option>
          </select>
          <button v-if="statementList.length > 0 && canSend" @click="sendAll" class="btn btn-outline-danger"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>一括送信</button>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="sendModalDiv">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">送信</h5>
          </div>
          <div class="modal-body text-start">
            <div v-if="status" class="form-edit-line">
              <span v-if="status === 'SENT'" class="badge text-black" style="background-color: lightgray; font-weight: normal;">送信済</span>
              <span v-if="status === 'PROCESSING'" class="badge text-black" style="background-color: #ffff99; font-weight: normal;">処理中</span>
              <span v-if="status === 'ERROR'" class="badge text-black" style="background-color: #ff6633; font-weight: normal;">エラー</span>
            </div>
            <div class="form-label align-bottom">送付先</div>
            <div class="form-edit-line">
              {{name}}
            </div>
            <div class="form-label align-bottom">メールアドレス</div>
            <div class="form-edit-line">
              {{email}}
            </div>
            <div v-if="sentDate" class="form-label align-bottom">最終送信日時</div>
            <div v-if="sentDate" class="form-edit-line">
              {{sentDate}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button v-if="!canSend" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>プラン上限のため送信できません</button>
              <button v-if="!sendTarget" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>送信対象がありません</button>
              <button v-if="canSend && sendTarget && status === 'SENT'" @click="sendOne" type="button" class="btn btn-danger"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>再送信</button>
              <button v-else-if="canSend && sendTarget && status !== 'PROCESSING'" @click="sendOne" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>送信</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
            <p class="text-center fs-5" style="margin-top: 20px;" v-if="allSendCount !== -1">
              {{sentCount}} / {{allSendCount}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { authFunction } from '@/functions/auth'
import exception from '@/functions/exception'
import { Modal, Toast } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const { identifyEx } = exception()
    const session = ref(null)
    const canSend = ref(false)
    const sendTarget = ref(false)
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const messages = ref([])
    const messageToastDiv = ref(null)
    const contract = ref({})
    const system = ref('')
    const capoCompanyId = ref('')
    const capoCompanyName = ref('')
    const capoCompanyList = ref([])
    const capoDivTermIndex = ref(0)
    const capoDivTermList = ref([])
    const capoSenderName = ref('1')
    const jrcaCompanyId = ref('')
    const jrcaCompanyName = ref('')
    const jrcaCompanyList = ref([])
    const jrcaDivTermIndex = ref(0)
    const jrcaDivTermList = ref([])
    const jrcaSenderName = ref('1')
    const contanoCompanyId = ref('')
    const contanoCompanyName = ref('')
    const contanoCompanyList = ref([])
    const contanoDivMonthIndex = ref(0)
    const contanoDivMonthList = ref([])
    const contanoSenderName = ref('1')
    const redCompanyId = ref('')
    const redCompanyName = ref('')
    const redCompanyList = ref([])
    const redDivTermIndex = ref(0)
    const redDivTermList = ref([])
    const redSenderName = ref('1')
    const searchString = ref('')
    const searchRhName = ref('')
    const page = ref(0)
    const statementResult = ref(null)
    const statementList = ref([])
    let processModal = null
    let messageToast = null
    // 送信用
    const sendModalDiv = ref(null)
    let sendModal = null
    const sendIndex = ref(null)
    const destinationId = ref('')
    const name = ref('')
    const email = ref('')
    const status = ref('')
    const sentDate = ref('')
    const detailSearchMode = ref(false)
    const statementMakeStatus = ref('made')
    const statementSendStatus = ref('notSent')
    const paymentAmountType = ref('all')
    const generatedAmountType = ref('all')
    const allSendCount = ref(-1)
    const sentCount = ref(0)
    // メソッド
    const find = async () => {
      processMessage.value = '明細情報読み込み中です。'
      processModal.show()
      const formatter = new Intl.NumberFormat('ja-JP')
      if (system.value === 'red') {
        for (const company of redCompanyList.value) {
          if (redCompanyId.value === company.id) {
            redCompanyName.value = company.name
            break
          }
        }
        const params = {
          companyId: redCompanyId.value,
          divYear: redDivTermList.value[redDivTermIndex.value].divYear,
          divTerm: redDivTermList.value[redDivTermIndex.value].divTerm,
          searchString: searchString.value,
          rightsHolderName: searchRhName.value,
          page: page.value,
          perPage: 30
        }
        if (statementMakeStatus.value !== 'all') {
          params.statementMakeStatus = statementMakeStatus.value
        }
        if (statementSendStatus.value !== 'all') {
          params.statementSendStatus = statementSendStatus.value
        }
        if (paymentAmountType.value !== 'all') {
          params.paymentAmountType = paymentAmountType.value
        }
        if (generatedAmountType.value !== 'all') {
          params.generatedAmountType = generatedAmountType.value
        }
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: params
        }).then(async response => {
          statementResult.value = response.data
          statementList.value = statementResult.value.list
          for (const statement of statementList.value) {
            statement.destinationName = statement.name
            statement.destinationEmail = statement.email
            await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/sent/status/red/' + statement.id + '/' + redDivTermList.value[redDivTermIndex.value].id, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              statement.status = response.data.status
              statement.sentDate = response.data.createdAt
            })
            await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/list/destination/' + statement.id + '/' + redDivTermList.value[redDivTermIndex.value].id, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              statement.rightholderList = response.data
              for (const rightsHolder of statement.rightholderList) {
                rightsHolder.name = rightsHolder.rightsHolderName
                rightsHolder.paymentAmountFmt = formatter.format(rightsHolder.paymentAmount)
                rightsHolder.amountCarriedForwardFmt = formatter.format(rightsHolder.amountCarriedForward)
                rightsHolder.updatedAt = rightsHolder.createdAt
                if (system.value === 'red') {
                  if (rightsHolder.paymentAmount === null && rightsHolder.amountCarriedForwardOutTax === null && rightsHolder.amountCarriedForwardInTax === null) {
                    rightsHolder.notCalculated = true
                  }
                } else {
                  if (rightsHolder.paymentAmount === null && rightsHolder.amountCarriedForward === null) {
                    rightsHolder.notCalculated = true
                  }
                }
              }
              if (statement.rightholderList && statement.rightholderList.some(x => !x.notCalculated)) {
                statement.sendTarget = true
              } else {
                statement.sendTarget = false
              }
            })
          }
        }).catch(e => {
          if (e.response.status === 404) {
            statementResult.value = {}
            statementList.value = []
          } else {
            console.error(e)
          }
        })
      } else {
        let companyId
        switch (system.value) {
          case 'capo':
            companyId = capoCompanyId.value
            for (const company of capoCompanyList.value) {
              if (companyId === company.id) {
                capoCompanyName.value = company.name
                break
              }
            }
            break
          case 'jrca':
            companyId = jrcaCompanyId.value
            for (const company of jrcaCompanyList.value) {
              if (companyId === company.id) {
                jrcaCompanyName.value = company.name
                break
              }
            }
            break
          case 'contano':
            companyId = contanoCompanyId.value
            for (const company of contanoCompanyList.value) {
              if (companyId === company.id) {
                contanoCompanyName.value = company.name
                break
              }
            }
            break
          default: break
        }
        if (!companyId) {
          statementResult.value = null
          statementList.value = []
        } else {
          let divYear
          let divTerm
          switch (system.value) {
            case 'capo': {
              divYear = capoDivTermList.value[capoDivTermIndex.value].divYear
              divTerm = capoDivTermList.value[capoDivTermIndex.value].divTerm
              break
            }
            case 'jrca': {
              divYear = jrcaDivTermList.value[jrcaDivTermIndex.value].divYear
              divTerm = jrcaDivTermList.value[jrcaDivTermIndex.value].divTerm
              break
            }
            case 'contano': {
              if (contanoDivMonthList.value[contanoDivMonthIndex.value]) {
                divYear = contanoDivMonthList.value[contanoDivMonthIndex.value].divYear
                divTerm = contanoDivMonthList.value[contanoDivMonthIndex.value].divMonth
              }
              break
            }
          }
          const params = {
            system: system.value,
            companyId: companyId,
            divYear: divYear,
            divTerm: divTerm,
            searchString: searchString.value,
            rightsHolderName: searchRhName.value,
            page: page.value,
            perPage: 30
          }
          if (statementMakeStatus.value !== 'all') {
            params.statementMakeStatus = statementMakeStatus.value
          }
          if (statementSendStatus.value !== 'all') {
            params.statementSendStatus = statementSendStatus.value
          }
          if (paymentAmountType.value !== 'all') {
            params.paymentAmountType = paymentAmountType.value
          }
          if (generatedAmountType.value !== 'all') {
            params.generatedAmountType = generatedAmountType.value
          }
          const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: params
          })
          statementResult.value = data
          statementList.value = statementResult.value.list
          const statementPromises = []
          for (const statement of statementList.value) {
            statementPromises.push(getStatement(statement, companyId, divYear, divTerm))
          }
          await Promise.all(statementPromises)
        }
      }
      processModal.hide()
    }
    const getStatement = async (statement, companyId, divYear, divTerm) => {
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/sent/status/' + system.value + '/' + statement.destinationId + '/' + companyId + '/' + divYear + '/' + divTerm, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(async response => {
        statement.status = response.data.status
        statement.sentDate = response.data.createdAt
        const rightsHolderPromises = []
        for (const rightholder of statement.rightholderList) {
          rightsHolderPromises.push(getRightsHolderStatus(companyId, rightholder, divYear, divTerm))
        }
        await Promise.all(rightsHolderPromises)
        statement.sendTarget = !!(statement.rightholderList && statement.rightholderList.some(x => !x.notCalculated))
      })
    }
    const getRightsHolderStatus = async (companyId, rightholder, divYear, divTerm) => {
      const formatter = new Intl.NumberFormat('ja-JP')
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/header/' + system.value + '/' + companyId + '/' + rightholder.id + '/' + divYear + '/' + divTerm, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        if (response.status === 204) {
          rightholder.notCalculated = true
        } else {
          rightholder.notCalculated = false
          rightholder.paymentAmount = response.data.paymentAmount
          rightholder.amountCarriedForward = response.data.amountCarriedForward
          rightholder.paymentAmountFmt = formatter.format(response.data.paymentAmount)
          rightholder.amountCarriedForwardFmt = formatter.format(response.data.amountCarriedForward)
          rightholder.updatedAt = response.data.updatedAt
          rightholder.amountInThisTerm = response.data.amountInThisTerm
          rightholder.totalAmount = response.data.totalAmount
        }
      })
    }
    const clearSearch = () => {
      page.value = 0
      searchString.value = ''
      find()
    }
    const firstPage = () => {
      page.value = 0
      find()
    }
    const prevPage = () => {
      page.value = page.value - 1
      find()
    }
    const nextPage = () => {
      page.value = page.value + 1
      find()
    }
    const lastPage = () => {
      page.value = statementResult.value.allPages - 1
      find()
    }
    const loadCompanyList = async () => {
      processMessage.value = '会社情報を読み込み中です。'
      processModal.show()
      capoCompanyList.value = []
      jrcaCompanyList.value = []
      contanoCompanyList.value = []
      redCompanyList.value = []
      capoCompanyId.value = ''
      jrcaCompanyId.value = ''
      contanoCompanyId.value = ''
      redCompanyId.value = ''
      capoDivTermIndex.value = 0
      jrcaDivTermIndex.value = 0
      contanoDivMonthIndex.value = 0
      redDivTermIndex.value = 0
      page.value = 0
      if (system.value === 'red') {
        await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(async response => {
          redCompanyList.value = response.data
          if (redCompanyList.value.length > 0) {
            redCompanyId.value = redCompanyList.value[0].id
          }
          await loadDivTermList()
          await find()
        })
      } else {
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/contract/' + system.value + '/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(async response => {
          for (const company of response.data) {
            await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system.value + '/company/' + company.companyId, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              const obj = {
                id: response.data.id,
                name: response.data.name
              }
              switch (system.value) {
                case 'capo':
                  if (!capoCompanyId.value) {
                    capoCompanyId.value = obj.id
                  }
                  capoCompanyList.value.push(obj)
                  break
                case 'jrca':
                  if (!jrcaCompanyId.value) {
                    jrcaCompanyId.value = obj.id
                  }
                  jrcaCompanyList.value.push(obj)
                  break
                case 'contano':
                  if (!contanoCompanyId.value) {
                    contanoCompanyId.value = obj.id
                  }
                  contanoCompanyList.value.push(obj)
                  break
                default:
                  break
              }
            })
          }
          await loadDivTermList()
          await find()
        }).catch(error => { identifyEx(error) })
      }
      processModal.hide()
    }
    const loadDivTermList = async () => {
      if (system.value === 'red') {
        await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + redCompanyId.value + '/divterm/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          redDivTermList.value = response.data
          redDivTermList.value.sort((a, b) => { return (b.divYear * 100 + b.divTerm) - (a.divYear * 100 + a.divTerm) })
        })
      } else {
        let divTermOrMonth
        let companyId
        switch (system.value) {
          case 'capo' : {
            divTermOrMonth = 'divterm'
            companyId = capoCompanyId.value
            break
          }
          case 'jrca': {
            divTermOrMonth = 'divterm'
            companyId = jrcaCompanyId.value
            break
          }
          case 'contano': {
            divTermOrMonth = 'divmonth'
            companyId = contanoCompanyId.value
            break
          }
        }
        const divTermRes = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system.value + '/company/' + companyId + '/' + divTermOrMonth + '/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        switch (system.value) {
          case 'capo': {
            capoDivTermList.value = divTermRes.data
            break
          }
          case 'jrca': {
            jrcaDivTermList.value = divTermRes.data
            break
          }
          case 'contano': {
            contanoDivMonthList.value = divTermRes.data
            break
          }
        }
      }
    }
    const openSendModal = (index) => {
      const statement = statementList.value[index]
      sendIndex.value = index
      if (system.value === 'red') {
        destinationId.value = statement.id
      } else {
        destinationId.value = statement.destinationId
      }
      name.value = statement.destinationName
      email.value = statement.destinationEmail
      status.value = statement.status
      sentDate.value = statement.sentDate
      sendTarget.value = statement.sendTarget
      sendModal.show()
    }
    const sendOne = async () => {
      errors.value = []
      messages.value = []
      processMessage.value = '送信処理中です。しばらくお待ちください。'
      processModal.show()
      sendModal.hide()
      const status = await send(destinationId.value)
      processModal.hide()
      if (status === 'success') {
        messages.value = ['送信が完了しました']
        messageToast.show()
      } else {
        errors.value = ['送信エラーが発生しました']
        errorToast.show()
      }
    }
    const send = async (paramDestinationId) => {
      const params = {}
      params.senderName = contract.value.name
      switch (system.value) {
        case 'capo':
          params.companyId = capoCompanyId.value
          params.companyName = capoCompanyName.value
          params.divYear = capoDivTermList.value[capoDivTermIndex.value].divYear
          params.divTerm = capoDivTermList.value[capoDivTermIndex.value].divTerm
          if (capoSenderName.value === '2') {
            params.senderName = contract.value.name + '（' + capoCompanyName.value + 'の代理で送信）'
          } else if (capoSenderName.value === '3') {
            params.senderName = capoCompanyName.value
          }
          break
        case 'jrca':
          params.companyId = jrcaCompanyId.value
          params.companyName = jrcaCompanyName.value
          params.divYear = jrcaDivTermList.value[jrcaDivTermIndex.value].divYear
          params.divTerm = jrcaDivTermList.value[jrcaDivTermIndex.value].divTerm
          if (jrcaSenderName.value === '2') {
            params.senderName = contract.value.name + '（' + jrcaCompanyName.value + 'の代理で送信）'
          } else if (jrcaSenderName.value === '3') {
            params.senderName = jrcaCompanyName.value
          }
          break
        case 'contano':
          params.companyId = contanoCompanyId.value
          params.companyName = contanoCompanyName.value
          params.divYear = contanoDivMonthList.value[contanoDivMonthIndex.value].divYear
          params.divMonth = contanoDivMonthList.value[contanoDivMonthIndex.value].divMonth
          if (contanoSenderName.value === '2') {
            params.senderName = contract.value.name + '（' + contanoCompanyName.value + 'の代理で送信）'
          } else if (contanoSenderName.value === '3') {
            params.senderName = contanoCompanyName.value
          }
          break
        case 'red':
          params.companyId = redCompanyId.value
          params.companyName = redCompanyName.value
          params.companyDivTermId = redDivTermList.value[redDivTermIndex.value].id
          if (redSenderName.value === '2') {
            params.senderName = contract.value.name + '（' + redCompanyName.value + 'の代理で送信）'
          } else if (redSenderName.value === '3') {
            params.senderName = redCompanyName.value
          }
          break
        default:
          break
      }
      params.system = system.value
      params.destinationId = paramDestinationId
      if (statementList.value[sendIndex.value]) {
        statementList.value[sendIndex.value].status = 'PROCESSING'
      }
      let status = ''
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/send', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: params
      }).then(() => {
        if (statementList.value[sendIndex.value]) {
          statementList.value[sendIndex.value].status = 'SENT'
        }
        status = 'success'
      }).catch(error => {
        console.error(error)
        if (statementList.value[sendIndex.value]) {
          statementList.value[sendIndex.value].status = 'ERROR'
        }
        status = 'failure'
      })
      sentCount.value++
      return status
    }
    const sendAll = async () => {
      sentCount.value = 0
      errors.value = []
      messages.value = []
      processMessage.value = '送信処理中です。しばらくお待ちください。'
      processModal.show()
      sendModal.hide()
      if (system.value === 'red') {
        for (const company of redCompanyList.value) {
          if (redCompanyId.value === company.id) {
            redCompanyName.value = company.name
            break
          }
        }
        const params = {
          companyId: redCompanyId.value,
          divYear: redDivTermList.value[redDivTermIndex.value].divYear,
          divTerm: redDivTermList.value[redDivTermIndex.value].divTerm,
          searchString: searchString.value,
          rightsHolderName: searchRhName.value,
          page: 0,
          perPage: 99999
        }
        if (statementMakeStatus.value !== 'all') {
          params.statementMakeStatus = statementMakeStatus.value
        }
        if (statementSendStatus.value !== 'all') {
          params.statementSendStatus = statementSendStatus.value
        }
        if (paymentAmountType.value !== 'all') {
          params.paymentAmountType = paymentAmountType.value
        }
        if (generatedAmountType.value !== 'all') {
          params.generatedAmountType = generatedAmountType.value
        }
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: params
        }).then(async response => {
          const list = response.data.list
          let sentCount = 0
          const sendDestinationIdList = []
          for (const statement of list) {
            await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/list/destination/' + statement.id + '/' + redDivTermList.value[redDivTermIndex.value].id, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              statement.rightholderList = response.data
              for (const rightsHolder of statement.rightholderList) {
                if (rightsHolder.paymentAmount === null && rightsHolder.amountCarriedForwardOutTax === null && rightsHolder.amountCarriedForwardInTax === null) {
                  rightsHolder.notCalculated = true
                }
              }
              if (statement.rightholderList && statement.rightholderList.some(x => !x.notCalculated)) {
                sendDestinationIdList.push(statement.id)
                sentCount++
              }
            })
          }
          if (sentCount === 0) {
            messages.value = ['送信対象が1件もありませんでした']
            messageToast.show()
          } else {
            const promises = sendDestinationIdList.map(x => send(x))
            allSendCount.value = promises.length
            const status = await Promise.all(promises)
            if (status.every(x => x === 'success')) {
              messages.value = ['一括送信が完了しました']
              messageToast.show()
            } else {
              errors.value = ['送信エラーが発生しました']
              errorToast.show()
            }
          }
        })
      } else {
        let companyId
        switch (system.value) {
          case 'capo':
            companyId = capoCompanyId.value
            for (const company of capoCompanyList.value) {
              if (companyId === company.id) {
                capoCompanyName.value = company.name
                break
              }
            }
            break
          case 'jrca':
            companyId = jrcaCompanyId.value
            for (const company of jrcaCompanyList.value) {
              if (companyId === company.id) {
                jrcaCompanyName.value = company.name
                break
              }
            }
            break
          case 'contano':
            companyId = contanoCompanyId.value
            for (const company of contanoCompanyList.value) {
              if (companyId === company.id) {
                contanoCompanyName.value = company.name
                break
              }
            }
            break
          default: break
        }
        if (!companyId) {
          statementResult.value = null
          statementList.value = []
        } else {
          let divYear
          let divTerm
          switch (system.value) {
            case 'capo': {
              divYear = capoDivTermList.value[capoDivTermIndex.value].divYear
              divTerm = capoDivTermList.value[capoDivTermIndex.value].divTerm
              break
            }
            case 'jrca': {
              divYear = jrcaDivTermList.value[jrcaDivTermIndex.value].divYear
              divTerm = jrcaDivTermList.value[jrcaDivTermIndex.value].divTerm
              break
            }
            case 'contano': {
              if (contanoDivMonthList.value[contanoDivMonthIndex.value]) {
                divYear = contanoDivMonthList.value[contanoDivMonthIndex.value].divYear
                divTerm = contanoDivMonthList.value[contanoDivMonthIndex.value].divMonth
              }
              break
            }
          }
          const params = {
            system: system.value,
            companyId: companyId,
            divYear: divYear,
            divTerm: divTerm,
            searchString: searchString.value,
            rightsHolderName: searchRhName.value,
            page: 0,
            perPage: 99999
          }
          if (statementMakeStatus.value !== 'all') {
            params.statementMakeStatus = statementMakeStatus.value
          }
          if (statementSendStatus.value !== 'all') {
            params.statementSendStatus = statementSendStatus.value
          }
          if (paymentAmountType.value !== 'all') {
            params.paymentAmountType = paymentAmountType.value
          }
          if (generatedAmountType.value !== 'all') {
            params.generatedAmountType = generatedAmountType.value
          }
          await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: params
          }).then(async response => {
            const list = response.data.list
            let divYear
            let divTerm
            switch (system.value) {
              case 'capo': {
                divYear = capoDivTermList.value[capoDivTermIndex.value].divYear
                divTerm = capoDivTermList.value[capoDivTermIndex.value].divTerm
                break
              }
              case 'jrca': {
                divYear = jrcaDivTermList.value[jrcaDivTermIndex.value].divYear
                divTerm = jrcaDivTermList.value[jrcaDivTermIndex.value].divTerm
                break
              }
              case 'contano': {
                if (contanoDivMonthList.value[contanoDivMonthIndex.value]) {
                  divYear = contanoDivMonthList.value[contanoDivMonthIndex.value].divYear
                  divTerm = contanoDivMonthList.value[contanoDivMonthIndex.value].divMonth
                }
                break
              }
            }
            let sentCount = 0
            const sendDestinationIdList = []
            for (const statement of list) {
              for (const rightholder of statement.rightholderList) {
                await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/statement/header/' + system.value + '/' + companyId + '/' + rightholder.id + '/' + divYear + '/' + divTerm, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  if (response.status === 204) {
                    rightholder.notCalculated = true
                  }
                })
              }
              if (statement.rightholderList && statement.rightholderList.some(x => !x.notCalculated)) {
                sendDestinationIdList.push(statement.destinationId)
                sentCount++
              }
            }
            if (sentCount === 0) {
              messages.value = ['送信対象が1件もありませんでした']
              messageToast.show()
            } else {
              const promises = sendDestinationIdList.map(x => send(x))
              allSendCount.value = promises.length
              const status = await Promise.all(promises)
              if (status.every(x => x === 'success')) {
                messages.value = ['一括送信が完了しました']
                messageToast.show()
              } else {
                errors.value = ['送信エラーが発生しました']
                errorToast.show()
              }
            }
          }).catch(error => { identifyEx(error) })
        }
      }
      allSendCount.value = -1
      processModal.hide()
      await find()
    }
    async function canSendStatement () {
      if (!session.value.planGreen) {
        return false
      }
      const plan = session.value.planGreen
      const capo = session.value.greenCapo
      const jrca = session.value.greenJrca
      const contano = session.value.greenContano
      const red = session.value.red
      let contractMode = 'SINGLE'
      if ((capo || jrca) && (contano || red)) {
        contractMode = 'DOUBLE'
      }
      let maxCount = 0
      if (!(!capo && !jrca && !contano && !red)) {
        switch (plan) {
          case 'FREE':
            if (contractMode === 'SINGLE') {
              maxCount = 99
            } else {
              maxCount = 0
            }
            break
          case 'BRONZE':
            if (contractMode === 'SINGLE') {
              maxCount = 999
            } else {
              maxCount = 99
            }
            break
          case 'SILVER':
            if (contractMode === 'SINGLE') {
              maxCount = 2999
            } else {
              maxCount = 999
            }
            break
          case 'GOLD':
            maxCount = 99999
            break
          default:
            maxCount = 0
        }
      }
      const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/count', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      return (data <= maxCount)
    }
    const clearSearchDetail = () => {
      searchString.value = ''
      searchRhName.value = ''
      statementMakeStatus.value = 'made'
      statementSendStatus.value = 'notSent'
      paymentAmountType.value = 'all'
      generatedAmountType.value = 'all'
    }
    const statementMakeStatusChange = () => {
      if (statementMakeStatus.value === 'notMade') {
        paymentAmountType.value = 'all'
        generatedAmountType.value = 'all'
      }
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      sendModal = new Modal(sendModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal.show()
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        session.value = response.data
        if (session.value.greenCapo) {
          system.value = 'capo'
        } else if (session.value.greenJrca) {
          system.value = 'jrca'
        } else if (session.value.greenContano) {
          system.value = 'contano'
        } else if (session.value.red) {
          system.value = 'red'
        }
      }).catch(error => { identifyEx(error) })
      const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contract', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).catch(error => { identifyEx(error) })
      contract.value = response.data
      await loadCompanyList()
      canSend.value = await canSendStatement()
    })
    return {
      session,
      canSend,
      processMessage,
      processModalDiv,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      contract,
      system,
      capoCompanyId,
      capoCompanyName,
      jrcaCompanyId,
      jrcaCompanyName,
      contanoCompanyId,
      contanoCompanyName,
      redCompanyId,
      redCompanyName,
      capoCompanyList,
      capoDivTermIndex,
      capoDivTermList,
      capoSenderName,
      jrcaCompanyList,
      jrcaDivTermList,
      jrcaDivTermIndex,
      jrcaSenderName,
      contanoCompanyList,
      contanoDivMonthList,
      contanoDivMonthIndex,
      contanoSenderName,
      redCompanyList,
      redDivTermList,
      redDivTermIndex,
      redSenderName,
      searchString,
      page,
      statementResult,
      statementList,
      sendModalDiv,
      sendIndex,
      destinationId,
      name,
      email,
      status,
      sentDate,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      loadCompanyList,
      loadDivTermList,
      openSendModal,
      send,
      sendAll,
      sendTarget,
      sendOne,
      searchRhName,
      detailSearchMode,
      statementMakeStatus,
      statementSendStatus,
      paymentAmountType,
      generatedAmountType,
      clearSearchDetail,
      allSendCount,
      sentCount,
      statementMakeStatusChange
    }
  }
})
</script>

<style scoped>
.statusSent {
  background-color: lightgray;
}
.statusProcessing {
  background-color: #ffff99;
}
.statusError {
  background-color: #ff6633;
}
</style>
