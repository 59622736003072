<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>rights.services <span style="color: #8EC31F;">GREEN</span> ログイン手順</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>明細送付の案内が届いたメールアドレスを入力すると認証コードがそのメールアドレス宛てに届きます。そのコードを入力することでメールアドレスの所有確認とログイン処理が行われます。</p>
      <p>ログインができない場合には、<b>明細送付の案内が届いたメールアドレス</b>、<b>明細送付の案内を送付した会社</b>（メールに記載してあります）と<b>「ログインできない」</b>旨を記載したメールを「<a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>」宛てにお送りください。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">メールアドレスを入力し、認証コードを送信します。
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              明細送付の連絡が届いたメールアドレスを所定の場所に入力します。<br>
              <img src="../../assets/help/login_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              認証コード送信ボタンをクリック／タップします。<br>
              <img src="../../assets/help/login_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">認証コードを入力します。
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              認証コード送信に使用したメールアドレス宛に認証コードが届きます。<br>
              <img src="../../assets/help/auth_mail.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              前項の認証コードを所定の場所に入力します。<br>
              <img src="../../assets/help/auth_01.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              ログインボタンをクリック／タップします。<br>
              <img src="../../assets/help/auth_02.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">利用規約に同意してシステムの利用を開始します。
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              初回ログイン時のみ利用規約が表示されますので、ご同意の上「利用規約に同意し、本サービスの利用を開始する」ボタンをクリック／タップします。<br>
              <img src="../../assets/help/agree.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">受領明細一覧の画面が表示されればログイン成功です。受領した明細の確認については<router-link to="/help/receive">こちら</router-link>をご覧ください。</li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
