<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>認証コードの入力回数を減らす</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>ログイン時の認証コードによる認証を省略し、簡単にログインできるように設定することができます。</p>
      <p>本設定をした端末は誰でもログインできるようになってしまいます。他者と共有している端末では<b class="red-text text-darken-3">決して設定しない</b>ようにしてください。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">
          ログイン時に<router-link to="/help/login">2-1</router-link>の手順に従い、認証コード入力画面まで進みます。
        </li>
        <li style="margin-top: 20px;">
          認証コード入力画面にある設定を「この端末を認証」に変更します。<br>
          <img src="../../assets/help/remember.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
        <li style="margin-top: 20px;">
          以降は<router-link to="/help/login">2-1</router-link>と同様の手順でログインを行います。
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
