<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>rights.services <span style="color: #8EC31F;">GREEN</span> 利用規約</b></h4>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第1条（利用規約の適用）
    </div>
    <div class="row text-start">
      東京ライツサービス株式会社（以下、「当社」という。）は、この利用規約（以下、単に「利用規約」という）に基づき、オンライン明細サービス「rights.services GREEN」（以下、「本サービス」という。）を提供する。
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第2条（通知）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>当社から本サービスを利用するもの（以下、「利用者」という。）への通知は、別途特段の定めのない限り、通知内容を電子メールまたは当社のホームページ等に掲載するなど、当社が適当と判断する方法により行う。</li>
        <li>前項の規定に基づき当社から利用者へ行われた通知は、それぞれ電子メールの送信またはホームページ等への掲載がなされた時点から効力を生じるものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第3条（反社会的勢力の排除）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>
          利用者および当社は、現在、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標榜ゴロまたは特殊知能暴力集団等、その他これに準ずる者（以下、「反社会的勢力」という。）のいずれでもなく、また、反社会的勢力が経営に実質的に関与している法人等に属する者ではないことを表明し、かつ将来にわたっても該当しないことを確約する。
        </li>
        <li>
          当社は、利用者が次の各号のいずれかに該当する場合、何らの催告をすることなく本サービスの利用を停止することができ、利用者および第三者に損害が生じてもこれを賠償することを要しない。
          <ol style="padding-left: 20px; margin-bottom: 0;">
            <li>反社会的勢力に該当すると認められるとき</li>
            <li>利用者の経営に反社会的勢力が実質的に関与していると認められるとき</li>
            <li>反社会的勢力を利用していると認められるとき</li>
            <li>反社会的勢力に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められるとき</li>
            <li>利用者または利用者の役員もしくは利用者の経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有しているとき</li>
            <li>自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動、暴力および風説の流布・偽計・威力を用いた信用棄損・業務妨害その他これらに準ずる行為に及んだとき</li>
          </ol>
        </li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第4条（合意管轄）
    </div>
    <div class="row text-start">
      利用者と当社間の紛争については、東京地方裁判所を第一審の管轄裁判所とする。
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第5条（一次的な中断および提供停止）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>
          当社は、次の各号のいずれかに該当する場合には、利用者への事前の通知または承諾を要することなく、本サービスの提供を中断することができるものとする。
          <ol style="padding-left: 20px; margin-bottom: 0;">
            <li>本サービス用設備等の故障により保守を行う場合</li>
            <li>運用上または技術上の理由でやむを得ない場合</li>
            <li>その他天災地変等不可抗力により本サービスを提供できない場合</li>
          </ol>
        </li>
        <li>
          当社は、本サービス用設備等の定期点検を行うため、利用者に事前に通知の上、本サービスの提供を一時的に中断できるものとする。
        </li>
        <li>
          当社は、前各項に定める事由のいずれかにより本サービスを提供できなかったことに関して利用者またはその他の第三者が損害を被った場合であっても、一切責任を負わないものとする。
        </li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第6条（利用者からの利用終了通知）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>当社は、本サービス用設備等の定期点検を行うため、利用者に事前に通知の上、本サービスの提供を一時的に中断できるものとする。</li>
        <li>当社は、前各項に定める事由のいずれかにより本サービスを提供できなかったことに関して利用者またはその他の第三者が損害を被った場合であっても、一切責任を負わないものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第7条（当社からの利用終了措置）
    </div>
    <div class="row text-start">
      当社は、利用者が次の各号のいずれかに該当すると判断した場合、利用者への事前の通知もしくは催告を要することなく本サービスの利用を停止することができるものとする。
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>利用規約に違反し当社がかかる違反の是正を催告した後合理的な期間内に是正されない場合</li>
        <li>監督官庁から営業許可の取り消し、停止等の処分を受けた場合</li>
        <li>解散、減資、営業の全部または重要な一部の譲渡等の決議をした場合</li>
        <li>利用規約を履行することが困難となる事由が生じた場合</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第8条（本サービスの廃止）
    </div>
    <div class="row text-start">
      当社は、次の各号のいずれかに該当する場合、本サービスの全部または一部を廃止するものとする。
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>本サービスの登録データを継承した新サービスを開始し、利用者が新サービスの利用を開始している場合</li>
        <li>天災地変等不可抗力により本サービスを提供できない場合</li>
        <li>廃止日の90日前までに利用者に通知した場合</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第9条（禁止事項）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>
          利用者は本サービスの利用に関して、以下の行為を行わないものとする。
          <ol style="padding-left: 20px; margin-bottom: 0;">
            <li>日本の法律に反する違反行為</li>
            <li>第三者に損失または損害を与える行為</li>
            <li>人権を侵害する行為、またはそのおそれのある行為</li>
            <li>誹謗、中傷など、公序良俗に反する行為、またはそのおそれのある行為</li>
            <li>わいせつ、児童ポルノまたは児童虐待にあたる画像、文書等を送信または掲載する行為</li>
            <li>犯罪的行為、犯罪的行為に結びつく行為、またはそのおそれのある行為</li>
            <li>当社もしくは第三者の著作権、商標権などの知的財産権その他の権利を侵害する行為、またそのおそれのある行為</li>
            <li>第三者に本サービスを利用させる行為</li>
            <li>第三者になりすまして本サービスを利用する行為</li>
            <li>ウィルス等の有害なコンピュータプログラム等を送信または掲載する行為</li>
            <li>本サービスの利用で知り得た、当社および第三者の営業秘密を漏洩する行為</li>
            <li>広告、宣伝もしくは勧誘のメールを送信する行為</li>
            <li>本サービス用設備または第三者の設備の利用もしくは運営に支障を与える行為、または与えるおそれのある行為</li>
            <li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為</li>
          </ol>
        </li>
        <li>当社は、本サービスの利用に関して、利用者の行為が第1項各号のいずれかに該当するものであることまたは利用者の提供した情報が第１項各号のいずれかの行為に関連する情報であることを知った場合、事前に利用者に通知することなく、本サービスの全部または一部の提供を一時停止し、または第１項各号に該当する行為に関連する情報を削除することができるものとする。ただし、当社は、利用者の行為または利用者が提供または伝送する（利用者の利用とみなされる場合も含む。）情報（データ、コンテンツを含む。）を監視する義務を負うものではない。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第10条（秘密情報の取り扱い）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>利用者および当社は、本サービス上で相手方より提供を受けた技術上または営業上その他業務上の情報全てを秘密情報として扱い、第三者に開示または漏洩しないものとする。ただし、相手方からあらかじめ書面による承諾を受けた場合および次の各号のいずれかに該当する情報についてはこの限りではない。</li>
        <li>前各項の定めにかかわらず、利用者および当社は、秘密情報のうち法令の定めに基づきまたは権限ある官公署からの要求により開示すべき情報を、当該法令の定めに基づく開示先または当該官公署に対し開示することができるものとする。この場合、利用者および当社は、関連法令に反しない限り、当該開示前に開示する旨を相手方に通知するものとし、開示前に通知を行うことができない場合は開示後すみやかにこれを行うものとする。</li>
        <li>秘密情報の提供を受けた当事者は、相手方より提供を受けた秘密情報を本サービスまたは本サービスでサポートされる業務およびシステム操作を目的としてのみ使用し、本サービス遂行上必要な範囲内で秘密情報を化体した資料等（以下本条において「資料等」という。）を複製または改変（以下本項においてあわせて「複製等」という。）することができるものとする。この場合、利用者および当社は、当該複製等された秘密情報についても、本条に定める秘密情報として取り扱うものとする。</li>
        <li>秘密情報の提供を受けた当事者は、相手方の要請があったときは資料等（本条第3項に基づき相手方の承諾を得て複製、改変した秘密情報を含む。）を相手方に返還し、秘密情報が利用者の設備、当社の設備または本サービス用設備に蓄積されている場合はこれを完全に消去するものとする。</li>
        <li>本条の規定は、本サービスの利用終了後および本サービスの廃止後であっても継続して効力を有するものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第11条（個人情報の取り扱い）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>利用者および当社は、本サービス上で相手方より提供を受けた営業上その他業務上の情報に含まれる個人情報（個人情報の保護に関する法律に定める「個人情報」をいう。以下同じとする。）を本サービスまたは本サービスでサポートされる業務およびシステム操作を目的としてのみ使用し、第三者に開示または漏洩しないものとするとともに、個人情報に関して個人情報の保護に関することを含め関連法令を遵守するものとする。</li>
        <li>個人情報の取り扱いについては、前条（秘密情報の取り扱い）第４項の規定を準用するものとする。</li>
        <li>本条の規定は、本サービスの利用終了後および本サービスの廃止後であっても継続して効力を有するものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第12条（免責）
    </div>
    <div class="row text-start">
      本サービスの利用に関して当社が負う責任は当社が本利用規約に違反した場合に限られるものとし、当社は、利用者に発生した損害については一切の責任を負わないものとする。
    </div>
    <div class="row justify-content-end">
      以上
    </div>
    <footer class="alert alert-warning" style="margin-top: 50px;">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
