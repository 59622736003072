<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-guitars fa-fw" style="margin-right: 5px;"></i> 印税種別一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/royaltytype/edit" class="btn btn-outline-primary"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 60px; overflow: auto;">
          <table v-if="royaltytypeList.length > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th>印税種別名</th>
                <th style="width: 116px;">略称</th>
                <th>印税種別英語名</th>
                <th style="width: 116px;">英語略称</th>
              </tr>
            </thead>
            <tbody v-if="royaltytypeList">
              <tr v-for="royaltytype in royaltytypeList" :key="royaltytype.id" class="text-start">
                <td>{{ royaltytype.name }}</td>
                <td>{{ royaltytype.abbreviation }}</td>
                <td>{{ royaltytype.nameEn }}</td>
                <td>{{ royaltytype.abbreviationEn }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { royaltyTypeFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const royaltyTypeFunc = royaltyTypeFunction()
    const royaltytypeList = ref([])
    // メソッド
    const find = async () => {
      royaltytypeList.value = await royaltyTypeFunc.find(session.session.company.id)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      royaltytypeList,
      find
    }
  }
})
</script>
