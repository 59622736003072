<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績ファイル一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="downloadStatusList" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-download fa-fw" style="margin-right: 5px;"></i>取込状況一覧</button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <select v-model="store.status" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 150px;">
              <option value="all">全て</option>
              <option value="todo">未取込</option>
              <option value="done">取込済</option>
              <option value="none">ファイルなし</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="userSalesResult && userSalesResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 90%;">
            <thead>
              <tr>
                <th style="width: 80px;"></th>
                <th>利用者</th>
                <th>頒布形態</th>
                <th class="d-none d-lg-table-cell">定義</th>
                <th style="width: 100px;">ステータス</th>
              </tr>
            </thead>
            <tbody v-if="userSalesList">
              <tr v-for="usage in userSalesList" :key="usage.id" class="text-start align-middle" :class="{ 'no-data-row': usage.dataNotExists }">
                <td v-if="!usage.dataNotExists" class="text-center">
                  <button @click="router.push('/red/usage/register/' + usage.id)" class="btn btn-sm btn-outline-primary">
                    <i class="fas fa-cloud-upload fa-fw" style="margin-right: 3px;"></i>
                    <i class="fas fa-magnifying-glass-plus fa-fw"></i>
                  </button>
                </td>
                <td v-else class="text-center">
                  <button @click="router.push('/red/usage/register/' + usage.id)" class="btn btn-sm btn-outline-primary"><i class="fas fa-edit fa-fw"></i></button>
                </td>
                <td>{{ usage.userName }}</td>
                <td>{{ usage.name }}</td>
                <td class="d-none d-lg-table-cell">{{ usage.definitionName }}</td>
                <td class="text-center">
                  <span v-if="usage.status === 'done'" class="badge bg-success">取込済</span>
                  <span v-else-if="usage.status === 'none'" class="badge bg-secondary">ファイルなし</span>
                  <span v-else class="badge bg-primary">未取込</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="userSalesResult && userSalesResult.allRecords > 0">{{ userSalesResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="userSalesResult && userSalesResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="userSalesResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ userSalesResult.allPages }}ページ</span></li>
                <li v-if="store.page !== userSalesResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== userSalesResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usageStore } from './store'
import { usageFunction } from './function'
import { redCompanyFunction } from '@/functions/common'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const session = sessionStore()
    // 検索
    const usageFunc = usageFunction()
    const companyFunc = redCompanyFunction()
    const store = usageStore()
    const userSalesResult = ref(null)
    const userSalesList = ref([])
    // メソッド
    const find = async () => {
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      userSalesResult.value = await usageFunc.find(session.session.company.id, divTerm.id)
      userSalesList.value = userSalesResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = userSalesResult.value.allPages - 1
      find()
    }
    const downloadStatusList = async () => {
      processMessage.value = '状況一覧の作成中です。しばらくお待ちください。'
      processModal.show()
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      const url = await usageFunc.getStatusListUrl(divTerm.id)
      const a = document.createElement('a')
      a.href = url
      a.click()
      processModal.hide()
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await find()
    })
    return {
      processMessage,
      processModalDiv,
      router,
      store,
      userSalesResult,
      userSalesList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      downloadStatusList
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
