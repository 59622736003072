<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/contract/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] 契約一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="id" :to="'/admin/contract/edit/' + id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link to="/admin/contract/edit/NEW" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div v-if="useOnTest" class="alert alert-warning text-center">テスト契約</div>
          <div class="form-label align-bottom">名前</div>
          <div class="form-edit-line">
            {{name}}
          </div>
          <div class="form-label align-bottom">使用システム</div>
          <div class="form-edit-line">
            <span v-if="blue === 'YES'" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
            <span v-if="red === 'YES'" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
            <span v-if="pink === 'YES'" class="badge text-white" style="background-color: #EE86A8; margin-right: 5px;">PINK</span>
            <span v-if="green === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">GREEN</span>
          </div>
          <div class="form-label align-bottom">ユーザー</div>
          <div class="form-edit-line">
            <table class="table table-responsive table-hover">
              <tbody>
              <tr v-for="(user, index) in userList" :key="index">
                <td>
                  {{user.name}}<br>
                  <span class="d-block d-md-none">{{user.email}}</span>
                </td>
                <td>
                  <span style="font-size: 90%;">{{user.email}}</span>
                </td>
                <td style="width: 80px;" class="text-end">
                  <span v-if="user.hasPicRole === 'YES'" class="badge bg-warning"><i class="fas fa-crown" style="margin-right: 5px;"></i>責任者</span>
                  <span v-else class="badge bg-light text-black">一般</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="red === 'YES'" class="card" style="margin-bottom: 10px;">
            <div class="card-header text-white" style="background-color: #E83821;">RED</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/green" target="_blank">こちら</a></div>
              <div class="form-edit-line">
                {{planRed}}
              </div>
              <div class="form-label align-bottom">利用実績件数</div>
              <div class="form-edit-line">
                現在期の件数 : {{redCurrentCount.toLocaleString()}}件
              </div>
              <div class="form-edit-line">
                <bar-chart :chart-data="redUsage"></bar-chart>
              </div>
            </div>
          </div>
          <div v-if="green === 'YES'" class="card" style="margin-bottom: 10px;">
            <div class="card-header text-white" style="background-color: #8EC31F;">GREEN</div>
            <div class="card-body" style="padding: 10px 5px 5px 10px;">
              <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/green" target="_blank">こちら</a></div>
              <div class="form-edit-line">
                {{planGreen}}
              </div>
              <div class="form-label align-bottom">メッセージ機能オプション</div>
              <div class="form-edit-line">
                <span v-if="greenOptionExternal">利用する</span>
                <span v-else>利用しない</span>
              </div>
              <div class="form-label align-bottom">送付先件数</div>
              <div class="form-edit-line">
                {{greenDestinationCount.toLocaleString()}}
              </div>
              <div class="form-label align-bottom">連携システム</div>
              <div class="form-edit-line">
                <span v-if="greenCapo === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">capo</span>
                <span v-if="greenJrca === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">JRCA</span>
                <span v-if="greenContano === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">contano</span>
              </div>
              <div v-if="greenCapo === 'YES'" class="form-label align-bottom">capo会社</div>
              <div v-if="greenCapo === 'YES'" class="form-edit-line">
                <table class="table table-responsive table-hover">
                  <tbody>
                  <tr v-for="(company, index) in capoCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                    <td>{{company.companyName}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="greenJrca === 'YES'" class="form-label align-bottom">JRCA会社</div>
              <div v-if="greenJrca === 'YES'" class="form-edit-line">
                <table class="table table-responsive table-hover">
                  <tbody>
                  <tr v-for="(company, index) in jrcaCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                    <td>{{company.companyName}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="greenContano === 'YES'" class="form-label align-bottom">contano会社</div>
              <div v-if="greenContano === 'YES'" class="form-edit-line">
                <table class="table table-responsive table-hover">
                  <tbody>
                  <tr v-for="(company, index) in contanoCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                    <td>{{company.companyName}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-label align-bottom">PDFダウンロード</div>
              <div class="form-edit-line">
                <span v-if="downloadablePdf">受領者によるPDFダウンロードを許可する</span>
                <span v-else>受領者によるPDFダウンロードを許可しない</span>
              </div>
              <div class="form-label align-bottom">テキストダウンロード</div>
              <div class="form-edit-line">
                <span v-if="downloadableText">受領者によるテキストダウンロードを許可する</span>
                <span v-else>受領者によるテキストダウンロードを許可しない</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Modal } from 'bootstrap'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

export default defineComponent({
  components: { navComponent, BarChart },
  setup () {
    const auth = authFunction()
    const route = useRoute()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    // Form
    const useOnTest = ref(false)
    const id = ref(null)
    const name = ref('')
    const blue = ref('NO')
    const red = ref('NO')
    const pink = ref('NO')
    const green = ref('NO')
    const greenCapo = ref('NO')
    const greenContano = ref('NO')
    const greenJrca = ref('NO')
    const greenQaEmail = ref('')
    const planGreen = ref('FREE')
    const planRed = ref('BRONZE')
    const greenDestinationCount = ref(0)
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    const userList = ref([])
    const rightAreaMode = ref('BLUE')
    const greenOptionExternal = ref(false)
    const downloadablePdf = ref(true)
    const downloadableText = ref(true)
    const redCurrentCount = ref(0)
    const labelList = ref([])
    const dataList = ref([])
    const bgList = ref([])
    const redUsage = {
      labels: labelList.value,
      datasets: [{
        label: '利用実績件数',
        data: dataList.value,
        backgroundColor: bgList.value
      }]
    }
    // メソッド
    function getCompanyName (system, id, set) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        set(response.data.name)
      })
    }
    function getCompanyList (system, set) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + id.value + '/' + system + '/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        set(response.data)
      })
    }
    function entityToForm (model) {
      name.value = model.name
      blue.value = model.blue
      red.value = model.red
      pink.value = model.pink
      green.value = model.green
      greenCapo.value = model.greenCapo
      greenJrca.value = model.greenJrca
      greenContano.value = model.greenContano
      greenQaEmail.value = model.greenQaEmail
      planGreen.value = model.planGreen
      planRed.value = model.planRed
      greenOptionExternal.value = model.greenOptionExternal
      downloadablePdf.value = model.downloadablePdf
      downloadableText.value = model.downloadableText
      useOnTest.value = model.useOnTest
      userList.value = model.accountList
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      id.value = route.params.id
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
        if (blue.value !== 'YES') {
          if (red.value === 'YES') {
            rightAreaMode.value = 'RED'
          } else if (green.value === 'YES') {
            rightAreaMode.value = 'GREEN'
          }
        }
        getCompanyList('capo', function (list) {
          capoCompanyList.value = list
          for (const company of capoCompanyList.value) {
            getCompanyName('capo', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        getCompanyList('jrca', function (list) {
          jrcaCompanyList.value = list
          for (const company of jrcaCompanyList.value) {
            getCompanyName('jrca', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        getCompanyList('contano', function (list) {
          contanoCompanyList.value = list
          for (const company of contanoCompanyList.value) {
            getCompanyName('contano', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + id.value + '/destination/count', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          greenDestinationCount.value = response.data
        })
        if (red.value === 'YES') {
          axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list/contract/' + id.value, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            const companyList = response.data
            const companyIdList = companyList.map(x => x.id)
            axios.get(process.env.VUE_APP_RED_ENDPOINT + '/usage/count/list', {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
              params: {
                company_id_list: companyIdList.join(',')
              }
            }).then(response => {
              response.data.forEach((usage, index) => {
                redCurrentCount.value = usage.count
                labelList.value.push(usage.div_year + '年' + usage.div_term + '期')
                dataList.value.push(usage.count)
                bgList.value.push('#77CEFF')
              })
            })
          })
        }
      })
      loadingModal.hide()
    })
    return {
      loadingModalDiv,
      id,
      useOnTest,
      name,
      blue,
      red,
      pink,
      green,
      greenCapo,
      greenJrca,
      greenContano,
      greenQaEmail,
      planGreen,
      planRed,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      userList,
      greenDestinationCount,
      rightAreaMode,
      greenOptionExternal,
      downloadablePdf,
      downloadableText,
      redUsage,
      redCurrentCount
    }
  }
})
</script>
