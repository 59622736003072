<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component v-if="route.path.indexOf('/unmatched') === -1" class="d-none d-md-block"></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div v-if="route.path.indexOf('/unmatched') === -1" class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/album/list"><i class="fas fa-album fa-fw" style="margin-right: 5px;"></i>
                      アルバム一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
                <ol v-if="route.path.includes('/red/result/album/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/result/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      リアルタイム結果一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/result/disp/' + route.params.resultId"><i class="fas fa-eye" style="margin-right: 5px;"></i>結果詳細</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/album/edit/' + form.id" class="btn btn-outline-primary d-none d-lg-block" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link v-if="form && form.id" :to="'/red/album/edit/' + form.id" class="btn btn-outline-primary d-block d-lg-none" style="margin-right: 5px;"><i class="fas fa-edit fa-fw"></i></router-link>
              <router-link v-if="form && form.id" :to="'/red/album/copy/' + form.id" class="btn btn-outline-primary d-none d-lg-block" style="margin-right: 5px;"><i class="fas fa-copy fa-fw" style="margin-right: 5px;"></i>コピー</router-link>
              <router-link v-if="form && form.id" :to="'/red/album/copy/' + form.id" class="btn btn-outline-primary d-block d-lg-none" style="margin-right: 5px;"><i class="fas fa-copy fa-fw"></i></router-link>
              <router-link to="/red/album/add" class="btn btn-outline-primary d-none d-lg-block"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
              <router-link to="/red/album/add" class="btn btn-outline-primary d-block d-lg-none"><i class="fas fa-plus fa-fw"></i></router-link>
            </div>
          </div>
          <div v-else style="margin-top: 10px;" class="container text-start"><button class="btn btn-outline-primary" @click="router.go(-1)"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button></div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="row">
              <div class="col-lg-7 col-md-12">
                <div class="form-label align-bottom">名前</div>
                <div class="form-edit-line fs-5" style="font-weight: bold;">
                  {{ form.name }}
                </div>
                <div v-if="form.kana" class="form-label align-bottom">カナ</div>
                <div v-if="form.kana" class="form-edit-line">
                  {{ form.kana }}
                </div>
                <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
                <div v-if="form.nameEn" class="form-edit-line">
                  {{ form.nameEn }}
                </div>
                <div v-if="form.artistId" class="form-label align-bottom">アーティスト</div>
                <div v-if="form.artistId" class="form-edit-line">
                  <button @click="openArtistRefModal(form.artistId)" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                  {{ form.artistName }}
                </div>
                <div v-if="form.projectId" class="form-label align-bottom">プロジェクト</div>
                <div v-if="form.projectId" class="form-edit-line">
                  <router-link :to="'/common/project/disp/' + form.projectId">{{ form.projectName }}</router-link>
                </div>
                <div v-if="form.makerList && form.makerList.length > 0" class="form-label align-bottom">
                  レコード会社 / レーベル
                </div>
                <div v-if="form.makerList" class="form-edit-line">
                  <p v-for="maker in form.makerList" :key="maker.userId" style="margin: 0;">
                    {{maker.userName}}
                  </p>
                </div>
                <div v-if="form.catalogNoList && form.catalogNoList.length > 0" class="form-label align-bottom">
                  製品番号
                </div>
                <div class="form-edit-line">
                  <p v-for="(catalogNo, index) in form.catalogNoList" :key="index" style="margin: 0;">
                    {{catalogNo.catalogNo}}
                  </p>
                </div>
                <div v-if="form.janCodeList && form.janCodeList.length > 0" class="form-label align-bottom">
                  JANコード
                </div>
                <div class="form-edit-line">
                  <p v-for="(janCode, index) in form.janCodeList" :key="index" style="margin: 0;">
                    {{janCode.janCode}}
                  </p>
                </div>
                <div v-if="form.salesDate" class="form-label align-bottom">発売日</div>
                <div v-if="form.salesDate" class="form-edit-line">
                  {{form.salesDate.split('-')[0] + '年' + form.salesDate.split('-')[1] + '月' + form.salesDate.split('-')[2] + '日'}}
                </div>
                <div v-if="form.salesPriceOutTax || form.salesPriceInTax" class="form-label align-bottom">販売価格</div>
                <div v-if="form.salesPriceOutTax || form.salesPriceInTax" class="form-edit-line">
                  <span v-if="form.salesPriceOutTax">￥{{form.salesPriceOutTax.toLocaleString()}} (税抜)<br v-if="form.salesPriceInTax"></span>
                  <span v-if="form.salesPriceInTax">￥{{form.salesPriceInTax.toLocaleString()}} (税込)</span>
                </div>
                <div v-if="form.allRecordMusicCount" class="form-label align-bottom">総収録曲数</div>
                <div v-if="form.allRecordMusicCount" class="form-edit-line">
                  {{form.allRecordMusicCount}}
                </div>
                <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-label align-bottom">総収録時間
                </div>
                <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-edit-line">
                  <span v-if="form.playTimeH">{{ form.playTimeH }}時間</span>
                  <span v-if="form.playTimeM">{{form.playTimeM }}分</span>
                  <span v-if="form.playTimeS">{{ form.playTimeS }}秒</span>
                </div>
                <div class="card" style="margin-bottom: 10px;">
                  <div class="card-header">計算</div>
                  <div class="card-body" style="padding: 10px 5px 5px 10px;">
                    <div class="form-label align-bottom">計算単位</div>
                    <div class="form-edit-line">
                      <span v-if="form.masterBreakDown">原盤単位</span>
                      <span v-if="!form.masterBreakDown">アルバム単位</span>
                    </div>
                    <div class="form-label align-bottom">価格取得元</div>
                    <div class="form-edit-line">
                      <span v-if="form.priorityPrice === 'data'">実績データに価格情報がある場合、これを優先</span>
                      <span v-if="form.priorityPrice === 'master'">本画面で登録された価格を優先</span>
                    </div>
                    <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-label align-bottom">管理割合</div>
                    <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-edit-line">
                      {{form.portionC}} / {{form.portionM}}
                    </div>
                    <div class="form-label align-bottom">計算対象</div>
                    <div class="form-edit-line">
                      <span v-if="form.calculateTarget">分配計算の対象とする</span>
                      <span v-if="!form.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
                    </div>
                    <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                    <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
                      <span v-if="!form.suspension">分配計算の対象とする</span>
                      <span v-if="form.suspension">分配計算を保留する</span>
                    </div>
                  </div>
                </div>
                <div v-if="form.photoList && form.photoList.length > 0" class="form-label align-bottom">ジャケ写</div>
                <div v-if="form.photoList && form.photoList.length > 0" class="form-edit-line">
                  <div v-for="(photo, index) in form.photoList" :key="photo.blobName">
                    <a @click="selectPhoto(index)" href="#"><img v-if="photo.url" :src="photo.url" class="img-thumbnail float-start" style="max-width: 180px; margin: 5px;"></a>
                  </div>
                </div>
                <div v-if="form.masterBreakDown === true || form.masterBreakDown === 'true'" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">収録原盤</div>
                  <div class="card-body" style="padding: 5px;">
                    <table class="table table-responsive">
                      <tbody>
                      <tr v-for="(record, index) in form.recordMusicList" :key="index" class="align-middle">
                        <td style="width: 55px;" class="text-center">
                          <button @click="openMasterRefModal(record.masterId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                        </td>
                        <td style="width: 40px; vertical-align: middle;">
                          {{index + 1}}
                        </td>
                        <td>
                          <span v-if="record.isrcList.length > 0" style="font-size: 80%;">ISRC : {{record.isrcList.map(x => x.isrc).join(' / ')}}<br></span>
                          {{record.masterName}}
                          <span v-if="record.masterArtistName" style="font-size: 80%;"><br>{{record.masterArtistName}}</span>
                          <hr style="margin: 5px 0 5px 0;">
                          <div style="font-size: 80%;">
                            <span v-if="record.managed" class="badge bg-info">管理</span>
                            <span v-if="!record.managed" class="badge bg-secondary">非管理</span>
                            <span v-if="record.playTimeH || record.playTimeM || record.playTimeS" style="margin-left: 5px;">収録時間 :</span>
                            <span v-if="record.playTimeH">{{record.playTimeH}}時間</span>
                            <span v-if="record.playTimeM">{{record.playTimeM}}分</span>
                            <span v-if="record.playTimeS">{{record.playTimeS}}秒</span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="form.userCodeList && form.userCodeList.length > 0" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">報告コード</div>
                  <div class="card-body" style="padding: 5px;">
                    <table v-if="form.userCodeList" class="table table-responsive">
                      <tbody>
                      <tr v-for="(userCode, index) in form.userCodeList" :key="index">
                        <td>{{userCode.userName}}</td>
                        <td>{{userCode.code}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">保留期</div>
                  <div class="card-body" style="padding: 5px;">
                    <table class="table table-responsive">
                      <tbody>
                      <tr v-for="pool in form.poolList" :key="pool.id">
                        <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                        <td class="text-end">
                          <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                          <span v-else>解除しても支払わない</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="form.memo" class="form-label align-bottom">メモ</div>
                <div v-if="form.memo" class="form-edit-line">
                  <span v-html="form.memo"></span>
                </div>
                <div class="form-label align-bottom">登録</div>
                <div class="form-edit-line">
                  <span v-html="form.createdAt"></span>（{{form.createdBy}}）
                </div>
                <div class="form-label align-bottom">最終更新</div>
                <div class="form-edit-line">
                  <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item">
                    <a @click="rightAreaMode = 'CONTRACT'" class="nav-link" :class="{ 'active': rightAreaMode === 'CONTRACT' }" aria-current="page" href="#">
                      契約書
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="rightAreaMode = 'SUMMARY'" class="nav-link" :class="{ 'active': rightAreaMode === 'SUMMARY' }" aria-current="page" href="#">
                      累計
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }" aria-current="page" href="#">
                      分析
                    </a>
                  </li>
                </ul>
                <div v-if="rightAreaMode === 'CONTRACT'">
                  <div class="row">
                    <div class="input-group">
                      <input v-model="contractCondition.searchString" @keydown.enter="findContract" type="text" class="form-control">
                      <button v-show="contractCondition.searchString" @click="contractCondition.searchString = ''; findContract();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                      <button @click="findContract" class="btn btn-outline-primary"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  <table v-if="form.contractList && form.contractList.length > 0" class="table table-responsive table-hover">
                    <tbody>
                      <tr v-for="contract in form.contractList" :key="contract.id" class="align-middle">
                        <td style="width: 55px;">
                          <button @click="openContractRefModal(contract.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                        </td>
                        <td>
                          <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
                          {{contract.name}}
                          <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
                  <hr v-if="form.contractList && form.contractList.length > 0" style="margin-top: 30px!important;">
                  <div v-if="form.contractList && form.contractList.length > 0" class="form-edit-line">利用種別契約状況</div>
                  <div v-if="form.contractList && form.contractList.length > 0" class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <template v-for="useType in useTypeList" :key="useType.id">
                        <h2 class="accordion-header" :id="'heading_' + useType.id">
                          <button class="accordion-button collapsed" :class="{ 'bg-light': !(useType.userSalesList && useType.userSalesList.length > 0), 'text-secondary': !(useType.userSalesList && useType.userSalesList.length > 0) }" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' + useType.id" :aria-expanded="false" :aria-controls="'collapse_' + useType.id" :disabled="!(useType.userSalesList && useType.userSalesList.length > 0)">
                            <span v-if="!useType.hasCondition" style="margin-right: 5px;" class="badge bg-secondary">契約なし</span>
                            <span v-else style="margin-right: 5px;" class="badge bg-info">契約あり</span>
                            {{useType.name}}
                          </button>
                        </h2>
                        <div :id="'collapse_' + useType.id" class="accordion-collapse collapse" :aria-labelledby="'heading_' + useType.id" data-bs-parent="#accordionExample">
                          <table class="table table-responsive">
                            <tbody>
                            <tr v-for="userSales in useType.userSalesList" :key="userSales.id">
                              <td style="font-size: 90%;">{{userSales.name}}</td>
                              <td v-if="userSales.contractConditionList && userSales.contractConditionList.length > 0" style="font-size: 90%;" class="text-end">
                                <p v-for="condition in userSales.contractConditionList" :key="condition.id" style="margin: 0;">
                                  <span style="margin-right: 5px;">{{condition.rightsHolderName}}</span>
                                  <span class="badge bg-info">{{condition.royaltyTypeAbbreviation}}</span>
                                </p>
                              </td>
                              <td v-else style="width: 100px;"><span class="badge bg-secondary">契約なし</span></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="rightAreaMode === 'PROFIT'">
                  <div class="form-edit-line">
                    <bar-chart :chart-data="usedNoSummary" :styles="{ height: '300px' }"></bar-chart>
                  </div>
                  <div class="form-edit-line">
                    <bar-chart :chart-data="incomeSummary" :styles="{ height: '300px' }"></bar-chart>
                  </div>
                  <template v-if="currentSummary && ((currentSummary.usedNo && currentSummary.usedNo !== 0) || (currentSummary.incomeAmount && currentSummary.incomeAmount !== 0))">
                    <div v-if="currentSummary && currentSummary.usedNo && currentSummary.usedNo !== 0" class="form-label align-bottom">今期使用数</div>
                    <div v-if="currentSummary && currentSummary.usedNo && currentSummary.usedNo !== 0" class="form-edit-line">
                      {{currentSummary.usedNo.toLocaleString()}}
                    </div>
                    <div v-if="currentSummary && currentSummary.incomeAmount && currentSummary.incomeAmount !== 0" class="form-label align-bottom">今期受領額</div>
                    <div v-if="currentSummary && currentSummary.incomeAmount && currentSummary.incomeAmount !== 0" class="form-edit-line">
                      {{currentSummary.incomeAmount.toLocaleString()}}
                    </div>
                  </template>
                  <div v-else class="form-edit-line">
                    今期実績なし
                  </div>
                </div>
                <div v-if="rightAreaMode === 'SUMMARY'">
                  <div v-if="!form.totalModel" class="form-edit-line">
                    実績なし
                  </div>
                  <div v-if="form.totalModel" class="form-label align-bottom">累計使用数</div>
                  <div v-if="form.totalModel" class="form-edit-line">
                    {{form.totalModel.usedNo.toLocaleString()}}
                  </div>
                  <div v-if="form.totalModel" class="form-label align-bottom">累計受領額</div>
                  <div v-if="form.totalModel" class="form-edit-line">
                    ￥{{form.totalModel.incomeAmount.toLocaleString()}}
                  </div>
                  <div v-if="form.totalModel" class="form-label align-bottom">累計支払額</div>
                  <div v-if="form.totalModel" class="form-edit-line">
                    ￥{{form.totalModel.paymentAmount.toLocaleString()}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="photoModalDiv">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img :src="photoUrl" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterRefModalDiv">
      <div class="modal-dialog modal-xl">
        <div v-if="refMasterId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤詳細</h5>
          </div>
          <div class="modal-body text-start">
            <master-modal :master-id="refMasterId"></master-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeMasterRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoMaster(refMasterId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>原盤画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="artistRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refArtistId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティスト詳細</h5>
          </div>
          <div class="modal-body text-start">
            <artist-modal :artist-id="refArtistId"></artist-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeArtistRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoArtist(refArtistId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アーティスト画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="contractRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refContractId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書詳細</h5>
          </div>
          <div class="modal-body text-start">
            <contract-modal :contract-id="refContractId"></contract-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeContractRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoContract(refContractId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>契約書画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { displayFunction } from '@/functions/common'
import navComponent from '@/components/Nav'
import { Modal } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { albumFunction } from './function'
import { contractFunction } from '../contract/function'
import { contractStore } from './store'
import { useTypeFunction } from '../usetype/function'
import { Chart, registerables } from 'chart.js'
import { BarChart } from 'vue-chart-3'
import { summaryFunction } from '@/functions/summary'
import artistModal from '@/components/modal/ArtistModal'
import masterModal from '@/components/modal/MasterModal'
import contractModal from '@/components/modal/ContractModal'

Chart.register(...registerables)

export default defineComponent({
  components: { footerComponent, navComponent, BarChart, artistModal, masterModal, contractModal },
  setup () {
    const dispFunc = displayFunction()
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const photoModalDiv = ref(null)
    let photoModal = null
    // Form
    const albumFunc = albumFunction()
    const contractFunc = contractFunction()
    const useTypeFunc = useTypeFunction()
    const contractCondition = contractStore()
    const form = ref(null)
    const rightAreaMode = ref('CONTRACT')
    const useTypeList = ref([])
    const photoUrl = ref('')
    // 分析
    const summaryFunc = summaryFunction()
    const usedNoSummaryLabelList = ref([])
    const usedNoSummaryDataList = ref([])
    const usedNoSummaryBgList = ref([])
    const usedNoSummary = {
      labels: usedNoSummaryLabelList.value,
      datasets: [{
        label: '使用数推移',
        data: usedNoSummaryDataList.value,
        backgroundColor: usedNoSummaryBgList.value
      }],
      height: 150
    }
    const incomeSummaryLabelList = ref([])
    const incomeSummaryDataList = ref([])
    const incomeSummaryBgList = ref([])
    const incomeSummary = {
      labels: incomeSummaryLabelList.value,
      datasets: [{
        label: '受領額推移',
        data: incomeSummaryDataList.value,
        backgroundColor: incomeSummaryBgList.value
      }]
    }
    const currentSummary = ref(null)
    // アーティスト詳細
    const artistRefModalDiv = ref({})
    let artistRefModal = null
    const refArtistId = ref('')
    // 原盤詳細
    const masterRefModalDiv = ref({})
    let masterRefModal = null
    const refMasterId = ref('')
    // 契約書詳細
    const contractRefModalDiv = ref({})
    let contractRefModal = null
    const refContractId = ref('')
    // メソッド
    const selectPhoto = (index) => {
      photoUrl.value = form.value.photoList[index].url
      photoModal.show()
    }
    async function init (id) {
      form.value = {
        id: id
      }
      form.value = await albumFunc.get(id)
      await findContract()
      useTypeList.value = await useTypeFunc.findHasSales(session.session.company.id)
      for (const useType of useTypeList.value) {
        for (const userSales of useType.userSalesList) {
          albumFunc.findContractCondition(form.value.id, useType.id, userSales.id, function (data) {
            if (data.length > 0) {
              useType.hasCondition = true
              userSales.contractConditionList = data
            }
          })
        }
      }
      // 利用年月別使用実績集計
      const today = new Date()
      const thisYear = today.getFullYear()
      const thisMonth = today.getMonth() + 1
      const usagePerUseSummaryPromises = []
      let year = thisYear
      let month = thisMonth
      for (let i = 0; i < 10; i++) {
        usagePerUseSummaryPromises.push(summaryFunc.getAlbumUsageSummaryPerUse(form.value.id, year, month))
        month = month - 1
        if (month === 0) {
          year = year - 1
          month = 12
        }
      }
      const usagePerUseSummaryResponse = await Promise.all(usagePerUseSummaryPromises)
      usagePerUseSummaryResponse.reverse().forEach((res, index) => {
        usedNoSummaryLabelList.value.push(res.usedYear + '年' + res.usedMonth + '月')
        usedNoSummaryDataList.value.push(res.usedNo)
        usedNoSummaryBgList.value.push('#77CEFF')
        incomeSummaryLabelList.value.push(res.usedYear + '年' + res.usedMonth + '月')
        incomeSummaryDataList.value.push(res.incomeAmount)
        incomeSummaryBgList.value.push('#77CEFF')
      })
      // axios.get(process.env.VUE_APP_RED_ENDPOINT + '/album/' + id + '/usage/summary', {
      //   headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      //   params: {
      //     company_id: session.session.company.id
      //   }
      // }).then(response => {
      //   currentSummary.value = response.data
      // })
    }
    const findContract = async () => {
      form.value.contractList = await contractFunc.findByAlbum(form.value.id, contractCondition)
    }
    const openArtistRefModal = async (artistId) => {
      refArtistId.value = artistId
      artistRefModal.show()
    }
    const closeArtistRefModal = () => {
      refArtistId.value = null
      artistRefModal.hide()
    }
    const gotoArtist = (id) => {
      artistRefModal.hide()
      router.push('/common/artist/disp/' + id)
    }
    const openContractRefModal = async (contractId) => {
      refContractId.value = contractId
      contractRefModal.show()
    }
    const closeContractRefModal = () => {
      refContractId.value = null
      contractRefModal.hide()
    }
    const gotoContract = (id) => {
      contractRefModal.hide()
      router.push('/red/contract/disp/' + id)
    }
    const gotoMaster = (masterId) => {
      masterRefModal.hide()
      router.push('/red/master/disp/' + masterId)
    }
    const openMasterRefModal = async (masterId) => {
      refMasterId.value = masterId
      masterRefModal.show()
    }
    const closeMasterRefModal = () => {
      refMasterId.value = null
      masterRefModal.hide()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      photoModal = new Modal(photoModalDiv.value, {
        keyboard: false
      })
      artistRefModal = new Modal(artistRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterRefModal = new Modal(masterRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      contractRefModal = new Modal(contractRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      dispFunc,
      route,
      router,
      photoModalDiv,
      form,
      rightAreaMode,
      photoUrl,
      selectPhoto,
      useTypeList,
      currentSummary,
      contractCondition,
      findContract,
      usedNoSummaryLabelList,
      usedNoSummaryDataList,
      usedNoSummaryBgList,
      usedNoSummary,
      incomeSummaryLabelList,
      incomeSummaryDataList,
      incomeSummaryBgList,
      incomeSummary,
      openArtistRefModal,
      closeArtistRefModal,
      gotoArtist,
      artistRefModalDiv,
      refArtistId,
      openContractRefModal,
      closeContractRefModal,
      gotoContract,
      contractRefModalDiv,
      refContractId,
      gotoMaster,
      openMasterRefModal,
      closeMasterRefModal,
      masterRefModalDiv,
      refMasterId
    }
  }
})
</script>
