<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/usage/list"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績ファイル一覧</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="'/red/usage/register/' + route.params.userSalesId"><i class="fas fa-cloud fa-fw" style="margin-right: 5px;"></i> 取込</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-list fa-fw" style="margin-right: 5px;"></i>詳細</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
<!--          <table v-if="fileResult && fileResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 90%; width: 2000px;">-->
          <table v-if="columnList.length > 0" class="table table-responsive table-hover" style="font-size: 85%;">
            <thead>
              <tr>
                <th style="width: 60px;">マッチ</th>
                <th v-if="columnList.includes('catalog_no')" style="width: 150px; word-break: break-all;">品番</th>
                <th v-if="columnList.includes('jan_code')" style="width: 150px; word-break: break-all;">JAN</th>
                <th v-if="columnList.includes('user_album_code')" style="width: 150px; word-break: break-all;">利用者アルバムコード</th>
                <th v-if="columnList.includes('isrc')" style="width: 150px; word-break: break-all;">ISRC</th>
                <th v-if="columnList.includes('master_code')" style="width: 150px; word-break: break-all;">原盤コード</th>
                <th v-if="columnList.includes('user_master_code')" style="width: 150px; word-break: break-all;">利用者原盤コード</th>
                <th v-if="columnList.includes('album_title')" style="width: 300px; word-break: break-all;">アルバム名</th>
                <th v-if="columnList.includes('master_name')" style="width: 300px; word-break: break-all;">原盤名</th>
                <th v-if="columnList.includes('artist_name')" style="width: 300px; word-break: break-all;">アーティスト名</th>
                <th style="width: 100px; word-break: break-all;">利用年月</th>
                <th v-if="columnList.includes('used_no')" style="width: 150px; word-break: break-all;">使用数</th>
                <th v-if="columnList.includes('price_out_tax')" style="width: 150px; word-break: break-all;">税抜価格(円)</th>
                <th v-if="columnList.includes('revenue_out_tax')" style="width: 150px; word-break: break-all;">税抜売上(円)</th>
                <th v-if="columnList.includes('income_amount_out_tax')" style="width: 150px; word-break: break-all;">税抜受領額(円)</th>
              </tr>
            </thead>
            <tbody v-if="fileList">
              <tr v-for="file in fileList" :key="file.id" class="text-start align-middle">
                <td class="text-center">
                  <span v-if="file.matching" class="badge bg-success" style="padding: 7px;"><i class="fas fa-circle fa-fw"></i></span>
                  <span v-if="!file.matching" class="badge bg-secondary" style="padding: 7px;"><i class="fas fa-times fa-fw"></i></span>
                </td>
                <td v-if="columnList.includes('catalog_no')" style="word-break: break-all;">{{file.catalogNo}}</td>
                <td v-if="columnList.includes('jan_code')" style="word-break: break-all;">{{file.janCode}}</td>
                <td v-if="columnList.includes('user_album_code')" style="word-break: break-all;">{{file.userAlbumCode}}</td>
                <td v-if="columnList.includes('isrc')" style="word-break: break-all;">{{file.isrc}}</td>
                <td v-if="columnList.includes('master_code')" style="word-break: break-all;">{{file.masterCode}}</td>
                <td v-if="columnList.includes('user_master_code')" style="word-break: break-all;">{{file.userMasterCode}}</td>
                <td v-if="columnList.includes('album_title')" style="word-break: break-all;">{{file.albumTitle}}</td>
                <td v-if="columnList.includes('master_name')" style="word-break: break-all;">{{file.masterName}}</td>
                <td v-if="columnList.includes('artist_name')" style="word-break: break-all;">{{file.artistName}}</td>
                <td>{{file.usedYear}}年{{file.usedMonth}}月</td>
                <td v-if="columnList.includes('used_no')" style="word-break: break-all;" class="text-end">{{file.usedNo}}</td>
                <td v-if="columnList.includes('price_out_tax')" style="word-break: break-all;" class="text-end">{{file.convertedPriceOutTax}}</td>
                <td v-if="columnList.includes('revenue_out_tax')" style="word-break: break-all;" class="text-end">{{file.convertedRevenueOutTax}}</td>
                <td v-if="columnList.includes('income_amount_out_tax')" style="word-break: break-all;" class="text-end">{{file.convertedIncomeAmountOutTax}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="fileResult && fileResult.allRecords > 0">{{ fileResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="fileResult && fileResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="fileResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ fileResult.allPages }}ページ</span></li>
                <li v-if="store.page !== fileResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== fileResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { dataStore } from './store'
import { usageFunction } from './function'
import { userFunction } from '../user/function'
import { definitionFunction } from '../definition/function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const usageFunc = usageFunction()
    const userFunc = userFunction()
    const definitionFunc = definitionFunction()
    const store = dataStore()
    const fileResult = ref(null)
    const fileList = ref([])
    const columnList = ref([])
    // メソッド
    const find = async () => {
      fileResult.value = await usageFunc.findData(route.params.uploadedFileId, store)
      fileList.value = fileResult.value.list
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = fileResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      const userSales = await userFunc.getUserSales(route.params.userSalesId)
      columnList.value = await definitionFunc.findColumns(userSales.definitionId)
      console.log(columnList.value)
      await find()
    })
    return {
      router,
      route,
      store,
      fileResult,
      fileList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      columnList
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
