import CommonProjectDisp from './Disp'
import CommonProjectEdit from './Edit'
import CommonProjectList from './List'

export default {
  routes: [
    {
      path: '/common/project/list',
      name: 'CommonProjectList',
      component: CommonProjectList
    },
    {
      path: '/common/project/disp/:id',
      name: 'CommonProjectDisp',
      component: CommonProjectDisp
    },
    {
      path: '/common/project/edit/:id',
      name: 'CommonProjectEdit',
      component: CommonProjectEdit
    },
    {
      path: '/common/project/add',
      name: 'CommonProjectAdd',
      component: CommonProjectEdit
    }
  ]
}
