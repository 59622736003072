import RedUsageDetailList from './List'

export default {
  routes: [
    {
      path: '/red/usage/detail/list',
      name: 'RedUsageDetailList',
      component: RedUsageDetailList
    }
  ]
}
