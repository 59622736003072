<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/addressee/list"><i class="fas fa-envelope-open-text fa-fw" style="margin-right: 5px;"></i> 送付先一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/addressee/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/common/addressee/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-label align-bottom">用途</div>
              <div class="form-edit-line">
                <span v-if="session.session.blue && form.useOnBlue" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
                <span v-if="session.session.red && form.useOnRed" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
                <span v-if="session.session.pink && form.useOnPink" class="badge text-white" style="background-color: #EE86A8;">PINK</span>
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div class="form-label align-bottom">敬称</div>
              <div class="form-edit-line">
                <span v-if="form.honorificPosition === 'BEFORE'">(前)</span>
                <span v-else-if="form.honorificPosition === 'AFTER'">(後)</span>
                <span v-else>なし</span>
                <span v-if="form.honorificPosition">&nbsp;{{form.honorificTitle}}</span>
              </div>
              <div class="form-label align-bottom">住所</div>
              <div v-if="form.domesticOrOversees === 'DOMESTIC'" class="form-edit-line">
                <p v-if="form.postalCode" style="margin: 0;">〒{{ form.postalCode }}</p>
                <p style="margin: 0;">{{ form.prefecture }}{{ form.city }}{{ form.townArea }}</p>
                <p style="margin: 0;">{{ form.building }}</p>
              </div>
              <div v-else class="form-edit-line">
                <pre>{{ form.overseesAddress }}</pre>
              </div>
              <div v-if="form.tel" class="form-label align-bottom">電話番号</div>
              <div v-if="form.tel" class="form-edit-line">
                {{ form.tel }}
              </div>
              <div v-if="form.email" class="form-label align-bottom">メールアドレス</div>
              <div v-if="form.email" class="form-edit-line">
                {{ form.email }}
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <pre>{{ form.memo }}</pre>
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'RED'" class="nav-link" :class="{ 'active': rightAreaMode === 'RED' }" aria-current="page" href="#">
                    RED権利者
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'RED'">
                <table v-if="redRightsholderList" class="table table-responsive">
                  <tbody>
                  <tr v-for="(redRightsholder, index) in redRightsholderList" :key="index">
                    <td style="width: 55px;">
                      <router-link :to="'/red/rightsholder/disp/' + redRightsholder.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                    </td>
                    <td>
                      {{redRightsholder.name}}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="redRightsholderList.length > 0">{{ redRightsholderList.length }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { addresseeFunction } from './function'
import { rightsHolderFunction } from '../../red/rightsholder/function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    // Form
    const addresseeFind = addresseeFunction()
    const form = ref(null)
    const rightAreaMode = ref('RED')
    // RED権利者一覧
    const rightsHolderFunc = rightsHolderFunction()
    const redRightsholderList = ref([])
    // メソッド
    const findRedRightsholder = async () => {
      redRightsholderList.value = await rightsHolderFunc.findByAddressee(form.value.id)
    }
    async function init (id) {
      form.value = await addresseeFind.get(id)
      await findRedRightsholder()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      session,
      form,
      rightAreaMode,
      redRightsholderList,
      findRedRightsholder
    }
  }
})
</script>
