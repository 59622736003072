import RedUserDisp from './Disp'
import RedUserEdit from './Edit'
import RedUserList from './List'

export default {
  routes: [
    {
      path: '/red/user/list',
      name: 'RedUserList',
      component: RedUserList
    },
    {
      path: '/red/user/edit/:id',
      name: 'RedUserEdit',
      component: RedUserEdit
    },
    {
      path: '/red/user/add',
      name: 'RedUserAdd',
      component: RedUserEdit
    },
    {
      path: '/red/user/disp/:id',
      name: 'RedUserDisp',
      component: RedUserDisp
    }
  ]
}
