import RedRoyaltytypeEdit from './Edit'
import RedRoyaltytypeList from './List'

export default {
  routes: [
    {
      path: '/red/royaltytype/list',
      name: 'RedRoyaltytypeList',
      component: RedRoyaltytypeList
    },
    {
      path: '/red/royaltytype/edit',
      name: 'RedRoyaltytypeEdit',
      component: RedRoyaltytypeEdit
    }
  ]
}
