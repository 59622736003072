<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/artist/list"><i class="fas fa-user-music fa-fw" style="margin-right: 5px;"></i>アーティスト一覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/common/artist/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="form.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">カナ</div>
            <div class="form-edit-line">
              <input v-model="form.kana" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">英語名</div>
            <div class="form-edit-line">
              <input v-model="form.nameEn" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">公式サイト</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.officialSiteUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-twitter fa-fw" style="margin-right: 5px;"></i>Twitter ID</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-at fa-fw"></i></span>
                <input v-model="form.twitterId" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-facebook fa-fw" style="margin-right: 5px;"></i>facebook URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.facebookUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-instagram fa-fw" style="margin-right: 5px;"></i>Instagram ID</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-at fa-fw"></i></span>
                <input v-model="form.instagramId" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-tiktok fa-fw" style="margin-right: 5px;"></i>TikTok ID</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-at fa-fw"></i></span>
                <input v-model="form.tiktokId" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-soundcloud fa-fw" style="margin-right: 5px;"></i>SOUNDCLOUD ID</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-at fa-fw"></i></span>
                <input v-model="form.soundcloudId" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-youtube fa-fw" style="margin-right: 5px;"></i>YouTube URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.youtubeUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-apple fa-fw" style="margin-right: 5px;"></i>Apple Music URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.appleMusicUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-spotify fa-fw" style="margin-right: 5px;"></i>Spotify URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.spotifyUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-youtube-square fa-fw" style="margin-right: 5px;"></i>YouTube Music URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.youtubeMusicUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-amazon fa-fw" style="margin-right: 5px;"></i>Amazon Music URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.amazonMusicUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom"><i class="fa-brands fa-line fa-fw" style="margin-right: 5px;"></i>LINE Music URL</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-globe fa-fw"></i></span>
                <input v-model="form.lineMusicUrl" type="text" class="form-control">
              </div>
            </div>
            <div class="form-label align-bottom">
              <i class="fas fa-globe fa-fw" style="margin-right: 5px;"></i>その他のサイト
              <button @click="openAddSite" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(site, index) in siteList" :key="site.siteUrl">
                  <td>{{ site.name }}</td>
                  <td style="width: 80px;" class="text-end">
                    <button @click="deleteSite(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">
              アー写
              <button v-if="rightAreaMode === 'PHOTO'" @click="openAddFile()" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(blob, index) in blobList" :key="blob.blob">
                  <td>
                    <img v-if="blob.url" :src="blob.url" style="max-width: 300px;">
                  </td>
                  <td style="width: 80px;" class="text-end">
                    <button @click="deleteFile(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">メモ</div>
            <div class="form-edit-line">
              <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
            </div>
          </div>
          <div style="height: 50px;"></div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="fileModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">添付ファイル追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>ファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="uploadFile" accept="image/*" ref="uploadFile" multiple="multiple">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="addFile" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="siteModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">サイト追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="siteName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>URL</div>
            <div class="form-edit-line">
              <input v-model="siteUrl" type="url" class="form-control">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="addSite" type="button" class="btn btn-primary"><i class="fas fa-plus fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティストの削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下のアーティストを削除しようとしています。削除すると、アーティスト情報の確認ができなくなり、該当アーティストが紐づいている楽曲、アルバム、原盤のアーティスト情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" data-bs-delay="2000" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import navComponent from '@/components/Nav'
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { artistFunction } from './function'
import footerComponent from '@/components/Footer.vue'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const artistFunc = artistFunction()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const fileModalDiv = ref(null)
    let fileModal = null
    const siteModalDiv = ref(null)
    let siteModal = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const form = ref(null)
    const rightAreaMode = ref('PHOTO')
    const blobList = ref([])
    const photoList = ref([])
    // アー写追加
    const uploadFile = ref(null)
    // サイト追加
    const siteName = ref('')
    const siteUrl = ref('')
    const siteList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です'
        processModal.show()
        if (route.path.includes('/add')) {
          form.value.id = await artistFunc.insert(session.session.company.id, form.value)
        } else {
          const deletePromises = []
          deletePromises.push(artistFunc.deletePhoto(form.value.id))
          deletePromises.push(artistFunc.deleteSite(form.value.id))
          await Promise.all(deletePromises)
          const promises = []
          promises.push(artistFunc.update(form.value.id, form.value))
          for (const photo of blobList.value) {
            promises.push(artistFunc.insertPhoto(form.value.id, photo.blobName))
          }
          for (const site of siteList.value) {
            promises.push(artistFunc.insertSite(form.value.id, site))
          }
          await Promise.all(promises)
        }
        artistFunc.indexUpdate(form.value.id)
        processModal.hide()
        await router.push('/common/artist/disp/' + form.value.id)
      }
    }
    const openAddFile = () => {
      uploadFile.value.value = ''
      fileModal.show()
    }
    const addFile = async () => {
      errors.value = []
      if (uploadFile.value.files.length === 0) {
        errors.value.push('ファイルを選択してください')
        errorToast.show()
      } else {
        processMessage.value = 'ファイルのアップロード中です。しばらくお待ちください。'
        processModal.show()
        fileModal.hide()
        for (const file of uploadFile.value.files) {
          const blobName = await getBlobName(file.name)
          const urlUl = await getUrlForUpload(blobName)
          await axios.put(urlUl.toString(), file, {
            headers: { 'Content-Type': 'application/octet-stream' }
          }).catch(error => {
            ex.identifyEx(error)
            errors.value = ['システムエラーが発生したためアップロードされませんでした']
            errorToast.show()
          })
          const urlDl = await getUrlForDownload(blobName)
          blobList.value.push({
            blobName: blobName,
            url: urlDl
          })
        }
        processModal.hide()
      }
    }
    const deleteFile = (index) => {
      blobList.value.splice(index, 1)
    }
    async function getBlobName (fileName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/blob', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          file_type: 'artist_photo',
          file_name: fileName
        }
      })
      return data
    }
    async function getUrlForUpload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/ul', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    async function getUrlForDownload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    const openAddSite = () => {
      siteName.value = ''
      siteUrl.value = ''
      siteModal.show()
    }
    const addSite = () => {
      errors.value = []
      if (!siteName.value) {
        errors.value.push('サイト名を入力してください')
      }
      if (!siteUrl.value) {
        errors.value.push('サイトのURLを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        siteList.value.push({
          name: siteName.value,
          siteUrl: siteUrl.value
        })
      }
    }
    const deleteSite = (index) => {
      siteList.value.splice(index, 1)
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/artist/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      deleteModal.hide()
      await router.push('/common/artist/list')
    }
    const init = async (id) => {
      session.session = await auth.getSession()
      showDeleteButton.value = false
      if (route.path.includes('/common/artist/add')) {
        form.value = {
          id: null,
          name: '',
          kana: '',
          name_en: '',
          memo: ''
        }
      } else {
        const promises = []
        promises.push(artistFunc.get(id))
        promises.push(artistFunc.findPhoto(id))
        promises.push(artistFunc.findSite(id))
        const response = await Promise.all(promises)
        form.value = response[0]
        blobList.value = response[1]
        form.value.siteList = response[2]
      }
    }
    onMounted(() => {
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      fileModal = new Modal(fileModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      siteModal = new Modal(siteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      init(route.params.id)
    })
    return {
      router,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      fileModalDiv,
      siteModalDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      rightAreaMode,
      blobList,
      uploadFile,
      photoList,
      siteName,
      siteUrl,
      siteList,
      save,
      openAddFile,
      addFile,
      deleteFile,
      openAddSite,
      addSite,
      deleteSite,
      openDeleteModal,
      executeDelete
    }
  }
})
</script>
