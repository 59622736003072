<template>
  <div v-if="form" class="row">
    <div class="col-lg-7">
      <div v-if="form.code" class="form-label align-bottom">管理コード</div>
      <div v-if="form.code" class="form-edit-line">
        {{ form.code }}
      </div>
      <div class="form-label align-bottom">名前</div>
      <div class="form-edit-line fs-5" style="font-weight: bold;">
        {{ form.name }}
      </div>
      <div class="form-label align-bottom">権利者</div>
      <div class="form-edit-line">
        {{ form.rightsHolderName }}
      </div>
      <div class="form-label align-bottom">ステータス</div>
      <div class="form-edit-line">
        <span v-if="form.status === 'agree'" class="badge bg-success">契約中</span>
        <span v-else-if="form.status === 'not_agree'" class="badge bg-secondary">未契約（保留）</span>
        <span v-else-if="form.status === 'not_pay'" class="badge bg-primary">全て自社取分とする</span>
      </div>
      <div class="form-label align-bottom">免税事業者に対する扱い</div>
      <div class="form-edit-line">
        <span v-if="form.useContractSetting">契約書で個別設定</span>
        <span v-else>会社の設定に従う</span>
      </div>
      <div v-if="form.useContractSetting" class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
      <div v-if="form.useContractSetting" class="form-edit-line">
        <span v-if="form.invoiceExemptionTaxPayable==='pay'">課税事業者と同様に全額支払う</span>
        <span v-if="form.invoiceExemptionTaxPayable==='deduct'">消費税相当額を支払額から控除する</span>
      </div>
      <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
      <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
        <span v-if="form.invoiceSmallException==='adapt'">対応する</span>
        <span v-if="form.invoiceSmallException==='not_adapt'">対応しない</span>
      </div>
      <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
      <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
        <span v-if="form.invoiceTransitionalMeasures==='adapt'">対応する</span>
        <span v-if="form.invoiceTransitionalMeasures==='not_adapt'">対応しない</span>
      </div>
      <div v-if="form && form.conditionList && form.conditionList.length > 0" class="card" style="margin-bottom: 10px;">
        <div class="card-header">支払条件</div>
        <div class="card-body" style="padding: 10px 5px 5px 10px;">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th>利用種別</th>
                <th>頒布形態</th>
                <th>印税種別</th>
                <th>料率</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(condition, index) in form.conditionList" :key="index">
                <td>
                  <span v-if="condition.useTypeId && condition.useTypeId !== 'none'">{{condition.useTypeAbbreviation}}</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-if="condition.useTypeId && condition.useTypeId !== 'none' && condition.userSalesId && condition.userSalesId !== 'none'">{{condition.userSalesName}}</span>
                  <span v-else>-</span>
                </td>
                <td>{{condition.royaltyTypeAbbreviation}}</td>
                <td>
                    <span v-if="condition.calcType === 'unit_rate' || condition.calcType === 'div_rate' || condition.calcType === 'after_rate'">
                      <span v-if="condition.royaltyRateType === 'decimal'">{{condition.royaltyRate}}%</span>
                      <span v-else>{{condition.royaltyRateC}} / {{condition.royaltyRateM}}</span>
                    </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="card text-bg-danger" style="margin-bottom: 10px;">
        <div class="card-header">支払条件未設定</div>
      </div>
      <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
        <div class="card-header">保留期</div>
        <div class="card-body" style="padding: 5px;">
          <table class="table table-responsive">
            <tbody>
            <tr v-for="pool in form.poolList" :key="pool.id">
              <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
              <td class="text-end">
                <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                <span v-else>解除しても支払わない</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="form.memo" class="form-label align-bottom">メモ</div>
      <div v-if="form.memo" class="form-edit-line">
        <pre>{{ form.memo }}</pre>
      </div>
    </div>
    <div class="col-lg-5">
      <ul class="nav nav-tabs" style="margin-bottom: 10px;">
        <li class="nav-item">
          <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
            <i class="fas fa-file-music" style="margin-right: 5px;"></i>原盤
          </a>
        </li>
        <li class="nav-item">
          <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
            <i class="fas fa-album" style="margin-right: 5px;"></i>アルバム
          </a>
        </li>
      </ul>
      <div v-if="rightAreaMode === 'ALBUM'">
        <div class="input-group">
          <input @keydown.enter="albumList = []; albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
          <button @click="albumList = []; albumCondition.searchString = ''; albumCondition.page = 0; findAlbum();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
          <button @click="albumList = []; albumCondition.page = 0; findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
        </div>
        <div class="text-start" style="margin-top: 10px;">
          <span v-if="albumResult && albumResult.allRecords > 0">{{ albumResult.allRecords }}件の一致データ</span>
          <span v-else style="margin-top: 20px;">一致データが存在しません</span>
        </div>
        <table v-if="albumList.length > 0" class="table table-responsive table-hover">
          <tbody>
            <tr v-for="album in albumList" :key="album.id" class="align-middle">
              <td>
                <p v-if="album.catalogNo" style="margin: 0 0 3px 0; font-size: 80%;">{{album.catalogNo}}</p>
                <p style="margin: 0;">{{ album.name }}</p>
                <p v-if="album.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{album.artistName}}</p>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="albumResult.allRecords > albumList.length">
            <tr>
              <td colspan="5">
                <div class="d-grid gap-2">
                  <button @click="albumCondition.page = albumResult.page + 1; findAlbum();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="rightAreaMode === 'MASTER'">
        <div class="input-group">
          <input @keydown.enter="masterList = []; masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
          <button @click="masterList = []; masterCondition.searchString = ''; masterCondition.page = 0; findMaster();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
          <button @click="masterList = []; masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
        </div>
        <div class="text-start" style="margin-top: 10px;">
          <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
          <span v-else style="margin-top: 20px;">一致データが存在しません</span>
        </div>
        <table v-if="masterList.length > 0" class="table table-responsive table-hover">
          <tbody>
            <tr v-for="master in masterList" :key="master.id" class="align-middle">
              <td style="vertical-align: middle;">
                <p v-if="master.isrc" style="margin: 0 0 3px 0; font-size: 80%;"><span v-html="master.isrc"></span></p>
                <p style="margin: 0;">{{ master.displayName }}</p>
                <p v-if="master.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{master.artistName}}</p>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="masterResult.allRecords > masterList.length">
            <tr>
              <td colspan="5">
                <div class="d-grid gap-2">
                  <button @click="masterCondition.page = masterResult.page + 1; findMaster();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { contractFunction } from '@/views/red/contract/function'
import { masterFunction } from '@/views/red/master/function'
import { albumFunction } from '@/views/red/album/function'
import { masterStore, albumStore } from './store'

export default defineComponent({
  props: ['contractId'],
  setup (props) {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    // Form
    const contractFunc = contractFunction()
    const form = ref(null)
    const rightAreaMode = ref('MASTER')
    // 原盤
    const masterFunc = masterFunction()
    const masterCondition = masterStore()
    const masterResult = ref({})
    const masterList = ref([])
    // アルバム
    const albumFunc = albumFunction()
    const albumCondition = albumStore()
    const albumResult = ref({})
    const albumList = ref([])
    // メソッド
    const init = async (id) => {
      albumCondition.page = 0
      albumCondition.searchString = ''
      masterCondition.page = 0
      masterCondition.searchString = ''
      form.value = await contractFunc.get(id)
      await findAlbum()
      await findMaster()
    }
    const findMaster = async () => {
      masterResult.value = await masterFunc.findByContract(form.value.id, masterCondition.searchString, masterCondition.page)
      masterList.value = masterList.value.concat(masterResult.value.list)
    }
    const findAlbum = async () => {
      albumResult.value = await albumFunc.findByContract(form.value.id, albumCondition.searchString, albumCondition.page)
      albumList.value = albumList.value.concat(albumResult.value.list)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(props.contractId)
    })
    return {
      router,
      route,
      form,
      rightAreaMode,
      masterCondition,
      findMaster,
      masterResult,
      masterList,
      albumCondition,
      findAlbum,
      albumResult,
      albumList
    }
  }
})
</script>
