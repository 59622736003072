<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-address-card fa-fw" style="margin-right: 5px;"></i> 登録依頼一覧</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div v-if="system && form" class="col d-flex justify-content-end">
<!--              <router-link :to="'/green/print/' + system + '/' + form.id" class="btn btn-outline-primary"><i class="fas fa-share-square" style="margin-right: 5px;"></i>印刷用ページ</router-link>-->
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div v-if="form.code" class="form-label align-bottom">コード</div>
            <div v-if="form.code" class="form-edit-line">
              {{form.code}}
              <span v-if="!form.code"><br></span>
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{form.name}}
              <span v-if="!form.name"><br></span>
            </div>
            <div v-if="system !== 'contano' && system !== 'red'" class="form-label align-bottom">筆名</div>
            <div v-if="system !== 'contano' && system !== 'red'" class="form-edit-line">
              {{form.penName}}
              <span v-if="!form.penName"><br></span>
            </div>
            <ul class="nav nav-tabs" style="margin-bottom: 10px;">
              <li class="nav-item">
                <a @click="rightAreaMode = 'statement'" class="nav-link" :class="{ 'active': rightAreaMode === 'statement' }" aria-current="page" href="#">
                  明細送付先収集用
                </a>
              </li>
              <li class="nav-item">
                <a @click="rightAreaMode = 'invoice'" class="nav-link" :class="{ 'active': rightAreaMode === 'invoice' }" aria-current="page" href="#">
                  インボイス制度情報収集用
                </a>
              </li>
            </ul>
            <div v-if="rightAreaMode === 'invoice'">
              <div v-if="system === 'red' && !form.taxation" class="alert alert-secondary">消費税非課税権利者のため不要</div>
              <template v-else>
                <div v-if="invoice" class="alert alert-info">回答済</div>
                <div v-if="!invoice" class="alert alert-secondary">未回答</div>
                <div class="card" style="margin-bottom: 10px;">
                  <div class="card-header">権利者登録状況</div>
                  <div class="card-body" style="padding: 5px;">
                    <table class="table table-responsive table-borderless" style="margin-bottom: 0;">
                      <tbody>
                        <tr>
                          <td style="width: 150px;">課税／免税</td>
                          <td>
                            <span v-if="form.taxationType === 'taxation'">課税事業者</span>
                            <span v-else>免税事業者</span>
                          </td>
                        </tr>
                        <tr v-if="form.taxationType === 'taxation'">
                          <td style="width: 150px;">事業者登録番号</td>
                          <td>
                            T{{form.invoiceNo}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr style="margin-top: 20px;">
                <div class="text-end">
<!--                  <button class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-mail-forward" style="margin-right: 5px;"></i>メールで送信</button>-->
                  <button @click="downloadInvoicePdf" class="btn btn-outline-primary"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDFダウンロード</button>
                </div>
                <div class="form-label align-bottom">収集用URL</div>
                <div class="form-edit-line">
                  <div class="input-group" style="margin-top: 10px;">
                    <input v-model="invoiceUrl" type="text" class="form-control" disabled>
                    <button @click="copyInvoiceUrl" class="btn btn-outline-primary"><i class="fas fa-copy"></i></button>
                    <button @click="generateInvoiceUrl(true)" class="btn btn-outline-danger"><i class="fas fa-undo"></i></button>
                  </div>
                </div>
                <div class="alert alert-warning text-start">
                  URLを既知のメールアドレス等で先方に伝え、受領に使用したいメールアドレスを登録してもらってください。URL右の<i class="fas fa-copy" style="margin-left: 5px; margin-right: 5px;"></i>ボタンをクリックするとコピーされますので、任意の場所でペーストしてご使用ください。コピーボタン隣の<i class="fas fa-undo" style="margin-left: 5px; margin-right: 5px;"></i>ボタンでURLを変更することができます。 <b class="text-danger">URL通知後に変更すると登録できなくなってしまう</b>のでご注意ください。
                </div>
                <div class="form-label align-bottom">宛先</div>
                <div class="form-edit-line">
                  <input v-model="invoiceDestinationName" type="text" class="form-control">
                </div>
                <div class="form-label align-bottom">挨拶文テンプレート</div>
                <div class="form-edit-line">
                  <select @change="changeInvoiceTemplate" v-model="invoiceTemplate" class="form-select">
                    <option value="">-</option>
                    <option v-for="greeting in invoiceGreetingList" :key="greeting.id" :value="greeting.greeting">{{greeting.title}}</option>
                  </select>
                </div>
                <div class="form-label align-bottom">挨拶文</div>
                <div class="form-edit-line">
                  <textarea v-model="invoiceGreeting" class="form-control" rows="10"></textarea>
                </div>
              </template>
            </div>
            <div v-if="rightAreaMode === 'statement' && (!destinationList || destinationList.length === 0)">
              <div class="text-end">
<!--                <button class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-mail-forward" style="margin-right: 5px;"></i>メールで送信</button>-->
                <button @click="downloadCollectPdf" class="btn btn-outline-primary"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>PDFダウンロード</button>
              </div>
              <div class="form-label align-bottom">収集用URL</div>
              <div class="form-edit-line">
                <div class="input-group" style="margin-top: 10px;">
                  <input v-model="collectUrl" type="text" class="form-control" disabled>
                  <button @click="copyCollectUrl" class="btn btn-outline-primary"><i class="fas fa-copy"></i></button>
                  <button @click="generateCollectUrl(true)" class="btn btn-outline-danger"><i class="fas fa-undo"></i></button>
                </div>
              </div>
              <div class="alert alert-warning text-start">
                URLを既知のメールアドレス等で先方に伝え、受領に使用したいメールアドレスを登録してもらってください。URL右の<i class="fas fa-copy" style="margin-left: 5px; margin-right: 5px;"></i>ボタンをクリックするとコピーされますので、任意の場所でペーストしてご使用ください。コピーボタン隣の<i class="fas fa-undo" style="margin-left: 5px; margin-right: 5px;"></i>ボタンでURLを変更することができます。 <b class="text-danger">URL通知後に変更すると登録できなくなってしまう</b>のでご注意ください。
              </div>
              <div class="form-label align-bottom">宛先</div>
              <div class="form-edit-line">
                <input v-model="collectDestinationName" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">挨拶文テンプレート</div>
              <div class="form-edit-line">
                <select @change="changeCollectTemplate" v-model="collectTemplate" class="form-select">
                  <option value="">-</option>
                  <option v-for="greeting in collectGreetingList" :key="greeting.id" :value="greeting.greeting">{{greeting.title}}</option>
                </select>
              </div>
              <div class="form-label align-bottom">挨拶文</div>
              <div class="form-edit-line">
                <textarea v-model="collectGreeting" class="form-control" rows="10"></textarea>
              </div>
            </div>
            <div v-else-if="rightAreaMode === 'statement' && !destinationList.some(x => x.id === null)">
              <div class="alert alert-secondary">送付先情報は既に登録済みです</div>
              <p v-for="destination in destinationList" :key="destination.id" style="margin: 0 0 3px 0;">
                <button v-if="destination.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                {{destination.name}}
              </p>
            </div>
            <div v-else-if="rightAreaMode === 'statement' && destinationList.some(x => x.id === null)">
              <div class="alert alert-danger">この権利者は他の契約に紐付き、既にそちらで送付先が作成されています</div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { useRoute } from 'vue-router'
import { contractFindFunction } from '@/views/common/contract/function'
import { rightsHolderFunction } from '@/views/red/rightsholder/function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const processModalDiv = ref(null)
    let processModal = null
    const processMessage = ref('')
    const messages = ref([])
    const messageToastDiv = ref(null)
    const system = ref('capo')
    let messageToast = null
    // 権利者閲覧用
    const form = ref(null)
    const collectUrl = ref('')
    const invoiceUrl = ref('')
    const greeting = ref('')
    const destinationList = ref([])
    const rightAreaMode = ref('statement')
    const destinationRegistered = ref(false)
    const contractFunc = contractFindFunction()
    const collectDestinationName = ref('')
    const invoiceDestinationName = ref('')
    const collectGreetingList = ref([])
    const invoiceGreetingList = ref([])
    const collectTemplate = ref('')
    const collectGreeting = ref('')
    const invoiceTemplate = ref('')
    const invoiceGreeting = ref('')
    const redRightsHolderFunc = rightsHolderFunction()
    const invoice = ref(null)
    const taxationType = ref('')
    const invoiceNo = ref('')
    const company = ref({})
    // メソッド
    const copyCollectUrl = () => {
      navigator.clipboard.writeText(collectUrl.value)
      messages.value = ['コピーされました']
      messageToast.show()
    }
    const copyInvoiceUrl = () => {
      navigator.clipboard.writeText(invoiceUrl.value)
      messages.value = ['コピーされました']
      messageToast.show()
    }
    const generateCollectUrl = async (renew) => {
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/token', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          system: system.value,
          rightholderId: form.value.id,
          renew: renew
        }
      }).then(response => {
        collectUrl.value = response.data
      }).catch(error => {
        ex.identifyEx(error)
        throw error
      })
    }
    const generateInvoiceUrl = async (renew) => {
      let tokenUrl = '/token'
      if (renew) {
        tokenUrl = '/token/renew'
      }
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + system.value + '/' + form.value.id + tokenUrl, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        invoiceUrl.value = response.data
        console.log(tokenUrl)
        console.log(response.data)
      }).catch(error => {
        ex.identifyEx(error)
        throw error
      })
    }
    const saveCollectGreeting = () => {
      axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/collect/greeting', greeting.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).catch(error => { ex.identifyEx(error) })
    }
    const saveInvoiceGreeting = () => {
      axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting', greeting.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).catch(error => { ex.identifyEx(error) })
    }
    async function init (paramId) {
      if (system.value === 'red') {
        const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/seq/' + paramId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        form.value = data
        form.value.id = data.seq
      } else {
        const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system.value + '/rightholder/' + paramId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        form.value = data
      }
      collectDestinationName.value = form.value.name + ' 様'
      invoiceDestinationName.value = form.value.name + ' 様'
      collectGreetingList.value = await contractFunc.findCollectGreeting()
      invoiceGreetingList.value = await contractFunc.findInvoiceGreeting()
      if (system.value === 'red') {
        const redRightsHolder = await redRightsHolderFunc.getBySeq(paramId)
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/' + redRightsHolder.id, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          destinationList.value = response.data
          destinationRegistered.value = destinationList.value.length > 0
          for (const destination of destinationList.value) {
            axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + destination.destinationId, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              destination.id = response.data.id
              destination.name = response.data.name
              if (response.data.accountId) {
                axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + destination.accountId + '/invoice', {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  if (response.status !== 204) {
                    invoice.value = response.data
                  }
                })
              }
            })
          }
        })
      } else {
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + system.value + '/' + form.value.companyId + '/' + paramId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          destinationList.value = response.data
          for (const destination of destinationList.value) {
            destinationRegistered.value = true
            axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + destination.accountId + '/invoice', {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            }).then(response => {
              if (response.status !== 204) {
                invoice.value = response.data
                console.log(invoice.value)
              }
            })
          }
        }).catch(error => {
          ex.identifyEx(error)
        })
      }
      if (destinationList.value.length === 0) {
        try {
          await generateCollectUrl(false)
        } catch (error) {
          destinationList.value = [{
            id: null
          }]
        }
      }
      await generateInvoiceUrl(false)
    }
    const downloadCollectPdf = async () => {
      processMessage.value = 'PDFファイルの作成中です。しばらくお待ちください。'
      processModal.show()
      console.log(company.value)
      const { data } = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + system.value + '/' + form.value.id + '/pdf', {
        companyName: company.value.name,
        destinationName: collectDestinationName.value,
        greeting: collectGreeting.value
      }, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      const a = document.createElement('a')
      a.href = data
      a.click()
      processModal.hide()
    }
    const downloadInvoicePdf = async () => {
      processMessage.value = 'PDFファイルの作成中です。しばらくお待ちください。'
      processModal.show()
      const contract = await contractFunc.get()
      const { data } = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + system.value + '/' + form.value.id + '/pdf', {
        companyName: contract.name,
        destinationName: invoiceDestinationName.value,
        greeting: invoiceGreeting.value
      }, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      const a = document.createElement('a')
      a.href = data
      a.click()
      processModal.hide()
    }
    const changeCollectTemplate = () => {
      let greeting = ''
      if (collectTemplate.value) {
        greeting = collectTemplate.value
      }
      collectGreeting.value = greeting
    }
    const changeInvoiceTemplate = () => {
      let greeting = ''
      if (invoiceTemplate.value) {
        greeting = invoiceTemplate.value
      }
      invoiceGreeting.value = greeting
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      system.value = route.params.system
      session.session = await auth.getSession()
      console.log(route.params.companyId)
      if (system.value === 'red') {
        await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + route.params.companyId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(async response => {
          company.value = response.data
          await init(route.params.id)
          loadingModal.hide()
        })
      } else {
        await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system.value + '/company/' + route.params.companyId, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(async response => {
          company.value = response.data
          await init(route.params.id)
          loadingModal.hide()
        })
      }
    })
    return {
      loadingModalDiv,
      processMessage,
      processModalDiv,
      messages,
      messageToastDiv,
      system,
      form,
      collectUrl,
      invoiceUrl,
      greeting,
      destinationList,
      rightAreaMode,
      copyCollectUrl,
      copyInvoiceUrl,
      generateCollectUrl,
      generateInvoiceUrl,
      saveCollectGreeting,
      saveInvoiceGreeting,
      downloadCollectPdf,
      downloadInvoicePdf,
      destinationRegistered,
      collectDestinationName,
      invoiceDestinationName,
      collectGreetingList,
      invoiceGreetingList,
      collectTemplate,
      collectGreeting,
      invoiceTemplate,
      invoiceGreeting,
      changeCollectTemplate,
      changeInvoiceTemplate,
      invoice,
      taxationType,
      invoiceNo,
      company
    }
  }
})
</script>
