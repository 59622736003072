import { defineStore } from 'pinia'
import { ref } from 'vue'

export const paymentRecordStore = defineStore('paymentrecord', () => {
  const year = ref(0)
  const searchString = ref('')
  const findTarget = ref('all')
  const page = ref(0)
  return { year, searchString, findTarget, page }
})
