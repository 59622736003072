<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-address-card fa-fw" style="margin-right: 5px;"></i> 登録依頼一覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item"><router-link :to="'/green/rightsholder/external/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link></li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw"></i></button>
              <button v-if="showDeleteButton" @click="openDelete" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw"></i></button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
        <div v-if="form" class="container text-start" style="margin-top: 20px;">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">外部システム連携用ID</div>
          <div class="form-edit-line">
            <input v-model="form.externalId" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">メモ</div>
          <div class="form-edit-line">
            <textarea v-model="form.memo" class="form-control" rows="5"></textarea>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">収集用権利者の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">以下の収集用権利者を削除しようとしています。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{form.name}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="deleteExternal" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { authFunction } from '@/functions/auth'
import exception from '@/functions/exception'
import { Modal, Toast } from 'bootstrap'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const { identifyEx } = exception()
    const session = ref(null)
    const errors = ref([])
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const errorToastDiv = ref(null)
    const deleteModalDiv = ref(null)
    let processModal = null
    let errorToast = null
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const form = ref(null)
    // メソッド
    const initAdd = () => {
      form.value = {
        id: null,
        name: '',
        externalId: null,
        memo: ''
      }
    }
    const openDelete = () => {
      deleteModal.show()
    }
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        try {
          processMessage.value = '保存処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
          processModal.show()
          const data = {
            name: form.value.name,
            externalId: form.value.externalId,
            memo: form.value.memo
          }
          if (!form.value.id) {
            const response = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder', data, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
            form.value.id = response.data
          } else {
            await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + form.value.id, data, {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
            })
          }
          processModal.hide()
          await router.push('/green/rightsholder/external/disp/' + form.value.id)
        } catch (error) {
          await identifyEx(error)
        }
      }
    }
    const deleteExternal = async () => {
      errors.value = []
      await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(() => {
        deleteModal.hide()
        router.push('/green/rightsholder/list')
      }).catch(error => {
        identifyEx(error)
      })
    }
    function initEdit (id) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        form.value = response.data
      }).catch(error => { identifyEx(error) })
    }
    onMounted(async () => {
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        session.value = response.data
      }).catch(error => { identifyEx(error) })
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      if (route.params.id === 'NEW') {
        initAdd()
      } else {
        initEdit(route.params.id)
      }
    })
    return {
      session,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      save,
      deleteExternal,
      openDelete
    }
  }
})
</script>
