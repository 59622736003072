<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-sitemap fa-fw" style="margin-right: 5px;"></i> 利用種別一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/usetype/edit" class="btn btn-outline-primary"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 60px; overflow: auto;">
          <table v-if="usetypeList.length > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th>利用種別名</th>
                <th style="width: 116px;">略称</th>
                <th>利用種別英語名</th>
                <th style="width: 116px;">英語略称</th>
              </tr>
            </thead>
            <tbody v-if="usetypeList">
              <tr v-for="usetype in usetypeList" :key="usetype.id" class="text-start">
                <td>{{ usetype.name }}</td>
                <td>{{ usetype.abbreviation }}</td>
                <td>{{ usetype.nameEn }}</td>
                <td>{{ usetype.abbreviationEn }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useTypeFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const useTypeFunc = useTypeFunction()
    const usetypeList = ref([])
    // メソッド
    const find = async () => {
      usetypeList.value = await useTypeFunc.find(session.session.company.id)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      usetypeList,
      find
    }
  }
})
</script>
