<template>
  <div>
    <nav-component></nav-component>
    <div class="container-fluid">
      <div style="height: 50px;"></div>
      <div class="row align-items-center" style="margin-top: 15px;">
        <div class="d-none d-md-block col-md-8 text-start">
          <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
            <ol class="breadcrumb" style="margin: 0;">
              <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 収集用権利者一覧</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-upload" style="margin-right: 5px;"></i>アップロード</li>
            </ol>
          </nav>
        </div>
        <div class="col d-flex justify-content-end">
          <button @click="upload" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-file-upload fa-fw"></i></button>
        </div>
      </div>
      <div class="container text-start" style="margin-top: 20px;">
        <div class="form-label align-bottom">アップロードするファイルの種類</div>
        <div class="form-edit-line">
          <div class="form-check form-check-inline">
            <input v-model="fileType" class="form-check-input" type="radio" name="fileTypeOptions" id="fileTypeTrs" value="trs">
            <label class="form-check-label" for="fileTypeTrs">TRSフォーマット</label>
            <span @click="openTrsModal" class="badge bg-success" style="margin-left: 3px; cursor: pointer;"><i class="fas fa-question"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input v-model="fileType" class="form-check-input" type="radio" name="fileTypeOptions" id="fileTypeDcs" value="dcs" disabled>
            <label class="form-check-label" for="fileTypeDcs">DCSフォーマット</label>
            <span @click="openDcsModal" class="badge bg-success" style="margin-left: 3px; cursor: pointer;"><i class="fas fa-question"></i></span>
          </div>
          <div class="form-check form-check-inline">
            <input v-model="fileType" class="form-check-input" type="radio" name="fileTypeOptions" id="fileTypeIandc" value="iandc" disabled>
            <label class="form-check-label" for="fileTypeIandc">I&Cフォーマット</label>
            <span @click="openIandcModal" class="badge bg-success" style="margin-left: 3px; cursor: pointer;"><i class="fas fa-question"></i></span>
          </div>
        </div>
        <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>ファイル</div>
        <div class="form-edit-line">
          <input class="form-control" type="file" id="uploadFile" accept="text/*" ref="uploadFile">
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="trsModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">TRSフォーマット</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <th style="width: 150px;">ファイル形式</th>
                  <td>Excel（XLSXファイル）</td>
                </tr>
                <tr>
                  <th style="width: 150px;">文字コード</th>
                  <td>-</td>
                </tr>
                <tr>
                  <th style="width: 150px;">ヘッダー行</th>
                  <td>先頭1行</td>
                </tr>
                <tr>
                  <th style="width: 150px;">項目</th>
                  <td>
                    <table class="table table-responsive">
                      <tbody>
                        <tr>
                          <td style="width: 80px;">1列</td>
                          <td>システムコード</td>
                          <td style="width: 120px;">半角英数</td>
                          <td style="width: 100px;" class="text-secondary">任意</td>
                        </tr>
                        <tr>
                          <td style="width: 80px;">2列</td>
                          <td>権利者コード</td>
                          <td>半角英数</td>
                          <td class="text-secondary">任意</td>
                        </tr>
                        <tr>
                          <td style="width: 80px;">3列</td>
                          <td>権利者名</td>
                          <td>全ての文字</td>
                          <td class="text-danger"><i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>必須</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th style="width: 150px;">フォーマット</th>
                  <td><a href="/green/external/trs/format.xlsx" download="format.xlsx">ダウンロード</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="dcsModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">DCSフォーマット</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <th style="width: 150px;">ファイル形式</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">文字コード</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">ヘッダー行</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">項目</th>
                  <td>
                    <table class="table table-responsive">
                      <tbody>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="iandcModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">I&Cフォーマット</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <th style="width: 150px;">ファイル形式</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">文字コード</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">ヘッダー行</th>
                  <td>不明</td>
                </tr>
                <tr>
                  <th style="width: 150px;">項目</th>
                  <td>
                    <table class="table table-responsive">
                      <tbody>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import auth from '@/functions/auth'
import exception from '@/functions/exception'
import { Modal, Toast } from 'bootstrap'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const router = useRouter()
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    const session = ref(null)
    const errors = ref([])
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const errorToastDiv = ref(null)
    const trsModalDiv = ref(null)
    const dcsModalDiv = ref(null)
    const iandcModalDiv = ref(null)
    let processModal = null
    let errorToast = null
    let trsModal = null
    let dcsModal = null
    let iandcModal = null
    // Form
    const fileType = ref('trs')
    const uploadFile = ref(null)
    // メソッド
    const upload = async () => {
      errors.value = []
      const file = uploadFile.value.files[0]
      if (!file) {
        errors.value.push('ファイルを選択してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
        return
      }
      const formData = new FormData()
      formData.append('file', file, file.name)
      processMessage.value = 'データのアップロードとシステムへの取り込みを実行中です。しばらくこのままでお待ちください。'
      processModal.show()
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/upload/' + fileType.value, formData, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).catch(error => { identifyEx(error) })
      uploadFile.value.value = null
      processModal.hide()
      await router.push('/green/rightsholder/list')
    }
    const openTrsModal = () => {
      trsModal.show()
    }
    const openDcsModal = () => {
      dcsModal.show()
    }
    const openIandcModal = () => {
      iandcModal.show()
    }
    onMounted(async () => {
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(response => {
        session.value = response.data
      }).catch(error => { identifyEx(error) })
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      trsModal = new Modal(trsModalDiv.value, {
        keyboard: false
      })
      dcsModal = new Modal(dcsModalDiv.value, {
        keyboard: false
      })
      iandcModal = new Modal(iandcModalDiv.value, {
        keyboard: false
      })
    })
    return {
      session,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      trsModalDiv,
      dcsModalDiv,
      iandcModalDiv,
      fileType,
      uploadFile,
      upload,
      openTrsModal,
      openDcsModal,
      openIandcModal
    }
  }
})
</script>
