import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Portal from '../views/Portal.vue'
import InternalServerError from '../views/500.vue'
import CommonAccount from '../views/common/account/routes'
import CommonAddressee from '../views/common/addressee/routes'
import CommonArtist from '../views/common/artist/routes'
import CommonBank from '../views/common/bank/routes'
import CommonContract from '../views/common/contract/routes'
import CommonCurrency from '../views/common/currency/routes'
import CommonPayee from '../views/common/payee/routes'
import CommonProject from '../views/common/project/routes'
import Admin from '../views/admin/convert/routes'
import AdminContract from '../views/admin/contract/routes'
import AdminUser from '../views/admin/user/routes'
import AdminMessage from '../views/admin/message/routes'
import AdminPostal from '../views/admin/postal/routes'
import AdminSystem from '../views/admin/system/routes'
import Green from '../views/green/routes'
import GreenDestination from '../views/green/destination/routes'
import GreenRightsHolder from '../views/green/rightsholder/routes'
import GreenSender from '../views/green/sender/routes'
import GreenOrganization from '../views/green/organization/routes'
import RedAlbum from '../views/red/album/routes'
import RedContract from '../views/red/contract/routes'
import RedDefinition from '../views/red/definition/routes'
import RedError from '../views/red/error/routes'
import RedMaster from '../views/red/master/routes'
import RedPayment from '../views/red/payment/routes'
import RedResult from '../views/red/result/routes'
import RedRightsHolder from '../views/red/rightsholder/routes'
import RedRoyaltyType from '../views/red/royaltytype/routes'
import RedSetting from '../views/red/setting/routes'
import RedTermUpdate from '../views/red/term/routes'
import RedUsage from '../views/red/usage/routes'
import RedUsageDetail from '../views/red/usage/detail/routes'
import RedUser from '../views/red/user/routes'
import RedUseType from '../views/red/usetype/routes'
import RedPaymentRecord from '../views/red/paymentrecord/routes'
import RedPaymentDataDefinition from '../views/red/paymentdatadefinition/routes'
import RedPaymentPdfDefinition from '../views/red/paymentpdfdefinition/routes'
import Help from '../views/help/routes'
import InvoiceValidation from '../views/tool/InvoiceValidation.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal
  },
  {
    path: '/500',
    name: 'InternalServerError',
    component: InternalServerError
  },
  {
    path: '/invoice/validation/:invoiceNo/:token',
    name: 'InvoiceValidation',
    component: InvoiceValidation
  },
  ...Admin.routes,
  ...AdminContract.routes,
  ...AdminUser.routes,
  ...AdminMessage.routes,
  ...AdminPostal.routes,
  ...AdminSystem.routes,
  ...CommonAccount.routes,
  ...CommonAddressee.routes,
  ...CommonArtist.routes,
  ...CommonBank.routes,
  ...CommonContract.routes,
  ...CommonCurrency.routes,
  ...CommonPayee.routes,
  ...CommonProject.routes,
  ...Green.routes,
  ...GreenDestination.routes,
  ...GreenRightsHolder.routes,
  ...GreenSender.routes,
  ...GreenOrganization.routes,
  ...RedAlbum.routes,
  ...RedContract.routes,
  ...RedDefinition.routes,
  ...RedError.routes,
  ...RedMaster.routes,
  ...RedPayment.routes,
  ...RedResult.routes,
  ...RedRightsHolder.routes,
  ...RedRoyaltyType.routes,
  ...RedSetting.routes,
  ...RedTermUpdate.routes,
  ...RedUsage.routes,
  ...RedUsageDetail.routes,
  ...RedUser.routes,
  ...RedUseType.routes,
  ...RedPaymentRecord.routes,
  ...RedPaymentDataDefinition.routes,
  ...RedPaymentPdfDefinition.routes,
  ...Help.routes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
