<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component class="d-none d-md-block"></nav-component>
      <div class="container-fluid" style="margin-top: 20px; padding-left: 20px; padding-right: 20px;">
        <div class="d-md-none">
          <select v-model="mobileMenu" @change="changeMobileMenu" class="form-select">
            <option value="portal">TOP</option>
            <option value="receipt">受領一覧</option>
          </select>
        </div>
        <div class="d-flex justify-content-start align-items-center fs-5">
          <div class="me-auto d-none d-lg-block"><i class="fas fa-info-circle fa-fw" style="margin-right: 5px"></i> お知らせ</div>
        </div>
        <table class="table table-responsive" style="margin-top: 10px;">
          <thead>
            <tr>
              <th class="d-none d-md-table-cell" style="width: 150px">種別</th>
              <th style="width: 200px">日時</th>
              <th>メッセージタイトル</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(message, index) in messageList" :key="message.id" class="text-start">
              <td class="d-none d-md-table-cell">
                <span v-if="message.messageType === 'GLOBAL'">全体周知</span>
                <span v-if="message.messageType === 'VERSION'">バージョンアップ</span>
              </td>
              <td>{{ message.createdAt }}</td>
              <td>
                <a href="#" @click.prevent.stop="openMessageDisp(index)">{{ message.title }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <footer class="alert alert-warning" style="margin-top: 50px">
          <div class="d-flex justify-content-end" style="margin-bottom: 10px">
            <img src="../assets/logo_corp.png" style="max-width: 200px" />
          </div>
          <div class="text-end" style="margin-left: 20px; font-size: 90%">東京ライツサービス株式会社</div>
          <div class="text-end" style="margin-left: 20px; font-size: 90%">〒151-0053 東京都渋谷区代々木1-30-14</div>
          <div class="text-end" style="margin-left: 20px; font-size: 90%">050-3138-2564</div>
          <div class="text-end" style="margin-left: 8px; font-size: 90%">
            <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
          </div>
          <div class="text-end" style="margin-left: 8px; font-size: 90%">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </footer>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="messageDispModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">お知らせの閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">お知らせ日時</div>
            <div class="form-edit-line">
              {{ createdAt }}
            </div>
            <div class="form-label align-bottom">種別</div>
            <div class="form-edit-line">
              <span v-if="messageType === 'GLOBAL'">停止等の案内</span>
              <span v-if="messageType === 'VERSION'">バージョンアップ</span>
            </div>
            <div class="form-label align-bottom">タイトル</div>
            <div class="form-edit-line">
              {{ title }}
            </div>
            <div class="form-label align-bottom">お知らせ内容</div>
            <div class="form-edit-line">
              <span v-html="messageBody.replaceAll('\n', '<br>')" class="text-break"></span>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px"></i>閉じる</button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import axios from 'axios'
import { Modal } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '../components/Nav'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const mobileMenu = ref('portal')
    const auth = authFunction()
    const session = sessionStore()
    const messageResult = ref(null)
    const messageList = ref([])
    const messageDispModalDiv = ref(null)
    let messageDispModal = null
    // Form
    const messageType = ref('GLOBAL')
    const title = ref('')
    const messageBody = ref('')
    const createdAt = ref('')
    const openMessageDisp = (index) => {
      messageType.value = messageList.value[index].messageType
      title.value = messageList.value[index].title
      messageBody.value = messageList.value[index].messageBody
      createdAt.value = messageList.value[index].createdAt
      messageDispModal.show()
    }
    onMounted(async () => {
      messageDispModal = new Modal(messageDispModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/message/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          published: true,
          page: 0,
          perPage: 10
        }
      }).then(response => {
        messageResult.value = response.data
        messageList.value = messageResult.value.list
      })
    })
    const changeMobileMenu = () => {
      if (mobileMenu.value === 'receipt') {
        router.push('/green/receipt')
      }
    }
    return {
      session,
      messageResult,
      messageList,
      messageDispModalDiv,
      messageType,
      title,
      messageBody,
      createdAt,
      openMessageDisp,
      mobileMenu,
      changeMobileMenu
    }
  }
})
</script>
