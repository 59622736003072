<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component v-if="route.path.indexOf('/unmatched') === -1"></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div v-if="route.path.indexOf('/unmatched') === -1" class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/master/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      原盤一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
                <ol v-if="route.path.includes('/red/result/master/disp/')" class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/result/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      リアルタイム結果一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/red/result/disp/' + route.params.resultId"><i class="fas fa-eye" style="margin-right: 5px;"></i>結果詳細</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/master/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/red/master/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div v-else style="margin-top: 10px;" class="container text-start"><button class="btn btn-outline-primary" @click="router.go(-1)"><i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る</button></div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div class="form-label align-bottom">名前（表示用）</div>
              <div class="form-edit-line">
                {{ form.displayName }}
              </div>
              <div v-if="form.kana" class="form-label align-bottom">カナ</div>
              <div v-if="form.kana" class="form-edit-line">
                {{ form.kana }}
              </div>
              <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
              <div v-if="form.nameEn" class="form-edit-line">
                {{ form.nameEn }}
              </div>
              <div v-if="form.code" class="form-label align-bottom">管理コード</div>
              <div v-if="form.code" class="form-edit-line">
                {{ form.code }}
              </div>
              <div v-if="form.isrcList && form.isrcList.length > 0" class="form-label align-bottom">
                ISRC
              </div>
              <div class="form-edit-line">
                <p v-for="(isrc, index) in form.isrcList" :key="index" style="margin: 0;">
                  {{isrc.isrc}}
                </p>
              </div>
              <div v-if="form.artistId" class="form-label align-bottom">アーティスト</div>
              <div v-if="form.artistId" class="form-edit-line">
                <button @click="openArtistRefModal(form.artistId)" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                {{ form.artistName }}
              </div>
              <div v-if="form.projectId" class="form-label align-bottom">プロジェクト</div>
              <div v-if="form.projectId" class="form-edit-line">
                <router-link :to="'/common/project/disp/' + form.projectId" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                {{ form.projectName }}
              </div>
              <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-label align-bottom">演奏時間
              </div>
              <div v-if="form.playTimeH || form.playTimeM || form.playTimeS" class="form-edit-line">
                <span v-if="form.playTimeH">{{ form.playTimeH }}時間</span><span v-if="form.playTimeM">{{form.playTimeM}}分</span><span v-if="form.playTimeS">{{ form.playTimeS }}秒</span>
              </div>
              <div class="card" style="margin-bottom: 10px;">
                <div class="card-header">計算</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <div class="form-label align-bottom">計算対象</div>
                  <div class="form-edit-line">
                    <span v-if="form.calculateTarget">分配計算の対象とする</span>
                    <span v-if="!form.calculateTarget">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</span>
                  </div>
                  <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                  <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
                    <span v-if="!form.suspension">分配計算の対象とする</span>
                    <span v-if="form.suspension">分配計算を保留する</span>
                  </div>
                </div>
              </div>
              <div v-if="form.userCodeList && form.userCodeList.length > 0" class="accordion" id="accordionUserCode" style="margin-bottom: 10px;">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUserCode" aria-expanded="true" aria-controls="collapseUserCode">
                      報告コード
                    </button>
                  </h2>
                  <div id="collapseUserCode" class="accordion-collapse collapse" data-bs-parent="#accordionUserCode">
                    <div class="accordion-body">
                      <table class="table table-responsive">
                        <tbody>
                        <tr v-for="(userCode, index) in form.userCodeList" :key="index">
                          <td class="text-break">{{userCode.userName}}</td>
                          <td class="text-break">{{userCode.code}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
                <div class="card-header">保留期</div>
                <div class="card-body" style="padding: 5px;">
                  <table class="table table-responsive">
                    <tbody>
                    <tr v-for="pool in form.poolList" :key="pool.id">
                      <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                      <td class="text-end">
                        <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                        <span v-else>解除しても支払わない</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <span v-html="form.memo"></span>
              </div>
              <div class="form-label align-bottom">登録</div>
              <div class="form-edit-line">
                <span v-html="form.createdAt"></span>（{{form.createdBy}}）
              </div>
              <div class="form-label align-bottom">最終更新</div>
              <div class="form-edit-line">
                <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
              </div>
            </div>
            <div class="col-lg-5">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'CONTRACT'" class="nav-link" :class="{ 'active': rightAreaMode === 'CONTRACT' }"
                     aria-current="page" href="#">
                    契約書
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                    アルバム
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'SUMMARY'" class="nav-link" :class="{ 'active': rightAreaMode === 'SUMMARY' }" aria-current="page" href="#">
                    累計
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }" aria-current="page" href="#">
                    分析
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'ALBUM'">
                <table v-if="albumList.length > 0" class="table table-responsive">
                  <tbody>
                    <tr v-for="(album, index) in albumList" :key="index" class="align-middle">
                      <td style="width: 55px;" class="text-center">
                        <button @click="openAlbumRefModel(album.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                      </td>
                      <td>
                        <p v-if="album.catalogNo" style="margin: 0; font-size: 80%;">{{album.catalogNo}}</p>
                        {{album.name}}
                        <p v-if="album.artistName" style="margin: 0; font-size: 80%;">{{album.artistName}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="form-edit-line" style="margin-top: 10px;">関連アルバムが存在しません</div>
              </div>
              <div v-if="rightAreaMode === 'CONTRACT'">
                <table v-if="form.contractList && form.contractList.length > 0" class="table table-responsive table-hover">
                  <tbody>
                  <tr v-for="contract in form.contractList" :key="contract.id" class="align-middle">
                    <td style="width: 55px;">
                      <button @click="openContractRefModal(contract.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    </td>
                    <td>
                      <p v-if="contract.code" style="margin: 0 0 3px 0; font-size: 80%;">{{contract.code}}</p>
                      {{contract.name}}
                      <p v-if="contract.rightsHolderName" style="margin: 3px 0 0 0; font-size: 80%;">{{contract.rightsHolderName}}</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-else class="form-edit-line" style="margin-top: 10px;">契約が存在しません</div>
                <hr v-if="form.contractList && form.contractList.length > 0" style="margin-top: 30px!important;">
                <div v-if="form.contractList && form.contractList.length > 0" class="form-edit-line">利用種別契約状況</div>
                <div v-if="form.contractList && form.contractList.length > 0" class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <template v-for="useType in useTypeList" :key="useType.id">
                      <h2 class="accordion-header" :id="'heading_' + useType.id">
                        <button class="accordion-button collapsed" :class="{ 'bg-light': !(useType.userSalesList && useType.userSalesList.length > 0), 'text-secondary': !(useType.userSalesList && useType.userSalesList.length > 0) }" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' + useType.id" :aria-expanded="false" :aria-controls="'collapse_' + useType.id" :disabled="!(useType.userSalesList && useType.userSalesList.length > 0)">
                          <span v-if="!useType.hasCondition" style="margin-right: 5px;" class="badge bg-secondary">契約なし</span>
                          <span v-else style="margin-right: 5px;" class="badge bg-info">契約あり</span>
                          {{useType.name}}
                        </button>
                      </h2>
                      <div :id="'collapse_' + useType.id" class="accordion-collapse collapse" :aria-labelledby="'heading_' + useType.id" data-bs-parent="#accordionExample">
                        <table class="table table-responsive">
                          <tbody>
                          <tr v-for="userSales in useType.userSalesList" :key="userSales.id">
                            <td style="font-size: 90%;">{{userSales.name}}</td>
                            <td v-if="userSales.contractConditionList && userSales.contractConditionList.length > 0" style="font-size: 90%;" class="text-end">
                              <p v-for="condition in userSales.contractConditionList" :key="condition.id" style="margin: 0;">
                                <span style="margin-right: 5px;">{{condition.rightsHolderName}}</span>
                                <span class="badge bg-info">{{condition.royaltyTypeAbbreviation}}</span>
                              </p>
                            </td>
                            <td v-else style="width: 100px;"><span class="badge bg-secondary">契約なし</span></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="rightAreaMode === 'PROFIT'">
                <div class="form-edit-line">
                  <bar-chart :chart-data="usedNoSummary" :styles="{ height: '300px' }"></bar-chart>
                </div>
                <div class="form-edit-line">
                  <bar-chart :chart-data="incomeSummary" :styles="{ height: '300px' }"></bar-chart>
                </div>
                <template v-if="currentSummary && ((currentSummary.usedNo && currentSummary.usedNo !== 0) || (currentSummary.incomeAmount && currentSummary.incomeAmount !== 0))">
                  <div v-if="currentSummary && currentSummary.usedNo && currentSummary.usedNo !== 0" class="form-label align-bottom">今期使用数</div>
                  <div v-if="currentSummary && currentSummary.usedNo && currentSummary.usedNo !== 0" class="form-edit-line">
                    {{currentSummary.usedNo.toLocaleString()}}
                  </div>
                  <div v-if="currentSummary && currentSummary.incomeAmount && currentSummary.incomeAmount !== 0" class="form-label align-bottom">今期受領額</div>
                  <div v-if="currentSummary && currentSummary.incomeAmount && currentSummary.incomeAmount !== 0" class="form-edit-line">
                    {{currentSummary.incomeAmount.toLocaleString()}}
                  </div>
                  <div v-if="currentSummary && currentSummary.paymentList && currentSummary.paymentList.length > 0" class="form-label align-bottom">今期支払額</div>
                  <div v-if="currentSummary && currentSummary.paymentList && currentSummary.paymentList.length > 0" class="form-edit-line">
                    <table class="table table-responsive">
                      <tbody>
                        <tr v-for="payment in currentSummary.paymentList" :key="payment.rightsHolderId">
                          <td>{{payment.name}}</td>
                          <td style="width: 200px;" class="text-end">{{payment.paymentAmount.toLocaleString()}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>
              <div v-if="rightAreaMode === 'SUMMARY'">
                <div v-if="!form.totalModel" class="form-edit-line">
                  実績なし
                </div>
                <div v-if="form.totalModel" class="form-label align-bottom">累計使用数</div>
                <div v-if="form.totalModel" class="form-edit-line">
                  {{form.totalModel.usedNo.toLocaleString()}}
                </div>
                <div v-if="form.totalModel" class="form-label align-bottom">累計受領額</div>
                <div v-if="form.totalModel" class="form-edit-line">
                  ￥{{form.totalModel.incomeAmount.toLocaleString()}}
                </div>
                <div v-if="form.totalModel" class="form-label align-bottom">累計支払額</div>
                <div v-if="form.totalModel" class="form-edit-line">
                  ￥{{form.totalModel.paymentAmount.toLocaleString()}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="artistRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refArtistId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティスト詳細</h5>
          </div>
          <div class="modal-body text-start">
            <artist-modal :artist-id="refArtistId"></artist-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeArtistRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoArtist(refArtistId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アーティスト画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="albumRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refAlbumId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバム詳細</h5>
          </div>
          <div class="modal-body text-start">
            <album-modal :album-id="refAlbumId"></album-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeAlbumRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoAlbum(refAlbumId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アルバム画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="contractRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refContractId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書詳細</h5>
          </div>
          <div class="modal-body text-start">
            <contract-modal :contract-id="refContractId"></contract-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeContractRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoContract(refContractId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>契約書画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import { masterFunction } from './function'
import { albumFunction } from '../album/function'
import { contractFunction } from '../contract/function'
import { useTypeFunction } from '../usetype/function'
import { summaryFunction } from '@/functions/summary'
import artistModal from '@/components/modal/ArtistModal'
import { Modal } from 'bootstrap'
import albumModal from '@/components/modal/AlbumModal'
import contractModal from '@/components/modal/ContractModal'

Chart.register(...registerables)

export default defineComponent({
  components: { contractModal, albumModal, artistModal, footerComponent, navComponent, BarChart },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const masterFunc = masterFunction()
    const albumFunc = albumFunction()
    const contractFunc = contractFunction()
    const useTypeFunc = useTypeFunction()
    // Form
    const form = ref(null)
    const rightAreaMode = ref('CONTRACT')
    const useTypeList = ref([])
    // アルバム一覧
    const albumList = ref([])
    // 分析
    const summaryFunc = summaryFunction()
    const usedNoSummaryLabelList = ref([])
    const usedNoSummaryDataList = ref([])
    const usedNoSummaryBgList = ref([])
    const usedNoSummary = {
      labels: usedNoSummaryLabelList.value,
      datasets: [{
        label: '使用数推移',
        data: usedNoSummaryDataList.value,
        backgroundColor: usedNoSummaryBgList.value
      }],
      height: 150
    }
    const incomeSummaryLabelList = ref([])
    const incomeSummaryDataList = ref([])
    const incomeSummaryBgList = ref([])
    const incomeSummary = {
      labels: incomeSummaryLabelList.value,
      datasets: [{
        label: '受領額推移',
        data: incomeSummaryDataList.value,
        backgroundColor: incomeSummaryBgList.value
      }]
    }
    const currentSummary = ref(null)
    // アーティスト詳細
    const artistRefModalDiv = ref({})
    let artistRefModal = null
    const refArtistId = ref('')
    // アルバム詳細
    const albumRefModalDiv = ref(null)
    let albumRefModal = null
    const refAlbumId = ref('')
    // 契約書詳細
    const contractRefModalDiv = ref({})
    let contractRefModal = null
    const refContractId = ref('')
    // メソッド
    async function init (id) {
      form.value = {
        id: id
      }
      form.value = await masterFunc.get(id)
      await findContract()
      useTypeList.value = await useTypeFunc.findHasSales(session.session.company.id)
      for (const useType of useTypeList.value) {
        for (const userSales of useType.userSalesList) {
          masterFunc.findContractCondition(form.value.id, useType.id, userSales.id, function (data) {
            if (data.length > 0) {
              useType.hasCondition = true
              userSales.contractConditionList = data
            }
          })
        }
      }
      // 利用年月別使用実績集計
      const today = new Date()
      const thisYear = today.getFullYear()
      const thisMonth = today.getMonth() + 1
      const usagePerUseSummaryPromises = []
      let year = thisYear
      let month = thisMonth
      for (let i = 0; i < 10; i++) {
        usagePerUseSummaryPromises.push(summaryFunc.getMasterUsageSummaryPerUse(form.value.id, year, month))
        month = month - 1
        if (month === 0) {
          year = year - 1
          month = 12
        }
      }
      const usagePerUseSummaryResponse = await Promise.all(usagePerUseSummaryPromises)
      usagePerUseSummaryResponse.reverse().forEach((res, index) => {
        usedNoSummaryLabelList.value.push(res.usedYear + '年' + res.usedMonth + '月')
        usedNoSummaryDataList.value.push(res.usedNo)
        usedNoSummaryBgList.value.push('#77CEFF')
        incomeSummaryLabelList.value.push(res.usedYear + '年' + res.usedMonth + '月')
        incomeSummaryDataList.value.push(res.incomeAmount)
        incomeSummaryBgList.value.push('#77CEFF')
      })
      // axios.get(process.env.VUE_APP_RED_ENDPOINT + '/master/' + id + '/usage/summary', {
      //   headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      //   params: {
      //     company_id: session.session.company.id
      //   }
      // }).then(response => {
      //   currentSummary.value = response.data
      //   if (!currentSummary.value) {
      //     currentSummary.value = {}
      //   }
      //   axios.get(process.env.VUE_APP_RED_ENDPOINT + '/master/' + id + '/payment/summary', {
      //     headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      //     params: {
      //       company_id: session.session.company.id
      //     }
      //   }).then(response => {
      //     currentSummary.value.paymentList = response.data
      //   })
      // })
      await findAlbum()
    }
    const findAlbum = async () => {
      albumList.value = await albumFunc.findByMaster(form.value.id)
    }
    const findContract = async () => {
      form.value.contractList = await contractFunc.findByMaster(form.value.id)
    }
    const openArtistRefModal = async (artistId) => {
      refArtistId.value = artistId
      artistRefModal.show()
    }
    const closeArtistRefModal = () => {
      refArtistId.value = null
      artistRefModal.hide()
    }
    const gotoArtist = (id) => {
      artistRefModal.hide()
      router.push('/common/artist/disp/' + id)
    }
    const openAlbumRefModel = async (albumId) => {
      refAlbumId.value = albumId
      albumRefModal.show()
    }
    const closeAlbumRefModal = () => {
      refAlbumId.value = null
      albumRefModal.hide()
    }
    const gotoAlbum = (albumId) => {
      albumRefModal.hide()
      router.push('/red/album/disp/' + albumId)
    }
    const openContractRefModal = async (contractId) => {
      refContractId.value = contractId
      contractRefModal.show()
    }
    const closeContractRefModal = () => {
      refContractId.value = null
      contractRefModal.hide()
    }
    const gotoContract = (id) => {
      contractRefModal.hide()
      router.push('/red/contract/disp/' + id)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      artistRefModal = new Modal(artistRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      albumRefModal = new Modal(albumRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      contractRefModal = new Modal(contractRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      router,
      route,
      form,
      rightAreaMode,
      albumList,
      findAlbum,
      useTypeList,
      currentSummary,
      findContract,
      usedNoSummaryLabelList,
      usedNoSummaryDataList,
      usedNoSummaryBgList,
      usedNoSummary,
      incomeSummaryLabelList,
      incomeSummaryDataList,
      incomeSummaryBgList,
      incomeSummary,
      openArtistRefModal,
      closeArtistRefModal,
      gotoArtist,
      artistRefModalDiv,
      refArtistId,
      openAlbumRefModel,
      closeAlbumRefModal,
      gotoAlbum,
      albumRefModalDiv,
      refAlbumId,
      openContractRefModal,
      closeContractRefModal,
      gotoContract,
      contractRefModalDiv,
      refContractId
    }
  }
})
</script>

<style scoped>
.axis {
  display: none!important;
}
</style>
