<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>ログインに使用するメールアドレスを追加・統合する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p style="margin-bottom: 0;">1つのアカウントに複数のメールアドレスを紐づける事ができます。</p>
      <p style="margin-bottom: 0;">ご自身が使用している複数のメールアドレスが本システムに登録されている場合、追加作業を行う事で統合され、どのメールアドレスでログインをしてもまとめて閲覧できるようになります。</p>
      <p style="margin-bottom: 0;">通知は「メイン」に設定されてるメールアドレス宛のみになります。</p>
      <p>末尾に注意点を記載しておりますので合わせてご確認ください。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">
          <router-link to="/help/myaccount/edit">2-5</router-link>の手順に従い、自分の情報を変更する画面を表示します。
        </li>
        <li style="margin-top: 20px;">
          「メールアドレス」右にある「＋」ボタンをクリックします。<br>
          <img src="../../assets/help/email_01.png" class="responsive-img" style="border: 1px solid #cccccc; max-width: 600px;">
        </li>
        <li style="margin-top: 20px;">
          「新しいメールアドレス」欄に追加したいメールアドレスを入力し、「認証コード送信」ボタンをクリックします。<br>
          <img src="../../assets/help/email_02.png" class="responsive-img" style="border: 1px solid #cccccc; max-width: 600px;">
        </li>
        <li style="margin-top: 20px;">
          追加したいメールアドレス宛に届いた認証コード（数字6桁）を「認証コード」欄に入力し、「保存」ボタンをクリックします。<br>
          <img src="../../assets/help/email_03.png" class="responsive-img" style="border: 1px solid #cccccc; max-width: 600px;">
        </li>
        <li style="margin-top: 20px;">
          右下にある「編集」ボタンをクリックします<br>
          <img src="../../assets/help/email_04.png" class="responsive-img" style="border: 1px solid #cccccc; max-width: 600px;">
        </li>
        <li style="margin-top: 20px;">
          メインに設定したいメールアドレスの右側にあるメインボタンをクリックします。メインとして設定されるとボタンが青色に変わります。<br>
          <img src="../../assets/help/email_05.png" class="responsive-img" style="border: 1px solid #cccccc; max-width: 600px;">
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>下記注意点をご確認ください。</label></p>
      <ul>
        <li style="margin-left: 20px; margin-bottom: 0;">
          追加されたメールアドレスが既にrights.servicesに登録済だった場合、過去該当メールアドレス宛に送付されていた明細は全て追加先のアカウントに移動します
        </li>
        <li style="margin-left: 20px; margin-bottom: 0;">
          追加をした全てのメールアドレスで全ての明細書が確認できるようになりますので、追加をするメールアドレスの中にいずれかの明細書を閲覧できてはいけないメールアドレス含まれていないか、ご確認の上作業を行ってください。
        </li>
        <li style="margin-left: 20px; margin-bottom: 0;">
          一度追加をしたメールアドレスを分ける事はできかねます
        </li>
      </ul>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2023年3月17日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
