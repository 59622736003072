import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { resultStore } from './store'

export const resultFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const store = resultStore()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const find = async (companyId, divTermId) => {
    const params = {
      companyId: companyId,
      divTermId: divTermId,
      searchString: store.searchString,
      page: store.page,
      perPage: 20
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.page = 0
  }
  return { get, find, clear }
}
