import RedUsageRegister from './Register'
import RedUsageList from './List'
import RedUsageFile from './File'
import RedUsageUnmatch from './Unmatch'

export default {
  routes: [
    {
      path: '/red/usage/list',
      name: 'RedUsageList',
      component: RedUsageList
    },
    {
      path: '/red/usage/file/:userSalesId/:uploadedFileId',
      name: 'RedUsageFile',
      component: RedUsageFile
    },
    {
      path: '/red/usage/register/:id',
      name: 'RedUsageRegister',
      component: RedUsageRegister
    },
    {
      path: '/red/usage/file/:userSalesId/:uploadedFileId/unmatch',
      name: 'RedUsageUnmatch',
      component: RedUsageUnmatch
    }
  ]
}
