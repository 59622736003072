import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const summaryFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const getMasterUsageSummaryPerUse = async (masterId, usedYear, usedMonth) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/summary/master/' + masterId + '/peruse/' + usedYear + '/' + usedMonth, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getAlbumUsageSummaryPerUse = async (albumId, usedYear, usedMonth) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/summary/album/' + albumId + '/peruse/' + usedYear + '/' + usedMonth, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return {
    getMasterUsageSummaryPerUse, getAlbumUsageSummaryPerUse
  }
}
