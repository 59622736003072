<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-copyright fa-fw" style="margin-right: 5px;"></i> 権利者一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/rightsholder/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件" :disabled="store.detailSearchMode">
            <span class="input-group-text d-none d-lg-block">並び順</span>
            <select v-model="store.sortColumn" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 150px;" :disabled="store.detailSearchMode">
              <option value="name">名前</option>
              <option value="kana">カナ</option>
              <option value="code">コード</option>
            </select>
            <select v-model="store.sortOrder" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 160px;" :disabled="store.detailSearchMode">
              <option value="asc">昇順（A → Z）</option>
              <option value="desc">降順（Z → A）</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア" :disabled="store.detailSearchMode"><i class="fas fa-undo"></i></button>
            <button @click="store.detailSearchMode = true" class="btn btn-outline-primary" :disabled="store.detailSearchMode"><i class="fas fa-filter-list"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary" :disabled="store.detailSearchMode"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div v-if="store.detailSearchMode" class="alert border-dark bg-white" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="store.detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <label class="col-2 col-form-label">検索文字</label>
                  <div class="col-10">
                    <input v-model="store.detailCondition.searchString" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">事業者</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.taxationType" class="form-select">
                      <option value="all">全て</option>
                      <option value="taxation">課税事業者</option>
                      <option value="exemption">免税事業者</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">事業者登録番号</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.invoiceNo" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">支払／保留</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.payOrCarryForward" class="form-select">
                      <option value="all">全て</option>
                      <option value="pay">支払</option>
                      <option value="carry_forward">保留</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">保留状況</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.carryForwardStatus" class="form-select">
                      <option value="all">全て</option>
                      <option value="has">保留金がある</option>
                      <option value="none">保留金がない</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">計算書送付方法</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.statementSendType" class="form-select">
                      <option value="all">全て</option>
                      <option value="online">オンライン</option>
                      <option value="paper">郵送</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">並び順①</label>
                  <div class="col-10 col-xl-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn1" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="kana">カナ</option>
                        <option value="code">コード</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder1" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">並び順②</label>
                  <div class="col-10 col-xl-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn2" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="kana">カナ</option>
                        <option value="code">コード</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder2" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end" style="margin-top: 10px;">
<!--                <button @click="downloadStart" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-excel" style="margin-right: 5px;"></i>ダウンロード</button>-->
                <button @click="store.page = 0; find();" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
                <button @click="clearSearchDetail" class="btn btn-outline-success" title="クリア"><i class="fas fa-undo" style="margin-right: 5px;"></i>クリア</button>
              </div>
            </div>
          </div>
          <table v-if="rightsholderResult && rightsholderResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th class="d-none d-md-table-cell" style="width: 150px;">コード</th>
                <th>権利者名</th>
                <th class="d-none d-sm-table-cell" style="width: 80px;">支／保</th>
              </tr>
            </thead>
            <tbody v-if="rightsholderList">
              <tr v-for="rightsholder in rightsholderList" :key="rightsholder.id" class="text-start align-middle">
                <td><router-link :to="'/red/rightsholder/disp/' + rightsholder.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link></td>
                <td class="d-none d-md-table-cell">{{rightsholder.code}}</td>
                <td>
                  <span v-if="rightsholder.groupRightsHolder" class="badge bg-primary" style="margin-left: 5px;">G</span>
                  {{ rightsholder.name }}
                </td>
                <td class="d-none d-sm-table-cell">
                  <span v-if="rightsholder.payOrCarryForward === 'pay'" class="badge bg-success">支払</span>
                  <span v-if="rightsholder.payOrCarryForward === 'carry_forward'" class="badge bg-secondary">保留</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="rightsholderResult && rightsholderResult.allRecords > 0">{{ rightsholderResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="rightsholderResult && rightsholderResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="rightsholderResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ rightsholderResult.allPages }}ページ</span></li>
                <li v-if="store.page !== rightsholderResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== rightsholderResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import { rightsHolderStore } from './store'
import { rightsHolderFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const rightsHolderFunc = rightsHolderFunction()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    // 検索
    const store = rightsHolderStore()
    const rightsholderResult = ref(null)
    const rightsholderList = ref([])
    // メソッド
    const find = async () => {
      if (store.detailSearchMode) {
        rightsholderResult.value = await rightsHolderFunc.findDetail(session.session.company.id, store)
      } else {
        rightsholderResult.value = await rightsHolderFunc.find(session.session.company.id, store)
      }
      rightsholderList.value = rightsholderResult.value.list
    }
    const clearSearch = () => {
      rightsHolderFunc.clear(store)
      rightsHolderFunc.detailClear(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = rightsholderResult.value.allPages - 1
      find()
    }
    const downloadStart = async () => {
      processMessage.value = 'ダウンロードファイルの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      const params = {
        company_id: session.session.company.id,
        sortColumn1: store.detailCondition.sortColumn1,
        sortOrder1: store.detailCondition.sortOrder1,
        sortColumn2: store.detailCondition.sortColumn2,
        sortOrder2: store.detailCondition.sortOrder2
      }
      if (store.detailCondition.searchString) {
        params.searchString = store.detailCondition.searchString
      }
      if (store.detailCondition.taxationType !== 'all') {
        params.taxationType = store.detailCondition.taxationType
      }
      if (store.detailCondition.invoiceNo) {
        params.invoiceNo = store.detailCondition.invoiceNo.replace('T', '')
      }
      if (store.detailCondition.payOrCarryForward !== 'all') {
        params.payOrCarryForward = store.detailCondition.payOrCarryForward
      }
      if (store.detailCondition.carryForwardStatus !== 'all') {
        params.carryForwardStatus = store.detailCondition.carryForwardStatus
      }
      const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/rightsholder/list/detail/download', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: params
      })
      const a = document.createElement('a')
      a.href = data
      a.click()
      processModal.hide()
    }
    const clearSearchDetail = () => {
      rightsHolderFunc.detailClear(store)
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await find()
    })
    return {
      router,
      store,
      processMessage,
      processModalDiv,
      rightsholderResult,
      rightsholderList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      downloadStart,
      clearSearchDetail
    }
  }
})
</script>
