import RedDefinitionDisp from './Disp'
import RedDefinitionEdit from './Edit'
import RedDefinitionList from './List'

export default {
  routes: [
    {
      path: '/red/definition/list',
      name: 'RedDefinitionList',
      component: RedDefinitionList
    },
    {
      path: '/red/definition/edit/:id',
      name: 'RedDefinitionEdit',
      component: RedDefinitionEdit
    },
    {
      path: '/red/definition/add',
      name: 'RedDefinitionAdd',
      component: RedDefinitionEdit
    },
    {
      path: '/red/definition/copy/:id',
      name: 'RedDefinitionCopy',
      component: RedDefinitionEdit
    },
    {
      path: '/red/definition/disp/:id',
      name: 'RedDefinitionDisp',
      component: RedDefinitionDisp
    }
  ]
}
