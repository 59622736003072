<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>携帯電話番号を登録または変更する</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>本システムに携帯電話番号を登録したり、登録されている携帯電話番号を変更することができます。初期状態では未登録です。</p>
      <p>以下、詳細な手順です。</p>
      <ol>
        <li style="margin-top: 20px;">
          <router-link to="/help/myaccount/edit">2-5</router-link>の手順に従い、自分の情報を変更する画面を表示します。
        </li>
        <li style="margin-top: 20px;">
          携帯電話欄の記入マークをクリック／タップします。<br>
          <img src="../../assets/help/account_11.png" class="responsive-img" style="border: 1px solid #cccccc;">
        </li>
        <li style="margin-top: 20px;">
          認証コードを送信します。
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              表示された画面の携帯電話番号欄に新しい携帯電話番号を入力します。<br>
              <img src="../../assets/help/account_12.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              確認コード送信ボタンをクリック／タップします。<br>
              <img src="../../assets/help/account_13.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">
          認証コードを入力し、保存します。
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              入力した携帯電話番号宛てに届いたSMSに記載してある認証コードを入力します。<br>
              <img src="../../assets/help/account_14.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
            <li style="margin-top: 10px;">
              登録ボタンをクリック／タップして保存します。<br>
              <img src="../../assets/help/account_15.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
