import { defineStore } from 'pinia'
import { ref } from 'vue'

export const contractStore = defineStore('album', () => {
  const searchString = ref('')
  const page = ref(0)
  const detailCondition = ref({
    search_string: null,
    contract_status: 'all',
    code: null,
    rights_holder_name: null,
    rights_holder_code: null,
    master_name: null,
    album_name: null,
    artist_name: null,
    sort_column1: 'name',
    sort_order1: 'asc',
    sort_column2: 'status',
    sort_order2: 'asc'
  })
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder, detailCondition }
})

export const masterStore = defineStore('contract_master', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder }
})

export const albumStore = defineStore('contract_album', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})

export const rightsHolderStore = defineStore('contract_rightsHolder', () => {
  const searchString = ref('')
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, page, sortColumn, sortOrder }
})
