import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const bankFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const insert = async (form) => {
    const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/bank', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, insert, update, remove, clearSearch, indexUpdate }
}

export const branchFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (branchId) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + branchId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const find = async (bankId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + bankId + '/branch/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const insert = async (bankId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/bank/' + bankId + '/branch', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const update = async (branchId, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + branchId, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const remove = async (branchId) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + branchId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, insert, update, remove, clearSearch, indexUpdate }
}
