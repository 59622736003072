<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/user/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      利用者一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/user/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/red/user/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div class="form-label align-bottom">略称</div>
              <div class="form-edit-line">
                {{ form.abbreviation }}
              </div>
              <div v-if="form.kana" class="form-label align-bottom">カナ</div>
              <div v-if="form.kana" class="form-edit-line">
                {{ form.kana }}
              </div>
              <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
              <div v-if="form.nameEn" class="form-edit-line">
                {{ form.nameEn }}
              </div>
              <div class="card" style="margin-bottom: 10px;" id="calcDiv">
                <div class="card-header">頒布形態</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(sales, index) in form.salesList" :key="index" class="align-middle">
                      <td class="text-center" style="width: 55px;">
                        <button @click.prevent.stop="openUserSalesModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                      </td>
                      <td>
                        {{ sales.name }}
                        <span v-if="sales.siteUrl" style="font-size: 85%;"><br><a :href="sales.siteUrl" target="_blank">{{sales.siteUrl}}</a></span>
                      </td>
                      <td class="text-end">
                        <span v-if="sales.useTypeName" class="badge bg-info">{{ sales.useTypeName }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <span v-html="form.memo"></span>
              </div>
            </div>
            <div class="col-lg-5">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }"
                    aria-current="page" href="#">
                    <i class="fas fa-chart-pie" style="margin-right: 5px;"></i>分析
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'PROFIT'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" ref="userSalesModalDiv">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">頒布形態</h5>
            </div>
            <div class="modal-body text-start">
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
              <div class="form-edit-line">
                {{userSales.name}}
              </div>
              <div class="form-label align-bottom">英語名</div>
              <div class="form-edit-line">
                {{userSales.nameEn}}
              </div>
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>利用種別</div>
              <div class="form-edit-line">
                {{userSales.useTypeName}}
              </div>
              <div v-if="userSales.siteUrl" class="form-label align-bottom">URL</div>
              <div v-if="userSales.siteUrl" class="form-edit-line">
                {{userSales.siteUrl}}
              </div>
              <div class="form-label align-bottom">1期に取り込むファイル数</div>
              <div class="form-edit-line">
                {{userSales.registerFileCount}}ファイル
              </div>
              <div v-if="userSales.registerMonthList && userSales.registerMonthList.length > 0 && userSales.usedMonthType === 'input'" class="form-edit-line">
                <table class="table table-responsive">
                  <tbody>
                    <tr v-for="month in userSales.registerMonthList" :key="month.monthsAgo">
                      <td>{{month.monthsAgo}}ヶ月前</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="userSales.definitionId" class="form-label align-bottom">取込ファイル定義</div>
              <div v-if="userSales.definitionId" class="form-edit-line">
                {{userSales.definitionName}}
              </div>
              <div v-if="userSales.definitionItemList && userSales.definitionItemList.length > 0" class="form-label align-bottom">
                定義詳細
              </div>
              <div v-if="userSales.definitionItemList && userSales.definitionItemList.length > 0" class="form-edit-line">
                <table class="table table-responsive">
                  <thead>
                  <tr>
                    <th style="width: 40px;">#</th>
                    <th>種別</th>
                    <th style="width: 150px;">対象／対象外</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in userSales.definitionItemList" :key="index">
                    <td>{{index + 1}}</td>
                    <td>
                      <span v-if="item.itemType === 'catalog_no'">製品番号</span>
                      <span v-else-if="item.itemType === 'jan_code'">JANコード（POSコード）</span>
                      <span v-else-if="item.itemType === 'user_album_code'">利用者アルバムコード</span>
                      <span v-else-if="item.itemType === 'isrc'">ISRC</span>
                      <span v-else-if="item.itemType === 'master_code'">原盤管理コード</span>
                      <span v-else-if="item.itemType === 'user_master_code'">利用者原盤コード</span>
                      <span v-else-if="item.itemType === 'matching_code'">種類が混在したコード</span>
                      <span v-else-if="item.itemType === 'album_title'">アルバム名</span>
                      <span v-else-if="item.itemType === 'master_name'">原盤名</span>
                      <span v-else-if="item.itemType === 'album_or_master_name'">アルバム名／原盤名</span>
                      <span v-else-if="item.itemType === 'artist_name'">アーティスト名</span>
                      <span v-else-if="item.itemType === 'used_year'">使用年</span>
                      <span v-else-if="item.itemType === 'used_month'">使用月</span>
                      <span v-else-if="item.itemType === 'used_year_month_1'">使用年月（YYYYMM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_2'">使用年月（YYYY/MM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_3'">使用年月（YYYY-MM）</span>
                      <span v-else-if="item.itemType === 'used_year_month_4'">使用年月（YYYY年MM月）</span>
                      <span v-else-if="item.itemType === 'useTypeName'">利用種別</span>
                      <span v-else-if="item.itemType === 'royalty_type_name'">印税種別</span>
                      <span v-else-if="item.itemType === 'used_no'">使用数</span>
                      <span v-else-if="item.itemType === 'price_out_tax'">価格（税抜）</span>
                      <span v-else-if="item.itemType === 'price_in_tax'">価格（税込）</span>
                      <span v-else-if="item.itemType === 'revenue_out_tax'">売上（税抜）</span>
                      <span v-else-if="item.itemType === 'revenue_in_tax'">売上（税込）</span>
                      <span v-else-if="item.itemType === 'unit_price'">分配単価</span>
                      <span v-else-if="item.itemType === 'div_rate'">分配率</span>
                      <span v-else-if="item.itemType === 'income_amount_out_tax'">受領額（税抜）</span>
                      <span v-else-if="item.itemType === 'income_amount_in_tax'">受領額（税込）</span>
                      <span v-else-if="item.itemType === 'tax_rate'">消費税率</span>
                      <span v-else-if="item.itemType === 'memo'">メモ</span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center">
                      <div class="input-group input-group-sm">
                        <span class="input-group-text">
                          <span v-if="item.registerOrThrough === 'register'">特定取込</span>
                          <span v-else>取込除外</span>
                        </span>
                        <button v-if="item.rotStrings && item.rotStrings.length > 0" @click="openRotStrings(index)" class="btn btn-outline-danger btn-sm"><i class="fas fa-cog"></i></button>
                        <button v-else class="btn btn-secondary btn-sm"><i class="fas fa-cog"></i></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="userSales.currencyId" class="form-label align-bottom">通貨</div>
              <div v-if="userSales.currencyId" class="form-edit-line">
                {{userSales.currencyName}}
              </div>
              <div class="form-label align-bottom">消費税</div>
              <div class="form-edit-line">
                <span v-if="userSales.taxation">課税</span>
                <span v-else>非課税</span>
              </div>
              <div v-if="userSales.memo" class="form-label align-bottom">メモ</div>
              <div v-if="userSales.memo" class="form-edit-line">
                {{userSales.memo}}
              </div>
            </div>
            <div class="modal-footer d-flex">
              <div class="me-auto">
                <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="rotModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">特定取込／取込除外文字列</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <textarea v-model="rotStrings" class="form-control" rows="5"></textarea>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeRotStrings" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import footerComponent from '@/components/Footer.vue'
import { userFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    // Form
    const userFunc = userFunction()
    const form = ref(null)
    const rightAreaMode = ref('SALES')
    // 頒布形態
    const userSalesModalDiv = ref(null)
    let userSalesModal = null
    const userSales = ref({})
    const rotModalDiv = ref(null)
    let rotModal = null
    const rotStrings = ref([])
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      form.value = await userFunc.get(id)
      console.log(form.value)
    }
    const openUserSalesModal = (index) => {
      userSales.value = form.value.salesList[index]
      userSalesModal.show()
    }
    const openRotStrings = (index) => {
      const item = userSales.value.definitionItemList[index]
      if (item && item.rotStrings) {
        rotStrings.value = item.rotStrings.join('\n')
      } else {
        rotStrings.value = []
      }
      userSalesModal.hide()
      rotModal.show()
    }
    const closeRotStrings = () => {
      userSalesModal.show()
      rotModal.hide()
    }
    onMounted(async () => {
      userSalesModal = new Modal(userSalesModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rotModal = new Modal(rotModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    onUnmounted(() => {
      userSalesModal.hide()
    })
    return {
      router,
      form,
      rightAreaMode,
      userSalesModalDiv,
      userSales,
      openUserSalesModal,
      openRotStrings,
      rotModalDiv,
      rotStrings,
      closeRotStrings
    }
  }
})
</script>
