<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-5 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/message/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] お知らせ一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="publish(id)" v-if="id && !publishedAt" type="button" class="btn btn-outline-danger" style="margin-right: 5px;"><i class="fas fa-share-square fa-fw" style="margin-right: 5px;"></i>公開</button>
              <router-link v-if="id" :to="'/admin/message/edit/' + id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link to="/admin/message/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">お知らせ日時</div>
          <div class="form-edit-line">
            {{createdAt}}
          </div>
          <div class="form-label align-bottom">公開日時</div>
          <div class="form-edit-line">
            <span v-if="publishedAt">{{publishedAt}}</span>
            <span v-else>未公開</span>
          </div>
          <div class="form-label align-bottom">種別</div>
          <div class="form-edit-line">
            <span v-if="messageType === 'GLOBAL'">停止等の案内</span>
            <span v-if="messageType === 'VERSION'">バージョンアップ</span>
          </div>
          <div class="form-label align-bottom">タイトル</div>
          <div class="form-edit-line">
            {{title}}
          </div>
          <div class="form-label align-bottom">お知らせ内容</div>
          <div class="form-edit-line">
            <span v-html="messageBody.replaceAll('\n', '<br>')" class="text-break"></span>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index"><span v-html="message"></span>
        </p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Modal, Toast } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const route = useRoute()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const messages = ref([])
    const messageToastDiv = ref(null)
    let processModal = null
    let messageToast = null
    // Form
    const id = ref(null)
    const messageType = ref('GLOBAL')
    const title = ref('')
    const messageBody = ref('')
    const createdAt = ref('')
    const publishedAt = ref('')
    // メソッド
    const publish = async (id) => {
      processMessage.value = 'お知らせの公開処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
      processModal.show()
      await axios.put(process.env.VUE_APP_API_ENDPOINT + '/admin/message/' + id + '/publish', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      await init()
      processModal.hide()
      messages.value.push('公開されました')
      messageToast.show()
    }
    const init = async () => {
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/message/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
      })
    }
    function entityToForm (model) {
      messageType.value = model.messageType
      title.value = model.title
      messageBody.value = model.messageBody
      createdAt.value = model.createdAt
      publishedAt.value = model.publishedAt
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      id.value = route.params.id
      await init()
      loadingModal.hide()
    })
    return {
      loadingModalDiv,
      messages,
      processMessage,
      processModalDiv,
      messageToastDiv,
      id,
      messageType,
      title,
      messageBody,
      createdAt,
      publishedAt,
      publish
    }
  }
})
</script>
