<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>rights.servicesのアカウント管理</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      rights.servicesでは、メールアドレス単位でアカウントを管理しており、そのメールアドレスを保持していることが本人証明となります。
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      【パスワードレス認証について】<br>
      本サービスでは、ログイン時にパスワードの入力を求めておりません。その代わりに登録されているメールアドレスにワンタイムコードを送り、メールアドレスの所持者であることを確認しております。<br>
      これにより当社でパスワードを保持する必要がなく、データ漏洩リスクを格段に下げることが可能になっています。認証にメールを受信する必要があり、面倒に感じることもあるかと思いますが、お預かりしているデータの特性上、安全性を優先した結果の処置ですので何卒ご理解賜りますようお願い申し上げます。
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
