import RedTermUpdate from './Update.vue'

export default {
  routes: [
    {
      path: '/red/term/update',
      name: 'RedTermUpdate',
      component: RedTermUpdate
    }
  ]
}
