<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/project/list"><i class="fas fa-project-diagram fa-fw" style="margin-right: 5px;"></i>プロジェクト一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/project/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/common/project/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div v-if="form.kana" class="form-label align-bottom">カナ</div>
              <div v-if="form.kana" class="form-edit-line">
                {{ form.kana }}
              </div>
              <div v-if="form.nameEn" class="form-label align-bottom">英語名</div>
              <div v-if="form.nameEn" class="form-edit-line">
                {{ form.nameEn }}
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <span v-html="form.memo"></span>
              </div>
            </div>
            <div class="col-lg-5">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
                    原盤
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                    アルバム
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'PROFIT'" class="nav-link" :class="{ 'active': rightAreaMode === 'PROFIT' }" aria-current="page" href="#">
                    分析
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'MASTER'">
                <div class="input-group">
                  <input @keypress.enter="masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button v-show="masterCondition.searchString" @click="masterClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <table v-if="masterList" class="table table-responsive">
                  <tbody>
                    <tr v-for="(master, index) in masterList" :key="index" class="align-middle">
                      <td class="text-center" style="width: 55px;">
                        <router-link class="btn btn-outline-primary btn-sm" :to="'/red/master/disp/' + master.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                      </td>
                      <td>
                        <span v-if="master.isrc" style="font-size: 80%;">ISRC : {{master.isrc}}<br></span>
                        {{master.displayName}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                  <div v-if="masterResult && masterResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                    <nav v-if="masterResult" aria-label="Page navigation">
                      <ul class="pagination">
                        <li v-if="masterCondition.page !== 0" class="page-item">
                          <button @click="masterFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                        </li>
                        <li v-if="masterCondition.page !== 0" class="page-item">
                          <button @click="masterPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                        </li>
                        <li class="page-item"><span class="page-link">{{ masterCondition.page + 1 }}/{{ masterResult.allPages }}ページ</span></li>
                        <li v-if="masterCondition.page !== masterResult.allPages - 1" class="page-item">
                          <button @click="masterNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                        </li>
                        <li v-if="masterCondition.page !== masterResult.allPages - 1" class="page-item">
                          <button @click="masterLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div v-if="rightAreaMode === 'ALBUM'">
                <div class="input-group">
                  <input @keypress.enter="albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button v-show="albumCondition.searchString" @click="albumClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="albumCondition.page = 0; findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <table v-if="albumList" class="table table-responsive">
                  <tbody>
                  <tr v-for="(album, index) in albumList" :key="index" class="align-middle">
                    <td class="text-center" style="width: 55px;">
                      <router-link class="btn btn-outline-primary btn-sm" :to="'/red/album/disp/' + album.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                    </td>
                    <td>
                      <p v-if="album.catalog_no" style="margin: 0; font-size: 80%;">{{album.catalog_no}}</p>
                      <router-link :to="'/red/album/disp/' + album.id">{{album.name}}</router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="albumResult && albumResult.allRecords > 0">{{ albumResult.allRecords }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                  <div v-if="albumResult && albumResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                    <nav v-if="albumResult" aria-label="Page navigation">
                      <ul class="pagination">
                        <li v-if="albumCondition.page !== 0" class="page-item"><button @click="albumFirstPage" class="page-link"
                                                                             href="#"><i class="fas fa-fast-backward"></i></button></li>
                        <li v-if="albumCondition.page !== 0" class="page-item"><button @click="albumPrevPage" class="page-link"
                                                                             href="#"><i class="fas fa-chevron-left"></i></button></li>
                        <li class="page-item"><span class="page-link">{{ albumCondition.page + 1 }}/{{ albumResult.allPages }}ページ</span></li>
                        <li v-if="albumCondition.page !== albumResult.allPages - 1" class="page-item"><button
                          @click="albumNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                        </li>
                        <li v-if="albumCondition.page !== albumResult.allPages - 1" class="page-item"><button
                          @click="albumLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div v-if="rightAreaMode === 'PROFIT'">
                <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                  <li class="nav-item">
                    <a @click="profitMode = 'COPYRIGHT_INCOME'" class="nav-link"
                      :class="{ 'active': profitMode === 'COPYRIGHT_INCOME' }" aria-current="page" href="#">
                      著作権収入
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="profitMode = 'ALBUM_INCOME'" class="nav-link"
                      :class="{ 'active': profitMode === 'ALBUM_INCOME' }" aria-current="page" href="#">
                      アルバム収入
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="profitMode = 'MASTER_INCOME'" class="nav-link"
                      :class="{ 'active': profitMode === 'MASTER_INCOME' }" aria-current="page" href="#">
                      原盤収入
                    </a>
                  </li>
                  <li class="nav-item">
                    <a @click="profitMode = 'MASTER_USED_NO'" class="nav-link"
                      :class="{ 'active': profitMode === 'MASTER_USED_NO' }" aria-current="page" href="#">
                      原盤使用数
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { projectFunction } from './function'
import { albumFunction } from '@/views/red/album/function'
import { masterFunction } from '@/views/red/master/function'
import { masterStore, albumStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const projectFind = projectFunction()
    // Form
    const form = ref(null)
    const rightAreaMode = ref('MASTER')
    const profitMode = ref('COPYRIGHT_INCOME')
    // 原盤一覧
    const masterCondition = masterStore()
    const masterFind = masterFunction()
    const masterResult = ref(null)
    const masterList = ref([])
    // アルバム一覧
    const albumCondition = albumStore()
    const albumFind = albumFunction()
    const albumResult = ref(null)
    const albumList = ref([])
    // メソッド
    async function init (id) {
      form.value = {
        id: id
      }
      const promises = []
      promises.push(projectFind.get(id))
      promises.push(findMaster())
      promises.push(findAlbum())
      const response = await Promise.all(promises)
      form.value = response[0]
    }
    const findMaster = async () => {
      const result = await masterFind.findByProject(form.value.id, masterCondition)
      masterResult.value = result
      masterList.value = result.list
    }
    const masterClearSearch = () => {
      masterCondition.searchString = ''
      masterCondition.page = 0
      findMaster()
    }
    const masterFirstPage = () => {
      masterCondition.page = 0
      findMaster()
    }
    const masterPrevPage = () => {
      masterCondition.page = masterCondition.page - 1
      findMaster()
    }
    const masterNextPage = () => {
      masterCondition.page = masterCondition.page + 1
      findMaster()
    }
    const masterLastPage = () => {
      masterCondition.page = masterResult.value.allPages - 1
      findMaster()
    }
    const findAlbum = async () => {
      const result = await albumFind.findByProject(form.value.id, albumCondition)
      albumResult.value = result
      albumList.value = result.list
    }
    const albumClearSearch = () => {
      albumCondition.searchString = ''
      albumCondition.page = 0
      findAlbum()
    }
    const albumFirstPage = () => {
      albumCondition.page = 0
      findAlbum()
    }
    const albumPrevPage = () => {
      albumCondition.page = albumCondition.page - 1
      findAlbum()
    }
    const albumNextPage = () => {
      albumCondition.page = albumCondition.page + 1
      findAlbum()
    }
    const albumLastPage = () => {
      albumCondition.page = albumResult.value.allPages - 1
      findAlbum()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      form,
      rightAreaMode,
      profitMode,
      masterCondition,
      masterResult,
      masterList,
      findMaster,
      masterClearSearch,
      masterFirstPage,
      masterPrevPage,
      masterNextPage,
      masterLastPage,
      albumCondition,
      albumResult,
      albumList,
      findAlbum,
      albumClearSearch,
      albumFirstPage,
      albumPrevPage,
      albumNextPage,
      albumLastPage
    }
  }
})
</script>
