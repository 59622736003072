<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/common/bank/list"><i class="fas fa-building-columns fa-fw" style="margin-right: 5px;"></i> 銀行一覧</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/bank/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/common/bank/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="form-label align-bottom">コード</div>
              <div class="form-edit-line">
                {{form.code}}
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{form.name}}
              </div>
              <div class="form-label align-bottom">カナ</div>
              <div class="form-edit-line">
                {{form.kana}}
              </div>
            </div>
            <div class="col-lg-7">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">
                    支店
                    <button @click="openAddModal" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-plus"></i></button>
                  </a>
                </li>
              </ul>
              <div>
                <div class="input-group">
                  <input @keypress.enter="store.page = 0; findBranch();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button @click="branchClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="store.page = 0; findBranch();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="branchResult && branchResult.allRecords > 0" style="padding-top: 7px;">{{branchResult.allRecords}}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                  <div v-if="branchResult && branchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                    <nav v-if="branchResult" aria-label="Page navigation">
                      <ul class="pagination">
                        <li v-if="store.page !== 0" class="page-item"><button @click="branchFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                        <li v-if="store.page !== 0" class="page-item"><button @click="branchPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                        <li class="page-item"><span class="page-link">{{store.page + 1}}/{{branchResult.allPages}}</span></li>
                        <li v-if="store.page !== branchResult.allPages - 1" class="page-item"><button @click="branchNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                        <li v-if="store.page !== branchResult.allPages - 1" class="page-item"><button @click="branchLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <table class="table table-responsive">
                  <thead>
                  <tr>
                    <th style="width: 55px;"></th>
                    <th style="width: 80px;">コード</th>
                    <th>名前</th>
                    <th>カナ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="branch in branchList" :key="branch.id" class="align-middle">
                    <td class="text-center">
                      <button class="btn btn-outline-primary btn-sm" @click="openEditModal(branch.id)"><i class="fas fa-edit fa-fw"></i></button>
                    </td>
                    <td>{{branch.code}}</td>
                    <td>{{branch.name}}</td>
                    <td>{{branch.kana}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="editModalDiv">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="branchForm && branchForm.id" class="modal-title">支店の変更</h5>
            <h5 v-else class="modal-title">支店の新規登録</h5>
          </div>
          <div v-if="branchForm" class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>コード</div>
            <div class="form-edit-line">
              <input v-model="branchForm.code" type="text" class="form-control" style="width: 60px;" maxlength="3">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="branchForm.name" type="text" class="form-control" style="width: 200px;">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>カナ</div>
            <div class="form-edit-line">
              <input v-model="branchForm.kana" type="text" class="form-control" maxlength="15" style="width: 200px;">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="saveBranch" type="button" class="btn btn-primary"><i class="fas fa-save fa-fw"></i></button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw"></i></button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">支店の削除</h5>
          </div>
          <div v-if="branchForm" class="modal-body text-start">
            <div class="alert alert-danger">以下の支店を削除しようとしています。削除すると、支店情報の確認ができなくなるだけでなく、該当支店が紐づいている権利者の銀行情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{branchForm.name}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="deleteBranch" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import { useRoute } from 'vue-router'
import { Modal, Toast } from 'bootstrap'
import footerComponent from '@/components/Footer.vue'
import { bankFunction, branchFunction } from './function'
import { branchStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const editModalDiv = ref(null)
    let editModal = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    // 検索
    const store = branchStore()
    const branchResult = ref(null)
    const branchList = ref([])
    // Form
    const bankFunc = bankFunction()
    const branchFunc = branchFunction()
    const form = ref(null)
    const branchForm = ref(null)
    const showDeleteButton = ref(false)
    // メソッド
    const init = async (id) => {
      form.value = await bankFunc.get(id)
      await findBranch()
    }
    const findBranch = async () => {
      branchResult.value = await branchFunc.find(form.value.id, store)
      branchList.value = branchResult.value.list
    }
    const branchClearSearch = () => {
      branchFunc.clearSearch(store)
      findBranch()
    }
    const branchFirstPage = () => {
      store.page = 0
      findBranch()
    }
    const branchPrevPage = () => {
      store.page = store.page - 1
      findBranch()
    }
    const branchNextPage = () => {
      store.page = store.page + 1
      findBranch()
    }
    const branchLastPage = () => {
      store.page = branchResult.value.allPages - 1
      findBranch()
    }
    const openAddModal = () => {
      branchForm.value = {
        id: null,
        code: '',
        name: '',
        kana: ''
      }
      editModal.show()
    }
    const openEditModal = async (id) => {
      showDeleteButton.value = false
      branchForm.value = await branchFunc.get(id)
      editModal.show()
    }
    const openDeleteModal = () => {
      editModal.hide()
      deleteModal.show()
    }
    const saveBranch = async () => {
      errors.value = []
      if (!form.value.code) {
        errors.value.push('コードを入力してください')
      }
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!form.value.kana) {
        errors.value.push('カナを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!branchForm.value.id) {
          await branchFunc.insert(form.value.id, branchForm.value)
        } else {
          await branchFunc.update(branchForm.value.id, branchForm.value)
        }
        branchFunc.indexUpdate(branchForm.value.id)
        await findBranch()
        messages.value = ['保存されました']
        messageToast.show()
        editModal.hide()
      }
    }
    const deleteBranch = async () => {
      await branchFunc.remove(branchForm.value.id)
      await findBranch()
      messages.value = ['削除されました']
      messageToast.show()
      deleteModal.hide()
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      editModal = new Modal(editModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      messages,
      messageToastDiv,
      errors,
      errorToastDiv,
      editModalDiv,
      deleteModalDiv,
      form,
      store,
      branchResult,
      branchList,
      branchForm,
      showDeleteButton,
      branchClearSearch,
      branchFirstPage,
      branchPrevPage,
      branchNextPage,
      branchLastPage,
      findBranch,
      openAddModal,
      openEditModal,
      saveBranch,
      openDeleteModal,
      deleteBranch
    }
  }
})
</script>
