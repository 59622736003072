import CommonAccountDisp from './Disp'
import CommonAccountEdit from './Edit'

export default {
  routes: [
    {
      path: '/common/account/disp',
      name: 'CommonAccountDisp',
      component: CommonAccountDisp
    },
    {
      path: '/common/account/edit',
      name: 'CommonAccountEdit',
      component: CommonAccountEdit
    }
  ]
}
