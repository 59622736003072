import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const displayFunction = () => {
  const getContentStyle = () => {
    if (getViewport() >= 3) {
      return 'position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;'
    } else {
      return 'position: fixed; top: 65px; left: 30px; right: 30px; bottom: 50px; overflow: auto;'
    }
  }
  const getViewport = () => {
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (width <= 576) return 1 // xs
    if (width <= 768) return 2 // sm
    if (width <= 992) return 3 // md
    if (width <= 1200) return 4 // lg
    if (width <= 1400) return 5 // xl
    return 6 // xxl
  }
  return { getViewport, getContentStyle }
}

export const accountFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getByDestination = async (destinationId) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + destinationId + '/account', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { get, getByDestination }
}

export const commonCompanyFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const save = async (id, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/company/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  return { get, save }
}

export const redCompanyFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getCurrentDivTerm = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId + '/divterm/current', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getDivTerm = async (companyId, divYear, divTerm) => {
    try {
      const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId + '/divyear/' + divYear + '/divterm/' + divTerm, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      return data
    } catch (e) {
      await ex.identifyEx(e)
      return null
    }
  }
  const getNextDivTerm = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId + '/divterm/next', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getDivTermSetting = async (companyId, divTerm) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId + '/divterm/' + divTerm + '/setting', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findDivTermSetting = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + companyId + '/divterm/setting/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { get, getCurrentDivTerm, getNextDivTerm, getDivTermSetting, findDivTermSetting, getDivTerm }
}

export const holidayFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const isHoliday = async (year, month, day) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/holiday/' + year + '/' + month + '/' + day, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data > 0
  }
  const getCalendarCount = async (year) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/holiday/' + year, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const makeCalendar = async (year) => {
    await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/holiday/' + year, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  return { isHoliday, getCalendarCount, makeCalendar }
}

export const postalFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const getAddress = async (postalCode) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/postal/' + postalCode, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { getAddress }
}

export const taxFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const find = async () => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/tax/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { find }
}

export const bankFunction = (store) => {
  const auth = authFunction()
  const ex = exFunction()
  const getBranchWithBank = async (branchId) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/' + branchId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findBranchWithBank = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/branch/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { findBranchWithBank, getBranchWithBank }
}
