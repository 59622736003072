<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>契約
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/common/contract/edit'" class="btn btn-outline-primary"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line fs-5" style="font-weight: bold;">
              {{ form.name }}
            </div>
            <div class="form-label align-bottom">使用システム</div>
            <div class="form-edit-line">
              <span v-if="form.blue === 'YES'" class="badge text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</span>
              <span v-if="form.red === 'YES'" class="badge text-white" style="background-color: #E83821; margin-right: 5px;">RED</span>
              <span v-if="form.pink === 'YES'" class="badge text-white" style="background-color: #EE86A8; margin-right: 5px;">PINK</span>
              <span v-if="form.green === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">GREEN</span>
            </div>
            <div class="form-label align-bottom">ユーザー</div>
            <div class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(user, index) in userList" :key="index">
                    <td>{{ user.name }}</td>
                    <td>
                      <span style="font-size: 90%;">{{user.email}}</span>
                    </td>
                    <td style="width: 130px;" class="text-end">
                      <span v-if="user.hasPicRole === 'YES'" class="badge bg-warning"><i class="fas fa-crown" style="margin-right: 5px;"></i>責任者</span>
                      <span v-else class="badge bg-secondary">一般</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="form.red === 'YES'" class="card" style="margin-bottom: 10px;">
              <div class="card-header text-white" style="background-color: #E83821;">RED設定</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/red" target="_blank">こちら</a></div>
                <div class="form-edit-line">
                  {{ form.planRed }}
                </div>
                <div class="form-label align-bottom">
                  会社
                </div>
                <div class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in companyList" :key="index">
                      <td>{{ company.nameDp }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="form.green === 'YES'" class="card" style="margin-bottom: 10px;">
              <div class="card-header text-white" style="background-color: #8EC31F;">GREEN設定</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">プラン ※プラン詳細は<a href="https://www.rights.tokyo/service/green" target="_blank">こちら</a></div>
                <div class="form-edit-line">
                  {{ form.planGreen }}
                </div>
                <div class="form-label align-bottom">問い合わせ用メールアドレス</div>
                <div class="form-edit-line">
                  {{form.greenQaEmail}}
                </div>
                <div class="form-label align-bottom">連携システム</div>
                <div class="form-edit-line">
                  <span v-if="form.greenRed === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">RED</span>
                  <span v-if="form.greenCapo === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">capo</span>
                  <span v-if="form.greenJrca === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">JRCA</span>
                  <span v-if="form.greenContano === 'YES'" class="badge text-white" style="background-color: #8EC31F; margin-right: 5px;">contano</span>
                </div>
                <div v-if="form.greenCapo === 'YES'" class="form-label align-bottom">capo会社</div>
                <div v-if="form.greenCapo === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in capoCompanyList" :key="index">
                      <td>{{ company.name }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="form.greenJrca === 'YES'" class="form-label align-bottom">JRCA会社</div>
                <div v-if="form.greenJrca === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in jrcaCompanyList" :key="index">
                      <td>{{ company.name }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="form.greenContano === 'YES'" class="form-label align-bottom">contano会社</div>
                <div v-if="form.greenContano === 'YES'" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                    <tr v-for="(company, index) in contanoCompanyList" :key="index"
                        :class="{ 'deleted-row': company.deleted }">
                      <td>{{ company.name }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-label align-bottom">PDFダウンロード</div>
                <div class="form-edit-line">
                  <span v-if="form.downloadablePdf">受領者によるPDFダウンロードを許可する</span>
                  <span v-else>受領者によるPDFダウンロードを許可しない</span>
                </div>
                <div class="form-label align-bottom">テキストダウンロード</div>
                <div class="form-edit-line">
                  <span v-if="form.downloadableText">受領者によるテキストダウンロードを許可する</span>
                  <span v-else>受領者によるテキストダウンロードを許可しない</span>
                </div>
                <div class="form-label align-bottom">
                  権利者情報収集挨拶
                </div>
                <div class="form-edit-line">
                  <p v-for="(greeting, index) in collectGreetingList" :key="greeting.id" style="margin: 0 0 2px 0;">
                    <button @click="openGreetingModal('collect', index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    {{greeting.title}}
                  </p>
                </div>
                <div class="form-label align-bottom">
                  事業者登録番号収集挨拶
                </div>
                <div class="form-edit-line">
                  <p v-for="(greeting, index) in invoiceGreetingList" :key="greeting.id" style="margin: 0 0 2px 0;">
                    <button @click="openGreetingModal('invoice', index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                    {{greeting.title}}
                  </p>
                </div>
              </div>
            </div>
<!--            <ul class="nav nav-tabs" style="margin-bottom: 10px;">-->
<!--              <li v-if="form.blue === 'YES'" class="nav-item">-->
<!--                <a @click="detailMode = 'BLUE'" class="nav-link" :class="{ 'active': detailMode === 'BLUE' }" href="#">-->
<!--                  <i class="fas fa-square" style="margin-right: 5px; color: #0097E0;"></i>BLUE-->
<!--                </a>-->
<!--              </li>-->
<!--              <li v-if="form.pink === 'YES'" class="nav-item">-->
<!--                <a @click="detailMode = 'PINK'" class="nav-link" :class="{ active: detailMode === 'PINK' }" href="#">-->
<!--                  <i class="fas fa-square" style="margin-right: 5px; color: #EE86A8;"></i>PINK-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="greetingModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">
              用途
            </div>
            <div class="form-edit-line">
              {{greetingTitle}}
            </div>
            <div class="form-label align-bottom">
              挨拶文
            </div>
            <pre style="white-space: pre-wrap; word-break: break-all;">{{greeting}}</pre>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { contractFindFunction } from './function'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const contractFind = contractFindFunction()
    const route = useRoute()
    const router = useRouter()
    const greetingModalDiv = ref(null)
    let greetingModal = null
    // Form
    const form = ref(null)
    const userList = ref([])
    const companyList = ref([])
    const collectGreetingList = ref([])
    const invoiceGreetingList = ref([])
    const detailMode = ref('BLUE')
    const greeting = ref('')
    const greetingTitle = ref('')
    // GREEN会社
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    // メソッド
    const init = async (id) => {
      form.value = {
        id: id
      }
      let promises = []
      promises.push(contractFind.get())
      promises.push(contractFind.findUser())
      promises.push(contractFind.findCompany())
      promises.push(contractFind.findCollectGreeting())
      promises.push(contractFind.findInvoiceGreeting())
      let response = await Promise.all(promises)
      form.value = response[0]
      userList.value = response[1]
      companyList.value = response[2]
      collectGreetingList.value = response[3]
      invoiceGreetingList.value = response[4]
      if (form.value.blue === 'YES') {
        detailMode.value = 'BLUE'
      } else if (form.value.red === 'YES') {
        detailMode.value = 'RED'
      } else if (form.value.pink === 'YES') {
        detailMode.value = 'PINK'
      } else if (form.value.green === 'YES') {
        detailMode.value = 'GREEN'
      }
      promises = []
      if (form.value.green === 'YES') {
        promises.push(contractFind.findGreenCompany('capo'))
        promises.push(contractFind.findGreenCompany('jrca'))
        promises.push(contractFind.findGreenCompany('contano'))
        response = await Promise.all(promises)
        capoCompanyList.value = response[0]
        jrcaCompanyList.value = response[1]
        contanoCompanyList.value = response[2]
      }
    }
    const openGreetingModal = (target, index) => {
      if (target === 'collect') {
        console.log(collectGreetingList.value[index])
        greetingTitle.value = collectGreetingList.value[index].title
        greeting.value = collectGreetingList.value[index].greeting
      }
      if (target === 'invoice') {
        greetingTitle.value = invoiceGreetingList.value[index].title
        greeting.value = invoiceGreetingList.value[index].greeting
      }
      greetingModal.show()
    }
    onMounted(async () => {
      greetingModal = new Modal(greetingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init()
    })
    return {
      session,
      route,
      router,
      form,
      userList,
      companyList,
      collectGreetingList,
      invoiceGreetingList,
      detailMode,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      greetingModalDiv,
      greeting,
      greetingTitle,
      openGreetingModal
    }
  }
})
</script>
