import RedMasterDisp from './Disp'
import RedMasterEdit from './Edit'
import RedMasterList from './List'

export default {
  routes: [
    {
      path: '/red/master/list',
      name: 'RedMasterList',
      component: RedMasterList
    },
    {
      path: '/red/master/add',
      name: 'RedMasterAdd',
      component: RedMasterEdit
    },
    {
      path: '/red/master/edit/:id',
      name: 'RedMasterEdit',
      component: RedMasterEdit
    },
    {
      path: '/red/master/disp/:id',
      name: 'RedMasterDisp',
      component: RedMasterDisp
    },
    {
      path: '/red/master/disp/:id/unmatched',
      name: 'RedMasterDispUnmatched',
      component: RedMasterDisp
    },
    {
      path: '/red/result/master/disp/:id/:resultId',
      name: 'RedMasterDispResult',
      component: RedMasterDisp
    }
  ]
}
