<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 17px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-address-card fa-fw" style="margin-right: 5px;"></i> 登録依頼一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
<!--              <button @click="openDataModal" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file fa-fw" style="margin-right: 5px;"></i>データ</button>-->
<!--              <router-link v-if="system !== 'external'" :to="'/green/print/' + system + '/ALL'" class="btn btn-outline-primary" style="margin-right: 10px;"><i class="fas fa-print" style="margin-right: 5px;"></i>明細送付先登録用紙全印刷</router-link>-->
<!--              <router-link v-if="system !== 'external'" to="/green/rightsholder/internal/print/ALL" class="btn btn-outline-primary" style="margin-right: 10px;"><i class="fas fa-print" style="margin-right: 5px;"></i>IV情報登録用紙全印刷</router-link>-->
<!--              <router-link v-if="system === 'external'" to="/green/rightsholder/external/print/ALL" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-print" style="margin-right: 5px;"></i>IV情報登録用紙全印刷</router-link>-->
<!--              <button @click="downloadIvEx" class="btn btn-outline-primary" style="margin-right: 10px;"><i class="fas fa-file-excel" style="margin-right: 5px;"></i>IV情報登録用紙全DL(Excel)</button>-->
<!--              <router-link v-if="store.system === 'external'" to="/green/collect/external/upload" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-upload" style="margin-right: 5px;"></i>一括登録</router-link>-->
              <button v-if="store.system === 'external'" @click="download" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-download" style="margin-right: 5px;"></i>ダウンロード</button>
              <router-link v-if="store.system === 'external'" to="/green/rightsholder/external/edit/NEW" class="btn btn-outline-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>新規登録</router-link>
<!--              <button @click="download" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-download" style="margin-right: 5px;"></i>事業者登録番号DL</button>-->
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <span class="input-group-text">対象</span>
            <select @change="store.page = 0; loadCompanyList();" v-model="store.system" class="form-select" style="flex: 0 1 120px;" :disabled="store.detailSearchMode">
              <option v-if="session.session && session.session.greenCapo" value="capo">capo</option>
              <option v-if="session.session && session.session.greenJrca" value="jrca">JRCA</option>
              <option v-if="session.session && session.session.greenContano" value="contano">contano</option>
              <option v-if="session.session && session.session.red" value="red">RED</option>
              <option v-if="session.session" value="external">仮権利者</option>
            </select>
            <span v-if="store.system !== 'external'" class="input-group-text">会社</span>
            <select v-model="store.capoCompanyIndex" v-if="store.system === 'capo'" @change="changeCompany(); store.page = 0; find()" class="form-select" :disabled="store.detailSearchMode">
              <option v-for="(company, index) in capoCompanyList" :key="index" :value="index">{{company.name}}</option>
            </select>
            <select v-model="store.jrcaCompanyIndex" v-if="store.system === 'jrca'" @change="changeCompany(); store.page = 0; find()" class="form-select" :disabled="store.detailSearchMode">
              <option v-for="(company, index) in jrcaCompanyList" :key="index" :value="index">{{company.name}}</option>
            </select>
            <select v-model="store.contanoCompanyIndex" v-if="store.system === 'contano'" @change="changeCompany(); store.page = 0; find()" class="form-select" :disabled="store.detailSearchMode">
              <option v-for="(company, index) in contanoCompanyList" :key="index" :value="index">{{company.name}}</option>
            </select>
            <select v-model="store.redCompanyIndex" v-if="store.system === 'red'" @change="changeCompany(); store.page = 0; find()" class="form-select" :disabled="store.detailSearchMode">
              <option v-for="(company, index) in redCompanyList" :key="index" :value="index">{{company.name}}</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア" :disabled="store.detailSearchMode"><i class="fas fa-undo"></i></button>
            <button @click="store.detailSearchMode = true; find();" class="btn btn-outline-primary" :disabled="store.detailSearchMode"><i class="fas fa-filter-list"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary" :disabled="store.detailSearchMode"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 80px; overflow: auto;">
          <div v-if="store.detailSearchMode" class="alert border-dark bg-white text-start" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="store.detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">対象システム</div>
                <div class="form-edit-line">
                  <div v-if="session.session && session.session.greenCapo" class="form-check form-check-inline">
                    <input v-model="store.system" @change="loadCompanyList" class="form-check-input" type="radio" name="systemOptions" id="systemCapo" value="capo">
                    <label class="form-check-label" for="systemCapo">capo</label>
                  </div>
                  <div v-if="session.session && session.session.greenJrca" class="form-check form-check-inline">
                    <input v-model="store.system" @change="loadCompanyList" class="form-check-input" type="radio" name="systemOptions" id="systemJrca" value="jrca">
                    <label class="form-check-label" for="systemJrca">JRCA</label>
                  </div>
                  <div v-if="session.session && session.session.greenContano" class="form-check form-check-inline">
                    <input v-model="store.system" @change="loadCompanyList" class="form-check-input" type="radio" name="systemOptions" id="systemContano" value="contano">
                    <label class="form-check-label" for="systemContano">contano</label>
                  </div>
                  <div v-if="session.session && session.session.red" class="form-check form-check-inline">
                    <input v-model="store.system" @change="loadCompanyList" class="form-check-input" type="radio" name="systemOptions" id="systemRed" value="red">
                    <label class="form-check-label" for="systemRed">RED</label>
                  </div>
                  <div v-if="session.session" class="form-check form-check-inline">
                    <input v-model="store.system" @change="loadCompanyList" class="form-check-input" type="radio" name="systemOptions" id="systemExternal" value="external">
                    <label class="form-check-label" for="systemExternal">仮権利者</label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="store.system !== 'external'" class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">会社</div>
                <div class="form-edit-line">
                  <select v-model="store.capoCompanyIndex" v-if="store.system === 'capo'" @change="store.page = 0; find()" class="form-select">
                    <option v-for="(company, index) in capoCompanyList" :key="index" :value="index">{{company.name}}</option>
                  </select>
                  <select v-model="store.jrcaCompanyIndex" v-if="store.system === 'jrca'" @change="store.page = 0; find()" class="form-select">
                    <option v-for="(company, index) in jrcaCompanyList" :key="index" :value="index">{{company.name}}</option>
                  </select>
                  <select v-model="store.contanoCompanyIndex" v-if="store.system === 'contano'" @change="store.page = 0; find()" class="form-select">
                    <option v-for="(company, index) in contanoCompanyList" :key="index" :value="index">{{company.name}}</option>
                  </select>
                  <select v-model="store.redCompanyIndex" v-if="store.system === 'red'" @change="store.page = 0; find()" class="form-select">
                    <option v-for="(company, index) in redCompanyList" :key="index" :value="index">{{company.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">送付区分</div>
                <div class="form-edit-line">
                  <select v-model="store.detailCondition.useOnlineStatement" @change="store.page = 0; find()" class="form-select">
                    <option value="all">全て</option>
                    <option value="true">オンライン</option>
                    <option value="false">オンライン以外</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="store.system !== 'external'" class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">送付先作成状況</div>
                <div class="form-edit-line">
                  <select v-model="store.detailCondition.destinationNotCreated" @change="store.page = 0; find()" class="form-select">
                    <option value="false">全て</option>
                    <option value="true">送付先未作成権利者のみ</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">事業者登録番号に関するアンケート回答状況</div>
                <div class="form-edit-line">
                  <select v-model="store.detailCondition.registered" @change="store.page = 0; find();" class="form-select">
                    <option value="all">全て</option>
                    <option value="false">未回答</option>
                    <option value="true">回答済</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="store.system !== 'external'" class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">権利者名（カナ含む）</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <input @keypress.enter="store.page = 0; find();" v-model="store.detailCondition.searchString" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">並び順</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <select v-model="store.detailCondition.sortColumn" @change="store.page = 0; find();" class="form-select">
                      <option value="name">名前</option>
                      <option value="kana">カナ</option>
                      <option value="code">コード</option>
                    </select>
                    <select v-model="store.detailCondition.sortOrder" @change="store.page = 0; find();" class="form-select">
                      <option value="asc">昇順（A → Z）</option>
                      <option value="desc">降順（Z → A）</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="store.system === 'external'" class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">事業者登録番号に関するアンケート回答状況</div>
                <div class="form-edit-line">
                  <select v-model="store.detailCondition.registered" @change="store.page = 0; find();" class="form-select">
                    <option value="all">全て</option>
                    <option value="false">未回答</option>
                    <option value="true">回答済</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-label align-bottom">権利者名（カナ含む）</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <input @keypress.enter="store.page = 0; find();" v-model="store.detailCondition.searchString" type="text" class="form-control">
                    <button v-show="store.detailCondition.searchString" @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="store.system === 'external'" class="row">
              <div class="col-md-6">
                <div class="form-label align-bottom">並び順</div>
                <div class="form-edit-line">
                  <div class="input-group">
                    <select v-model="store.detailCondition.sortColumnE" @change="store.page = 0; find();" class="form-select">
                      <option value="name">名前</option>
                      <option value="externalId">外部システム連携用ID</option>
                    </select>
                    <select v-model="store.detailCondition.sortOrderE" @change="store.page = 0; find();" class="form-select">
                      <option value="asc">昇順（A → Z）</option>
                      <option value="desc">降順（Z → A）</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-end" style="margin-top: 10px;">
              <button @click="openCollectModal" v-if="store.detailCondition.destinationNotCreated === true || store.detailCondition.destinationNotCreated === 'true'" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>明細用一括</button>
              <button @click="store.page = 0; find();" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
              <button @click="clearSearchDetail" class="btn btn-outline-success" title="クリア"><i class="fas fa-undo" style="margin-right: 5px;"></i>クリア</button>
            </div>
          </div>
          <table v-if="rightholderResult && rightholderResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <td colspan="6" class="text-start" style="font-size: 90%;">
                  ※明細 : 明細送付登録、IV : インボイス情報登録
                </td>
              </tr>
              <tr>
                <th style="width: 55px;"></th>
                <th v-if="store.system !== 'external'" style="width: 65px;">明細</th>
                <th style="width: 65px;">IV</th>
<!--                <th style="width: 50px;">MN</th>-->
                <th>名前</th>
                <th v-if="store.system !== 'external'" style="width: 130px;">送付区分</th>
                <th v-if="store.system !== 'external'">登録済送付先</th>
              </tr>
            </thead>
            <tbody v-if="rightholderResult">
              <tr v-for="rightholder in rightholderList" :key="rightholder.id" class="text-start align-middle">
                <td v-if="store.system === 'red'">
                  <router-link :to="'/green/rightsholder/disp/' + store.system + '/' + rightholder.seq + '/' + linkCompanyId" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td v-else-if="store.system === 'external'">
                  <router-link :to="'/green/rightsholder/external/disp/' + rightholder.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td v-else>
                  <router-link :to="'/green/rightsholder/disp/' + store.system + '/' + rightholder.id + '/' + linkCompanyId" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td v-if="store.system !== 'external'" class="text-center">
                  <span v-if="rightholder.destinationList && rightholder.destinationList.length === 0">未登録</span>
                  <span v-else>登録済</span>
                </td>
                <td v-if="store.system !== 'external'" class="text-center">
                  <span v-if="!rightholder.invoice">未回答</span>
                  <span v-else>回答済</span>
                  <p v-if="rightholder.taxationType === 'taxation'" class="badge bg-success">課税</p>
                  <p v-if="rightholder.taxationType === 'exemption'" class="badge bg-secondary">免税</p>
                </td>
                <td v-else class="text-center">
                  <span v-if="!rightholder.invoiceAnswered">未回答</span>
                  <span v-else>回答済</span>
                  <p v-if="rightholder.taxationType === 'taxation'" class="badge bg-success">課税</p>
                  <p v-if="rightholder.taxationType === 'exemption'" class="badge bg-secondary">免税</p>
                </td>
<!--                <td class="text-center"></td>-->
                <td v-if="store.system === 'red'">
                  <span v-if="rightholder.code" style="font-size: 80%;">[{{rightholder.code}}]<br></span>
                  {{rightholder.name}}
                </td>
                <td v-else>
                  <span v-if="rightholder.code" style="font-size: 80%;">[{{rightholder.code}}]<br></span>
                  {{rightholder.name}}
                  <span v-if="rightholder.penName" style="font-size: 80%;"><br>(筆名 : {{rightholder.penName}})</span>
                </td>
                <td v-if="store.system !== 'external'" class="text-center">
                  <span v-if="store.system === 'capo' && rightholder.statementSendKbn === '5'" class="badge bg-info">オンライン</span>
                  <span v-if="store.system === 'red' && rightholder.statementSendType === 'online'" class="badge bg-info">オンライン</span>
                  <span v-if="store.system === 'red' && rightholder.statementSendType === 'paper'" class="badge bg-info">郵送</span>
                </td>
                <td v-if="store.system !== 'external' && store.system !== 'red'">
                  <p v-for="destination in rightholder.destinationList" :key="destination.id" style="margin: 0;">
                    <router-link :to="'/green/destination/disp/' + destination.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                    {{destination.name}}
                  </p>
                </td>
                <td v-else-if="store.system === 'red'">
                  <p v-for="destination in rightholder.destinationList" :key="destination.destinationId" style="margin: 0;">
                    <router-link :to="'/green/destination/disp/' + destination.destinationId" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                    {{destination.name}}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 10px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="rightholderResult && rightholderResult.allRecords > 0">{{rightholderResult.allRecords}}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="rightholderResult && rightholderResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="rightholderResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{store.page + 1}}/{{rightholderResult.allPages}}ページ</span></li>
                <li v-if="store.page !== rightholderResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== rightholderResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="collectModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">データダウンロード</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-warning">まとめてダウンロードする場合は、挨拶文は「標準」に設定されているものが使用されます。個別に設定したり、標準以外のテンプレートを使用したい場合には個別にダウンロードしてください。</div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>ファイル単位</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="collectForm.makeZip" class="form-check-input" type="radio" name="makeZip" id="makeZipFalse" value="false">
                <label class="form-check-label" for="makeZipFalse">1つのファイルに全権利者</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="collectForm.makeZip" class="form-check-input" type="radio" name="makeZip" id="makeZipTrue" value="true">
                <label class="form-check-label" for="makeZipTrue">権利者毎にファイルを作成し、ZIPファイルでダウンロード</label>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="downloadCollectPdf" class="btn btn-primary"><i class="fas fa-file-download" style="margin-right: 5px;"></i>ダウンロード開始</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'
import { rightsholderStore } from './store'
import { contractFindFunction } from '@/views/common/contract/function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const processModalDiv = ref(null)
    let processModal = null
    const processMessage = ref('')
    const collectModalDiv = ref(null)
    let collectModal = null
    const store = rightsholderStore()
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    const redCompanyList = ref([])
    const rightholderResult = ref(null)
    const rightholderList = ref([])
    const dataType = ref('statement')
    const contractFunc = contractFindFunction()
    const linkCompanyId = ref('')
    const collectForm = ref({
      makeZip: false
    })
    // メソッド
    const find = () => {
      if (store.system !== 'external') {
        if (store.system === 'red') {
          const companyId = redCompanyList.value[store.redCompanyIndex].id
          if (store.detailSearchMode) {
            const params = {
              companyId: companyId,
              page: store.page,
              perPage: 20,
              sortColumn: store.detailCondition.sortColumn,
              sortOrder: store.detailCondition.sortOrder
            }
            if (store.detailCondition.useOnlineStatement !== 'all') {
              if (store.detailCondition.useOnlineStatement === 'true') {
                params.statementSendType = 'online'
              } else {
                params.statementSendType = 'paper'
              }
            }
            if (store.detailCondition.destinationNotCreated === true || store.detailCondition.destinationNotCreated === 'true') {
              params.destinationNotCreated = true
            }
            if (store.detailCondition.registered !== 'all') {
              params.registered = store.detailCondition.registered
            }
            if (store.detailCondition.searchString) {
              params.searchString = store.detailCondition.searchString
            }
            axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list/detail/green', {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
              params: params
            }).then(response => {
              rightholderResult.value = response.data
              rightholderList.value = rightholderResult.value.list
              for (const rightsHolder of rightholderList.value) {
                rightsHolder.destinationList = []
                axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/' + rightsHolder.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  rightsHolder.destinationList = response.data
                })
                axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + store.system + '/' + rightsHolder.seq, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  if (response.data.accountId) {
                    axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + response.data.accountId + '/invoice', {
                      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                    }).then(response => {
                      if (response.status !== 204) {
                        rightsHolder.invoice = response.data
                      }
                    })
                    rightsHolder.accountId = response.data.accountId
                  }
                })
              }
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }).catch(error => {
              ex.identifyEx(error)
            })
          } else {
            const params = {
              companyId: companyId,
              searchString: '',
              page: store.page,
              perPage: 20,
              sortColumn: 'name',
              sortOrder: 'asc'
            }
            axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list', {
              headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
              params: params
            }).then(response => {
              rightholderResult.value = response.data
              rightholderList.value = rightholderResult.value.list
              for (const rightsHolder of rightholderList.value) {
                rightsHolder.destinationList = []
                axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/red/' + rightsHolder.id, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  rightsHolder.destinationList = response.data
                })
                axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + store.system + '/' + rightsHolder.seq, {
                  headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                }).then(response => {
                  if (response.data.accountId) {
                    axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + response.data.accountId + '/invoice', {
                      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                    }).then(response => {
                      if (response.status !== 204) {
                        rightsHolder.invoice = response.data
                      }
                    })
                    rightsHolder.accountId = response.data.accountId
                  }
                })
              }
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }).catch(error => {
              ex.identifyEx(error)
            })
          }
        } else {
          let companyId
          switch (store.system) {
            case 'capo':
              companyId = capoCompanyList.value[store.capoCompanyIndex].id
              break
            case 'jrca':
              companyId = jrcaCompanyList.value[store.jrcaCompanyIndex].id
              break
            case 'contano':
              companyId = contanoCompanyList.value[store.contanoCompanyIndex].id
              break
            case 'red':
              companyId = redCompanyList.value[store.redCompanyIndex].id
              break
            default:
              break
          }
          if (!companyId) {
            rightholderResult.value = null
            rightholderList.value = []
            return
          }
          const params = {
            companyId: companyId,
            searchString: '',
            sortColumn: store.detailCondition.sortColumn,
            sortOrder: store.detailCondition.sortOrder,
            page: store.page,
            perPage: 20
          }
          if (store.detailSearchMode) {
            if (store.detailCondition.useOnlineStatement !== 'all') {
              params.useOnlineStatement = store.detailCondition.useOnlineStatement
            }
            if (store.detailCondition.destinationNotCreated === true || store.detailCondition.destinationNotCreated === 'true') {
              params.destinationNotCreated = true
            }
            if (store.detailCondition.registered !== 'all') {
              params.invoiceCreated = store.detailCondition.registered
            }
            if (store.detailCondition.searchString) {
              params.searchString = store.detailCondition.searchString
            }
          }
          axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + store.system + '/rightholder/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: params
          }).then(response => {
            rightholderResult.value = response.data
            rightholderList.value = rightholderResult.value.list
            for (const rightholder of rightholderList.value) {
              axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + store.system + '/' + companyId + '/' + rightholder.id, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                rightholder.destinationList = response.data
              }).catch(error => {
                ex.identifyEx(error)
              })
              axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + store.system + '/' + rightholder.id, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                if (response.data.accountId) {
                  axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + response.data.accountId + '/invoice', {
                    headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
                  }).then(response => {
                    if (response.status !== 204) {
                      rightholder.invoice = response.data
                    }
                  })
                  rightholder.accountId = response.data.accountId
                }
              })
            }
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }).catch(error => {
            ex.identifyEx(error)
          })
        }
      } else {
        rightholderResult.value = null
        const params = {
          searchString: '',
          sortColumn: 'name',
          sortOrder: 'asc',
          page: store.page,
          perPage: 20
        }
        if (store.detailSearchMode) {
          if (store.detailCondition.registered !== 'all') {
            params.registered = store.detailCondition.registered
          }
          if (store.detailCondition.searchString) {
            params.searchString = store.detailCondition.searchString
          }
        }
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: params
        }).then(response => {
          rightholderResult.value = response.data
          rightholderList.value = rightholderResult.value.list
          for (const rightholder of rightholderList.value) {
            if (rightholder.accountId) {
              axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + rightholder.accountId + '/invoice', {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                rightholder.invoiceAnswered = response.status === 200
                rightholder.taxationType = response.data.taxationType
              })
              rightholder.accountId = response.data.accountId
            }
          }
        })
      }
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = rightholderResult.value.allPages - 1
      find()
    }
    const loadCompanyList = async () => {
      capoCompanyList.value = []
      jrcaCompanyList.value = []
      contanoCompanyList.value = []
      redCompanyList.value = []
      if (store.system !== 'external') {
        if (store.system === 'red') {
          await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(response => {
            redCompanyList.value = response.data
            for (const company of redCompanyList.value) {
              axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/company/' + company.id, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                company.name = response.data.name
              })
            }
            if (redCompanyList.value.length > 0 && store.redCompanyIndex === -1) {
              store.redCompanyIndex = 0
            }
          })
        } else {
          await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/contract/' + store.system + '/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).then(async response => {
            for (const company of response.data) {
              await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + store.system + '/company/' + company.companyId, {
                headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
              }).then(response => {
                const obj = {
                  id: response.data.id,
                  name: response.data.name
                }
                switch (store.system) {
                  case 'capo':
                    if (store.capoCompanyIndex === -1) {
                      store.capoCompanyIndex = 0
                    }
                    capoCompanyList.value.push(obj)
                    break
                  case 'jrca':
                    if (store.jrcaCompanyIndex === -1) {
                      store.jrcaCompanyIndex = 0
                    }
                    jrcaCompanyList.value.push(obj)
                    break
                  case 'contano':
                    if (store.contanoCompanyIndex === -1) {
                      store.contanoCompanyIndex = 0
                    }
                    contanoCompanyList.value.push(obj)
                    break
                  default:
                    break
                }
              })
            }
          }).catch(error => {
            ex.identifyEx(error)
          })
        }
      }
      changeCompany()
      find()
    }
    const download = () => {
      if (store.system !== 'external') {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/download', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            searchString: store.searchString,
            sortColumn: 'name',
            sortOrder: 'asc'
          }
        }).then(response => {
          const a = document.createElement('a')
          a.href = response.data
          a.click()
        })
      } else {
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/download', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            searchString: store.searchString,
            sortColumn: 'name',
            sortOrder: 'asc'
          }
        }).then(response => {
          const a = document.createElement('a')
          a.href = response.data
          a.click()
        })
      }
    }
    const openCollectModal = () => {
      collectModal.show()
    }
    const clearSearchDetail = () => {
      store.detailCondition = {
        useOnlineStatement: 'all',
        destinationNotCreated: false,
        registered: 'all',
        searchString: '',
        sortColumn: 'name',
        sortOrder: 'asc',
        sortColumnE: 'name',
        sortOrderE: 'asc'
      }
      find()
    }
    const downloadStart = async () => {
      processMessage.value = 'Excelファイルの作成中です。しばらくお待ちください。'
      processModal.show()

      processModal.hide()
    }
    const downloadCollectPdf = async () => {
      processMessage.value = 'PDFファイルの作成中です。しばらくお待ちください。'
      processModal.show()
      collectModal.hide()
      let list = []
      let companyId
      let companyName
      switch (store.system) {
        case 'capo':
          companyId = capoCompanyList.value[store.capoCompanyIndex].id
          companyName = capoCompanyList.value[store.capoCompanyIndex].name
          break
        case 'jrca':
          companyId = jrcaCompanyList.value[store.jrcaCompanyIndex].id
          companyName = jrcaCompanyList.value[store.jrcaCompanyIndex].name
          break
        case 'contano':
          companyId = contanoCompanyList.value[store.contanoCompanyIndex].id
          companyName = contanoCompanyList.value[store.contanoCompanyIndex].name
          break
        case 'red':
          companyId = redCompanyList.value[store.redCompanyIndex].id
          companyName = redCompanyList.value[store.redCompanyIndex].name
          break
        default:
          break
      }
      if (store.system !== 'external') {
        if (store.system === 'red') {
          const params = {
            companyId: companyId,
            page: 0,
            perPage: 99999,
            sortColumn: store.detailCondition.sortColumn,
            sortOrder: store.detailCondition.sortOrder
          }
          if (store.detailCondition.useOnlineStatement !== 'all') {
            if (store.detailCondition.useOnlineStatement === 'true') {
              params.statementSendType = 'online'
            } else {
              params.statementSendType = 'paper'
            }
          }
          if (store.detailCondition.destinationNotCreated === true || store.detailCondition.destinationNotCreated === 'true') {
            params.destinationNotCreated = true
          }
          if (store.detailCondition.registered !== 'all') {
            params.registered = store.detailCondition.registered
          }
          if (store.detailCondition.searchString) {
            params.searchString = store.detailCondition.searchString
          }
          await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/rightsholder/list/detail/green', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: params
          }).then(response => {
            list = response.data.list
          }).catch(error => {
            ex.identifyEx(error)
          })
        } else {
          if (!companyId) {
            rightholderResult.value = null
            rightholderList.value = []
            return
          }
          const params = {
            companyId: companyId,
            searchString: '',
            sortColumn: store.detailCondition.sortColumn,
            sortOrder: store.detailCondition.sortOrder,
            page: 0,
            perPage: 99999
          }
          if (store.detailCondition.useOnlineStatement !== 'all') {
            params.useOnlineStatement = store.detailCondition.useOnlineStatement
          }
          if (store.detailCondition.destinationNotCreated === true || store.detailCondition.destinationNotCreated === 'true') {
            params.destinationNotCreated = true
          }
          if (store.detailCondition.registered !== 'all') {
            params.invoiceCreated = store.detailCondition.registered
          }
          if (store.detailCondition.searchString) {
            params.searchString = store.detailCondition.searchString
          }
          const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + store.system + '/rightholder/list', {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: params
          })
          list = data.list
        }
      } else {
        rightholderResult.value = null
        const params = {
          searchString: '',
          sortColumn: 'name',
          sortOrder: 'asc',
          page: 0,
          perPage: 99999
        }
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/list', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: params
        }).then(response => {
          list = response.data.list
        })
      }
      const collectGreetingList = await contractFunc.findCollectGreeting()
      let collectGreeting = ''
      if (collectGreetingList.length > 0) {
        collectGreeting = collectGreetingList[0].greeting
      }
      const blobList = []
      for (const rh of list) {
        const collectDestinationName = rh.name + ' 様'
        let id = rh.id
        if (store.system === 'red') {
          id = rh.seq
        }
        try {
          const { data } = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + store.system + '/' + id + '/pdf/blob', {
            companyName: companyName,
            destinationName: collectDestinationName,
            greeting: collectGreeting
          }, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
          blobList.push(data)
        } catch (e) {}
      }
      if (blobList.length > 0) {
        if (collectForm.value.makeZip) {
          const { data } = await axios.post(process.env.VUE_APP_ZIP_ENDPOINT + '/collect/pdf/download', blobList, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
          const a = document.createElement('a')
          a.href = data
          a.click()
        } else {
          const { data } = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/pdf/unite', blobList, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
          const a = document.createElement('a')
          a.href = data
          a.click()
        }
      }
      processModal.hide()
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      collectModal = new Modal(collectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      if (!store.system) {
        if (session.session.green && session.session.greenCapo) {
          store.system = 'capo'
        } else if (session.session.green && session.session.greenJrca) {
          store.system = 'jrca'
        } else if (session.session.green && session.session.greenContano) {
          store.system = 'contano'
        } else if (session.session.green && session.session.red) {
          store.system = 'red'
        } else {
          store.system = 'external'
        }
      }
      await loadCompanyList()
    })
    const changeCompany = () => {
      if (store.system === 'capo' && store.capoCompanyIndex !== -1) {
        linkCompanyId.value = capoCompanyList.value[store.capoCompanyIndex].id
      } else if (store.system === 'jrca') {
        linkCompanyId.value = jrcaCompanyList.value[store.jrcaCompanyIndex].id
      } else if (store.system === 'contano') {
        linkCompanyId.value = contanoCompanyList.value[store.contanoCompanyIndex].id
      } else if (store.system === 'red') {
        linkCompanyId.value = redCompanyList.value[store.redCompanyIndex].id
      }
    }
    return {
      collectModalDiv,
      session,
      store,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      rightholderResult,
      rightholderList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      loadCompanyList,
      download,
      processMessage,
      processModalDiv,
      redCompanyList,
      openCollectModal,
      dataType,
      clearSearchDetail,
      downloadStart,
      downloadCollectPdf,
      collectForm,
      linkCompanyId,
      changeCompany
    }
  }
})
</script>
