import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const errorFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const findUnMatch = async (divTermId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getUnMatchCount = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const downloadUnMatch = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/usage/file/import/data/unmatch/download', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findNotDiv = async (divTermId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/error/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getNotDivCount = async (divTermId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/divresult/error/count', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { findUnMatch, findNotDiv, downloadUnMatch, getUnMatchCount, getNotDivCount }
}
