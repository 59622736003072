import RedNdError from './Notdiv'
import RedUnMatchError from './Unmatch'

export default {
  routes: [
    {
      path: '/red/error/unmatch',
      name: 'RedUnMatchError',
      component: RedUnMatchError
    },
    {
      path: '/red/error/notdiv',
      name: 'RedNdError',
      component: RedNdError
    }
  ]
}
