import axios from 'axios'
import { sessionStore } from '@/functions/sessionStore'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const artistFunction = () => {
  const session = sessionStore()
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (store) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: session.session.company.id,
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const insert = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/artist', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { companyId: companyId }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const findPhoto = async (artistId) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/photo/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    const photoList = data
    for (const photo of photoList) {
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blob_name: photo.blob_name }
      }).then(response => {
        photo.url = response.data
      })
    }
    return photoList
  }
  const deletePhoto = async (artistId) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/photo', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const insertPhoto = async (artistId, blobName) => {
    await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/photo', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: { blobName: blobName }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const findSite = async (artistId) => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/site/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const deleteSite = async (artistId) => {
    await axios.delete(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/site', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const insertSite = async (artistId, siteForm) => {
    await axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + artistId + '/site', siteForm, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const clearSearch = (store) => {
    store.searchString = ''
    store.page = 0
  }
  const indexUpdate = (id) => {
    axios.post(process.env.VUE_APP_COMMON_ENDPOINT + '/artist/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  return { get, find, insert, update, findPhoto, deletePhoto, insertPhoto, findSite, deleteSite, insertSite, clearSearch, indexUpdate }
}
