<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/definition/list"><i class="fas fa-cabinet-filing fa-fw" style="margin-right: 5px;"></i> 取込ファイル定義一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/definition/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link v-if="form && form.id" :to="'/red/definition/copy/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-copy fa-fw" style="margin-right: 5px;"></i>コピー
              </router-link>
              <router-link to="/red/definition/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">名前</div>
          <div class="form-edit-line fs-5" style="font-weight: bold;">
            {{ form.name }}
          </div>
          <div class="form-label align-bottom">データ開始行</div>
          <div class="form-edit-line">
            {{ form.dataStartRow }}
          </div>
          <div class="form-label align-bottom">受領額</div>
          <div class="form-edit-line">
            <span v-if="form.incomeAmountType === 'data'">データ内</span>
            <span v-if="form.incomeAmountType === 'data_percent_deduction'">データ内の金額から割合控除</span>
            <span v-if="form.incomeAmountType === 'data_all_amount_deduction'">データ内の金額から総控除額を使用数按分した額を控除</span>
            <span v-if="form.incomeAmountType === 'distribution'">総受領額を使用数按分</span>
            <span v-if="form.incomeAmountType === 'distribution_percent_deduction'">総受領額を使用数按分した金額から割合控除</span>
            <span v-if="form.incomeAmountType === 'distribution_all_amount_deduction'">総受領額を使用数按分した金額から総控除額を使用数按分した額を控除</span>
          </div>
          <div class="form-label align-bottom">マッチング項目複数時の挙動</div>
          <div class="form-edit-line">
            <span v-if="form.multiMatchingType === 'or'">いずれかにマッチ</span>
            <span v-if="form.multiMatchingType === 'one'">一つのコードにしてマッチング</span>
            <span v-if="form.multiMatchingType === 'all'">全てがマッチング</span>
          </div>
          <div class="form-label align-bottom">利用年月指定箇所</div>
          <div class="form-edit-line">
            <span v-if="form.usedMonthType === 'input'">入力</span>
            <span v-if="form.usedMonthType === 'data'">データ内</span>
            <span v-if="form.usedMonthType === 'sheet'">Excelシート</span>
          </div>
          <div class="form-label align-bottom">
            データ詳細
          </div>
          <div class="form-edit-line">
            <table class="table table-responsive">
              <thead>
              <tr>
                <th style="width: 40px;">#</th>
                <th style="width: 70px;">必須</th>
                <th style="width: 90px;">マッチ</th>
                <th>種別</th>
                <th style="width: 150px;">集計</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in form.itemList" :key="index">
                <td>{{index + 1}}</td>
                <td>
                  <span v-if="item.required">●</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-if="item.matching">●</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-if="item.itemType === 'catalog_no'">製品番号</span>
                  <span v-else-if="item.itemType === 'jan_code'">JANコード（POSコード）</span>
                  <span v-else-if="item.itemType === 'user_album_code'">利用者アルバムコード</span>
                  <span v-else-if="item.itemType === 'isrc'">ISRC</span>
                  <span v-else-if="item.itemType === 'master_code'">原盤管理コード</span>
                  <span v-else-if="item.itemType === 'user_master_code'">利用者原盤コード</span>
                  <span v-else-if="item.itemType === 'matching_code'">種類が混在したコード</span>
                  <span v-else-if="item.itemType === 'album_title'">アルバム名</span>
                  <span v-else-if="item.itemType === 'master_name'">原盤名</span>
                  <span v-else-if="item.itemType === 'album_or_master_name'">アルバム名／原盤名</span>
                  <span v-else-if="item.itemType === 'artist_name'">アーティスト名</span>
                  <span v-else-if="item.itemType === 'used_year'">使用年</span>
                  <span v-else-if="item.itemType === 'used_month'">使用月</span>
                  <span v-else-if="item.itemType === 'used_year_month_1'">使用年月（YYYYMM）</span>
                  <span v-else-if="item.itemType === 'used_year_month_2'">使用年月（YYYY/MM）</span>
                  <span v-else-if="item.itemType === 'used_year_month_3'">使用年月（YYYY-MM）</span>
                  <span v-else-if="item.itemType === 'used_year_month_4'">使用年月（YYYY年MM月）</span>
                  <span v-else-if="item.itemType === 'use_type_name'">利用種別</span>
                  <span v-else-if="item.itemType === 'royalty_type_name'">印税種別</span>
                  <span v-else-if="item.itemType === 'used_no'">使用数</span>
                  <span v-else-if="item.itemType === 'price_out_tax'">価格（税抜）</span>
                  <span v-else-if="item.itemType === 'price_in_tax'">価格（税込）</span>
                  <span v-else-if="item.itemType === 'revenue_out_tax'">売上（税抜）</span>
                  <span v-else-if="item.itemType === 'revenue_in_tax'">売上（税込）</span>
                  <span v-else-if="item.itemType === 'unit_price'">分配単価</span>
                  <span v-else-if="item.itemType === 'div_rate'">分配率</span>
                  <span v-else-if="item.itemType === 'income_amount_out_tax'">受領額（税抜）</span>
                  <span v-else-if="item.itemType === 'income_amount_in_tax'">受領額（税込）</span>
                  <span v-else-if="item.itemType === 'tax_rate'">消費税率</span>
                  <span v-else-if="item.itemType === 'memo'">メモ</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-if="item.groupType === 'key'">集計キー</span>
                  <span v-else-if="item.groupType === 'sum'">合計</span>
                  <span v-else-if="item.groupType === 'max'">最大</span>
                  <span v-else-if="item.groupType === 'min'">最小</span>
                  <span v-else-if="item.groupType === 'avg'">平均</span>
                  <span v-else>-</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="form.memo" class="form-label align-bottom">メモ</div>
          <div v-if="form.memo" class="form-edit-line">
            <pre>{{ form.memo }}</pre>
          </div>
          <div class="form-label align-bottom">登録</div>
          <div class="form-edit-line">
            <span v-html="form.createdAt"></span>（{{form.createdBy}}）
          </div>
          <div class="form-label align-bottom">最終更新</div>
          <div class="form-edit-line">
            <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="rotModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">特定取込／取込除外文字列</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <textarea v-model="rotStrings" class="form-control" rows="5" readonly></textarea>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Modal } from 'bootstrap'
import { definitionFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    // Form
    const definitionFindFunc = definitionFunction()
    const warnings = ref([])
    const form = ref(null)
    const rotModalDiv = ref(null)
    let rotModal = null
    const rotStrings = ref('')
    // メソッド
    const init = async (id) => {
      warnings.value = []
      form.value = await definitionFindFunc.get(id)
      let hasIncome = false
      let hasUsedNo = false
      for (const item of form.value.itemList) {
        if (item.itemType === 'income_amount_out_tax' || item.itemType === 'income_amount_in_tax') {
          hasIncome = true
        }
        if (item.itemType === 'used_no') {
          hasUsedNo = true
        }
      }
      if (form.value.incomeAmountType === 'data' || form.value.incomeAmountType === 'data_percent_deduction' || form.value.incomeAmountType === 'data_all_amount_deduction') {
        if (!hasIncome) {
          warnings.value.push('この定義では入金額を特定できないため、この定義で取り込まれたデータで入金額を使用した分配計算は行えません。')
        }
      }
      if (!hasUsedNo) {
        warnings.value.push('この定義では使用数を特定できないため、この定義で取り込まれたデータで使用数を使用した分配計算は行えません。')
      }
    }
    const openRotStrings = (index) => {
      const item = form.value.itemList[index]
      rotStrings.value = item.rot_strings.join('\n')
      rotModal.show()
    }
    onMounted(async () => {
      rotModal = new Modal(rotModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      warnings,
      form,
      openRotStrings,
      rotModalDiv,
      rotStrings
    }
  }
})
</script>
