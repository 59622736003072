<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/common/payee/list"><i class="fas fa-envelope-open-dollar fa-fw" style="margin-right: 5px;"></i> 振込先一覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/common/payee/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>用途</div>
          <div class="form-edit-line">
            <div v-if="session.session.blue" class="form-check form-check-inline">
              <input v-model="form.useOnBlue" class="form-check-input" type="checkbox" id="useOnBlue">
              <label class="form-check-label" for="useOnBlue" style="color: #0097E0;">BLUE</label>
            </div>
            <div v-if="session.session.red" class="form-check form-check-inline">
              <input v-model="form.useOnRed" class="form-check-input" type="checkbox" id="useOnRed">
              <label class="form-check-label" for="useOnRed" style="color: #E83821;">RED</label>
            </div>
            <div v-if="session.session.pink" class="form-check form-check-inline">
              <input v-model="form.useOnPink" class="form-check-input" type="checkbox" id="useOnPink">
              <label class="form-check-label" for="useOnPink" style="color: #EE86A8;">PINK</label>
            </div>
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger"
              style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>口座</div>
          <div class="form-edit-line">
            <div class="form-check form-check-inline">
              <input v-model="form.domesticOrOversees" class="form-check-input" type="radio" name="domesticOrOversees" id="domestic" value="domestic">
              <label class="form-check-label" for="domestic">日本</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="form.domesticOrOversees" class="form-check-input" type="radio" name="domesticOrOversees" id="oversees" value="oversees">
              <label class="form-check-label" for="oversees">その他</label>
            </div>
          </div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 122px;">銀行</span>
              <input v-model="bankCode" type="text" class="form-control" style="flex: 0 1 75px;" readonly>
              <input v-model="bankName" type="text" class="form-control" style="flex: 0 1 150px;" readonly>
              <input v-model="branchCode" type="text" class="form-control" style="flex: 0 1 60px;" readonly>
              <input v-model="branchName" type="text" class="form-control" style="flex: 0 1 150px;" readonly>
              <button v-if="!form.branchId" @click="openBankSearch" class="btn btn-outline-primary"><i
                  class="fas fa-search fa-fw"></i></button>
              <button v-if="form.branchId" @click="clearBank" class="btn btn-outline-primary"><i
                  class="fas fa-undo fa-fw"></i></button>
            </div>
          </div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-label align-bottom">預金種目</div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
            <select v-model="form.depositItem" class="form-select" style="width: 100px;">
              <option value="1">普通</option>
              <option value="2">当座</option>
              <option value="4">貯蓄</option>
              <option value="9">その他</option>
            </select>
          </div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 122px;">口座番号</span>
              <input v-model="form.accountNo" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
            </div>
          </div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 122px;">口座名義</span>
              <input v-model="form.accountName" type="text" class="form-control">
            </div>
          </div>
          <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
            <div class="input-group">
              <span class="input-group-text" style="flex: 0 1 122px;">口座名義カナ</span>
              <input v-model="form.accountKana" type="text" class="form-control" maxlength="30">
            </div>
          </div>
          <div v-if="form.domesticOrOversees === 'OVERSEES'" class="form-edit-line">
            <textarea v-model="form.overseesAccount" class="form-control" rows="3"></textarea>
          </div>
          <div class="form-label align-bottom">メモ</div>
          <div class="form-edit-line">
            <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
          </div>
          <div style="height: 50px;"></div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="bankSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">銀行検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="bankCondition.page = 0; findBank();" v-model="bankCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="bankCondition.searchString" @click="bankClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="bankCondition.page = 0; findBank();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="bankResult && bankResult.allRecords > 0" class="table table-responsive table-hover">
                <thead>
                <tr>
                  <th style="width: 120px;">銀行コード</th>
                  <th>銀行名</th>
                  <th style="width: 120px;">支店コード</th>
                  <th>支店名</th>
                </tr>
                </thead>
                <tbody v-if="bankList">
                <tr v-for="(bank, index) in bankList" :key="bank.branchId" class="text-start">
                  <td>{{ bank.bankCode }}</td>
                  <td>{{ bank.bankName }}</td>
                  <td>{{ bank.branchCode }}</td>
                  <td><a @click="selectBank(index)" href="#">{{ bank.branchName }}</a></td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="bankResult && bankResult.allRecords > 0">{{ bankResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="bankResult && bankResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="bankResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ bankCondition.page + 1 }}/{{ bankResult.allPages }}ページ</span>
                      </li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">振込先の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の振込先を削除しようとしています。削除すると、振込先情報の確認ができなくなり、該当振込先が紐づいている楽曲、アルバム、原盤の振込先情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { payeeFunction } from './function'
import { bankFunction } from '@/functions/common'
import { bankStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const payeeFunc = payeeFunction()
    const bankFunc = bankFunction()
    const form = ref(null)
    const bankCode = ref('')
    const bankName = ref('')
    const branchCode = ref('')
    const branchName = ref('')
    // 銀行検索
    const bankCondition = bankStore()
    const bankSearchModalDiv = ref(null)
    let bankSearchModal = null
    const bankResult = ref(null)
    const bankList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (form.value.domesticOrOversees === 'domestic') {
        if (!form.value.branchId) {
          errors.value.push('銀行を選択してください')
        }
        if (!form.value.accountNo) {
          errors.value.push('口座番号を入力してください')
        } else {
          if (isNaN(form.value.accountNo)) {
            errors.value.push('口座番号は整数で入力してください')
          }
          if (form.value.accountNo.length !== 7) {
            errors.value.push('口座番号は7桁で入力してください（7桁に満たない場合は前に0を付与）')
          }
        }
        if (!form.value.accountName) {
          errors.value.push('口座名義を入力してください')
        }
      } else {
        if (!form.value.overseesAccount) {
          errors.value.push('口座情報を入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!form.value.id) {
          form.value.id = await payeeFunc.insert(form.value)
        } else {
          await payeeFunc.update(form.value.id, form.value)
        }
        payeeFunc.indexUpdate(form.value.id)
        await router.push('/common/payee/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await payeeFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/common/payee/list')
    }
    const findBank = async () => {
      bankResult.value = await bankFunc.findBranchWithBank(bankCondition)
      bankList.value = bankResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const bankClearSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      findBank()
    }
    const bankFirstPage = () => {
      bankCondition.page = 0
      findBank()
    }
    const bankPrevPage = () => {
      bankCondition.page = bankCondition.page - 1
      findBank()
    }
    const bankNextPage = () => {
      bankCondition.page = bankCondition.page + 1
      findBank()
    }
    const bankLastPage = () => {
      bankCondition.page = bankResult.value.allPages - 1
      findBank()
    }
    const openBankSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      bankResult.value = null
      bankList.value = []
      bankSearchModal.show()
    }
    const selectBank = (index) => {
      const bank = bankList.value[index]
      form.value.branchId = bank.branchId
      bankCode.value = bank.bankCode
      bankName.value = bank.bankName
      branchCode.value = bank.branchCode
      branchName.value = bank.branchName
      bankSearchModal.hide()
    }
    const clearBank = () => {
      form.value.branchId = null
      bankCode.value = ''
      bankName.value = ''
      branchCode.value = ''
      branchName.value = ''
    }
    async function init (id) {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          useOnBlue: true,
          useOnRed: true,
          useOnPink: true,
          name: '',
          domesticOrOversees: 'domestic',
          branchId: null,
          depositItem: '1',
          accountNo: '',
          accountName: '',
          accountKana: '',
          overseesAccount: '',
          memo: ''
        }
        bankCode.value = ''
        bankName.value = ''
        branchCode.value = ''
        branchName.value = ''
      } else {
        form.value = await payeeFunc.get(id)
        if (form.value.branchId) {
          const branch = await bankFunc.getBranchWithBank(form.value.branchId)
          branchCode.value = branch.code
          branchName.value = branch.name
          bankCode.value = branch.bankCode
          bankName.value = branch.bankName
        }
      }
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      bankSearchModal = new Modal(bankSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      session,
      router,
      errors,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      bankCode,
      bankName,
      branchCode,
      branchName,
      bankSearchModalDiv,
      bankCondition,
      bankResult,
      bankList,
      save,
      openDeleteModal,
      executeDelete,
      findBank,
      bankClearSearch,
      bankFirstPage,
      bankPrevPage,
      bankNextPage,
      bankLastPage,
      openBankSearch,
      selectBank,
      clearBank
    }
  }
})
</script>
