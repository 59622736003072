import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const contractFindFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async () => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contract', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findUser = async () => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findCompany = async () => {
    const { data } = await axios.get(process.env.VUE_APP_COMMON_ENDPOINT + '/company/list/inuse', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findCollectGreeting = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/collect/greeting/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findInvoiceGreeting = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findGreenCompany = async (system) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/contract/' + system + '/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    for (const model of data) {
      const company = await getCompanyName(system, model.companyId)
      model.name = company.name
    }
    return data
  }
  const getCompanyName = async (system, companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + companyId, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { get, findUser, findCompany, findCollectGreeting, findInvoiceGreeting, findGreenCompany, getCompanyName }
}
