<template>
  <div>
    <nav-component></nav-component>
    <div class="container-fluid">
      <div style="height: 50px;"></div>
      <div class="row align-items-center" style="margin-top: 15px;">
        <div class="d-none d-md-block col-md-4 text-start">
          <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
            <ol class="breadcrumb" style="margin: 0;">
              <li class="breadcrumb-item"><router-link to="/green/rightsholder/list"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 収集用権利者一覧</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-import" style="margin-right: 5px;"></i>収集情報</li>
            </ol>
          </nav>
        </div>
        <div class="col d-flex justify-content-end">
          <router-link v-if="form && !invoice" :to="'/green/rightsholder/internal/print/' + form.id" class="btn btn-outline-primary"><i class="fas fa-share-square" style="margin-right: 5px;"></i>印刷用ページ</router-link>
        </div>
      </div>
      <div v-if="form" class="container text-start" style="margin-top: 20px;">
        <div class="form-label align-bottom">名前</div>
        <div class="form-edit-line">
          {{form.name}}
          <span v-if="!form.name"><br></span>
        </div>
        <div v-if="!invoice && invoiceNo">
          <div class="form-label align-bottom">事業者登録番号（連携システム直接登録）</div>
          <div class="form-edit-line fs-5">
            T{{invoiceNo}}
          </div>
        </div>
        <div v-else>
          <div class="form-label align-bottom">収集用URL</div>
          <div class="form-edit-line">
            <div class="input-group" style="margin-top: 10px;">
              <input v-model="url" type="text" class="form-control" disabled>
              <button @click="copyUrl" class="btn btn-outline-primary"><i class="fas fa-copy"></i></button>
              <button @click="generateUrl(true)" class="btn btn-outline-danger"><i class="fas fa-undo"></i></button>
            </div>
          </div>
          <div class="alert alert-warning text-start">
            URLを既知のメールアドレスや、印刷物で先方に伝え、連絡先のメールアドレスと事業者登録番号等を登録してもらってください。URL右の<i class="fas fa-copy" style="margin-left: 5px; margin-right: 5px;"></i>ボタンをクリックするとコピーされますので、任意の場所でペーストしてご使用ください。コピーボタン隣の<i class="fas fa-undo" style="margin-left: 5px; margin-right: 5px;"></i>ボタンでURLを変更することができます。 <b class="text-danger">URL通知後に変更すると登録できなくなってしまう</b>のでご注意ください。
          </div>
          <div class="form-label align-bottom">挨拶文</div>
          <div class="form-edit-line">
            <textarea v-model="greeting" @change="saveGreeting" class="form-control" rows="10"></textarea>
          </div>
          <div v-if="form.memo" class="form-label align-bottom">メモ</div>
          <div v-if="form.memo" class="form-edit-line">
            <div class="alert alert-secondary" v-html="form.memo.replaceAll('\n', '<br>')" style="font-size: 90%;"></div>
          </div>
          <div v-if="invoice">
            <div class="form-label align-bottom">課税事業者／免税事業者</div>
            <div class="form-edit-line">
              <span v-if="invoice.taxationType === 'taxation'">課税事業者</span>
              <span v-else>免税事業者</span>
            </div>
            <div v-if="invoice.taxationType !== 'taxation'" class="form-label align-bottom">課税事業者への変更予定</div>
            <div v-if="invoice.taxationType !== 'taxation'" class="form-edit-line">
              <span v-if="invoice.toTaxationSchedule">あり</span>
              <span v-else>なし</span>
            </div>
            <div v-if="invoice.taxationType === 'taxation'" class="form-label align-bottom">事業者登録番号</div>
            <div v-if="invoice.taxationType === 'taxation'" class="form-edit-line fs-5">
              T{{invoice.invoiceNo}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import auth from '@/functions/auth'
import exception from '@/functions/exception'
import { useRoute } from 'vue-router'
import { Toast } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    const session = ref(null)
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    // Form
    const form = ref(null)
    const url = ref('')
    const greeting = ref('')
    const invoice = ref(null)
    const invoiceNo = ref(null)
    // メソッド
    function init (system, id) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + system + '/' + id, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(response => {
        form.value = response.data
        let url = process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/' + response.data.systemId
        if (system === 'red') {
          url = process.env.VUE_APP_RED_ENDPOINT + '/rightsholder/seq/' + response.data.systemId
        }
        axios.get(url, {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          form.value.name = response.data.name
          if (system === 'red') {
            invoiceNo.value = response.data.invoice_no
          } else {
            invoiceNo.value = response.data.invoiceNo
          }
        })
        if (form.value.accountId) {
          axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + form.value.accountId + '/invoice', {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            invoice.value = response.data
          })
        }
        if (!form.value.greeting) {
          axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting', {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            greeting.value = response.data.replace('$name', form.value.name)
            form.value.greeting = greeting.value
            axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/external/rightsholder/' + id, {
              name: form.value.name,
              externalId: form.value.externalId,
              memo: form.value.memo,
              greeting: greeting.value
            }, {
              headers: { Authorization: 'Bearer ' + getAccessToken() }
            })
          })
        } else {
          greeting.value = form.value.greeting
        }
        generateUrl(false)
      }).catch(error => {
        if (error.response?.status === 404) {
          let url = process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/' + id
          if (system === 'red') {
            url = process.env.VUE_APP_RED_ENDPOINT + '/rightsholder/seq/' + id
          }
          axios.get(url, {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder', {
              system: system,
              systemId: id,
              name: response.data.name
            }, {
              headers: { Authorization: 'Bearer ' + getAccessToken() }
            }).then(response => {
              axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + response.data, {
                headers: { Authorization: 'Bearer ' + getAccessToken() }
              }).then(response => {
                form.value = response.data
                if (!form.value.greeting) {
                  axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/invoice/greeting', {
                    headers: { Authorization: 'Bearer ' + getAccessToken() }
                  }).then(response => {
                    greeting.value = response.data.replace('$name', form.value.name)
                    form.value.greeting = greeting.value
                    axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + id, {
                      name: form.value.name,
                      externalId: form.value.externalId,
                      memo: form.value.memo,
                      greeting: greeting.value
                    }, {
                      headers: { Authorization: 'Bearer ' + getAccessToken() }
                    })
                  })
                } else {
                  greeting.value = form.value.greeting
                }
                generateUrl(false)
              })
            })
          })
        } else {
          identifyEx(error)
        }
      })
    }
    const copyUrl = () => {
      navigator.clipboard.writeText(url.value)
      messages.value = ['コピーされました']
      messageToast.show()
    }
    const generateUrl = (renew) => {
      let tokenUrl = '/internal/rightsholder/' + form.value.id + '/token'
      if (renew) {
        tokenUrl = tokenUrl + '/renew'
      }
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + tokenUrl, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(response => {
        url.value = response.data.url
      }).catch(error => {
        identifyEx(error)
      })
    }
    const saveGreeting = () => {
      axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/internal/rightsholder/' + form.value.id, {
        system: form.value.system,
        systemId: form.value.systemId,
        externalId: form.value.externalId,
        memo: form.value.memo,
        greeting: greeting.value
      }, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).catch(error => { identifyEx(error) })
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      await axios.get(process.env.VUE_APP_API_ENDPOINT + '/session', {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(response => {
        session.value = response.data
      }).catch(error => { identifyEx(error) })
      init(route.params.system, route.params.id)
    })
    return {
      session,
      messages,
      messageToastDiv,
      form,
      url,
      greeting,
      invoice,
      invoiceNo,
      copyUrl,
      generateUrl,
      saveGreeting
    }
  }
})
</script>
