<template>
  <div class="container">
    <div class="row" style="margin-top: 10px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div style="height: 40px;"></div>
    <h1 style="margin-bottom: 30px;"><b>rights.services <span style="color: #8EC31F;">GREEN</span> HELP</b></h1>
    <div class="row justify-content-center">
      <div class="col-8 text-start">
        <div><h5 class="fs-4" style="margin-top: 10px;">0. 利用規約とプライバシーポリシー</h5></div>
        <div class="fs-5" style="margin-left: 20px;">0-1. <router-link to="/help/terms" target="_blank">利用規約</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">0-2. <router-link to="/help/policy" target="_blank">プライバシーポリシー</router-link></div>
        <div><h5 class="fs-4" style="margin-top: 10px;">1. サービス概要</h5></div>
        <div class="fs-5" style="margin-left: 20px;">1-1. <router-link to="/help/purpose">本サービスの目的</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">1-2. <router-link to="/help/security" class="secondary-content">アカウントの管理</router-link></div>
        <div><h5 class="fs-4" style="margin-top: 10px;">2. 基本操作方法</h5></div>
        <div class="fs-5" style="margin-left: 20px;">2-1. <router-link to="/help/login">システムにログインする</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-2. <router-link to="/help/receive">受領した明細を確認する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-3. <router-link to="/help/detail">明細の詳細を確認する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-4. <router-link to="/help/myaccount/disp">名前やメールアドレス等自分の情報を確認する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-5. <router-link to="/help/myaccount/edit">自分の名前を変更する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-6. <router-link to="/help/myaccount/email">ログインに使用するメールアドレスを追加・統合する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-7. <router-link to="/help/myaccount/mobile">携帯電話番号を追加または変更する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">2-8. <router-link to="/help/myaccount/notify">システム運営状況等のお知らせメールの受け取りを停止する</router-link></div>
        <div><h5 class="fs-4" style="margin-top: 10px;">3. その他の機能</h5></div>
        <div class="fs-5" style="margin-left: 20px;">3-1. <router-link to="/help/mfa">より安全なログイン方法に変更する</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">3-2. <router-link to="/help/remember">認証コードの入力回数を減らす</router-link></div>
        <div class="fs-5" style="margin-left: 20px;">3-3. <router-link to="/help/download">明細を一括でダウンロードする</router-link></div>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 40px;">
      <div class="col-8 text-start">
        <div class="fs-5"><b>システムの使用方法に関するお問い合わせ</b></div>
        <div style="margin-top: 10px;">システムの使用方法に関するお問い合わせ、送付する側としての導入に関するご相談は下記問い合わせ窓口までご連絡ください。</div>
        <div>※送付された明細に関するご質問には当社はお答え致しかねますので、送信者へお問い合わせください。</div>
        <div style="margin-top: 10px;">
          メール : <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
        </div>
        <div>
          電話  : 050-3138-2564（平日 10:00 ～ 18:00）
        </div>
      </div>
    </div>
    <footer class="alert alert-warning" style="margin-top: 50px;">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
  </div>
</template>
