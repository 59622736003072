import GreenAgreement from './Agreement'
import GreenExternal from './External'
import GreenHistory from './History'
import GreenInput from './Input'
import GreenPrint from './Print'
import GreenReceipt from './Receipt'
import GreenStatement from './Statement'

export default {
  routes: [
    {
      path: '/green/agreement',
      name: 'GreenAgreement',
      component: GreenAgreement
    },
    {
      path: '/green/print/:system/:id',
      name: 'GreenPrint',
      component: GreenPrint
    },
    {
      path: '/green/collect/:system/:id/:token',
      name: 'GreenInput',
      component: GreenInput
    },
    {
      path: '/green/statement',
      name: 'GreenStatement',
      component: GreenStatement
    },
    {
      path: '/green/receipt',
      name: 'GreenReceipt',
      component: GreenReceipt
    },
    {
      path: '/green/history',
      name: 'GreenHistory',
      component: GreenHistory
    },
    {
      path: '/green/external',
      name: 'GreenExternal',
      component: GreenExternal
    }
  ]
}
