<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/admin/contract/list"><i class="fas fa-cog fa-fw" style="margin-right: 5px;"></i>
                      [管理者用] 契約一覧
                    </router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item">
                    <router-link :to="'/admin/contract/disp/' + id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 10px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">テスト契約</div>
          <div class="form-edit-line">
            <div class="form-check form-switch">
              <input v-model="useOnTest" class="form-check-input" type="checkbox" role="switch" id="useOnTest">
              <label class="form-check-label" for="useOnTest">テストを目的とした契約（試用版は通常契約）</label>
            </div>
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">使用システム</div>
          <div class="form-edit-line">
            <button v-if="blue === 'YES'" @click="blue = 'NO'; changeSystem();" class="btn text-white" style="background-color: #0097E0; margin-right: 5px;">BLUE</button>
            <button v-if="blue === 'NO'" @click="blue = 'YES'; changeSystem();" class="btn btn-secondary" style="margin-right: 5px;">BLUE</button>
            <button v-if="red === 'YES'" @click="red = 'NO'; changeSystem();" class="btn text-white" style="background-color: #E83821; margin-right: 5px;">RED</button>
            <button v-if="red === 'NO'" @click="red = 'YES'; changeSystem();" class="btn btn-secondary" style="margin-right: 5px;">RED</button>
            <button v-if="pink === 'YES'" @click="pink = 'NO'; changeSystem();" class="btn text-white" style="background-color: #EE86A8; margin-right: 5px;">PINK</button>
            <button v-if="pink === 'NO'" @click="pink = 'YES'; changeSystem();" class="btn btn-secondary" style="margin-right: 5px;">PINK</button>
            <button v-if="green === 'YES'" @click="green = 'NO'; changeSystem();" class="btn text-white" style="background-color: #8EC31F; margin-right: 5px;">GREEN</button>
            <button v-if="green === 'NO'" @click="green = 'YES'; changeSystem();" class="btn btn-secondary" style="margin-right: 5px;">GREEN</button>
          </div>
          <div class="form-label align-bottom">ユーザー</div>
          <div class="form-edit-line">
            <button @click="openUserAdd" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
            <table class="table table-responsive table-hover">
              <tbody>
              <tr v-for="(user, index) in userList" :key="index" :class="{'deleted-row': user.deleted}">
                <td>{{user.name}}</td>
                <td style="width: 130px;" class="text-end">
                  <select v-model="user.hasPicRole" class="form-select" style="width: 120px;">
                    <option value="NO">一般</option>
                    <option value="YES">責任者</option>
                  </select>
                </td>
                <td style="width: 50px;" class="text-center">
                  <button @click="deleteUser(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="nav nav-tabs" style="margin-bottom: 10px;">
            <li v-if="blue === 'NO' && red === 'NO' && green === 'NO'">使用システムなし</li>
            <li v-if="blue === 'YES'" class="nav-item">
              <a @click="rightAreaMode = 'BLUE'" class="nav-link" :class="{ 'active': rightAreaMode === 'BLUE' }" aria-current="page" href="#">
                <i class="fas fa-square fa-fw" style="color: #0097E0; margin-right: 5px;"></i>BLUE
              </a>
            </li>
            <li v-if="red === 'YES'" class="nav-item">
              <a @click="rightAreaMode = 'RED'" class="nav-link" :class="{ 'active': rightAreaMode === 'RED' }" aria-current="page" href="#">
                <i class="fas fa-square fa-fw" style="color: #E83821; margin-right: 5px;"></i>RED
              </a>
            </li>
            <li v-if="green === 'YES'" class="nav-item">
              <a @click="rightAreaMode = 'GREEN'" class="nav-link" :class="{ 'active': rightAreaMode === 'GREEN' }" aria-current="page" href="#">
                <i class="fas fa-square fa-fw" style="color: #8EC31F; margin-right: 5px;"></i>GREEN
              </a>
            </li>
          </ul>
          <div v-if="rightAreaMode === 'GREEN'">
            <div v-if="green === 'YES'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>プラン ※プラン詳細は<a href="https://rs-green.rights.tokyo" target="_blank">こちら</a></div>
            <div v-if="green === 'YES'" class="form-edit-line">
              <select v-model="planGreen" class="form-select">
                <option value="FREE">FREE（0円）</option>
                <option value="BRONZE">BRONZE（月額 5,000円）</option>
                <option value="SILVER">SILVER（月額 10, 000円）</option>
                <option value="GOLD">GOLD（個別見積もり）</option>
              </select>
            </div>
            <div v-if="green === 'YES'" class="form-label align-bottom">
              メッセージ機能オプション
            </div>
            <div v-if="green === 'YES'" class="form-edit-line">
              <select v-model="greenOptionExternal" class="form-select">
                <option value="false">利用しない</option>
                <option value="true">利用する</option>
              </select>
            </div>
            <div v-if="green === 'YES'" class="form-label align-bottom">連携システム</div>
            <div v-if="green === 'YES'" class="form-edit-line">
              <button v-if="greenCapo === 'YES'" @click="greenCapo = 'NO'" class="btn text-white" style="background-color: #8EC31F; margin-right: 5px;">capo</button>
              <button v-if="greenCapo === 'NO'" @click="greenCapo = 'YES'" class="btn btn-secondary" style="margin-right: 5px;">capo</button>
              <button v-if="greenJrca === 'YES'" @click="greenJrca = 'NO'" class="btn text-white" style="background-color: #8EC31F; margin-right: 5px;">JRCA</button>
              <button v-if="greenJrca === 'NO'" @click="greenJrca = 'YES'" class="btn btn-secondary" style="margin-right: 5px;">JRCA</button>
              <button v-if="greenContano === 'YES'" @click="greenContano = 'NO'" class="btn text-white" style="background-color: #8EC31F; margin-right: 5px;">contano</button>
              <button v-if="greenContano === 'NO'" @click="greenContano = 'YES'" class="btn btn-secondary" style="margin-right: 5px;">contano</button>
            </div>
            <div v-if="greenCapo === 'YES'" class="form-label align-bottom">capo会社</div>
            <div v-if="greenCapo === 'YES'" class="form-edit-line">
              <button @click="openCompanySelect('capo')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(company, index) in capoCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                  <td>{{company.companyName}}</td>
                  <td style="width: 50px;" class="text-center">
                    <button @click="deleteCompany('capo', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="greenJrca === 'YES'" class="form-label align-bottom">JRCA会社</div>
            <div v-if="greenJrca === 'YES'" class="form-edit-line">
              <button @click="openCompanySelect('jrca')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(company, index) in jrcaCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                  <td>{{company.companyName}}</td>
                  <td style="width: 50px;" class="text-center">
                    <button @click="deleteCompany('jrca', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="greenContano === 'YES'" class="form-label align-bottom">contano会社</div>
            <div v-if="greenContano === 'YES'" class="form-edit-line">
              <button @click="openCompanySelect('contano')" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(company, index) in contanoCompanyList" :key="index" :class="{'deleted-row': company.deleted}">
                  <td>{{company.companyName}}</td>
                  <td style="width: 50px;" class="text-center">
                    <button @click="deleteCompany('contano', index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="green === 'YES'" class="form-label align-bottom">PDFダウンロード</div>
            <div v-if="green === 'YES'" class="form-edit-line">
              <select v-model="downloadablePdf" class="form-select">
                <option value="true">受領者によるPDFダウンロードを許可する</option>
                <option value="false">受領者によるPDFダウンロードを許可しない</option>
              </select>
            </div>
            <div v-if="green === 'YES'" class="form-label align-bottom">テキストダウンロード</div>
            <div v-if="green === 'YES'" class="form-edit-line">
              <select v-model="downloadableText" class="form-select">
                <option value="true">受領者によるテキストダウンロードを許可する</option>
                <option value="false">受領者によるテキストダウンロードを許可しない</option>
              </select>
            </div>
          </div>
          <div v-if="rightAreaMode === 'RED'">
            <div v-if="red === 'YES'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>プラン ※プラン詳細は<a href="https://rs-green.rights.tokyo" target="_blank">こちら</a></div>
            <div v-if="red === 'YES'" class="form-edit-line">
              <select v-model="planRed" class="form-select">
                <option value="BRONZE">BRONZE（月額 xxxx円）</option>
                <option value="SILVER">SILVER（月額 xxxx円）</option>
                <option value="GOLD">GOLD（個別見積もり）</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="userAddModalDiv">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ユーザーの追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
            <div class="form-edit-line">
              <input v-model="userName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
            <div class="form-edit-line">
              <input v-model="userEmail" type="email" class="form-control">
            </div>
            <div class="form-label align-bottom">権限</div>
            <div class="form-edit-line">
              <select v-model="userHasPicRole" class="form-select" style="width: 120px;">
                <option value="NO">一般</option>
                <option value="YES">責任者</option>
              </select>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeUserAdd" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="addUser" type="button" class="btn btn-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="companySelectModalDiv">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">会社の選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="findCompany" v-model="companySearchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="companySearchString" @click="clearCompanySearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="findCompany" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table class="table table-responsive table-hover">
              <tbody>
                <tr v-for="(company, index) in companyList" :key="company.id">
                  <td>
                    {{company.name}}<br>
                  </td>
                  <td class="text-end" style="width: 60px;">
                    <button @click="selectCompany(index)" class="btn btn-primary"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeCompanySelect" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const errorToastDiv = ref(null)
    const userAddModalDiv = ref(null)
    const companySelectModalDiv = ref(null)
    let processModal = null
    let errorToast = null
    let userAddModal = null
    let companySelectModal = null
    // Form
    const useOnTest = ref(false)
    const rightAreaMode = ref('BLUE')
    const id = ref(null)
    const name = ref('')
    const blue = ref('NO')
    const red = ref('NO')
    const pink = ref('NO')
    const green = ref('NO')
    const greenCapo = ref('NO')
    const greenContano = ref('NO')
    const greenJrca = ref('NO')
    const greenQaEmail = ref('')
    const planGreen = ref('FREE')
    const greenDestinationCount = ref(0)
    const planRed = ref('BRONZE')
    const greenOptionExternal = ref(false)
    const downloadablePdf = ref(true)
    const downloadableText = ref(true)
    // ユーザー追加用
    const userList = ref([])
    const userName = ref('')
    const userEmail = ref('')
    const userHasPicRole = ref('NO')
    // 会社検索用
    const selectedCompanySystem = ref('')
    const companyList = ref([])
    const companySearchString = ref('')
    const capoCompanyList = ref([])
    const jrcaCompanyList = ref([])
    const contanoCompanyList = ref([])
    // メソッド
    const initEdit = (paramId) => {
      id.value = paramId
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + id.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        entityToForm(response.data)
        changeSystem()
        getCompanyList('capo', function (list) {
          capoCompanyList.value = list
          for (const company of capoCompanyList.value) {
            getCompanyName('capo', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        getCompanyList('jrca', function (list) {
          jrcaCompanyList.value = list
          for (const company of jrcaCompanyList.value) {
            getCompanyName('jrca', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        getCompanyList('contano', function (list) {
          contanoCompanyList.value = list
          for (const company of contanoCompanyList.value) {
            getCompanyName('contano', company.companyId, function (name) {
              company.companyName = name
            })
          }
        })
        axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + id.value + '/destination/count', {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        }).then(response => {
          greenDestinationCount.value = response.data
        })
      })
    }
    const initAdd = () => {
      id.value = null
      name.value = ''
      blue.value = 'NO'
      red.value = 'NO'
      pink.value = 'NO'
      green.value = 'NO'
      greenCapo.value = 'NO'
      greenJrca.value = 'NO'
      greenContano.value = 'NO'
      greenQaEmail.value = ''
      greenOptionExternal.value = false
      downloadablePdf.value = true
      downloadableText.value = true
      useOnTest.value = false
      userList.value = []
      capoCompanyList.value = []
      jrcaCompanyList.value = []
      contanoCompanyList.value = []
    }
    const getCompanyList = (system, set) => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + id.value + '/' + system + '/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        set(response.data)
      })
    }
    const getCompanyName = (system, id, set) => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        set(response.data.name)
      })
    }
    const openUserAdd = () => {
      userName.value = ''
      userEmail.value = ''
      userAddModal.show()
    }
    const closeUserAdd = () => {
      userAddModal.hide()
    }
    const addUser = () => {
      errors.value = []
      if (!userName.value) {
        errors.value.push('名前を入力してください')
      }
      if (!userEmail.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(userEmail.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (userList.value.findIndex(e => e.email === userEmail.value) > -1) {
        errors.value.push('同じメールアドレスが追加済みです')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        userList.value.push({
          name: userName.value,
          email: userEmail.value,
          hasPicRole: userHasPicRole.value,
          deleted: false
        })
        console.log(userList.value)
        closeUserAdd()
      }
    }
    const deleteUser = (index) => {
      if (userList.value[index].accountId) {
        userList.value[index].deleted = !userList.value[index].deleted
      } else {
        userList.value.splice(index, 1)
      }
    }
    const openCompanySelect = (system) => {
      selectedCompanySystem.value = system
      findCompany()
      companySelectModal.show()
    }
    const findCompany = () => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/' + selectedCompanySystem.value + '/company/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { searchString: companySearchString.value }
      }).then(response => {
        companyList.value = response.data
      })
    }
    const clearCompanySearch = () => {
      companySearchString.value = ''
      findCompany()
    }
    const closeCompanySelect = () => {
      companySelectModal.hide()
    }
    const selectCompany = (index) => {
      switch (selectedCompanySystem.value) {
        case 'capo':
          if (!(capoCompanyList.value.some(e => e.companyId === companyList.value[index].id))) {
            capoCompanyList.value.push({
              companyId: companyList.value[index].id,
              companyName: companyList.value[index].name
            })
          }
          break
        case 'jrca':
          if (!(jrcaCompanyList.value.some(e => e.companyId === companyList.value[index].id))) {
            jrcaCompanyList.value.push({
              companyId: companyList.value[index].id,
              companyName: companyList.value[index].name
            })
          }
          break
        case 'contano':
          if (!(contanoCompanyList.value.some(e => e.companyId === companyList.value[index].id))) {
            contanoCompanyList.value.push({
              companyId: companyList.value[index].id,
              companyName: companyList.value[index].name
            })
          }
          break
        default:
          break
      }
    }
    const deleteCompany = (system, index) => {
      switch (system) {
        case 'capo':
          if (capoCompanyList.value[index].id) {
            capoCompanyList.value[index].deleted = !capoCompanyList.value[index].deleted
          } else {
            capoCompanyList.value.splice(index, 1)
          }
          break
        case 'jrca':
          if (jrcaCompanyList.value[index].id) {
            jrcaCompanyList.value[index].deleted = !jrcaCompanyList.value[index].deleted
          } else {
            jrcaCompanyList.value.splice(index, 1)
          }
          break
        case 'contano':
          if (contanoCompanyList.value[index].id) {
            contanoCompanyList.value[index].deleted = !contanoCompanyList.value[index].deleted
          } else {
            contanoCompanyList.value.splice(index, 1)
          }
          break
      }
    }
    const save = async () => {
      errors.value = []
      if (!name.value) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const form = {
          name: name.value,
          blue: blue.value,
          red: red.value,
          pink: pink.value,
          green: green.value,
          greenCapo: greenCapo.value,
          greenJrca: greenJrca.value,
          greenContano: greenContano.value,
          greenQaEmail: greenQaEmail.value,
          planGreen: planGreen.value,
          planRed: planRed.value,
          greenOptionExternal: greenOptionExternal.value,
          downloadablePdf: downloadablePdf.value,
          downloadableText: downloadableText.value,
          useOnTest: useOnTest.value,
          accountList: userList.value
        }
        processMessage.value = '保存処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
        processModal.show()
        if (red.value === 'YES') {
          form.greenRed = 'YES'
        } else {
          form.greenRed = 'NO'
        }
        if (!id.value) {
          const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/admin/contract', form, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
          id.value = response.data
        } else {
          if (greenCapo.value !== 'YES') {
            await deleteContractSystemByContractId('capo', id.value)
          }
          if (greenJrca.value !== 'YES') {
            await deleteContractSystemByContractId('jrca', id.value)
          }
          if (greenContano.value !== 'YES') {
            await deleteContractSystemByContractId('contano', id.value)
          }
          await axios.put(process.env.VUE_APP_API_ENDPOINT + '/admin/contract/' + id.value, form, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
        }
        for (const contractCompany of capoCompanyList.value) {
          await saveCompany('capo', id.value, contractCompany)
        }
        for (const contractCompany of jrcaCompanyList.value) {
          await saveCompany('jrca', id.value, contractCompany)
        }
        for (const contractCompany of contanoCompanyList.value) {
          await saveCompany('contano', id.value, contractCompany)
        }
        processModal.hide()
        await router.push('/admin/contract/disp/' + id.value)
      }
    }
    const deleteContractSystemByContractId = async (system, contractId) => {
      await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + contractId + '/' + system + '/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
    }
    const saveCompany = async (system, contractId, contractCompany) => {
      contractCompany.contractId = contractId
      delete contractCompany.companyName
      if (contractCompany.id) {
        if (contractCompany.deleted) {
          await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + system + '/' + contractCompany.id, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
        } else {
          delete contractCompany.id
          await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + system + '/' + contractCompany.id, contractCompany, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          })
        }
      } else {
        await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/admin/contract/' + system, contractCompany, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
      }
    }
    const changeSystem = () => {
      if (blue.value !== 'YES') {
        if (red.value === 'YES') {
          rightAreaMode.value = 'RED'
        } else if (green.value === 'YES') {
          rightAreaMode.value = 'GREEN'
        }
      } else {
        rightAreaMode.value = 'BLUE'
      }
    }
    function entityToForm (model) {
      name.value = model.name
      blue.value = model.blue
      red.value = model.red
      pink.value = model.pink
      green.value = model.green
      greenCapo.value = model.greenCapo
      greenJrca.value = model.greenJrca
      greenContano.value = model.greenContano
      greenQaEmail.value = model.greenQaEmail
      planGreen.value = model.planGreen
      greenOptionExternal.value = model.greenOptionExternal
      userList.value = model.accountList
      downloadablePdf.value = model.downloadablePdf
      downloadableText.value = model.downloadableText
      useOnTest.value = model.useOnTest
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      userAddModal = new Modal(userAddModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      companySelectModal = new Modal(companySelectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      if (route.params.id === 'NEW') {
        initAdd()
      } else {
        initEdit(route.params.id)
      }
      loadingModal.hide()
    })
    return {
      session,
      loadingModalDiv,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      userAddModalDiv,
      companySelectModalDiv,
      useOnTest,
      id,
      name,
      blue,
      red,
      pink,
      green,
      greenCapo,
      greenJrca,
      greenContano,
      greenQaEmail,
      planGreen,
      userList,
      userName,
      userEmail,
      userHasPicRole,
      selectedCompanySystem,
      companyList,
      companySearchString,
      capoCompanyList,
      jrcaCompanyList,
      contanoCompanyList,
      greenDestinationCount,
      planRed,
      initAdd,
      initEdit,
      openUserAdd,
      closeUserAdd,
      addUser,
      deleteUser,
      openCompanySelect,
      closeCompanySelect,
      selectCompany,
      deleteCompany,
      clearCompanySearch,
      findCompany,
      save,
      rightAreaMode,
      changeSystem,
      greenOptionExternal,
      downloadablePdf,
      downloadableText
    }
  }
})
</script>
