<template>
  <div>
    <div class="container-fluid no_print" style="margin-left: 5mm; width: 295mm;">
      <div class="row align-items-center" style="margin-top: 20px;">
        <div class="d-none d-md-block col-md-8 text-start">
          <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
            <ol class="breadcrumb" style="margin: 0;">
              <li class="breadcrumb-item">
                <router-link to="/red/payment/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>
                  支払明細一覧
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-print" style="margin-right: 5px;"></i>支払一覧表</li>
            </ol>
          </nav>
        </div>
        <div class="col d-flex justify-content-end">
          <button onclick="window.print()" class="btn btn-primary"><i class="fas fa-print fa-fw"></i></button>
        </div>
      </div>
    </div>
    <section v-for="(page, index) in pageList" :key="index" class="sheet" style="padding: 4mm 5mm 2mm 5mm;">
      <div v-if="divTerm && divTermSetting" class="row">
        <div class="col-8 text-start fs-5">
          {{divTerm.div_year}}年{{divTerm.div_term}}期 印税支払一覧表
          <span class="fs-6">（計算対象期間：{{divTermSetting.start_year}}年{{divTermSetting.start_month}}月～{{divTermSetting.end_year}}年{{divTermSetting.end_month}}月）</span>
        </div>
        <div class="col-4 text-end fs-6">
          {{company.name}}
        </div>
      </div>
      <table style="font-size: 7pt; margin-top: 4mm;">
        <thead>
          <tr>
            <th rowspan="3" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 14mm;">支払<br>保留</th>
            <th rowspan="3" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 91mm;">権利者名</th>
            <th colspan="2" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 30mm;">権利者コード</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">前期繰越</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">今期発生</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">合計発生</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">管理手数料</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">相殺対象</th>
            <th rowspan="3" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 26mm;">次期繰越</th>
            <th rowspan="3" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 26mm;">支払</th>
          </tr>
          <tr>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">源泉</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">消費税</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">相殺</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">課税対象</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">源泉徴収</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">消費税</th>
            <th style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">支払対象</th>
          </tr>
          <tr>
            <th colspan="7" style="background-color: #dddddd; font-weight: normal; padding: 5px 3px 5px 3px; border: 1px solid #333333; width: 20mm;">口座</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="payment in page.paymentList" :key="payment.id">
            <tr class="text-start">
              <td rowspan="3" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                <span v-if="payment.pay_or_carry_forward === 'pay'">支払</span>
                <span v-else>保留</span>
              </td>
              <td rowspan="3" style="padding: 2px 2px 2px 2px; border: 1px solid #333333; font-size: 8pt;">
                {{payment.rights_holder_name}}
              </td>
              <td colspan="2" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;">
                {{payment.rights_holder_code}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.amount_brought_forward.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.amount_in_this_term.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.total_amount.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.management_fee_amount.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.offset_target_amount_out_tax.toLocaleString()}}
              </td>
              <td rowspan="3" style="padding: 2px 2px 2px 2px; border: 1px solid #333333; font-size: 8pt;" class="text-end">
                {{payment.amount_carried_forward_out_tax.toLocaleString()}}
              </td>
              <td rowspan="3" style="padding: 2px 2px 2px 2px; border: 1px solid #333333; font-size: 8pt;" class="text-end">
                {{payment.payment_amount.toLocaleString()}}
              </td>
            </tr>
            <tr>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-start">
                <span v-if="payment.withholding_taxation === 'personal'">10%～20%</span>
                <span v-if="payment.withholding_taxation === 'none'">0%</span>
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-start">
                <span v-if="payment.taxation">課税</span>
                <span v-else>非課税</span>
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.offset_amount_out_tax.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.before_tax_amount.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.withholding_tax_amount.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                {{payment.tax_amount.toLocaleString()}}
              </td>
              <td style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-end">
                <span v-if="payment.pay_or_carry_forward === 'pay'">{{payment.payment_amount.toLocaleString()}}</span>
                <span v-else>{{payment.amount_carried_forward_out_tax.toLocaleString()}}</span>
              </td>
            </tr>
            <tr>
              <td colspan="7" style="padding: 2px 2px 2px 2px; border: 1px solid #333333;" class="text-start">
                <span v-if="payment.bank_domestic_or_oversees === 'DOMESTIC'">
                  <span v-if="payment.bank_name">{{payment.bank_name}} ({{payment.bank_code}})&nbsp;&nbsp;</span>
                  <span v-else>&nbsp;</span>
                  <span v-if="payment.branch_name">{{payment.branch_name}} ({{payment.branch_code}})&nbsp;&nbsp;</span>
                  <span v-else>&nbsp;</span>
                  <span v-if="payment.deposit_item === '1'">普通&nbsp;&nbsp;</span>
                  <span v-else-if="payment.deposit_item === '2'">当座&nbsp;&nbsp;</span>
                  <span v-else-if="payment.deposit_item === '4'">貯蓄&nbsp;&nbsp;</span>
                  <span v-else-if="payment.deposit_item === '9'">その他&nbsp;&nbsp;</span>
                  <span v-else>&nbsp;</span>
                  <span v-if="payment.account_no">{{payment.account_no}}&nbsp;&nbsp;</span>
                  <span v-else>&nbsp;</span>
                  <span v-if="payment.account_name">{{payment.account_name}} ({{payment.account_kana}})&nbsp;&nbsp;</span>
                  <span v-else>&nbsp;</span>
                </span>
                <span v-else>
                  {{payment.oversees_account}}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import auth from '@/functions/auth'
import axios from 'axios'
import exception from '@/functions/exception'

export default defineComponent({
  setup () {
    const route = useRoute()
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    // Form
    const pageList = ref([])
    const form = ref(null)
    const company = ref(null)
    const divTerm = ref(null)
    const divTermSetting = ref(null)
    const paymentResult = ref(null)
    async function init (companyDivTermId) {
      await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/divterm/' + companyDivTermId, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).then(async response => {
        divTerm.value = response.data
        await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/' + divTerm.value.company_id + '/divterm/' + divTerm.value.div_term + '/setting', {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          divTermSetting.value = response.data
          if (divTermSetting.value.start_month_year_type === 'current') {
            divTermSetting.value.start_year = divTerm.value.div_year
          } else {
            divTermSetting.value.start_year = divTerm.value.div_year - 1
          }
          if (divTermSetting.value.end_month_year_type === 'current') {
            divTermSetting.value.end_year = divTerm.value.div_year
          } else if (divTermSetting.value.end_month_year_type === 'previous') {
            divTermSetting.value.end_year = divTerm.value.div_year - 1
          } else {
            divTermSetting.value.end_year = divTerm.value.div_year + 1
          }
        })
        await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/company/' + divTerm.value.company_id, {
          headers: { Authorization: 'Bearer ' + getAccessToken() }
        }).then(response => {
          company.value = response.data
        })
      })
    }
    const find = async () => {
      let p = 0
      while (true) {
        const d = await getPage(p)
        if (!d || d.length === 0) {
          break
        }
        pageList.value.push({
          paymentList: d
        })
        p++
      }
      console.log(pageList.value)
    }
    async function getPage (p) {
      const params = {
        payment_status_id: sessionStorage.getItem('payment_statusId'),
        page: p,
        per_page: 10
      }
      if (sessionStorage.getItem('payment_searchString')) {
        params.search_string = sessionStorage.getItem('payment_searchString')
      }
      if (sessionStorage.getItem('payment_payOrCarryForward') !== 'ALL') {
        params.pay_or_carry_forward = sessionStorage.getItem('payment_payOrCarryForward')
      }
      const { data } = await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: params
      }).catch(error => { identifyEx(error) })
      return data.list
    }
    onBeforeMount(() => {
      const style = document.createElement('style')
      style.innerHTML = '@page { size: A4 landscape!important; margin: 0; }'
      document.head.appendChild(style)
    })
    onMounted(async () => {
      await init(route.params.id)
      await find()
    })
    return {
      route,
      pageList,
      form,
      company,
      divTerm,
      divTermSetting,
      paymentResult,
      find
    }
  }
})
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
@media print {
  body {
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', sans-serif!important;
    width: 297mm;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    line-height: 1.5em;
  }
  .no_print {
    display: none;
  }
}
.sheet {
  width: 297mm;
  height: 209.5mm;
  page-break-after: always;
  box-sizing: border-box;
  padding: 0;
  font-size: 10pt;
}
@media screen {
  body {
    background: #eee;
  }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}
</style>
