import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const royaltyTypeFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/royaltytype/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const find = async (companyId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/royaltytype/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const save = async (companyId, list) => {
    await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/royaltytype', list, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    })
  }
  return { get, find, save }
}
