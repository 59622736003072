<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/royaltytype/list"><i class="fas fa-guitars fa-fw" style="margin-right: 5px;"></i>
                      印税種別一覧
                    </router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
      <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 60px; overflow: auto;">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th>印税種別名</th>
              <th style="width: 116px;">略称</th>
              <th>印税種別英語名</th>
              <th style="width: 116px;">英語略称</th>
              <th style="width: 60px;"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(royaltytype, index) in royaltytypeList" :key="royaltytype.id" class="text-start" draggable="true"
              @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent @dragenter.prevent
              :class="{ 'deleted-row': royaltytype.deleted }">
              <td>
                <input v-model="royaltytype.name" type="text" class="form-control" :readonly="!!royaltytype.deleted">
              </td>
              <td>
                <input v-model="royaltytype.abbreviation" type="text" class="form-control" style="width: 100px;"
                  :readonly="!!royaltytype.deleted">
              </td>
              <td>
                <input v-model="royaltytype.nameEn" type="text" class="form-control" :readonly="!!royaltytype.deleted">
              </td>
              <td>
                <input v-model="royaltytype.abbreviationEn" type="text" class="form-control" style="width: 100px;"
                  :readonly="!!royaltytype.deleted">
              </td>
              <td>
                <button @click="royaltytype.deleted = !royaltytype.deleted" class="btn btn-danger"><i
                    class="fas fa-trash-alt"></i></button>
              </td>
              <td style="width: 20px; cursor: grab; vertical-align: middle;"><i class="fas fa-grip-vertical"></i></td>
            </tr>
            <tr>
              <td colspan="6" class="text-end">
                <button @click="addItem" class="btn btn-outline-primary"><i class="fas fa-plus"
                    style="margin-right: 5px;"></i>追加</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { royaltyTypeFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    // メソッド
    // 検索
    const royaltyTypeFunc = royaltyTypeFunction()
    const royaltytypeList = ref([])
    // メソッド
    const find = async () => {
      royaltytypeList.value = await royaltyTypeFunc.find(session.session.company.id)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = event.dataTransfer.getData('drag-index')
      const deleteList = royaltytypeList.value.splice(dragIndex, 1)
      royaltytypeList.value.splice(dropIndex, 0, deleteList[0])
    }
    const addItem = () => {
      royaltytypeList.value.push({
        name: '',
        name_en: '',
        abbreviation: '',
        abbreviation_en: ''
      })
    }
    const save = async () => {
      await royaltyTypeFunc.save(session.session.company.id, royaltytypeList.value)
      await router.push('/red/royaltytype/list')
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      royaltytypeList,
      find,
      dragList,
      dropList,
      addItem,
      save
    }
  }
})
</script>
