import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const accountFindFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async () => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/self/setting', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const existsEmail = async (email) => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/email/' + email + '/exists', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const findEmail = async () => {
    const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/self/setting/email/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const getInvoice = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/invoice', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findSuperior = async () => {
    const { data } = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/superior/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    for (const superior of data) {
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/account/' + superior.id + '/invoice', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        superior.invoice = response.data
      })
    }
    return data
  }
  return { get, findEmail, getInvoice, findSuperior, existsEmail }
}
