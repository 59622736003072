import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const masterFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        searchString: store.searchString,
        sortColumn: store.sortColumn,
        sortOrder: store.sortOrder,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findDetail = async (companyId, store) => {
    const params = {
      companyId: companyId,
      sortColumn1: store.detailCondition.sortColumn1,
      sortOrder1: store.detailCondition.sortOrder1,
      sortColumn2: store.detailCondition.sortColumn2,
      sortOrder2: store.detailCondition.sortOrder2,
      page: store.page,
      perPage: 20
    }
    if (store.detailCondition.searchString) {
      params.searchString = store.detailCondition.searchString
    }
    if (store.detailCondition.artistName) {
      params.artistName = store.detailCondition.artistName
    }
    if (store.detailCondition.code) {
      params.code = store.detailCondition.code
    }
    if (store.detailCondition.isrc) {
      params.isrc = store.detailCondition.isrc
    }
    if (store.detailCondition.userCode) {
      params.userCode = store.detailCondition.userCode
    }
    if (store.detailCondition.suspension !== 'all') {
      params.suspension = store.detailCondition.suspension
    }
    if (store.detailCondition.suspensionStatus !== 'all') {
      params.suspensionStatus = store.detailCondition.suspensionStatus
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/list/detail', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => { ex.identifyEx(error) })
    return data
  }
  const findForMatch = async (companyId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        searchString: store.searchString,
        sortColumn: 'name',
        sortOrder: 'asc',
        page: store.page,
        perPage: 30
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByArtist = async (artistId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/artist/' + artistId + '/master/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByProject = async (projectId, store) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/project/' + projectId + '/master/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: store.searchString,
        page: store.page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findByContract = async (contractId, searchString, page) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + contractId + '/master/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        searchString: searchString,
        page: page,
        perPage: 20
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const findContractCondition = (id, useTypeId, userSalesId, callback) => {
    axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id + '/contract/condition/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        useTypeId: useTypeId,
        userSalesId: userSalesId
      }
    }).then(response => {
      callback(response.data)
    })
  }
  const findSimilar = async (companyId, masterName, artistName) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/list/similar', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId,
        masterName: masterName,
        artistName: artistName
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.sortColumn = 'name'
    store.sortOrder = 'asc'
    store.page = 0
  }
  const detailClear = (store) => {
    store.detailCondition = {
      searchString: null,
      artistName: null,
      code: null,
      isrc: null,
      userCode: null,
      suspension: 'all',
      suspensionStatus: 'all',
      sortColumn1: 'name',
      sortOrder1: 'asc',
      sortColumn2: 'kana',
      sortOrder2: 'asc'
    }
    store.page = 0
  }
  const insert = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const remove = async (id) => {
    await axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/master/' + id + '/matching/clear', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  const updateIndex = (id) => {
    axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id + '/index/update', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(response => {})
  }
  const matching = (id, divTermId, callback) => {
    axios.post(process.env.VUE_APP_RED_REGISTER_ENDPOINT + '/master/' + id + '/matching', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        divTermId: divTermId
      }
    }).then(response => { callback() })
  }
  const create = async (companyId, form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/create', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        companyId: companyId
      }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const linkCode = async (id, codeType, code, userId) => {
    await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/master/' + id + '/link', {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: {
        codeType: codeType,
        code: code,
        userId: userId
      }
    })
  }
  const calculate = (id) => {
    console.log('calculate')
    axios.post(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/calculate/master/' + id, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).then(() => {})
  }
  const calculateAsync = async (id) => {
    await axios.post(process.env.VUE_APP_RED_CALCULATE_ENDPOINT + '/calculate/master/' + id, {}, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
  }
  return { get, find, findDetail, clear, detailClear, insert, update, remove, findByArtist, findByContract, findContractCondition, matching, create, findSimilar, linkCode, findForMatch, findByProject, updateIndex, calculate, calculateAsync }
}
