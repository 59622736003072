<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-calendar-lines-pen fa-fw" style="margin-right: 5px;"></i> 契約書一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/contract/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div v-if="!detailSearchMode" class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <span class="input-group-text">並び順</span>
            <select v-model="store.sortColumn" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 150px;">
              <option value="name">名前</option>
              <option value="code">コード</option>
              <option value="rightsHolderName">権利者</option>
              <option value="status">ステータス</option>
            </select>
            <select v-model="store.sortOrder" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
              <option value="asc">昇順（A → Z）</option>
              <option value="desc">降順（Z → A）</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="detailSearchMode = true" class="btn btn-outline-primary"><i class="fas fa-filter-list"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
          <div v-if="detailSearchMode" class="alert border-dark bg-white" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <label class="col-2 col-form-label">検索文字</label>
                  <div class="col-10">
                    <input v-model="store.detailCondition.searchString" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">ステータス</label>
                  <div class="col-10 col-xl-8">
                    <select class="form-select" v-model="store.detailCondition.contractStatus">
                      <option value="all">全て</option>
                      <option value="agree">契約中</option>
                      <option value="not_agree">未契約</option>
                      <option value="not_pay">全て自社取分</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">コード</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.code" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">権利者</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.rightsHolderName" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">権利者コード</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.rightsHolderCode" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">原盤</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.masterName" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">アルバム</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.albumName" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">アーティスト</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.artistName" @keydown.enter="store.page = 0; find();" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">並び順①</label>
                  <div class="col-10 col-xl-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn1" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="code">コード</option>
                        <option value="rightsHolderName">権利者</option>
                        <option value="status">ステータス</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder1" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">並び順②</label>
                  <div class="col-10 col-xl-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn2" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="code">コード</option>
                        <option value="rightsHolderName">権利者</option>
                        <option value="status">ステータス</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder2" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end" style="margin-top: 10px;">
                <button @click="store.page = 0; find();" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
                <button @click="clearSearchDetail" class="btn btn-outline-success" title="クリア"><i class="fas fa-undo" style="margin-right: 5px;"></i>クリア</button>
              </div>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="contractResult && contractResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 60px;"></th>
                <th style="width: 130px;">コード</th>
                <th style="width: 130px;">ステータス</th>
                <th>契約書名</th>
                <th>権利者</th>
              </tr>
            </thead>
            <tbody v-if="contractList">
              <tr v-for="contract in contractList" :key="contract.id" class="text-start">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/red/contract/disp/' + contract.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>{{ contract.code }}</td>
                <td>
                  <span v-if="contract.status === 'agree'" class="badge bg-success">契約中</span>
                  <span v-else-if="contract.status === 'not_agree'" class="badge bg-secondary">未契約（保留）</span>
                  <span v-else-if="contract.status === 'not_pay'" class="badge bg-primary">全て自社取分</span>
                </td>
                <td>{{ contract.name }}</td>
                <td>{{ contract.rightsHolderName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="contractResult && contractResult.allRecords > 0">{{ contractResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="contractResult && contractResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="contractResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ contractResult.allPages }}ページ</span></li>
                <li v-if="store.page !== contractResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== contractResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { contractStore } from './store'
import { contractFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const contractFind = contractFunction()
    // 検索
    const store = contractStore()
    const detailSearchMode = ref(false)
    const contractResult = ref(null)
    const contractList = ref([])
    // メソッド
    const find = async () => {
      if (detailSearchMode.value) {
        const result = await contractFind.findDetail(session.session.company.id, store)
        await renderResults(result)
      } else {
        const result = await contractFind.find(session.session.company.id, store)
        await renderResults(result)
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const renderResults = async (result) => {
      contractResult.value = result
      contractList.value = contractResult.value.list
    }
    const clearSearch = () => {
      contractFind.clear(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = contractResult.value.allPages - 1
      find()
    }
    const clearSearchDetail = () => {
      contractFind.detailClear(store)
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      contractResult,
      contractList,
      store,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      detailSearchMode,
      clearSearchDetail
    }
  }
})
</script>
