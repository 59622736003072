<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>rights.services <span style="color: #8EC31F;">GREEN</span> プライバシーポリシー</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 25px;">
      東京ライツサービス株式会社（以下、「当社」という。）は、オンライン明細サービス「rights.services GREEN」（以下、「本サービス」という。）におけるユーザーの個人情報の取り扱いについて、以下のとおりプライバシーポリシー（以下、「ポリシー」という。）を定める。
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第1条（個人情報）
    </div>
    <div class="row text-start">
      「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報および容貌、指紋、声紋にかかるデータ、および健康保険証の保険番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指す。
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第2条（個人情報の収集方法）
    </div>
    <div class="row text-start">
      当社は、ユーザーが本サービスを利用するために氏名、メールアドレスなどの個人情報を登録することを求めることがある。また、ユーザーと取引があり、かつ当社と個別契約を締結しているものより住所、電話番号、連絡先、印税支払額を収集することがある。
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第3条（個人情報を収集・利用する目的）
    </div>
    <div class="row text-start">
      当社が個人情報を収集・利用する目的は以下のとおり。
      <ol style="padding-left: 20px; margin-top: 0;">
        <li>本サービスの提供・運営のため</li>
        <li>ユーザーからの問い合わせに回答するため（本人確認を行うことを含む）</li>
        <li>本サービスの新機能、更新情報および当社が提供する他のサービスの案内メールを送付するため</li>
        <li>メンテナンス、重要なお知らせなど必要に応じた連絡のため</li>
        <li>利用規約に違反したユーザーや、不正・不当な目的で本サービスを利用しようとするユーザーの特定をし、利用を停止するため</li>
        <li>ユーザーが自身の登録情報の閲覧や変更、削除を行うため</li>
        <li>上記の利用目的に付随する目的</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第4条（利用目的の変更）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-top: 0;">
        <li>当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとする。</li>
        <li>利用目的の変更を行った場合には、変更後の目的について、当社所定の方法によりユーザーに通知するものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第5条（個人情報の第三者提供）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-bottom: 0;">
        <li>
          当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく第三者に個人情報を提供することはない。ただし、個人情報保護法その他の法令で認められる場合を除く。
          <ol style="padding-left: 20px; margin-top: 0;" type="a">
            <li>本サービスの利用目的遂行のためにユーザーと取引があり、かつ当社と個別契約を締結しているものに対してサービス利用目的の範囲内で開示するとき</li>
            <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
            <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
            <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            <li>
              予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
              <ol style="padding-left: 20px; margin-top: 0;" type="i">
                <li>利用目的に第三者への提供を含むこと</li>
                <li>第三者に提供されるデータの項目</li>
                <li>第三者への提供の手段または方法</li>
                <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                <li>本人の求めを受け付ける方法</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>前項の定めにかかわらず、次に掲げる場合には当該情報の提供先は第三者に該当しないものとする。
          <ol style="padding-left: 20px; margin-top: 0;" type="a">
            <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
            <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合</li>
          </ol>
        </li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第6条（個人情報の開示）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-top: 0;">
        <li>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示する。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知する。
          <ol style="margin-top: 0;" type="a">
            <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
            <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <li>その他法令に違反することとなる場合</li>
          </ol>
        </li>
        <li>前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示しない。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第7条（個人情報の訂正および削除）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-top: 0;">
        <li>ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」という。）を請求することができる。</li>
        <li>当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとする。</li>
        <li>当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知する。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第8条（個人情報の利用停止等）
    </div>
    <div class="row text-start">
      <ol style="padding-left: 20px; margin-top: 0;">
        <li>当社は、本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」という。）を求められた場合には、遅滞なく必要な調査を行う。</li>
        <li>前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく当該個人情報の利用停止等を行う。</li>
        <li>当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なくこれをユーザーに通知する。</li>
        <li>前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとする。</li>
      </ol>
    </div>
    <div class="row text-start fw-bold" style="margin-top: 15px;">
      第9条（プライバシーポリシーの変更）
    </div>
    <div class="row text-start">
      <ol style="margin-top: 0;">
        <li>本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく変更することができるものとする。</li>
        <li>当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとする。</li>
      </ol>
    </div>
    <div class="row justify-content-end">
      以上
    </div>
    <footer class="alert alert-warning" style="margin-top: 50px;">
      <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
        <img src="../../assets/logo_corp.png" style="max-width: 200px;">
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        東京ライツサービス株式会社
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="text-end" style="margin-left: 20px; font-size: 90%;">
        050-3138-2564
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="mailto:support@monstr2.rights.tokyo">support@monstr2.rights.tokyo</a>
      </div>
      <div class="text-end" style="margin-left: 8px; font-size: 90%;">
        <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
