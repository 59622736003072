import { useRouter } from 'vue-router'

export const exFunction = () => {
  const router = useRouter()
  const identifyEx = async (e) => {
    switch (e.response?.status) {
      case 401:
      case 403: {
        await router.push('/login')
        break
      }
      default: {
        console.error(e)
        // await router.push('/500')
      }
    }
  }
  return {
    identifyEx
  }
}

export default function () {
  const router = useRouter()
  const identifyEx = async (e) => {
    switch (e.response?.status) {
      case 401:
      case 403: {
        await router.push('/login')
        break
      }
      default: {
        console.error(e)
        // await router.push('/500')
      }
    }
  }
  return {
    identifyEx
  }
}
