<template>
  <div>
    <nav-component></nav-component>
    <div class="container-fluid">
      <div style="height: 50px;"></div>
      <div class="d-flex justify-content-start align-items-center fs-5" style="margin-top: 15px;">
        <div class="me-auto d-none d-lg-block">
          <i class="fas fa-mailbox fa-fw" style="margin-right: 5px;"></i> メッセージ送信
        </div>
        <div>
          <button @click="openExternalAdd" class="btn btn-outline-primary"><i class="fas fa-plus" style="margin-right: 5px;"></i>メッセージ新規追加</button>
        </div>
      </div>
      <div class="input-group" style="margin-top: 15px;">
        <input @keypress.enter="page = 0; find();" v-model="searchString" type="text" class="form-control" placeholder="検索条件">
        <button v-show="searchString" @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
        <button @click="page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
      </div>
      <table v-if="externalResult && externalResult.allRecords > 0" class="table table-responsive table-hover">
        <thead>
          <tr>
            <th>送付先</th>
            <th>タイトル</th>
          </tr>
        </thead>
        <tbody v-if="externalResult">
          <tr v-for="(external, index) in externalResult.list" :key="external.id" class="text-start">
            <td>{{external.destinationName}}</td>
            <td><a href="#" @click.prevent.stop="openExternalDisp(index)">{{external.title}}</a></td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-6 d-flex justify-content-start">
          <span v-if="externalResult && externalResult.allRecords > 0">{{externalResult.allRecords}}件の一致データ</span>
          <span v-else style="margin-top: 20px;">未送信メッセージは存在しません</span>
        </div>
        <div v-if="externalResult && externalResult.allRecords > 0" class="col-6 d-flex justify-content-end">
          <nav v-if="externalResult" aria-label="Page navigation">
            <ul class="pagination">
              <li v-if="page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
              <li v-if="page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
              <li class="page-item"><span class="page-link">{{page + 1}}/{{externalResult.allPages}}ページ</span></li>
              <li v-if="page !== externalResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
              <li v-if="page !== externalResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="externalDispModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メッセージの閲覧</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">送信日時</div>
            <div class="form-edit-line">
              <span v-if="sentAt">{{sentAt}}</span>
              <span v-else>未送信</span>
            </div>
            <div class="form-label align-bottom">送付先</div>
            <div class="form-edit-line">
              {{destinationName}}
            </div>
            <div class="form-label align-bottom">タイトル</div>
            <div class="form-edit-line">
              {{title}}
            </div>
            <div class="form-label align-bottom">メッセージ</div>
            <div class="form-edit-line">
              <span v-if="message" v-html="message.replaceAll('\n', '<br>')"></span>
            </div>
            <div v-if="blobList.length > 0" class="form-label align-bottom">添付ファイル</div>
            <div v-if="blobList.length > 0" class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="blob in blobList" :key="blob.blob">
                    <td>{{blob.fileName}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button v-if="!sentAt" @click="openExternalDelete" type="button" class="btn btn-outline-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>削除</button>
              <button v-if="!sentAt" @click="openExternalEdit" type="button" class="btn btn-outline-primary" style="margin-right: 15px;"><i class="fas fa-pencil" style="margin-right: 5px;"></i>編集</button>
              <button v-if="!sentAt" @click="send" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>送信する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="externalEditModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="id" class="modal-title">メッセージの変更</h5>
            <h5 v-else class="modal-title">メッセージの新規登録</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>送付先</div>
            <div class="form-edit-line input-group">
              <input v-model="destinationName" type="text" class="form-control" readonly>
              <button @click="openDestinationFindModal" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>タイトル</div>
            <div class="form-edit-line">
              <input v-model="title" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">メッセージ</div>
            <div class="form-edit-line">
              <textarea v-model="message" class="form-control" rows="8"></textarea>
            </div>
            <div class="form-label align-bottom">添付ファイル</div>
            <div class="form-edit-line">
              <button @click="openAddFileModal" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</button>
              <table class="table table-responsive table-hover">
                <tbody>
                  <tr v-for="(blob, index) in blobList" :key="blob.blob">
                    <td>{{blob.fileName}}</td>
                    <td style="width: 80px;" class="text-end">
                      <button @click="deleteFile(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="closeExternalEdit" v-if="id" type="button" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</button>
              <button @click="save" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="externalDeleteModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メッセージの削除</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger">以下のメッセージを削除しようとしています。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{title}}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="cancelExternalDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>削除をキャンセル</button>
            </div>
            <div>
              <button @click="deleteExternal" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="destinationFindModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">送付先の検索</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="destinationPage = 0; destinationFind();" v-model="destinationSearchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="destinationSearchString" @click="destinationClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="destinationPage = 0; destinationFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="destinationResult && destinationResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
              <tr>
                <th>名前</th>
                <th class="d-none d-md-block">メールアドレス</th>
              </tr>
              </thead>
              <tbody v-if="destinationList">
              <tr v-for="(destination, index) in destinationList" :key="destination.id" class="text-start">
                <td><a href="#" @click.prevent.stop="selectDestination(index)">{{destination.name}}</a></td>
                <td class="d-none d-md-table-cell">{{destination.email}}</td>
              </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="destinationResult && destinationResult.allRecords > 0">{{destinationResult.allRecords}}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="destinationResult && destinationResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="destinationResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="destinationPage !== 0" class="page-item"><button @click="destinationFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                    <li v-if="destinationPage !== 0" class="page-item"><button @click="destinationPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                    <li class="page-item"><span class="page-link">{{destinationPage + 1}}/{{destinationResult.allPages}}ページ</span></li>
                    <li v-if="destinationPage !== destinationResult.allPages - 1" class="page-item"><button @click="destinationNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                    <li v-if="destinationPage !== destinationResult.allPages - 1" class="page-item"><button @click="destinationLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="fileModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">添付ファイル追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">ファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="uploadFile" accept="*/*" ref="uploadFile">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeAddFileModal" type="button" class="btn btn-secondary"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="addFile" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>アプロードして追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import auth from '@/functions/auth'
import exception from '@/functions/exception'
import { Modal, Toast } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const { getAccessToken } = auth()
    const { identifyEx } = exception()
    const messages = ref([])
    const errors = ref([])
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    const externalEditModalDiv = ref(null)
    const externalDispModalDiv = ref(null)
    const externalDeleteModalDiv = ref(null)
    const destinationFindModalDiv = ref(null)
    const fileModalDiv = ref(null)
    let processModal = null
    let messageToast = null
    let errorToast = null
    let externalEditModal = null
    let externalDispModal = null
    let externalDeleteModal = null
    let destinationFindModal = null
    let fileModal = null
    // 検索
    const searchString = ref('')
    const page = ref(0)
    const externalResult = ref(null)
    // Form
    const id = ref(null)
    const destinationId = ref(null)
    const destinationName = ref('')
    const title = ref('')
    const message = ref('')
    const sentAt = ref('')
    const blobList = ref([])
    const uploadFile = ref(null)
    // 送付先検索
    const destinationSearchString = ref('')
    const destinationPage = ref(0)
    const destinationResult = ref(null)
    const destinationList = ref([])
    // メソッド
    const find = () => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: {
          searchString: searchString.value,
          page: page.value,
          perPage: 20
        }
      }).then(response => {
        externalResult.value = response.data
        for (const external of externalResult.value.list) {
          axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + external.destinationId, {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            external.destinationName = response.data.name
          })
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }).catch(error => { identifyEx(error) })
    }
    const clearSearch = () => {
      searchString.value = ''
      page.value = 0
      find()
    }
    const firstPage = () => {
      page.value = 0
      find()
    }
    const prevPage = () => {
      page.value = page.value - 1
      find()
    }
    const nextPage = () => {
      page.value = page.value + 1
      find()
    }
    const lastPage = () => {
      page.value = externalResult.value.allPages - 1
      find()
    }
    const openExternalAdd = () => {
      id.value = null
      destinationId.value = null
      destinationName.value = ''
      title.value = ''
      message.value = ''
      externalEditModal.show()
    }
    const openExternalDisp = (index) => {
      const list = externalResult.value.list
      id.value = list[index].id
      destinationId.value = list[index].destinationId
      destinationName.value = list[index].destinationName
      title.value = list[index].title
      message.value = list[index].message
      sentAt.value = list[index].sentAt
      blobList.value = list[index].blobList
      externalDispModal.show()
      externalEditModal.hide()
    }
    const openExternalEdit = () => {
      externalEditModal.show()
      externalDispModal.hide()
    }
    const closeExternalEdit = () => {
      externalDispModal.show()
      externalEditModal.hide()
    }
    const openExternalDelete = () => {
      externalDeleteModal.show()
      externalDispModal.hide()
    }
    const cancelExternalDelete = () => {
      externalDispModal.show()
      externalDeleteModal.hide()
    }
    const openDestinationFindModal = () => {
      destinationClearSearch()
      destinationFindModal.show()
      externalEditModal.hide()
    }
    const destinationFind = () => {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/list', {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: {
          searchString: destinationSearchString.value,
          page: destinationPage.value,
          perPage: 10
        }
      }).then(response => {
        destinationResult.value = response.data
        destinationList.value = destinationResult.value.list
        for (const destination of destinationResult.value.list) {
          axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + destination.accountId, {
            headers: { Authorization: 'Bearer ' + getAccessToken() }
          }).then(response => {
            destination.email = response.data.email
          }).catch(error => { identifyEx(error) })
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }).catch(error => { identifyEx(error) })
    }
    const destinationClearSearch = () => {
      destinationSearchString.value = ''
      destinationPage.value = 0
      destinationFind()
    }
    const destinationFirstPage = () => {
      destinationPage.value = 0
      destinationFind()
    }
    const destinationPrevPage = () => {
      destinationPage.value = destinationPage.value - 1
      destinationFind()
    }
    const destinationNextPage = () => {
      destinationPage.value = destinationPage.value + 1
      destinationFind()
    }
    const destinationLastPage = () => {
      destinationPage.value = destinationResult.value.allPages - 1
      destinationFind()
    }
    const selectDestination = (index) => {
      destinationId.value = destinationList.value[index].id
      destinationName.value = destinationList.value[index].name
      externalEditModal.show()
      destinationFindModal.hide()
    }
    const openAddFileModal = () => {
      uploadFile.value.value = null
      fileModal.show()
      externalEditModal.hide()
    }
    const closeAddFileModal = () => {
      externalEditModal.show()
      fileModal.hide()
    }
    const addFile = async () => {
      messages.value = []
      errors.value = []
      const file = uploadFile.value.files[0]
      if (file) {
        processMessage.value = 'ファイルのアップロード中です。しばらくお待ちください。'
        processModal.show()
        fileModal.hide()
        const blobRes = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/blob', {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
          params: { fileName: file.name }
        })
        const urlRes = await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/url/ul', {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
          params: { blobName: blobRes.data }
        })
        await axios.put(urlRes.data.toString(), file, {
          headers: { 'Content-Type': 'application/octet-stream' }
        }).catch(error => { identifyEx(error) })
        blobList.value.push({
          blobName: blobRes.data,
          fileName: file.name
        })
        processModal.hide()
        externalEditModal.show()
      } else {
        errors.value.push('ファイルを選択してください')
        errorToast.show()
      }
    }
    const deleteFile = (index) => {
      blobList.value.splice(index, 1)
    }
    const save = async () => {
      messages.value = []
      errors.value = []
      if (!destinationId.value) {
        errors.value.push('送付先を選択してください')
      }
      if (!title.value) {
        errors.value.push('タイトルを入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const form = {
          destinationId: destinationId.value,
          title: title.value,
          message: message.value,
          blobList: blobList.value
        }
        try {
          externalEditModal.hide()
          processMessage.value = '保存処理中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
          processModal.show()
          if (!id.value) {
            const response = await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext', form, {
              headers: { Authorization: 'Bearer ' + getAccessToken() }
            })
            id.value = response.data
          } else {
            await axios.put(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/' + id.value, form, {
              headers: { Authorization: 'Bearer ' + getAccessToken() }
            })
          }
          find()
          externalDispModal.show()
          processModal.hide()
          messages.value.push('保存されました')
          messageToast.show()
        } catch (error) {
          await identifyEx(error)
        }
      }
    }
    const deleteExternal = async () => {
      await axios.delete(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/' + id.value, {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      }).catch(error => { identifyEx(error) })
      find()
      externalDeleteModal.hide()
    }
    const send = async () => {
      externalDispModal.hide()
      processMessage.value = 'メッセージ送信中です。画面を閉じたり移動したりせずにそのままでお待ちください。'
      processModal.show()
      const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/contract', {
        headers: { Authorization: 'Bearer ' + getAccessToken() }
      })
      const companyName = response.data.name
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/ext/' + id.value + '/send', {}, {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
        params: { senderName: companyName }
      }).catch(error => { identifyEx(error) })
      find()
      processModal.hide()
      messages.value.push('送信されました')
      messageToast.show()
    }
    onMounted(() => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      externalEditModal = new Modal(externalEditModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      externalDispModal = new Modal(externalDispModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      externalDeleteModal = new Modal(externalDeleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      destinationFindModal = new Modal(destinationFindModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      fileModal = new Modal(fileModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      find()
    })
    return {
      messages,
      errors,
      processMessage,
      processModalDiv,
      messageToastDiv,
      errorToastDiv,
      externalEditModalDiv,
      externalDispModalDiv,
      externalDeleteModalDiv,
      destinationFindModalDiv,
      fileModalDiv,
      searchString,
      page,
      externalResult,
      id,
      destinationId,
      destinationName,
      title,
      message,
      sentAt,
      blobList,
      uploadFile,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      openExternalAdd,
      openExternalDisp,
      openExternalEdit,
      closeExternalEdit,
      openExternalDelete,
      cancelExternalDelete,
      openDestinationFindModal,
      destinationFind,
      destinationFirstPage,
      destinationPrevPage,
      destinationNextPage,
      destinationLastPage,
      destinationClearSearch,
      destinationSearchString,
      destinationPage,
      destinationResult,
      destinationList,
      save,
      deleteExternal,
      selectDestination,
      openAddFileModal,
      closeAddFileModal,
      addFile,
      deleteFile,
      send
    }
  }
})
</script>
