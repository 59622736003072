<template>
  <div>
    <footer-component v-if="session.session.contractId"></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-users fa-fw" style="margin-right: 5px;"></i> 受領グループ一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/green/organization/join" class="btn btn-outline-primary d-none d-lg-block" style="margin-right: 5px;"><i class="fas fa-link fa-fw" style="margin-right: 5px;"></i>参加</router-link>
              <router-link to="/green/organization/join" class="btn btn-outline-primary d-lg-none" style="margin-right: 5px;"><i class="fas fa-link fa-fw"></i></router-link>
              <router-link to="/green/organization/add" class="btn btn-outline-primary d-none d-lg-block"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
              <router-link to="/green/organization/add" class="btn btn-outline-primary d-lg-none"><i class="fas fa-plus fa-fw"></i></router-link>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 60px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="organizationList.length > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th>名前</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in organizationList" :key="project.id" class="text-start align-middle">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/green/organization/disp/' + project.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>{{ project.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="organizationList.length > 0">{{ organizationList.length }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { organizationFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const organizationFunc = organizationFunction()
    const organizationList = ref([])
    // メソッド
    const find = async () => {
      organizationList.value = await organizationFunc.find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      session,
      router,
      organizationList,
      find
    }
  }
})
</script>
