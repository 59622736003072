<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/usage/list"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績データ詳細一覧</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input v-model="store.divYear" @change="store.page = 0; find();" type="number" class="form-control" style="flex: 0 1 90px;" :disabled="detailSearchMode">
            <span class="input-group-text">年</span>
            <input v-model="store.divTerm" @change="store.page = 0; find();" type="number" class="form-control" style="flex: 0 1 60px;" :disabled="detailSearchMode">
            <span class="input-group-text">期</span>
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="dataResult && dataResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 85%; width: 3200px;">
            <thead>
              <tr>
                <th style="width: 200px; word-break: break-all;">利用者</th>
                <th style="width: 200px; word-break: break-all;">頒布形態</th>
                <th style="width: 300px; word-break: break-all;">ファイル名</th>
                <th style="width: 150px; word-break: break-all;">品番</th>
                <th style="width: 150px; word-break: break-all;">JAN</th>
                <th style="width: 150px; word-break: break-all; font-size: 95%;">利用者アルバムコード</th>
                <th style="width: 300px; word-break: break-all;">アルバム名</th>
                <th style="width: 150px; word-break: break-all;">ISRC</th>
                <th style="width: 150px; word-break: break-all;">原盤コード</th>
                <th style="width: 150px; word-break: break-all;">利用者原盤コード</th>
                <th style="width: 300px; word-break: break-all;">原盤名</th>
                <th style="width: 300px; word-break: break-all;">アーティスト名</th>
                <th style="width: 100px; word-break: break-all;">利用年月</th>
                <th style="width: 150px; word-break: break-all;">使用数</th>
                <th style="width: 150px; word-break: break-all;">税抜価格(円)</th>
                <th style="width: 150px; word-break: break-all;">税抜売上(円)</th>
                <th style="width: 150px; word-break: break-all;">税抜受領額(円)</th>
                <th style="width: 100px; word-break: break-all;">消費税</th>
              </tr>
            </thead>
            <tbody class="text-start">
              <tr v-for="data in dataList" :key="data.id" :class="{ 'selected-row': data.rowSelected }" @click="data.rowSelected = !data.rowSelected">
                <td style="word-break: break-all;">{{data.masterUserName}}</td>
                <td style="word-break: break-all;">{{data.masterUserSalesName}}</td>
                <td style="word-break: break-all;">{{data.fileName}}</td>
                <td style="word-break: break-all;">{{data.catalogNo}}</td>
                <td style="word-break: break-all;">{{data.janCode}}</td>
                <td style="word-break: break-all;">{{data.userAlbumCode}}</td>
                <td style="word-break: break-all;">{{data.masterAlbumName}}</td>
                <td style="word-break: break-all;">{{data.isrc}}</td>
                <td style="word-break: break-all;">{{data.masterCode}}</td>
                <td style="word-break: break-all;">{{data.userMasterCode}}</td>
                <td style="word-break: break-all;">{{data.masterMasterName}}</td>
                <td style="word-break: break-all;">{{data.mastgerArtistName}}</td>
                <td style="word-break: break-all;">{{data.usedYear}}年{{data.usedMonth}}月</td>
                <td style="word-break: break-all;" class="text-end">
                  <span v-if="data.usedNo">{{data.usedNo.toLocaleString()}}</span>
                  <span v-else>0</span>
                </td>
                <td style="word-break: break-all;" class="text-end">
                  <span v-if="data.convertedPriceOutTax">{{data.convertedPriceOutTax.toLocaleString()}}</span>
                  <span v-else>0</span>
                </td>
                <td style="word-break: break-all;" class="text-end">
                  <span v-if="data.convertedRevenueOutTax">{{data.convertedRevenueOutTax.toLocaleString()}}</span>
                  <span v-else>0</span>
                </td>
                <td style="word-break: break-all;" class="text-end">
                  <span v-if="data.convertedIncomeAmountOutTax">{{data.convertedIncomeAmountOutTax.toLocaleString()}}</span>
                  <span v-else>0</span>
                </td>
                <td style="word-break: break-all;" class="text-end">
                  <span v-if="data.taxRate">{{data.taxRate.toLocaleString()}}</span>
                  <span v-else>0</span>
                  %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="dataResult && dataResult.allRecords > 0">{{ dataResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="dataResult && dataResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="dataResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ dataResult.allPages }}ページ</span></li>
                <li v-if="store.page !== dataResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== dataResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { usageDetailStore } from './store'
import { usageDetailFunction } from './function'
import { redCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const companyFunc = redCompanyFunction()
    const usageFunc = usageDetailFunction()
    const store = usageDetailStore()
    const detailSearchMode = ref(false)
    const dataResult = ref(null)
    const dataList = ref([])
    // メソッド
    const find = async () => {
      const term = await companyFunc.getDivTerm(session.session.company.id, store.divYear, store.divTerm)
      dataResult.value = await usageFunc.find(term.id, store)
      dataList.value = dataResult.value.list
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = dataResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      const companyDivTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      store.divYear = companyDivTerm.divYear
      store.divTerm = companyDivTerm.divTerm
      await find()
    })
    return {
      router,
      route,
      store,
      dataResult,
      dataList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      detailSearchMode
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
