import { defineStore } from 'pinia'
import { ref } from 'vue'

export const rightsHolderStore = defineStore('rights_holder', () => {
  const searchString = ref('')
  const notMember = ref(false)
  const page = ref(0)
  const detailSearchMode = ref(false)
  const detailCondition = ref({
    searchString: null,
    taxationType: 'all',
    invoiceNo: null,
    payOrCarryForward: 'all',
    carryForwardStatus: 'all',
    statementSendType: 'all',
    sortColumn1: 'name',
    sortOrder1: 'asc',
    sortColumn2: 'kana',
    sortOrder2: 'asc'
  })
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, notMember, page, sortColumn, sortOrder, detailCondition, detailSearchMode }
})
export const contractStore = defineStore('rights_contract', () => {
  const searchString = ref('')
  return { searchString }
})
export const addresseeStore = defineStore('rights_addressee', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
export const payeeStore = defineStore('rights_payee', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
export const bankStore = defineStore('rights_bank', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
export const memberStore = defineStore('rights_member', () => {
  const searchString = ref('')
  const notMember = ref(true)
  const page = ref(0)
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  return { searchString, notMember, page, sortColumn, sortOrder }
})
export const destinationStore = defineStore('rights_destination', () => {
  const searchString = ref('')
  const page = ref(0)
  return { searchString, page }
})
