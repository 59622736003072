<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-user-music fa-fw" style="margin-right: 5px;"></i> アーティスト一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/common/artist/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keypress.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <button v-show="store.searchString" @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="artistResult && artistResult.allRecords > 0" class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th>名前</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="artistList">
              <tr v-for="artist in artistList" :key="artist.id" class="text-start align-middle">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/common/artist/disp/' + artist.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>{{ artist.name }}</td>
                <td class="text-end">
                  <a v-if="artist.officialSiteUrl" :href="artist.officialSiteUrl" class="btn btn-sm btn-light" target="_blank"><i class="fas fa-globe fa-fw"></i></a>
                  <a v-if="artist.twitterId" :href="'https://twitter.com/' + artist.twitterId" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-twitter fa-fw"></i></a>
                  <a v-if="artist.facebookUrl" :href="artist.facebookUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-facebook fa-fw"></i></a>
                  <a v-if="artist.instagramId" :href="'https://instagram.com/' + artist.instagramId" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-instagram fa-fw"></i></a>
                  <a v-if="artist.tiktokId" :href="'https://tiktok.com/@' + artist.tiktokId" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-tiktok fa-fw"></i></a>
                  <a v-if="artist.soundcloudId" :href="'https://soundcloud.com/' + artist.soundcloudId" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-soundcloud fa-fw"></i></a>
                  <a v-if="artist.youtubeUrl" :href="artist.youtubeUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-youtube fa-fw"></i></a>
                  <a v-if="artist.appleMusicUrl" :href="artist.appleMusicUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-apple fa-fw"></i></a>
                  <a v-if="artist.spotifyUrl" :href="artist.spotifyUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-spotify fa-fw"></i></a>
                  <a v-if="artist.youtubeMusicUrl" :href="artist.youtubeMusicUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-youtube-square fa-fw"></i></a>
                  <a v-if="artist.amazonMusicUrl" :href="artist.amazonMusicUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-amazon fa-fw"></i></a>
                  <a v-if="artist.lineMusicUrl" :href="artist.lineMusicUrl" class="btn btn-sm btn-light" target="_blank" style="margin-left: 5px;"><i class="fa-brands fa-line fa-fw"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="artistResult && artistResult.allRecords > 0">{{ artistResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="artistResult && artistResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="artistResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ artistResult.allPages }}ページ</span></li>
                <li v-if="store.page !== artistResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== artistResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { artistFunction } from './function'
import { artistStore } from './store'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    // 検索
    const artistFunc = artistFunction()
    const store = artistStore()
    const artistResult = ref(null)
    const artistList = ref([])
    // メソッド
    const find = async () => {
      artistResult.value = await artistFunc.find(store)
      artistList.value = artistResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const clearSearch = () => {
      artistFunc.clearSearch(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = artistResult.value.allPages - 1
      find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      store,
      artistResult,
      artistList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage
    }
  }
})
</script>
