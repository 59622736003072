<template>
  <div class="container">
    <div class="row" style="margin-top: 20px;">
      <div class="d-flex justify-content-center">
        <img src="../../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row" style="margin-top: 40px;">
      <h4><b>明細を一括でダウンロードする</b></h4>
    </div>
    <div class="row text-start" style="margin-top: 20px;">
      <p>受領明細画面から「PDF一括保存」「テキスト一括保存」（権利者が2名以上の場合のみ表示）の各種ボタンをクリック／タップすることで、各種ファイルを一括でダウンロードすることができます。</p>
      <p>以下、詳細な説明です。</p>
      <ol>
        <li style="margin-top: 20px;">
          <router-link to="/help/receive">2-2</router-link>の手順に従い、受領明細画面を表示します。
        </li>
        <li style="margin-top: 20px;">PDF一括保存（権利者が2名以上の場合のみ表示）
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              受領明細画面の「PDF一括保存」ボタンをクリック／タップで明細のPDFファイルを複数権利者分ZIPファイルにまとめてダウンロードできます。<br>
              <img src="../../assets/help/header_09.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
        <li style="margin-top: 20px;">テキスト一括保存（権利者が2名以上の場合のみ表示）
          <ol style="margin-top: 10px;" type="a">
            <li style="margin-top: 10px;">
              受領明細画面の「テキスト一括保存」ボタンをクリック／タップで明細のテキストデータを複数権利者分ZIPファイルにまとめてダウンロードできます。<br>
              <img src="../../assets/help/header_10.png" class="responsive-img" style="border: 1px solid #cccccc;">
            </li>
          </ol>
        </li>
      </ol>
      <p style="margin-top: 50px;"><label>※説明に使用したスクリーンショットは2021年7月30日時点のものです。実際の画面とは若干異なる可能性がありますことご了承ください。</label></p>
    </div>
    <div class="row justify-content-start d-grid" style="margin-top: 20px; margin-bottom: 50px;">
      <button @click="$router.go(-1)" class="btn btn-success">
        <i class="fas fa-arrow-left" style="margin-right: 5px;"></i>戻る
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    return {}
  }
})
</script>
